import axios from "../config/axios";

export const identifiedDataSource = [
  "All",
  "Identified Today",
  "Identified Yesterday",
  "Identified This Week",
  "Identified Last Week",
  "Identified This Month",
  "Identified Last Month",
  "Identified This Quarter",
  "Identified Last Quarter",
  "Identified This Year",
  "Identified This Year Q1",
  "Identified This Year Q2",
  "Identified This Year Q3",
  "Identified This Year Q4",
  "Identified Last Year",
  "Identified Last Year Q1",
  "Identified Last Year Q2",
  "Identified Last Year Q3",
  "Identified Last Year Q4",
  "Identified 2 Years Ago",
  "Identified 3 Years Ago",
  "Identified 4 Years Ago",
  "Identified 5 Years Ago",
  "Identified Before 5 Years Ago",
  "Identified in Last 2 Years",
  "Identified in Last 3 Years",
  "Identified in Last 4 Years",
  "Identified in Last 5 Years",
];

export const FundedDataSource = [
  "All",
  "Funded Today",
  "Funded Yesterday",
  "Funded This Week",
  "Funded Last Week",
  "Funded This Month",
  "Funded Last Month",
  "Funded This Quarter",
  "Funded Last Quarter",
  "Funded This Year",
  "Funded This Year Q1",
  "Funded This Year Q2",
  "Funded This Year Q3",
  "Funded This Year Q4",
  "Funded Last Year",
  "Funded Last Year Q1",
  "Funded Last Year Q2",
  "Funded Last Year Q3",
  "Funded Last Year Q4",
  "Funded 2 Years Ago",
  "Funded 3 Years Ago",
  "Funded 4 Years Ago",
  "Funded 5 Years Ago",
  "Funded Before 5 Years Ago",
  "Funded in Last 2 Years",
  "Funded in Last 3 Years",
  "Funded in Last 4 Years",
  "Funded in Last 5 Years",
];

export const getIdentificationDateFilteredData = (data, filter, dates) => {
  return data?.filter((item) => {
    const identificationDate = new Date(item.IdentificationDate);

    if (filter === "Identified Today") {
      return identificationDate === dates.today;
    } else if (filter === "Identified Tomorrow") {
      return identificationDate === dates.tomorrow;
    } else if (filter === "Identified Yesterday") {
      return identificationDate === dates.yesterday;
    } else if (filter === "Identified This Week") {
      return (
        identificationDate >= dates.startOfWeek &&
        identificationDate <= dates.endOfWeek
      );
    } else if (filter === "Identified Next Week") {
      return (
        identificationDate >= dates.startOfNextWeek &&
        identificationDate <= dates.endOfNextWeek
      );
    } else if (filter === "Identified Last Week") {
      return (
        identificationDate >= dates.startOfLastWeek &&
        identificationDate <= dates.endOfLastWeek
      );
    } else if (filter === "Identified This Month") {
      return (
        identificationDate >= dates.startOfMonth &&
        identificationDate <= dates.endOfMonth
      );
    } else if (filter === "Identified Next Month") {
      return (
        identificationDate >= dates.startOfNextMonth &&
        identificationDate <= dates.endOfNextMonth
      );
    } else if (filter === "Identified Last Month") {
      return (
        identificationDate >= dates.startOfLastMonth &&
        identificationDate <= dates.endOfLastMonth
      );
    } else if (filter === "Identified This Quarter") {
      return (
        identificationDate >= dates.startOfQuarter &&
        identificationDate <= dates.endOfQuarter
      );
    } else if (filter === "Identified Next Quarter") {
      return (
        identificationDate >= dates.startOfNextQuarter &&
        identificationDate <= dates.endOfNextQuarter
      );
    } else if (filter === "Identified Last Quarter") {
      return (
        identificationDate >= dates.startOfLastQuarter &&
        identificationDate <= dates.endOfLastQuarter
      );
    } else if (filter === "Identified This Year") {
      return (
        identificationDate >= dates.startOfYear &&
        identificationDate <= dates.endOfYear
      );
    } else if (filter === "Identified Next Year") {
      return (
        identificationDate >= dates.startOfNextYear &&
        identificationDate <= dates.endOfNextYear
      );
    } else if (filter === "Identified Last Year") {
      return (
        identificationDate >= dates.startOfLastYear &&
        identificationDate <= dates.endOfLastYear
      );
    } else if (filter === "Identified This Year Q1") {
      return (
        identificationDate >= dates.startOfQ1 &&
        identificationDate <= dates.endOfQ1
      );
    } else if (filter === "Identified Next Year Q1") {
      return (
        identificationDate >= dates.startOfNextQ1 &&
        identificationDate <= dates.endOfNextQ1
      );
    } else if (filter === "Identified Last Year Q1") {
      return (
        identificationDate >= dates.startOfLastQ1 &&
        identificationDate <= dates.endOfLastQ1
      );
    } else if (filter === "Identified This Year Q2") {
      return (
        identificationDate >= dates.startOfQ2 &&
        identificationDate <= dates.endOfQ2
      );
    } else if (filter === "Identified Next Year Q2") {
      return (
        identificationDate >= dates.startOfNextQ2 &&
        identificationDate <= dates.endOfNextQ2
      );
    } else if (filter === "Identified Last Year Q2") {
      return (
        identificationDate >= dates.startOfLastQ2 &&
        identificationDate <= dates.endOfLastQ2
      );
    } else if (filter === "Identified This Year Q3") {
      return (
        identificationDate >= dates.startOfQ3 &&
        identificationDate <= dates.endOfQ3
      );
    } else if (filter === "Identified Next Year Q3") {
      return (
        identificationDate >= dates.startOfNextQ3 &&
        identificationDate <= dates.endOfNextQ3
      );
    } else if (filter === "Identified Last Year Q3") {
      return (
        identificationDate >= dates.startOfLastQ3 &&
        identificationDate <= dates.endOfLastQ3
      );
    } else if (filter === "Identified This Year Q4") {
      return (
        identificationDate >= dates.startOfQ4 &&
        identificationDate <= dates.endOfQ4
      );
    } else if (filter === "Identified Next Year Q4") {
      return (
        identificationDate >= dates.startOfNextQ4 &&
        identificationDate <= dates.endOfNextQ4
      );
    } else if (filter === "Identified Last Year Q4") {
      return (
        identificationDate >= dates.startOfLastQ4 &&
        identificationDate <= dates.endOfLastQ4
      );
    } else if (filter === "Identified After Next Year") {
      return identificationDate > dates.endOfNextYearQ4;
    } else if (filter === "Identified Before Last Year") {
      return identificationDate < dates.startOfLastYearQ4;
    } else {
      // Return all Data if filter is not matched
      return true;
    }
  });
};

export const getFundingDateFilteredData = (data, filter, dates) => {
  return data?.filter((item) => {
    const fundingDate = new Date(item.FundingDate);

    if (filter === "Funded Today") {
      return fundingDate === dates.today;
    } else if (filter === "Funded Tomorrow") {
      return fundingDate === dates.tomorrow;
    } else if (filter === "Funded Yesterday") {
      return fundingDate === dates.yesterday;
    } else if (filter === "Funded This Week") {
      return fundingDate >= dates.startOfWeek && fundingDate <= dates.endOfWeek;
    } else if (filter === "Funded Next Week") {
      return (
        fundingDate >= dates.startOfNextWeek &&
        fundingDate <= dates.endOfNextWeek
      );
    } else if (filter === "Funded Last Week") {
      return (
        fundingDate >= dates.startOfLastWeek &&
        fundingDate <= dates.endOfLastWeek
      );
    } else if (filter === "Funded This Month") {
      return (
        fundingDate >= dates.startOfMonth && fundingDate <= dates.endOfMonth
      );
    } else if (filter === "Funded Next Month") {
      return (
        fundingDate >= dates.startOfNextMonth &&
        fundingDate <= dates.endOfNextMonth
      );
    } else if (filter === "Funded Last Month") {
      return (
        fundingDate >= dates.startOfLastMonth &&
        fundingDate <= dates.endOfLastMonth
      );
    } else if (filter === "Funded This Quarter") {
      return (
        fundingDate >= dates.startOfQuarter && fundingDate <= dates.endOfQuarter
      );
    } else if (filter === "Funded Next Quarter") {
      return (
        fundingDate >= dates.startOfNextQuarter &&
        fundingDate <= dates.endOfNextQuarter
      );
    } else if (filter === "Funded Last Quarter") {
      return (
        fundingDate >= dates.startOfLastQuarter &&
        fundingDate <= dates.endOfLastQuarter
      );
    } else if (filter === "Funded This Year") {
      return fundingDate >= dates.startOfYear && fundingDate <= dates.endOfYear;
    } else if (filter === "Funded Next Year") {
      return (
        fundingDate >= dates.startOfNextYear &&
        fundingDate <= dates.endOfNextYear
      );
    } else if (filter === "Funded Last Year") {
      return (
        fundingDate >= dates.startOfLastYear &&
        fundingDate <= dates.endOfLastYear
      );
    } else if (filter === "Funded This Year Q1") {
      return fundingDate >= dates.startOfQ1 && fundingDate <= dates.endOfQ1;
    } else if (filter === "Funded Next Year Q1") {
      return (
        fundingDate >= dates.startOfNextQ1 && fundingDate <= dates.endOfNextQ1
      );
    } else if (filter === "Funded Last Year Q1") {
      return (
        fundingDate >= dates.startOfLastQ1 && fundingDate <= dates.endOfLastQ1
      );
    } else if (filter === "Funded This Year Q2") {
      return fundingDate >= dates.startOfQ2 && fundingDate <= dates.endOfQ2;
    } else if (filter === "Funded Next Year Q2") {
      return (
        fundingDate >= dates.startOfNextQ2 && fundingDate <= dates.endOfNextQ2
      );
    } else if (filter === "Funded Last Year Q2") {
      return (
        fundingDate >= dates.startOfLastQ2 && fundingDate <= dates.endOfLastQ2
      );
    } else if (filter === "Funded This Year Q3") {
      return fundingDate >= dates.startOfQ3 && fundingDate <= dates.endOfQ3;
    } else if (filter === "Funded Next Year Q3") {
      return (
        fundingDate >= dates.startOfNextQ3 && fundingDate <= dates.endOfNextQ3
      );
    } else if (filter === "Funded Last Year Q3") {
      return (
        fundingDate >= dates.startOfLastQ3 && fundingDate <= dates.endOfLastQ3
      );
    } else if (filter === "Funded This Year Q4") {
      return fundingDate >= dates.startOfQ4 && fundingDate <= dates.endOfQ4;
    } else if (filter === "Funded Next Year Q4") {
      return (
        fundingDate >= dates.startOfNextQ4 && fundingDate <= dates.endOfNextQ4
      );
    } else if (filter === "Funded Last Year Q4") {
      return (
        fundingDate >= dates.startOfLastQ4 && fundingDate <= dates.endOfLastQ4
      );
    } else if (filter === "Funded After Next Year") {
      return fundingDate > dates.endOfNextYearQ4;
    } else if (filter === "Funded Before Last Year") {
      return fundingDate < dates.startOfLastYearQ4;
    } else {
      // Return all Data if filter is not matched
      return true;
    }
  });
};

export function getNonCompliantProjects(projects, beneficiaries) {
  return projects?.filter((project) => {
    const projectNum = project.ProjectNum;
    const projectBeneficiaries = beneficiaries?.filter((beneficiary) => {
      return (
        beneficiary.ProjNum === projectNum && beneficiary.BenStatus === "Active"
      );
    });
    const beneficiaryCount = Number(projectBeneficiaries?.length || 0);
    return (
      project.ProjectStatus !== "Deleted" &&
      (beneficiaryCount < 5 ||
        project.AccountNum === "" ||
        project.ActiveBankAccount === "" ||
        project.ActiveBankAccount === "No" ||
        project.RegNum.length !== 14 ||
        !project.RegNum.endsWith("/24"))
    );
  });
}

export function getCompliantProjects(projects, beneficiaries) {
  const nonCompliantProjects = getNonCompliantProjects(projects, beneficiaries);
  return projects.filter((project) => !nonCompliantProjects?.includes(project));
}

export function getCipcNonCompliantProjects(projects) {
  return projects?.filter((project) => {
    return (
      project.Status !== "Deleted" &&
      (project.RegNum.length !== 14 || !project.RegNum.endsWith("/24"))
    );
  });
}

export function getCipcCompliantProjects(projects) {
  const nonCompliantProjects = getCipcNonCompliantProjects(projects);
  return projects.filter((project) => !nonCompliantProjects?.includes(project));
}

export function getProjectsWithInsufficientBeneficiaries(
  projects,
  beneficiaries
) {
  return projects?.filter((project) => {
    const projectNum = project.ProjectNum;
    const projectBeneficiaries = beneficiaries?.filter((beneficiary) => {
      return (
        beneficiary.ProjNum === projectNum && beneficiary.BenStatus === "Active"
      );
    });
    const beneficiaryCount = projectBeneficiaries?.length;
    return project.Status !== "Deleted" && beneficiaryCount < 5;
  });
}

export function getProjectsWithSufficientDeneficiaries(
  projects,
  beneficiaries
) {
  const projectsWithInsufficientBeneficiaries =
    getProjectsWithInsufficientBeneficiaries(projects, beneficiaries);
  return projects.filter(
    (project) => !projectsWithInsufficientBeneficiaries?.includes(project)
  );
}

export function getProjectsWithNoBankAccount(projects) {
  return projects?.filter((project) => {
    return (
      project.Status !== "Deleted" &&
      (project.AccountNum === "" ||
        project.ActiveBankAccount === "" ||
        project.ActiveBankAccount === "No")
    );
  });
}

export function getProjectsWithBankAccount(projects) {
  const projectsWithNoBankAccount = getProjectsWithNoBankAccount(projects);
  return projects.filter(
    (project) => !projectsWithNoBankAccount.includes(project)
  );
}

export const getUpdateProjectGridData = (
  updatedProjectData,
  beneficiaryData,
  provinceFilter,
  regionFilter,
  statusFilter,
  sectorFilter,
  subSectorFilter,
  stageFilter,
  phaseFilter,
  noCompliantFilter,
  bankAccountFilter,
  cipcCompliantFilter,
  beneficiaryFilter,
  identifiedDateFilter,
  fundedDateFilter,
  projectFilter,
  dates
) => {
  let provinceFilteredData;
  if (provinceFilter !== "All") {
    provinceFilteredData = updatedProjectData?.filter(
      (item) => item.Province === provinceFilter
    );
  } else {
    provinceFilteredData = updatedProjectData;
  }
  let regionFilteredData;
  if (regionFilter !== "All") {
    regionFilteredData = provinceFilteredData?.filter(
      (item) => item.Region === regionFilter
    );
  } else {
    regionFilteredData = provinceFilteredData;
  }

  let statusFilteredData;
  if (statusFilter !== "All") {
    statusFilteredData = regionFilteredData?.filter(
      (item) => item.ProjectStatus === statusFilter
    );
  } else {
    statusFilteredData = regionFilteredData;
  }

  let sectorFilteredData;
  if (sectorFilter !== "All") {
    sectorFilteredData = statusFilteredData?.filter(
      (item) => item.Sector === sectorFilter
    );
  } else {
    sectorFilteredData = statusFilteredData;
  }

  let subSectorFilteredData;
  if (subSectorFilter !== "All") {
    subSectorFilteredData = sectorFilteredData?.filter(
      (item) => item.SubSector === subSectorFilter
    );
  } else {
    subSectorFilteredData = sectorFilteredData;
  }

  let phaseFilteredData;
  if (phaseFilter !== "All") {
    phaseFilteredData = subSectorFilteredData?.filter(
      (item) => item.Phase === phaseFilter
    );
  } else {
    phaseFilteredData = subSectorFilteredData;
  }

  let stageFilteredData;
  if (stageFilter !== "All") {
    stageFilteredData = phaseFilteredData?.filter(
      (item) => item.Stage === stageFilter
    );
  } else {
    stageFilteredData = phaseFilteredData;
  }

  let nonCompliantFilteredData;
  if (noCompliantFilter === "Compliant") {
    nonCompliantFilteredData = getCompliantProjects(
      stageFilteredData,
      beneficiaryData
    );
  } else if (noCompliantFilter === "Non-Compliant") {
    nonCompliantFilteredData = getNonCompliantProjects(
      stageFilteredData,
      beneficiaryData
    );
  } else {
    nonCompliantFilteredData = stageFilteredData;
  }

  let beneficiaryFilteredData;
  if (beneficiaryFilter === "Sufficiant Beneficiaries") {
    beneficiaryFilteredData = getProjectsWithSufficientDeneficiaries(
      nonCompliantFilteredData,
      beneficiaryData
    );
  } else if (beneficiaryFilter === "Insufficiant Beneficiaries") {
    beneficiaryFilteredData = getProjectsWithInsufficientBeneficiaries(
      nonCompliantFilteredData,
      beneficiaryData
    );
  } else {
    beneficiaryFilteredData = nonCompliantFilteredData;
  }

  let bankFilteredData;
  if (bankAccountFilter === "With Bank Account") {
    bankFilteredData = getProjectsWithBankAccount(beneficiaryFilteredData);
  } else if (bankAccountFilter === "No Bank Account") {
    bankFilteredData = getProjectsWithNoBankAccount(beneficiaryFilteredData);
  } else {
    bankFilteredData = beneficiaryFilteredData;
  }

  let cipcFilteredData;
  if (cipcCompliantFilter === "CIPC Compliant") {
    cipcFilteredData = getCipcCompliantProjects(bankFilteredData);
  } else if (cipcCompliantFilter === "CIPC Non-Compliant") {
    cipcFilteredData = getCipcNonCompliantProjects(bankFilteredData);
  } else {
    cipcFilteredData = bankFilteredData;
  }

  const identificationDateFilteredData = getIdentificationDateFilteredData(
    cipcFilteredData,
    identifiedDateFilter,
    dates
  );
  const fundedDateFilteredData = getFundingDateFilteredData(
    identificationDateFilteredData,
    fundedDateFilter,
    dates
  );

  let projectFilteredData;
  if (projectFilter !== "All") {
    projectFilteredData = fundedDateFilteredData?.filter(
      (item) => item.ProjectName === projectFilter
    );
  } else {
    projectFilteredData = fundedDateFilteredData;
  }

  return projectFilteredData;
};

export const getUpdateProjectData = (projectData, data) => {
  const uniqueProjectIDs = [
    ...new Set(projectData?.map((item) => (item.id ? item.id : ""))),
  ];
  const updateProjectData = data.filter((project) =>
    uniqueProjectIDs.includes(project.ProjectID)
  );
  return updateProjectData;
};

export const getUpdateBeneficiaryData = (
  projectData,
  beneficiaryData,
  data
) => {
  const uniqueProjectNums = [
    ...new Set(
      projectData?.map((item) => (item.ProjectNum ? item.ProjectNum : ""))
    ),
  ];
  const filteredBeneficiaryData = beneficiaryData.filter((beneficiary) =>
    uniqueProjectNums.includes(beneficiary.ProjNum)
  );
  const uniqueBeneficiaryIDs = [
    ...new Set(
      filteredBeneficiaryData?.map((item) => (item.id ? item.id : ""))
    ),
  ];
  const updateBeneficiaryData = data.filter((beneficiary) =>
    uniqueBeneficiaryIDs.includes(beneficiary.BeneficiaryID)
  );
  return updateBeneficiaryData;
};

export const CheckNameDuplicates = (
  projectData,
  newProjectName,
  selectedID
) => {
  let isDuplicates = false;
  if (selectedID === "") {
    const updateProjectData = projectData?.filter(
      (project) => project.ProjectName === newProjectName
    );
    isDuplicates = updateProjectData.length > 0;
    console.log("isDuplicates-new", isDuplicates);
  } else {
    const updateProjectData = projectData?.filter(
      (project) =>
        project.ProjectName === newProjectName && project.id !== selectedID
    );
    isDuplicates = updateProjectData.length > 0;
    console.log("isDuplicates-old", isDuplicates);
  }
  return isDuplicates;
};
