import React from 'react';
import ToDoProjects from './capacity/ToDoProjects';
import MyProjects from './capacity/MyProjects';
import AllProjects from './capacity/AllProjects';
import ToDoTasks from './capacity/ToDoTasks';
import ToDoAttachments from './capacity/ToDoAttachments';
import ToDoBeneficiaries from './capacity/ToDoBeneficiaries';
import '../../../shared/PageTabs.css';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';


function CapacityBuilding() {
  let headerText = [{ text: "ToDo List - Projects" }, {text: "ToDo List - Beneficiaries" }, {text: "ToDo List - Tasks" }, {text: "ToDo List - Attachments" }, { text: "My Projects" }, { text: "All Projects" }];
  

  return (
    <div className='main-tab-container'>
      <TabComponent >
        <TabItemsDirective >
          <TabItemDirective header={headerText[0]} content= {ToDoProjects} />
          <TabItemDirective header={headerText[1]} content={ToDoBeneficiaries} />
          <TabItemDirective header={headerText[2]} content={ToDoTasks} />
          <TabItemDirective header={headerText[3]} content={ToDoAttachments} />
          <TabItemDirective header={headerText[4]} content={MyProjects} />
          <TabItemDirective header={headerText[5]} content={AllProjects} />
        </TabItemsDirective>
      </TabComponent>
    </div>
    
  )
}

export default CapacityBuilding