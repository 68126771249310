import React, { useEffect, useState, useRef } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  Resize,
  Reorder,
  PdfExport,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { getValue } from "@syncfusion/ej2-base";
import axios from "../../../../config/axios";
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import "../../projects/children/ListView.css";
import useOtherDataFetch from "../../../shared/useOtherDataFetch";
import useProjectDataFetch from "../../../shared/useProjectDataFetch";
import useDateCalculator from "../../../shared/useDateCalculator";
import useUserStore from "../../../../app/user";
import UploadIcon from "@mui/icons-material/Upload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import {
  FundedDataSource,
  getUpdateProjectGridData,
  identifiedDataSource,
} from "../../../../utils/project";
import { dateFormatter } from "../../../../utils/formats";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import ForumIcon from "@mui/icons-material/Forum";
import MessageBox from "../../messages/MessageBox";
import useUserDataFetch from "../../../shared/useUserDataFetch";
import {
  getCommonUsers,
  getPaymentChatUsers,
  getProjectName,
  getUserDeleteRight,
  getUserLocation,
} from "../../../../utils";
import useUserChatsDataFetch from "../../../shared/useUserChatsDataFetch";
import socket from "../../../../utils/socket";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "90%",
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 2,
};

function FinLists() {
  const user = useUserStore((state) => state.user);
  const dates = useDateCalculator();
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { paymentData, beneficiaryData, isBeneficiaryLoaded, isPaymentLoaded } =
    useOtherDataFetch();
  const { allUserData, isAllUserLoaded } = useUserDataFetch([]);
  const [commonUserData, setCommonUserData] = useState();
  const [isMessagesVisible, setIsMessagesVisible] = useState(false);
  const [projectNames, setProjectNames] = useState();
  const [status, setStatus] = useState();
  const [provinces, setProvinces] = useState();
  const [regions, setRegions] = useState();
  const [sectors, setSectors] = useState();
  const [subSectors, setSubSectors] = useState();
  const [selectedID, setSelectedID] = useState("");
  const [selectedRefNum, setSelectedRefNum] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [projectFilter, setProjectFilter] = useState("All");
  const [provinceFilter, setProvinceFilter] = useState("All");
  const [regionFilter, setRegionFilter] = useState("All");
  const [sectorFilter, setSectorFilter] = useState("All");
  const [subSectorFilter, setSubSectorFilter] = useState("All");
  const [statusFilter, setStatusFilter] = useState("All");
  const [stageFilter, setStageFilter] = useState("All");
  const [phaseFilter, setPhaseFilter] = useState("All");
  const [noCompliantFilter, setNoCompliantFilter] = useState("All");
  const [bankAccountFilter, setBankAccountFilter] = useState("All");
  const [cipcCompliantFilter, setCipcCompliantFilter] = useState("All");
  const [beneficiaryFilter, setBeneficiaryFilter] = useState("All");
  const [identifiedDateFilter, setIdentifiedDateFilter] = useState("All");
  const [fundedDateFilter, setFundedDateFilter] = useState("All");

  const [userLocation, setUserLocation] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // Initial state
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isApiRequest, setIsApiRequest] = useState(true);
  const [updatedProjectData, setUpdatedProjectData] = useState([]);
  const [gridData, setGridData] = useState([]);

  const [selectedChatUsers, setSelectedChatUsers] = useState([]);
  const { userChatsData, isUserChatsLoaded, setUserChatsData } =
    useUserChatsDataFetch([]);

  useEffect(() => {
    if (isProjectLoaded && isPaymentLoaded && isBeneficiaryLoaded) {
      const finalProjectData = projectData.filter(
        (project) => project.ProjectStatus !== "Deleted"
      );
      setUpdatedProjectData(finalProjectData);
      const filteredPayments = paymentData.filter((payment) => {
        const matchingProject = finalProjectData.find(
          (project) => project.ProjectNum === payment.ProjectNum
        );
        return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
      });
      setGridData(filteredPayments);

      const filteredUserData = getCommonUsers(allUserData);
      setCommonUserData(filteredUserData);

      setIsDataLoaded(true);
      setIsDataChanged(true);
    }
  }, [
    paymentData,
    isPaymentLoaded,
    projectData,
    isProjectLoaded,
    isBeneficiaryLoaded,
  ]);

  useEffect(() => {
    socket.on("notification", (data) => {
      console.log("Receive Notification: ", data);
      const isTrue = userChatsData.find((chat) => chat.ID === data.ID);
      if (!isTrue)
        setUserChatsData((prev) => (prev ? [...prev, data] : [data]));
    });
  }, []);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded && !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  useEffect(() => {
    const deleteRights = getUserDeleteRight(user);
    setIsButtonEnabled(deleteRights);
    const location = getUserLocation(user);
    setUserLocation(location);
  }, [user]);

  useEffect(() => {
    //Get DropdownData
    const uniqueProvinces = [
      ...new Set(
        projectData?.map((item) => (item.Province ? item.Province : ""))
      ),
    ];
    const formattedUniqueProvinces = ["All", ...uniqueProvinces];
    setProvinces(formattedUniqueProvinces);

    const uniqueProjects = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.ProjectName ? item.ProjectName : ""
        )
      ),
    ];
    const formattedUniqueProjects = ["All", ...uniqueProjects];
    setProjectNames(formattedUniqueProjects);

    const uniqueregions = [
      ...new Set(
        updatedProjectData?.map((item) => (item.Region ? item.Region : ""))
      ),
    ];
    const formattedUniqueregions = ["All", ...uniqueregions];
    setRegions(formattedUniqueregions);

    const uniqueProjectstatus = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.ProjectStatus ? item.ProjectStatus : ""
        )
      ),
    ];
    const formattedUniqueProjectstatus = ["All", ...uniqueProjectstatus];
    setStatus(formattedUniqueProjectstatus);

    const uniqueSectors = [
      ...new Set(
        updatedProjectData?.map((item) => (item.Sector ? item.Sector : ""))
      ),
    ];
    const formattedUniqueSectors = ["All", ...uniqueSectors];
    setSectors(formattedUniqueSectors);

    const uniqueSubSectors = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.SubSector ? item.SubSector : ""
        )
      ),
    ];
    const formattedUniqueSubSectors = ["All", ...uniqueSubSectors];
    setSubSectors(formattedUniqueSubSectors);
  }, [updatedProjectData]);

  useEffect(() => {
    if (!isApiRequest) {
      const updatedData = getUpdateProjectGridData(
        updatedProjectData,
        beneficiaryData,
        provinceFilter,
        regionFilter,
        statusFilter,
        sectorFilter,
        subSectorFilter,
        stageFilter,
        phaseFilter,
        noCompliantFilter,
        bankAccountFilter,
        cipcCompliantFilter,
        beneficiaryFilter,
        identifiedDateFilter,
        fundedDateFilter,
        projectFilter,
        dates
      );

      const filteredPayments = paymentData.filter((payment) => {
        const matchingProject = updatedData.find(
          (project) => project.ProjectNum === payment.ProjectNum
        );
        return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
      });
      setGridData(filteredPayments);
      setIsDataChanged(true);
      setIsApiRequest(true);
    }
  }, [
    updatedProjectData,
    beneficiaryData,
    provinceFilter,
    regionFilter,
    statusFilter,
    sectorFilter,
    subSectorFilter,
    stageFilter,
    phaseFilter,
    noCompliantFilter,
    bankAccountFilter,
    cipcCompliantFilter,
    beneficiaryFilter,
    identifiedDateFilter,
    fundedDateFilter,
    projectFilter,
    dates,
  ]);

  const handleProvinceChange = (e) => {
    if (e && e.value) {
      let provinceFilteredData;
      if (e.value !== "All") {
        provinceFilteredData = updatedProjectData?.filter(
          (item) => item.Province === e.value
        );
      } else {
        provinceFilteredData = updatedProjectData;
      }
      const uniqueregions = [
        ...new Set(
          provinceFilteredData?.map((item) => (item.Region ? item.Region : ""))
        ),
      ];
      const formattedUniqueregions = ["All", ...uniqueregions];
      setRegions(formattedUniqueregions);

      const uniqueProjects = [
        ...new Set(
          provinceFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setProvinceFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleRegionChange = (e) => {
    if (e && e.value) {
      let regionFilteredData;
      if (e.value !== "All") {
        regionFilteredData = updatedProjectData?.filter(
          (item) => item.Region === e.value
        );
      } else {
        regionFilteredData = updatedProjectData;
      }
      const uniqueProjects = [
        ...new Set(
          regionFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setRegionFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleStatusChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setStatusFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleSectorChange = (e) => {
    if (e && e.value) {
      let sectorFilteredData;
      if (e.value !== "All") {
        sectorFilteredData = updatedProjectData?.filter(
          (item) => item.Sector === e.value
        );
      } else {
        sectorFilteredData = updatedProjectData;
      }
      const uniqueSubSectors = [
        ...new Set(
          sectorFilteredData?.map((item) =>
            item.SubSector ? item.SubSector : ""
          )
        ),
      ];
      const formattedUniqueSubSectors = ["All", ...uniqueSubSectors];
      setSubSectors(formattedUniqueSubSectors);

      const uniqueProjects = [
        ...new Set(
          sectorFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setSectorFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleSubSectorChange = (e) => {
    if (e && e.value) {
      let subSectorFilteredData;
      if (e.value !== "All") {
        subSectorFilteredData = updatedProjectData?.filter(
          (item) => item.SubSector === e.value
        );
      } else {
        subSectorFilteredData = updatedProjectData;
      }

      const uniqueProjects = [
        ...new Set(
          subSectorFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setSubSectorFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleProjectChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setProjectFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleStageChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setStageFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handlePhaseChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setPhaseFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleNonComliantChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setNoCompliantFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleBankChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setBankAccountFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleCipcChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setCipcCompliantFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleBeneficiaryChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setBeneficiaryFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleIdentificationChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setIdentifiedDateFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleFundingChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setFundedDateFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleRowSelect = async (args) => {
    setSelectedID(args.data.id);
    setSelectedRefNum(args.data.ReferenceNum);
    if (args.data.ProjectNum) {
      const projectName = getProjectName(projectData, args.data.ProjectNum);
      setSelectedProjectName(projectName);
      const chatUsers = getPaymentChatUsers(
        projectData,
        args.data.ProjectNum,
        allUserData,
        commonUserData,
        user
      );
      setSelectedChatUsers(chatUsers);
    } else {
      setSelectedProjectName("");
      setSelectedChatUsers("");
    }
  };

  console.log(selectedChatUsers, "selectedChatUsers");

  const handleUploadButtonClick = () => {};

  const currentTitleDate = dateFormatter(new Date());
  const handleExcelButtonClick = () => {
    if (GridRef.current && GridRef.current.excelExport) {
      const exportProperties = {
        pageOrientation: "Landscape",
        fileName:
          "List Printed by " +
          user.FullName +
          " on " +
          currentTitleDate +
          ".xlsx",
      };
      GridRef.current.excelExport(exportProperties);
    }
  };

  const handlePdfButtonClick = () => {};

  const handleDeleteButtonClick = () => {};

  const cumulativeAmountTemplate = (props) => {
    const cumulativeAmount = props.CumulativeAmounts; // Assuming the field name is "CumulativeAmounts"
    const fontColor = cumulativeAmount < 0 ? "red" : "inherit"; // Change font color to red if cumulativeAmount is negative
    // Format the amount with the desired currency symbol, space separator, and decimal places
    const formattedAmount = new Intl.NumberFormat("en-ZA", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 2,
    }).format(cumulativeAmount);

    return <div style={{ color: fontColor }}>{formattedAmount}</div>;
  };

  const currencyFormatter1 = (field, data, column) => {
    const debitAmount = getValue("DebitAmount", data); // Assuming the field name is "DebitAmount"
    // Format the amount with the desired currency symbol, space separator, and decimal places
    const formattedAmount = new Intl.NumberFormat("en-ZA", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 2,
    }).format(debitAmount);
    return formattedAmount;
  };

  const currencyFormatter2 = (field, data, column) => {
    const creditAmount = getValue("CreditAmount", data); // Assuming the field name is "DebitAmount"
    // Format the amount with the desired currency symbol, space separator, and decimal places
    const formattedAmount = new Intl.NumberFormat("en-ZA", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 2,
    }).format(creditAmount);

    return formattedAmount;
  };

  const projectNameTemplate = (props) => {
    const projectNum = props.ProjectNum; // Assuming the field name is "ProjectNum"
    // Lookup the project name in projectData based on the provided projectNum
    const project = projectData.find(
      (project) => project.ProjectNum === projectNum
    );
    // Check if a project with the given projectNum was found
    if (project) {
      const projectName = project.ProjectName;
      return <div>{projectName}</div>;
    } else {
      // Handle the case where no matching project was found
      return <div>Project Not Found</div>;
    }
  };

  const settings = { wrapMode: "Content" };
  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];
  const loadingIndicator = { indicatorType: "Spinner" };

  const renderButtons = () => {
    return (
      <div className="button-container-whereabouts">
        <div className="button-container-subA1">
          <button
            className={`view-button ${
              userChatsData.length > 0 ? "chat-button" : ""
            }`}
            onClick={viewMessages}
            disabled={selectedID === ""}
          >
            <ForumIcon className="view-icon" /> Chats{" "}
          </button>
        </div>
        <div className="button-container-whereabouts-subA1">
          <button
            className="edit-button-whereabouts"
            onClick={handleUploadButtonClick}
          >
            <UploadIcon className="but-icon-whereabouts" /> Upload Payment{" "}
          </button>
          <button
            className={`delete-button-whereabouts ${
              isButtonEnabled ? "" : "disabled-button"
            }`}
            onClick={handleDeleteButtonClick}
            disabled={!isButtonEnabled}
          >
            <DeleteIcon className="but-icon-whereabouts" /> Delete{" "}
          </button>
        </div>
        <div className="button-container-subA2">
          <button className="add-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          {/* <button className="edit-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button> */}
        </div>
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  //-------------=---------Messages--------------------------------//
  const viewMessages = () => {
    setIsMessagesVisible(true);
    axios
      .patch(process.env.REACT_APP_SERVER_URL + `/api/chat/read`, {
        type: "payment_id",
        id: selectedID,
      })
      .then(({ data }) => {
        console.log("Read : ", data);
        setUserChatsData((prev) =>
          prev?.filter((notif) => notif.payment_id !== selectedID)
        );
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  };

  // Custom template for the "message" column
  const chatCountTemplate = (props) => {
    const ID = props.id;
    const chatCount = userChatsData.filter(
      (chat) => chat.payment_id === ID
    ).length;

    const fontColor = chatCount < 1 ? "darkgreen" : "darkred";
    const backgroundColor = chatCount < 1 ? "lightgreen" : "pink";
    const fontWeight = chatCount > 0 ? "bold" : "normal";

    const style = {
      color: fontColor,
      backgroundColor: backgroundColor,
      width: "fit-content",
      height: "100%",
      display: "inline-flex",
      justifyContent: "left",
      alignItems: "left",
      padding: "5px 10px",
      borderRadius: "10px",
      fontWeight: fontWeight,
    };

    return (
      <div style={style}>
        <span>{chatCount}</span>
      </div>
    );
  };

  const handleMessageDialogClose = () => {
    setIsMessagesVisible(false);
  };
  //-------------=---------Messages--------------------------------//
  console.log(selectedID, "selectedID");
  return (
    <div className="this-week-whereabouts">
      <div className="summary-container-project">
        <div className="decicion_requester1">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Province"
            floatLabelType="Always"
            dataSource={provinces}
            change={handleProvinceChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Region"
            floatLabelType="Always"
            dataSource={regions}
            change={handleRegionChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Sector"
            floatLabelType="Always"
            dataSource={sectors}
            change={handleSectorChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Sub-Sector"
            floatLabelType="Always"
            dataSource={subSectors}
            change={handleSubSectorChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Project Status"
            floatLabelType="Always"
            dataSource={status}
            change={handleStatusChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Project"
            floatLabelType="Always"
            dataSource={projectNames}
            change={handleProjectChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Phase"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Phase 1: Coordinators",
              "Phase 2: Facilitattors",
              "Phase 3: NF",
            ]}
            change={handlePhaseChange}
          />
        </div>
      </div>
      <div className="summary-container-project">
        <div className="decicion_requester1">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Stage"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Identification",
              "Capacity Building",
              "Funding",
              "Monitoring",
            ]}
            change={handleStageChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Overall Compliant"
            floatLabelType="Always"
            dataSource={["All", "Compliant", "Non-Compliant"]}
            change={handleNonComliantChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Beneficiary Compliant"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Sufficiant Beneficiaries",
              "Insufficiant Beneficiaries",
            ]}
            change={handleBeneficiaryChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Bank Compliant"
            floatLabelType="Always"
            dataSource={["All", "With Bank Account", "No Bank Account"]}
            change={handleBankChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="CIPC Compliant"
            floatLabelType="Always"
            dataSource={["All", "CIPC Compliant", "CIPC Non-Compliant"]}
            change={handleCipcChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Identification Date"
            floatLabelType="Always"
            dataSource={identifiedDataSource}
            change={handleIdentificationChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Funding Date"
            floatLabelType="Always"
            dataSource={FundedDataSource}
            change={handleFundingChange}
          />
        </div>
      </div>
      <br />
      <h3>List of All Payments in {userLocation}</h3>
      <div className="feed-line-this-week-whereabouts">
        {isDataLoaded && (
          <GridComponent
            dataSource={gridData}
            childMapping="items"
            ref={GridRef}
            classClass="custom-treegrid-class"
            height="650"
            allowExcelExport={true}
            allowPdfExport={true}
            allowPaging={true}
            allowSorting={true}
            allowFiltering={true}
            showColumnChooser={true}
            filterSettings={FilterOptions}
            pageSettings={pageSettings}
            editSettings={editOptions}
            toolbar={updatedToolbarOptions}
            loadingIndicator={loadingIndicator}
            allowResizing={true}
            allowReordering={true}
            textWrapSettings={settings}
            allowSelection={true}
            rowSelected={handleRowSelect}
          >
            <ColumnsDirective>
              {/* <ColumnDirective
                field="id"
                headerText="ID"
                width="60"
                textAlign="Left"
                isPrimaryKey={true}
              /> */}
              <ColumnDirective
                headerText="Chats"
                width="100"
                textAlign="Left"
                template={chatCountTemplate}
              />
              <ColumnDirective
                field="ProjectNum"
                headerText="Project #"
                width="120"
                textAlign="Left"
                editType="dropdownedit"
              />
              <ColumnDirective
                headerText="Project Name"
                width="200"
                textAlign="Left"
                template={projectNameTemplate}
              />
              <ColumnDirective
                field="PaymentDate"
                headerText="Payment Date"
                width="120"
                format="yyyy/MM/dd"
                textAlign="Left"
                type="date"
                editType="datepickeredit"
                allowFiltering={false}
              />
              <ColumnDirective
                field="EntryType"
                headerText="Entry Type"
                width="100"
                textAlign="Left"
              />
              <ColumnDirective
                field="ReferenceNum"
                headerText="Reference #"
                width="120"
                textAlign="Left"
              />
              <ColumnDirective
                field="ContraAcc"
                headerText="Contract Account"
                width="90"
                textAlign="Left"
              />
              <ColumnDirective
                field="Description"
                headerText="Description"
                width="250"
                textAlign="Left"
                clipMode="EllipsisWithTooltip"
              />
              <ColumnDirective
                field="DebitAmount"
                headerText="Debit Amount"
                width="150"
                textAlign="Left"
                valueAccessor={currencyFormatter1}
              />
              <ColumnDirective
                field="CreditAmount"
                headerText="Credit Amount"
                width="150"
                textAlign="Left"
                valueAccessor={currencyFormatter2}
              />
              <ColumnDirective
                field="CumulativeAmounts"
                headerText="Cumulative Amount"
                width="150"
                type="N2"
                textAlign="Left"
                template={cumulativeAmountTemplate}
              />
            </ColumnsDirective>
            <Inject
              services={[
                Page,
                Sort,
                Filter,
                Edit,
                Toolbar,
                ColumnChooser,
                Resize,
                Reorder,
                PdfExport,
                ExcelExport,
              ]}
            />
          </GridComponent>
        )}
      </div>
      {isMessagesVisible && (
        <div>
          <Modal
            open={isMessagesVisible}
            onClose={handleMessageDialogClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="message_outer_container">
                <h1>{`Chats about Payment (Ref#: ${selectedRefNum}) of ${selectedProjectName} Project`}</h1>
                <div className="message_inner_container">
                  <MessageBox
                    users={allUserData}
                    chatID={selectedID}
                    chatUsers={selectedChatUsers}
                    type="payment_id"
                  />
                </div>
                <button
                  className="add-button"
                  onClick={handleMessageDialogClose}
                >
                  <CloseIcon className="send-icon" /> Close
                </button>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
}
export default FinLists;
