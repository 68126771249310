import React from 'react'
import '../Sidebar.css'
import AttachmentOption from './AttachmentOption';
import ListIcon from '@mui/icons-material/List';
import AssessmentIcon from '@mui/icons-material/Assessment';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import SavingsIcon from '@mui/icons-material/Savings';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { NavLink } from 'react-router-dom';

function Attachments() {
  return (
    <div className='sidebar'>
        <NavLink to="dashboard">{<AttachmentOption Icon = {AssessmentIcon} title="Dashboard"/>}</NavLink>
        <NavLink to="listview">{<AttachmentOption Icon = {ListIcon} title="List of All Attachments"/>}</NavLink>
        <NavLink to="uptodate">{<AttachmentOption Icon = {CheckCircleIcon} title="Up to Date List"/>}</NavLink>
        <NavLink to="dueforupdate">{<AttachmentOption Icon = {OfflinePinIcon} title="Due for Update List"/>}</NavLink>
        <NavLink to="overdue">{<AttachmentOption Icon = {UnpublishedIcon} title="Overdue List"/>}</NavLink>
        <NavLink to="accountdue">{<AttachmentOption Icon = {SavingsIcon} title="Bank Accounts Due for Update"/>}</NavLink>
        <NavLink to="idcopiesdue">{<AttachmentOption Icon = {CoPresentIcon} title="ID Copies Due for Update"/>}</NavLink>        
    </div>
  )
}

export default Attachments