import React from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import './StageApprovalDialog.css'

function PhaseApprovalDialog({ selectedID, selectedStage, selectedPhase, nextPhase, nextStage, declaration}) {

  return (
    <table className='main-container-dialog'>
      <tbody className='project-field-dialog'>
        <tr className='my-dialog-row1'>
          <td className='field_projectid'>
            <TextBoxComponent id='id' name='id' value={selectedID} placeholder=" Proj ID" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='field_projectstage'>
            <TextBoxComponent id='Stage' name='Stage' value={selectedStage} placeholder=" Current Stage" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='field_datetime'>
            <TextBoxComponent  id='stage' value={nextStage} placeholder="Next Stage" floatLabelType="Always" disabled={true}/>
          </td>
        </tr>
        <tr className='my-dialog-row2'>
          <td className='field_from'>
            <TextBoxComponent  id='phase' value={selectedPhase} placeholder="Current Phase" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='field_to'>
            <TextBoxComponent  id='phase' value={nextPhase} placeholder=" Next Phase" floatLabelType="Always" disabled={true}/>
          </td>
        </tr>
        <tr className='my-dialog-row3'>
          <td className='field_notes'>
            <TextBoxComponent  id='notes' name='notes' value={declaration} multiline={true} placeholder=" Declaration" floatLabelType="Always" disabled={true} cssClass="multiline-textbox"/>
          </td>
        </tr>
      </tbody>
    </table>
    
  )
}

export default PhaseApprovalDialog
