import React, { useState } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import './OneChange.css'

function RegNumChange({ projectData, uniqueProjects, requestor, setNewName, setProjectID, setFutureRegNum, setCurrentRegNum, setNotes}) {
  const [selectedID, setSelectedID] = useState("");
  const [selectedRegNum, setSelectedRegNum] = useState("");
  const [selectedProjectNum, setSelectedProjectNum] = useState("");

  
  const handleNameChange = (e) => {
    setNewName(e.value);
    const selectedProject = e.value
    const filteredData = projectData.filter(
      (item) => item.ProjectName === selectedProject
    );
    if (filteredData.length > 0) {
      setSelectedID(filteredData[0]?.id);
      setProjectID(filteredData[0]?.id);
      setSelectedProjectNum(filteredData[0]?.ProjectNum);
      setSelectedRegNum(filteredData[0]?.RegNum);
      setCurrentRegNum(filteredData[0]?.RegNum);
    }
  };
  const handleNotesChange = (e) => {
    setNotes(e.value);
  };
  const handleNewRegNumChange = (e) => {
    if (e !== ""){setFutureRegNum(e.value)};
  };
  
  return (
    <table className='main-name-dialog'>
      <tbody className='name-field-dialog'>
        <tr className='name-change-row1'>
          <td className='name_project-id'>
            <TextBoxComponent id='id' name='id' value={selectedID} placeholder=" Proj ID" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='name_project-name'>
            <AutoCompleteComponent id='name' name='name' onChange={handleNameChange} dataSource={uniqueProjects} placeholder="Project Name" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='name_project-num'>
            <TextBoxComponent id='num' name='num' value={selectedProjectNum} placeholder="Project Number" floatLabelType="Always" disabled={true}/>
          </td>
        </tr>
        <tr className='name-change-row2'>
          <td className='name_current-status'>
            <TextBoxComponent  id='current-status' name='current-status' value={selectedRegNum} placeholder="Current Reg. Number" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='name_new-status'>
          <TextBoxComponent
              id='comboelement'
              name='comboelement'
              placeholder="New Reg. Number"
              floatLabelType="Always"
              change={handleNewRegNumChange}
            />
          </td>
          <td className='name_requestor'>
            <TextBoxComponent  id='requestor' name='requestor' value={requestor} placeholder="Requestor" floatLabelType="Always" disabled={true}/>
          </td>
        </tr>
        <tr className='name-change-row3'>
          <td className='name_notes'>
            <TextBoxComponent  id='notes' name='notes' onChange={handleNotesChange} multiline={true} placeholder=" Notes" floatLabelType="Always" />
          </td>
        </tr>
      </tbody>
    </table>
    
  )
}

export default RegNumChange