import React, { useState } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent, ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import './OneChange.css'

function StatusChange({ projectData, uniqueProjects, requestor, setNewName, setProjectID, setFutureStatus, setCurrentStatus, setNotes}) {
  const [selectedID, setSelectedID] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedProjectNum, setSelectedProjectNum] = useState("");

  
  const handleNameChange = (e) => {
    setNewName(e.value);
    const selectedProject = e.value
    const filteredData = projectData.filter(
      (item) => item.ProjectName === selectedProject
    );
    if (filteredData.length > 0) {
      setSelectedID(filteredData[0]?.id);
      setProjectID(filteredData[0]?.id);
      setSelectedProjectNum(filteredData[0]?.ProjectNum);
      setSelectedStatus(filteredData[0]?.ProjectStatus);
      setCurrentStatus(filteredData[0]?.ProjectStatus);
    }
  };
  const handleNotesChange = (e) => {
    setNotes(e.value);
  };
  const handleNewStatusChange = (e) => {
    if (e !== ""){setFutureStatus(e.value)};
  };
  
  return (
    <table className='main-name-dialog'>
      <tbody className='name-field-dialog'>
        <tr className='name-change-row1'>
          <td className='name_project-id'>
            <TextBoxComponent id='id' name='id' value={selectedID} placeholder=" Proj ID" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='name_project-name'>
            <AutoCompleteComponent id='name' name='name' onChange={handleNameChange} dataSource={uniqueProjects} placeholder="Project Name" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='name_project-num'>
            <TextBoxComponent id='num' name='num' value={selectedProjectNum} placeholder="Project Number" floatLabelType="Always" disabled={true}/>
          </td>
        </tr>
        <tr className='name-change-row2'>
          <td className='name_current-status'>
            <TextBoxComponent  id='current-status' name='current-status' value={selectedStatus} placeholder="Current Status" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='name_new-status'>
          <ComboBoxComponent
              id='comboelement'
              name='comboelement'
              placeholder="New Status"
              floatLabelType="Always"
              dataSource={['Active', 'Non-active', 'Canceled', 'On-hold', 'Suspended', 'Expelled']}
              change={handleNewStatusChange}
            />
          </td>
          <td className='name_requestor'>
            <TextBoxComponent  id='requestor' name='requestor' value={requestor} placeholder="Requestor" floatLabelType="Always" disabled={true}/>
          </td>
        </tr>
        <tr className='name-change-row3'>
          <td className='name_notes'>
            <TextBoxComponent  id='notes' name='notes' onChange={handleNotesChange} multiline={true} placeholder=" Notes" floatLabelType="Always" />
          </td>
        </tr>
      </tbody>
    </table>
    
  )
}

export default StatusChange