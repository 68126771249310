import React, { useState, useEffect } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  ComboBoxComponent,
  AutoCompleteComponent,
} from "@syncfusion/ej2-react-dropdowns";
import "./WhereaboutsDialog.css";
import { format } from "date-fns";

function WhereaboutsDialog({
  selectedID,
  selectedFullName,
  selectedDate,
  selectedProvince,
  selectedLocation,
  selectedNotes,
  selectedWeekDay,
  setNewFullName,
  setNewDate,
  setNewProvince,
  setNewLocation,
  setNewNotes,
  setNewWeekDay,
  uniqueLocations,
}) {
  const [weekDay, setWeekDay] = useState([]);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  useEffect(() => {
    if (selectedDate) {
      const date = new Date(selectedDate);
      const dayOfWeek = format(date, "EEEE");
      setWeekDay(dayOfWeek);
    }
  }, [selectedDate]);

  const handleFullNameChange = (event) => {
    setNewFullName(event.target.value);
  };

  const handleDateChange = (event) => {
    const date = new Date(event.target.value);
    const dayOfWeek = format(date, "EEEE");

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const newMonth = month < 10 ? `0${month}` : month;
    const newDay = day < 10 ? `0${day}` : day;

    const newDate = `${date.getFullYear()}-${newMonth}-${newDay}`;

    setNewDate(new Date(`${newDate}T00:00:00Z`));
    setNewWeekDay(dayOfWeek);
    setWeekDay(dayOfWeek);
  };

  const handleProvinceChange = (event) => {
    setNewProvince(event.target.value);
  };
  const handleLocationChange = (event) => {
    setNewLocation(event.target.value);
  };

  const handleNotesChange = (event) => {
    setNewNotes(event.target.value);
  };

  return (
    <table className="main-container-dialog">
      <tbody className="project-field-dialog">
        <tr className="my-dialog-row1">
          <td className="field_record-id">
            <TextBoxComponent
              id="id"
              name="id"
              value={selectedID}
              placeholder="ID"
              floatLabelType="Always"
              disabled={true}
            />
          </td>
          <td className="field_record-name">
            <TextBoxComponent
              id="name"
              name="name"
              value={selectedFullName}
              onChange={handleFullNameChange}
              placeholder="Full Name"
              floatLabelType="Always"
              disabled={true}
            />
          </td>
          <td className="field_record-date">
            <DatePickerComponent
              id="date"
              name="date"
              value={selectedDate}
              onChange={handleDateChange}
              placeholder="Date"
              floatLabelType="Always"
              format="yyyy-MM-dd"
              min={yesterday}
            />
          </td>
          <td className="field_record-day">
            <TextBoxComponent
              id="day"
              name="day"
              value={weekDay}
              placeholder="Week Day"
              floatLabelType="Always"
              disabled={true}
            />
          </td>
        </tr>
        <tr className="my-dialog-row2">
          <td className="field_record-province">
            <ComboBoxComponent
              id="province"
              name="province"
              value={selectedProvince}
              placeholder="Province"
              onChange={handleProvinceChange}
              floatLabelType="Always"
              dataSource={[
                "Gauteng",
                "Kwazulu-Natal",
                "Limpopo",
                "Mpumalanga",
                "North West",
                "Other Locations",
                "Training",
                "On Leave",
              ]}
            />
          </td>
          <td className="field_record-location">
            <AutoCompleteComponent
              id="location"
              name="location"
              value={selectedLocation}
              onChange={handleLocationChange}
              dataSource={uniqueLocations}
              placeholder="Location"
              floatLabelType="Always"
            />
          </td>
        </tr>
        <tr className="my-dialog-row3">
          <td className="field_notes">
            <TextBoxComponent
              id="notes"
              name="notes"
              value={selectedNotes}
              onChange={handleNotesChange}
              multiline={true}
              placeholder="Notes"
              floatLabelType="Always"
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default WhereaboutsDialog;
