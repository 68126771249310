import React from "react";
import { Outlet } from "react-router-dom";
import Messages from "./Messages";
import "../MainBody.css";

function MessageLayout() {
  return (
    <div className="projects_container">
      <div className="projects_sidebar">
        <Messages />
      </div>
      <div className="projects_main">
        <Outlet />
      </div>
    </div>
  );
}
export default MessageLayout;
