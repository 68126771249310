import React, { useState, useEffect } from "react";
import "./BenYouthDashboard.css";
import useProjectDataFetch from "../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../shared/useOtherDataFetch"; // Import the custom hook
import useDateCalculator from "../../../shared/useDateCalculator";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  DataLabel,
  Category,
  Tooltip,
  ColumnSeries,
} from "@syncfusion/ej2-react-charts"; // Import Syncfusion chart components
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import useUserStore from "../../../../app/user";
import { toast } from "react-toastify";
import {
  FundedDataSource,
  getUpdateProjectGridData,
  identifiedDataSource,
} from "../../../../utils/project";

function BenYouthDashboard() {
  const user = useUserStore((state) => state.user);
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { beneficiaryData, isBeneficiaryLoaded } = useOtherDataFetch();
  const [gridData, setGridData] = useState([]);

  const dates = useDateCalculator();
  const [projectNames, setProjectNames] = useState();
  const [status, setStatus] = useState();
  const [provinces, setProvinces] = useState();
  const [regions, setRegions] = useState();
  const [sectors, setSectors] = useState();
  const [subSectors, setSubSectors] = useState();

  const [projectFilter, setProjectFilter] = useState("All");
  const [provinceFilter, setProvinceFilter] = useState("All");
  const [regionFilter, setRegionFilter] = useState("All");
  const [sectorFilter, setSectorFilter] = useState("All");
  const [subSectorFilter, setSubSectorFilter] = useState("All");
  const [statusFilter, setStatusFilter] = useState("All");
  const [stageFilter, setStageFilter] = useState("All");
  const [phaseFilter, setPhaseFilter] = useState("All");
  const [noCompliantFilter, setNoCompliantFilter] = useState("All");
  const [bankAccountFilter, setBankAccountFilter] = useState("All");
  const [cipcCompliantFilter, setCipcCompliantFilter] = useState("All");
  const [beneficiaryFilter, setBeneficiaryFilter] = useState("All");
  const [identifiedDateFilter, setIdentifiedDateFilter] = useState("All");
  const [fundedDateFilter, setFundedDateFilter] = useState("All");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isApiRequest, setIsApiRequest] = useState(true);
  const [updatedProjectData, setUpdatedProjectData] = useState([]);

  useEffect(() => {
    if (isProjectLoaded && isBeneficiaryLoaded) {
      const finalProjectData = projectData.filter(
        (project) => project.ProjectStatus !== "Deleted"
      );
      setUpdatedProjectData(finalProjectData);
      setGridData(finalProjectData);
      setIsDataLoaded(true);
      setIsDataChanged(true);
    }
  }, [projectData, isProjectLoaded, isBeneficiaryLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded && !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  useEffect(() => {
    //Get DropdownData
    const uniqueProvinces = [
      ...new Set(
        projectData?.map((item) => (item.Province ? item.Province : ""))
      ),
    ];
    const formattedUniqueProvinces = ["All", ...uniqueProvinces];
    setProvinces(formattedUniqueProvinces);

    const uniqueProjects = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.ProjectName ? item.ProjectName : ""
        )
      ),
    ];
    const formattedUniqueProjects = ["All", ...uniqueProjects];
    setProjectNames(formattedUniqueProjects);

    const uniqueregions = [
      ...new Set(
        updatedProjectData?.map((item) => (item.Region ? item.Region : ""))
      ),
    ];
    const formattedUniqueregions = ["All", ...uniqueregions];
    setRegions(formattedUniqueregions);

    const uniqueProjectstatus = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.ProjectStatus ? item.ProjectStatus : ""
        )
      ),
    ];
    const formattedUniqueProjectstatus = ["All", ...uniqueProjectstatus];
    setStatus(formattedUniqueProjectstatus);

    const uniqueSectors = [
      ...new Set(
        updatedProjectData?.map((item) => (item.Sector ? item.Sector : ""))
      ),
    ];
    const formattedUniqueSectors = ["All", ...uniqueSectors];
    setSectors(formattedUniqueSectors);

    const uniqueSubSectors = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.SubSector ? item.SubSector : ""
        )
      ),
    ];
    const formattedUniqueSubSectors = ["All", ...uniqueSubSectors];
    setSubSectors(formattedUniqueSubSectors);
  }, [updatedProjectData]);

  useEffect(() => {
    if (!isApiRequest) {
      const updatedData = getUpdateProjectGridData(
        updatedProjectData,
        beneficiaryData,
        provinceFilter,
        regionFilter,
        statusFilter,
        sectorFilter,
        subSectorFilter,
        stageFilter,
        phaseFilter,
        noCompliantFilter,
        bankAccountFilter,
        cipcCompliantFilter,
        beneficiaryFilter,
        identifiedDateFilter,
        fundedDateFilter,
        projectFilter,
        dates
      );
      setGridData(updatedData);

      setIsDataChanged(true);
      setIsApiRequest(true);
    }
  }, [
    updatedProjectData,
    beneficiaryData,
    provinceFilter,
    regionFilter,
    statusFilter,
    sectorFilter,
    subSectorFilter,
    stageFilter,
    phaseFilter,
    noCompliantFilter,
    bankAccountFilter,
    cipcCompliantFilter,
    beneficiaryFilter,
    identifiedDateFilter,
    fundedDateFilter,
    projectFilter,
    dates,
  ]);

  const handleProvinceChange = (e) => {
    if (e && e.value) {
      let provinceFilteredData;
      if (e.value !== "All") {
        provinceFilteredData = updatedProjectData?.filter(
          (item) => item.Province === e.value
        );
      } else {
        provinceFilteredData = updatedProjectData;
      }
      const uniqueregions = [
        ...new Set(
          provinceFilteredData?.map((item) => (item.Region ? item.Region : ""))
        ),
      ];
      const formattedUniqueregions = ["All", ...uniqueregions];
      setRegions(formattedUniqueregions);

      const uniqueProjects = [
        ...new Set(
          provinceFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setProvinceFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleRegionChange = (e) => {
    if (e && e.value) {
      let regionFilteredData;
      if (e.value !== "All") {
        regionFilteredData = updatedProjectData?.filter(
          (item) => item.Region === e.value
        );
      } else {
        regionFilteredData = updatedProjectData;
      }
      const uniqueProjects = [
        ...new Set(
          regionFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setRegionFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleStatusChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setStatusFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleSectorChange = (e) => {
    if (e && e.value) {
      let sectorFilteredData;
      if (e.value !== "All") {
        sectorFilteredData = updatedProjectData?.filter(
          (item) => item.Sector === e.value
        );
      } else {
        sectorFilteredData = updatedProjectData;
      }
      const uniqueSubSectors = [
        ...new Set(
          sectorFilteredData?.map((item) =>
            item.SubSector ? item.SubSector : ""
          )
        ),
      ];
      const formattedUniqueSubSectors = ["All", ...uniqueSubSectors];
      setSubSectors(formattedUniqueSubSectors);

      const uniqueProjects = [
        ...new Set(
          sectorFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setSectorFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleSubSectorChange = (e) => {
    if (e && e.value) {
      let subSectorFilteredData;
      if (e.value !== "All") {
        subSectorFilteredData = updatedProjectData?.filter(
          (item) => item.SubSector === e.value
        );
      } else {
        subSectorFilteredData = updatedProjectData;
      }

      const uniqueProjects = [
        ...new Set(
          subSectorFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setSubSectorFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleProjectChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setProjectFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleStageChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setStageFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handlePhaseChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setPhaseFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleNonComliantChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setNoCompliantFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleBankChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setBankAccountFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleCipcChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setCipcCompliantFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleBeneficiaryChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setBeneficiaryFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleIdentificationChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setIdentifiedDateFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleFundingChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setFundedDateFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const uniqueProjectNumbers = [
    ...new Set(
      gridData?.map((item) => (item.ProjectNum ? item.ProjectNum : ""))
    ),
  ];
  const filteredBeneficiaryData = beneficiaryData.filter((item) =>
    uniqueProjectNumbers.includes(item.ProjNum)
  );

  // Get unique provinces from gridData
  const uniqueProvinces = [...new Set(gridData?.map((item) => item.Province))];
  // Count beneficiaries for each province
  const provinceCounts = {};
  const provinceYouthCounts = {}; // For Youth beneficiaries
  uniqueProvinces.forEach((province) => {
    const matchingProjects = gridData.filter(
      (item) => item.Province === province
    );
    // Initialize counts for both Beneficiaries and Youth Beneficiaries
    provinceCounts[province] = 0;
    provinceYouthCounts[province] = 0;
    matchingProjects.forEach((project) => {
      const matchingBeneficiaries = filteredBeneficiaryData.filter(
        (item) => item.ProjNum === project.ProjectNum
      );
      // Count Beneficiaries and Youth Beneficiaries separately
      matchingBeneficiaries.forEach((beneficiary) => {
        provinceCounts[province]++; // Total Beneficiaries count
        if (!beneficiary.D_O_B) {
          return; // Skip if Date of Birth is missing
        }
        const dob = new Date(beneficiary.D_O_B);
        if (!isNaN(dob.getTime())) {
          const currentDate = new Date();
          const ageInMilliseconds = currentDate - dob;
          const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
          if (ageInYears < 36) {
            provinceYouthCounts[province]++; // Youth Beneficiaries count
          }
        }
      });
    });
  });
  // Prepare data for the chart
  const provinceChartData = Object.keys(provinceCounts).map((province) => ({
    x: province,
    yBeneficiaries: provinceCounts[province], // Total Beneficiaries count
    yYouthBeneficiaries: provinceYouthCounts[province], // Youth Beneficiaries count
  }));

  // Get unique regions from gridData
  const uniqueRegions = [...new Set(gridData?.map((item) => item.Region))];
  // Count beneficiaries for each region
  const regionCounts = {};
  const regionYouthCounts = {}; // For Youth beneficiaries
  uniqueRegions.forEach((region) => {
    const matchingProjects = gridData.filter((item) => item.Region === region);
    // Initialize counts for both Beneficiaries and Youth Beneficiaries
    regionCounts[region] = 0;
    regionYouthCounts[region] = 0;
    matchingProjects.forEach((project) => {
      const matchingBeneficiaries = filteredBeneficiaryData.filter(
        (item) => item.ProjNum === project.ProjectNum
      );
      // Count Beneficiaries and Youth Beneficiaries separately
      matchingBeneficiaries.forEach((beneficiary) => {
        regionCounts[region]++; // Total Beneficiaries count
        if (!beneficiary.D_O_B) {
          return; // Skip if Date of Birth is missing
        }
        const dob = new Date(beneficiary.D_O_B);
        if (!isNaN(dob.getTime())) {
          const currentDate = new Date();
          const ageInMilliseconds = currentDate - dob;
          const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
          if (ageInYears < 36) {
            regionYouthCounts[region]++; // Youth Beneficiaries count
          }
        }
      });
    });
  });
  // Prepare data for the chart
  const regionChartData = Object.keys(regionCounts).map((region) => ({
    x: region,
    yBeneficiaries: regionCounts[region], // Total Beneficiaries count
    yYouthBeneficiaries: regionYouthCounts[region], // Youth Beneficiaries count
  }));

  // BENEFICIARY COUNT BY THEIR STATUS
  let statusChartData = [];
  if (user?.Access === "National") {
    // Get unique beneficiary statuses from beneficiaryData
    const uniqueStatuses = [
      ...new Set(beneficiaryData?.map((item) => item.BenStatus)),
    ];
    // Count beneficiaries for each status
    const statusCounts = {};
    const statusYouthCounts = {}; // For Youth beneficiaries
    uniqueStatuses.forEach((status) => {
      const matchingBeneficiaries = filteredBeneficiaryData.filter(
        (item) => item.BenStatus === status
      );
      // Initialize counts for both Beneficiaries and Youth Beneficiaries
      statusCounts[status] = matchingBeneficiaries.length;
      statusYouthCounts[status] = matchingBeneficiaries.reduce(
        (count, beneficiary) => {
          // Age group calculation logic
          const dob = new Date(beneficiary.D_O_B);
          const currentDate = new Date();
          const ageInMilliseconds = currentDate - dob;
          const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Approximate calculation for leap years
          const ageGroup =
            ageInYears < 36 ? "Youth" : ageInYears < 65 ? "Adult" : "Pensioner";

          if (ageGroup === "Youth") {
            return count + 1;
          }
          return count;
        },
        0
      );
    });
    // Prepare data for the chart
    statusChartData = Object.keys(statusCounts).map((status) => ({
      x: status,
      yBeneficiaries: statusCounts[status], // Total Beneficiaries count
      yYouthBeneficiaries: statusYouthCounts[status] || 0, // Youth Beneficiaries count
    }));
  }
  if (user?.Access === "Provincial") {
    const province = user?.AccessProvince;
    const matchingProjects = gridData.filter(
      (item) => item.Province === province
    );
    const matchingProjectNums = matchingProjects.map((item) => item.ProjectNum);
    const filteredBeneficiaryData2 = filteredBeneficiaryData.filter((item) =>
      matchingProjectNums.includes(item.ProjNum)
    );
    // Count the occurrences of each status
    const statusCounts = filteredBeneficiaryData2.reduce((counts, item) => {
      const status = item.BenStatus; // Assuming the field name is 'BenStatus'
      counts[status] = (counts[status] || 0) + 1;
      return counts;
    }, {});
    // Count the occurrences of each youth status
    const statusYouthCounts = filteredBeneficiaryData.reduce((counts, item) => {
      // Age group calculation logic
      const dob = new Date(item.D_O_B);
      const currentDate = new Date();
      const ageInMilliseconds = currentDate - dob;
      const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Approximate calculation for leap years
      if (ageInYears < 36) {
        const status = item.BenStatus; // Assuming the field name is 'BenStatus'
        counts[status] = (counts[status] || 0) + 1;
      }
      return counts;
    }, {});
    // Prepare data for the chart
    statusChartData = Object.keys(statusCounts).map((status) => ({
      x: status,
      yBeneficiaries: statusCounts[status],
      yYouthBeneficiaries: statusYouthCounts[status] || 0,
    }));
  }
  if (user?.Access === "Regional") {
    const region = user?.AccessRegion;
    const matchingProjects = gridData.filter((item) => item.Region === region);
    const matchingProjectNums = matchingProjects.map((item) => item.ProjectNum);
    const filteredBeneficiaryData = filteredBeneficiaryData.filter((item) =>
      matchingProjectNums.includes(item.ProjNum)
    );

    // Count the occurrences of each status
    const statusCounts = filteredBeneficiaryData.reduce((counts, item) => {
      const status = item.BenStatus; // Assuming the field name is 'BenStatus'
      counts[status] = (counts[status] || 0) + 1;
      return counts;
    }, {});

    // Count the occurrences of each youth status
    const statusYouthCounts = filteredBeneficiaryData.reduce((counts, item) => {
      // Age group calculation logic
      const dob = new Date(item.D_O_B);
      const currentDate = new Date();
      const ageInMilliseconds = currentDate - dob;
      const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Approximate calculation for leap years

      if (ageInYears < 36) {
        const status = item.BenStatus; // Assuming the field name is 'BenStatus'
        counts[status] = (counts[status] || 0) + 1;
      }
      return counts;
    }, {});

    // Prepare data for the chart
    statusChartData = Object.keys(statusCounts).map((status) => ({
      x: status,
      yBeneficiaries: statusCounts[status],
      yYouthBeneficiaries: statusYouthCounts[status] || 0,
    }));
  }

  // Get unique sectors from gridData
  const uniqueSectors = [...new Set(gridData?.map((item) => item.Sector))];
  // Count beneficiaries for each sector
  const sectorCounts = {};
  const sectorYouthCounts = {}; // For Youth beneficiaries
  uniqueSectors.forEach((sector) => {
    const matchingProjects = gridData.filter((item) => item.Sector === sector);
    // Initialize counts for both Beneficiaries and Youth Beneficiaries
    sectorCounts[sector] = 0;
    sectorYouthCounts[sector] = 0;
    matchingProjects.forEach((project) => {
      const matchingBeneficiaries = filteredBeneficiaryData.filter(
        (item) => item.ProjNum === project.ProjectNum
      );
      // Count Beneficiaries and Youth Beneficiaries separately
      matchingBeneficiaries.forEach((beneficiary) => {
        sectorCounts[sector]++; // Total Beneficiaries count
        if (!beneficiary.D_O_B) {
          return; // Skip if Date of Birth is missing
        }
        const dob = new Date(beneficiary.D_O_B);
        if (!isNaN(dob.getTime())) {
          const currentDate = new Date();
          const ageInMilliseconds = currentDate - dob;
          const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

          if (ageInYears < 36) {
            sectorYouthCounts[sector]++; // Youth Beneficiaries count
          }
        }
      });
    });
  });
  // Prepare data for the chart
  const sectorChartData = Object.keys(sectorCounts).map((sector) => ({
    x: sector,
    yBeneficiaries: sectorCounts[sector], // Total Beneficiaries count
    yYouthBeneficiaries: sectorYouthCounts[sector], // Youth Beneficiaries count
  }));

  // Count Beneficiaries for all projects nationally
  const totalBeneficiaries = filteredBeneficiaryData.length;
  const totalYouthBeneficiaries = filteredBeneficiaryData.reduce(
    (count, beneficiary) => {
      if (!beneficiary.D_O_B) {
        return count;
      }
      const dob = new Date(beneficiary.D_O_B);
      if (!isNaN(dob.getTime())) {
        const currentDate = new Date();
        const ageInMilliseconds = currentDate - dob;
        const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
        if (ageInYears < 36) {
          return count + 1;
        }
      }
      return count;
    },
    0
  );
  // Prepare data for the chart
  const beneficiaryChartData = [
    {
      x: "Total",
      yBeneficiaries: totalBeneficiaries,
      yYouthBeneficiaries: totalYouthBeneficiaries,
    },
  ];

  // Get unique subsectors from gridData
  const uniqueSubSectors = [
    ...new Set(gridData?.map((item) => item.SubSector)),
  ];
  // Count beneficiaries for each subsector
  const subSectorCounts = {};
  const subSectorYouthCounts = {}; // For Youth beneficiaries
  uniqueSubSectors.forEach((subSector) => {
    const matchingProjects = gridData.filter(
      (item) => item.SubSector === subSector
    );
    // Initialize counts for both Beneficiaries and Youth Beneficiaries
    subSectorCounts[subSector] = 0;
    subSectorYouthCounts[subSector] = 0;
    matchingProjects.forEach((project) => {
      const matchingBeneficiaries = filteredBeneficiaryData.filter(
        (item) => item.ProjNum === project.ProjectNum
      );
      // Count Beneficiaries and Youth Beneficiaries separately
      matchingBeneficiaries.forEach((beneficiary) => {
        subSectorCounts[subSector]++; // Total Beneficiaries count
        if (!beneficiary.D_O_B) {
          return; // Skip if Date of Birth is missing
        }
        const dob = new Date(beneficiary.D_O_B);
        if (!isNaN(dob.getTime())) {
          const currentDate = new Date();
          const ageInMilliseconds = currentDate - dob;
          const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
          if (ageInYears < 36) {
            subSectorYouthCounts[subSector]++; // Youth Beneficiaries count
          }
        }
      });
    });
  });
  // Prepare data for the chart
  const subSectorChartData = Object.keys(subSectorCounts).map((subSector) => ({
    x: subSector,
    yBeneficiaries: subSectorCounts[subSector], // Total Beneficiaries count
    yYouthBeneficiaries: subSectorYouthCounts[subSector], // Youth Beneficiaries count
  }));

  const chartWidth = "100%"; // Set the desired chart width (e.g., "100%", "80px", etc.)
  const chartHeight = "80%"; // Set the desired chart width (e.g., "100%", "80px", etc.)
  const primaryxAxis = {
    valueType: "Category",
    labelRotation: -45,
    labelStyle: {
      color: "rgb(96,172,86)",
      fontWeight: "bold",
      size: "16px",
      fontFamily: "Segoe UI",
    },
  };
  const primaryyAxis = { visible: false };
  const marker = {
    dataLabel: {
      visible: true,
      font: {
        color: "rgb(96,172,86)",
        background: "white",
        fontWeight: "bold",
        size: "14px",
        fontFamily: "Segoe UI",
      },
    },
  };
  const tooltip = { enable: false, shared: false };
  // Define an array of varying colors for each series
  const varyingColors = [
    "red",
    "green",
    "rgb(96,172,86)",
    "#1565C0",
    "#FF5722",
    "#E91E63",
    "#9C27B0",
    "#009688",
    "#FF9800",
  ];

  return (
    <div className="reports-container">
      <div className="summary-container-project">
        <div className="decicion_requester1">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Province"
            floatLabelType="Always"
            dataSource={provinces}
            change={handleProvinceChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Region"
            floatLabelType="Always"
            dataSource={regions}
            change={handleRegionChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Sector"
            floatLabelType="Always"
            dataSource={sectors}
            change={handleSectorChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Sub-Sector"
            floatLabelType="Always"
            dataSource={subSectors}
            change={handleSubSectorChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Project Status"
            floatLabelType="Always"
            dataSource={status}
            change={handleStatusChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Project"
            floatLabelType="Always"
            dataSource={projectNames}
            change={handleProjectChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Phase"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Phase 1: Coordinators",
              "Phase 2: Facilitattors",
              "Phase 3: NF",
            ]}
            change={handlePhaseChange}
          />
        </div>
      </div>
      <div className="summary-container-project">
        <div className="decicion_requester1">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Stage"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Identification",
              "Capacity Building",
              "Funding",
              "Monitoring",
            ]}
            change={handleStageChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Overall Compliant"
            floatLabelType="Always"
            dataSource={["All", "Compliant", "Non-Compliant"]}
            change={handleNonComliantChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Beneficiary Compliant"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Sufficiant Beneficiaries",
              "Insufficiant Beneficiaries",
            ]}
            change={handleBeneficiaryChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Bank Compliant"
            floatLabelType="Always"
            dataSource={["All", "With Bank Account", "No Bank Account"]}
            change={handleBankChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="CIPC Compliant"
            floatLabelType="Always"
            dataSource={["All", "CIPC Compliant", "CIPC Non-Compliant"]}
            change={handleCipcChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Identification Date"
            floatLabelType="Always"
            dataSource={identifiedDataSource}
            change={handleIdentificationChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Funding Date"
            floatLabelType="Always"
            dataSource={FundedDataSource}
            change={handleFundingChange}
          />
        </div>
      </div>
      {/*National Dashboard*/}
      {user?.Access === "National" && (
        <div className="national-reports-container1">
          <div className="chart-row">
            <div className="chart-container2">
              <h2>Youth Count by Status</h2>
              <ChartComponent
                id="status-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={statusChartData}
                    xName="x"
                    yName="yBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={statusChartData}
                    xName="x"
                    yName="yYouthBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container1">
              <h2>Youth Count by National</h2>
              <ChartComponent
                id="national-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={beneficiaryChartData}
                    xName="x"
                    yName="yBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={beneficiaryChartData}
                    xName="x"
                    yName="yYouthBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container3">
              <h2>Youth Count by Province</h2>
              <ChartComponent
                id="province-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={provinceChartData}
                    xName="x"
                    yName="yBeneficiaries"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={provinceChartData}
                    xName="x"
                    yName="yYouthBeneficiaries"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-row">
            <div className="chart-container4">
              <h2>Youth Count by Sector</h2>
              <ChartComponent
                id="sector-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={sectorChartData}
                    xName="x"
                    yName="yBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={sectorChartData}
                    xName="x"
                    yName="yYouthBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container5">
              <h2>Youth Count by Sub-Sector</h2>
              <ChartComponent
                id="sub-sector-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={subSectorChartData}
                    xName="x"
                    yName="yBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={subSectorChartData}
                    xName="x"
                    yName="yYouthBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-row">
            <div className="chart-container6">
              <h2>Youth Count by Region</h2>
              <ChartComponent
                id="region-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="yBeneficiaries"
                    type="Column"
                    name="Region"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="yYouthBeneficiaries"
                    type="Column"
                    name="Region"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      )}

      {/*Provincial Dashboard*/}
      {user?.Access === "Provincial" && (
        <div className="provincial-reports-container1">
          <div className="chart-row">
            <div className="chart-container8">
              <h2>Youth Count by Status</h2>
              <ChartComponent
                id="status-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={statusChartData}
                    xName="x"
                    yName="yBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={statusChartData}
                    xName="x"
                    yName="yYouthBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>

            <div className="chart-container7">
              <h2>Youth Count by Province</h2>
              <ChartComponent
                id="province-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={provinceChartData}
                    xName="x"
                    yName="yBeneficiaries"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={provinceChartData}
                    xName="x"
                    yName="yYouthBeneficiaries"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>

            <div className="chart-container9">
              <h2>Youth Count by Region</h2>
              <ChartComponent
                id="region-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="yBeneficiaries"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="yYouthBeneficiaries"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-row">
            <div className="chart-container10">
              <h2>Youth Count by Sector</h2>
              <ChartComponent
                id="sector-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={sectorChartData}
                    xName="x"
                    yName="yBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={sectorChartData}
                    xName="x"
                    yName="yYouthBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container11">
              <h2>Youth Count by Sub-Sector</h2>
              <ChartComponent
                id="sub-sector-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={subSectorChartData}
                    xName="x"
                    yName="yBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={subSectorChartData}
                    xName="x"
                    yName="yYouthBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      )}

      {/*Regional Dashboard*/}
      {user?.Access === "Regional" && (
        <div className="regional-reports-container1">
          <div className="chart-row">
            <div className="chart-container13">
              <h2>Youth Count by Status</h2>
              <ChartComponent
                id="status-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={statusChartData}
                    xName="x"
                    yName="yBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={statusChartData}
                    xName="x"
                    yName="yYouthBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container12">
              <h2>Youth Count by Region</h2>
              <ChartComponent
                id="region-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="yBeneficiaries"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="yYouthBeneficiaries"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container14">
              <h2>Youth Count by Sector</h2>
              <ChartComponent
                id="sector-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={sectorChartData}
                    xName="x"
                    yName="yBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={sectorChartData}
                    xName="x"
                    yName="yYouthBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-row1">
            <div className="chart-container15">
              <h2>Youth Count by Sub-Sector</h2>
              <ChartComponent
                id="sub-sector-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={subSectorChartData}
                    xName="x"
                    yName="yBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={subSectorChartData}
                    xName="x"
                    yName="yYouthBeneficiaries"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BenYouthDashboard;
