import React from 'react'
import '../Sidebar.css'
import BeneficiaryOption from './BeneficiaryOption';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ElderlyWomanIcon from '@mui/icons-material/ElderlyWoman';
import ScubaDivingIcon from '@mui/icons-material/ScubaDiving';
import HailIcon from '@mui/icons-material/Hail';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import WcIcon from '@mui/icons-material/Wc';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import PageviewIcon from '@mui/icons-material/Pageview';
import { NavLink } from 'react-router-dom';


function Beneficiaries() {
  return (
    <div className='sidebar'>
        <NavLink to="femaledashboard">{<BeneficiaryOption Icon = {ElderlyWomanIcon} title="Female Dashboard"/>}</NavLink>
        <NavLink to="youthdashboard">{<BeneficiaryOption Icon = {EscalatorWarningIcon} title="Youth Dashboard"/>}</NavLink>
        <NavLink to="listview">{<BeneficiaryOption Icon = {FamilyRestroomIcon} title="All Beneficiaries"/>}</NavLink>
        <NavLink to="activebeneficiaries">{<BeneficiaryOption Icon = {HailIcon} title="Active Beneficiaries"/>}</NavLink>
        <NavLink to="resignedbeneficiaries">{<BeneficiaryOption Icon = {ScubaDivingIcon} title="Resigned Beneficiaries"/>}</NavLink>
        <NavLink to="deceasedbeneficiaries">{<BeneficiaryOption Icon = {PersonOffIcon} title="Deceased Beneficiaries"/>}</NavLink>
        <NavLink to="nogenderspecified">{<BeneficiaryOption Icon = {WcIcon} title="No Gender Specified"/>}</NavLink>
        <NavLink to="noidnumber">{<BeneficiaryOption Icon = {EventBusyIcon} title="No ID Number"/>}</NavLink>               
    </div>
  )
}

export default Beneficiaries