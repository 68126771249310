import React, { useEffect, useState } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import "./ProDashboard.css";
import useProjectDataFetch from "../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../shared/useOtherDataFetch"; // Import the custom hook
import useDateCalculator from "../../shared/useDateCalculator";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  DataLabel,
  Category,
  Tooltip,
  LineSeries,
  ColumnSeries,
} from "@syncfusion/ej2-react-charts"; // Import Syncfusion chart components
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  PieSeries,
  AccumulationDataLabel,
} from "@syncfusion/ej2-react-charts";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import {
  FundedDataSource,
  getUpdateProjectGridData,
  identifiedDataSource,
} from "../../../utils/project";

function ProDashboard() {
  const user = useUserStore((state) => state.user);
  const dates = useDateCalculator();
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const {
    paymentData,
    beneficiaryData,
    attachmentData,
    isAttachmentLoaded,
    isBeneficiaryLoaded,
    isPaymentLoaded,
  } = useOtherDataFetch();
  const [projectNames, setProjectNames] = useState();
  const [status, setStatus] = useState();
  const [provinces, setProvinces] = useState();
  const [regions, setRegions] = useState();
  const [sectors, setSectors] = useState();
  const [subSectors, setSubSectors] = useState();

  const [projectFilter, setProjectFilter] = useState("All");
  const [provinceFilter, setProvinceFilter] = useState("All");
  const [regionFilter, setRegionFilter] = useState("All");
  const [sectorFilter, setSectorFilter] = useState("All");
  const [subSectorFilter, setSubSectorFilter] = useState("All");
  const [statusFilter, setStatusFilter] = useState("All");
  const [stageFilter, setStageFilter] = useState("All");
  const [phaseFilter, setPhaseFilter] = useState("All");
  const [noCompliantFilter, setNoCompliantFilter] = useState("All");
  const [bankAccountFilter, setBankAccountFilter] = useState("All");
  const [cipcCompliantFilter, setCipcCompliantFilter] = useState("All");
  const [beneficiaryFilter, setBeneficiaryFilter] = useState("All");
  const [identifiedDateFilter, setIdentifiedDateFilter] = useState("All");
  const [fundedDateFilter, setFundedDateFilter] = useState("All");

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isApiRequest, setIsApiRequest] = useState(true);
  const [updatedProjectData, setUpdatedProjectData] = useState([]);
  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    if (
      isProjectLoaded &&
      isAttachmentLoaded &&
      isBeneficiaryLoaded &&
      isPaymentLoaded
    ) {
      const finalProjectData = projectData.filter(
        (project) => project.ProjectStatus !== "Deleted"
      );
      setUpdatedProjectData(finalProjectData);
      setGridData(finalProjectData);
      setIsDataLoaded(true);
      setIsDataChanged(true);
    }
  }, [
    projectData,
    isProjectLoaded,
    isAttachmentLoaded,
    isBeneficiaryLoaded,
    isPaymentLoaded,
  ]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded && !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  useEffect(() => {
    //Get DropdownData
    const uniqueProvinces = [
      ...new Set(
        projectData?.map((item) => (item.Province ? item.Province : ""))
      ),
    ];
    const formattedUniqueProvinces = ["All", ...uniqueProvinces];
    setProvinces(formattedUniqueProvinces);

    const uniqueProjects = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.ProjectName ? item.ProjectName : ""
        )
      ),
    ];
    const formattedUniqueProjects = ["All", ...uniqueProjects];
    setProjectNames(formattedUniqueProjects);

    const uniqueregions = [
      ...new Set(
        updatedProjectData?.map((item) => (item.Region ? item.Region : ""))
      ),
    ];
    const formattedUniqueregions = ["All", ...uniqueregions];
    setRegions(formattedUniqueregions);

    const uniqueProjectstatus = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.ProjectStatus ? item.ProjectStatus : ""
        )
      ),
    ];
    const formattedUniqueProjectstatus = ["All", ...uniqueProjectstatus];
    setStatus(formattedUniqueProjectstatus);

    const uniqueSectors = [
      ...new Set(
        updatedProjectData?.map((item) => (item.Sector ? item.Sector : ""))
      ),
    ];
    const formattedUniqueSectors = ["All", ...uniqueSectors];
    setSectors(formattedUniqueSectors);

    const uniqueSubSectors = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.SubSector ? item.SubSector : ""
        )
      ),
    ];
    const formattedUniqueSubSectors = ["All", ...uniqueSubSectors];
    setSubSectors(formattedUniqueSubSectors);
  }, [updatedProjectData]);

  useEffect(() => {
    if (!isApiRequest) {
      const updatedData = getUpdateProjectGridData(
        updatedProjectData,
        beneficiaryData,
        provinceFilter,
        regionFilter,
        statusFilter,
        sectorFilter,
        subSectorFilter,
        stageFilter,
        phaseFilter,
        noCompliantFilter,
        bankAccountFilter,
        cipcCompliantFilter,
        beneficiaryFilter,
        identifiedDateFilter,
        fundedDateFilter,
        projectFilter,
        dates
      );

      setGridData(updatedData);
      setIsDataChanged(true);
      setIsApiRequest(true);
    }
  }, [
    updatedProjectData,
    beneficiaryData,
    provinceFilter,
    regionFilter,
    statusFilter,
    sectorFilter,
    subSectorFilter,
    stageFilter,
    phaseFilter,
    noCompliantFilter,
    bankAccountFilter,
    cipcCompliantFilter,
    beneficiaryFilter,
    identifiedDateFilter,
    fundedDateFilter,
    projectFilter,
    dates,
  ]);

  const handleProvinceChange = (e) => {
    if (e && e.value) {
      let provinceFilteredData;
      if (e.value !== "All") {
        provinceFilteredData = updatedProjectData?.filter(
          (item) => item.Province === e.value
        );
      } else {
        provinceFilteredData = updatedProjectData;
      }
      const uniqueregions = [
        ...new Set(
          provinceFilteredData?.map((item) => (item.Region ? item.Region : ""))
        ),
      ];
      const formattedUniqueregions = ["All", ...uniqueregions];
      setRegions(formattedUniqueregions);

      const uniqueProjects = [
        ...new Set(
          provinceFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setProvinceFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleRegionChange = (e) => {
    if (e && e.value) {
      let regionFilteredData;
      if (e.value !== "All") {
        regionFilteredData = updatedProjectData?.filter(
          (item) => item.Region === e.value
        );
      } else {
        regionFilteredData = updatedProjectData;
      }
      const uniqueProjects = [
        ...new Set(
          regionFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setRegionFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleStatusChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setStatusFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleSectorChange = (e) => {
    if (e && e.value) {
      let sectorFilteredData;
      if (e.value !== "All") {
        sectorFilteredData = updatedProjectData?.filter(
          (item) => item.Sector === e.value
        );
      } else {
        sectorFilteredData = updatedProjectData;
      }
      const uniqueSubSectors = [
        ...new Set(
          sectorFilteredData?.map((item) =>
            item.SubSector ? item.SubSector : ""
          )
        ),
      ];
      const formattedUniqueSubSectors = ["All", ...uniqueSubSectors];
      setSubSectors(formattedUniqueSubSectors);

      const uniqueProjects = [
        ...new Set(
          sectorFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setSectorFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleSubSectorChange = (e) => {
    if (e && e.value) {
      let subSectorFilteredData;
      if (e.value !== "All") {
        subSectorFilteredData = updatedProjectData?.filter(
          (item) => item.SubSector === e.value
        );
      } else {
        subSectorFilteredData = updatedProjectData;
      }

      const uniqueProjects = [
        ...new Set(
          subSectorFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setSubSectorFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleProjectChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setProjectFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleStageChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setStageFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handlePhaseChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setPhaseFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleNonComliantChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setNoCompliantFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleBankChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setBankAccountFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleCipcChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setCipcCompliantFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleBeneficiaryChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setBeneficiaryFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleIdentificationChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setIdentifiedDateFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleFundingChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setFundedDateFilter(e.value);
      setIsApiRequest(false);
    }
  };

  // Total the summaries
  // Calculate the total number of projects
  const totalProjects = gridData.length;
  const uniqueProjectNumbers = [
    ...new Set(
      gridData?.map((item) => (item.ProjectNum ? item.ProjectNum : ""))
    ),
  ];
  let totalBeneficiaries = 0;
  uniqueProjectNumbers.forEach((projectNum) => {
    const matchingBeneficiaries = beneficiaryData.filter(
      (beneficiary) => beneficiary.ProjNum === projectNum
    );
    totalBeneficiaries += matchingBeneficiaries.length;
  });
  // Format the totals as strings with a 1000 space separator
  const formattedTotalProjects = totalProjects?.toLocaleString("en-ZA");
  const formattedTotalBeneficiaries =
    totalBeneficiaries?.toLocaleString("en-ZA");

  // Get unique projects from gridData
  const uniqueProjects = [
    ...new Set(
      gridData?.map((item) => (item.ProjectNum ? item.ProjectNum : ""))
    ),
  ];
  // Initialize counts
  let totalProjectsCount = 0;
  let compliantProjectsCount = 0;
  let compliantPercentage = 0;
  let nonCompliantPercentage = 0;
  uniqueProjects.forEach((projectNum) => {
    // Filter beneficiaries for the current project
    const matchingBeneficiaries = beneficiaryData.filter(
      (beneficiary) =>
        beneficiary.ProjNum === projectNum && beneficiary.BenStatus === "Active"
    );
    // Find the project based on projectNum
    const project = gridData.find(
      (project) => project.ProjectNum === projectNum
    );

    // Check compliance conditions
    const isGreen =
      project.RegNum.length === 14 && project.RegNum.endsWith("/24");
    const isActiveBankAccount = project.ActiveBankAccount === "Yes";
    const isCompliant =
      isActiveBankAccount && matchingBeneficiaries.length >= 5 && isGreen;
    // Increment the totalProjectsCount
    totalProjectsCount++;

    // Increment the compliantProjectsCount if the project is compliant
    if (isCompliant) {
      compliantProjectsCount++;
    }
  });
  let formattedCompliantPercentage = 0;
  if (totalProjectsCount > 0) {
    compliantPercentage = (
      (compliantProjectsCount / totalProjectsCount) *
      100
    ).toFixed(0);
    nonCompliantPercentage = 100 - compliantPercentage;
    formattedCompliantPercentage = compliantPercentage.toString() + "%";
  } else {
    formattedCompliantPercentage = "0%";
  }
  // Prepare data for the chart
  const compliantChartData = [
    { x: "Compliant", y: compliantPercentage },
    { x: "NonComplian", y: nonCompliantPercentage },
  ];

  // Calculate the percentage of attachments loaded
  // Initialize total attachments count and loaded attachments count
  let totalAttachmentsCount = 0;
  let loadedAttachmentsCount = 0;
  uniqueProjectNumbers.forEach((projectNum) => {
    const matchingAttachments = attachmentData.filter(
      (attachment) => attachment.ProjectNum === projectNum
    );
    totalAttachmentsCount += matchingAttachments.length;
    loadedAttachmentsCount += matchingAttachments.filter(
      (attachment) => attachment.AttachPath !== ""
    ).length;
  });
  // Format the totals as strings with a 1000 space separator
  const formattedtotalAttachmentsCount =
    totalAttachmentsCount.toLocaleString("en-ZA");
  let attachmentLoadedPercentage = 0;
  let attachmentUnloadedPercentage = 0;
  let formattedAttachmentPercentage = 0; // Define the variable
  if (totalAttachmentsCount > 0) {
    attachmentLoadedPercentage = (
      (loadedAttachmentsCount / totalAttachmentsCount) *
      100
    ).toFixed(0);
    attachmentUnloadedPercentage = 100 - attachmentLoadedPercentage;
    formattedAttachmentPercentage = attachmentLoadedPercentage.toString() + "%"; // Assign the formatted percentage value
  } else {
    formattedAttachmentPercentage = "0%"; // Handle the case when there are no attachments
  }
  // Prepare data for the chart
  const attachmentChartData = [
    { x: "Uploaded", y: attachmentLoadedPercentage },
    { x: "NotAailable", y: attachmentUnloadedPercentage },
  ];

  // Assuming gridData is an array of objects with NormalBudget and AmountRequired properties
  const totalNormalBudget = gridData.reduce((total, project) => {
    // Ensure NormalBudget is a number before adding it
    const normalBudget = parseFloat(project.NormalBudget);
    return isNaN(normalBudget) ? total : total + normalBudget;
  }, 0);
  const totalAmountRequired = gridData.reduce((total, project) => {
    // Ensure AmountRequired is a number before adding it
    const amountRequired = parseFloat(project.AmountRequired);
    return isNaN(amountRequired) ? total : total + amountRequired;
  }, 0);
  const totalBudget = totalNormalBudget + totalAmountRequired;
  // Format the total budget as currency
  const formattedTotalBudget =
    "R" +
    totalBudget.toLocaleString("en-ZA", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

  //TOTAL PAYMENTS CALCULATION
  // Assuming you have already defined matchingPayments as:
  const matchingProjects = gridData;
  const matchingPayments = paymentData.filter((payment) =>
    matchingProjects.some(
      (project) => project.ProjectNum === payment.ProjectNum
    )
  );
  // Calculate TotalCost
  const totalCost = matchingPayments.reduce(
    (sum, payment) =>
      Number(sum) +
      (Number(payment.DebitAmount) - Number(payment.CreditAmount || 0)),
    0
  );
  // Format the total budget as currency
  const formattedTotalCost =
    "R" +
    totalCost.toLocaleString("en-ZA", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

  //REMAINING BUDGET CALCULATION
  const remainingBudget = totalBudget - totalCost;
  const textColor = remainingBudget < 0 ? "red" : "rgb(96,172,86)"; // Determine text color based on remainingBudget
  const formattedRemainingBudget =
    "R" +
    remainingBudget.toLocaleString("en-ZA", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

  // Count the occurrences of each ProjectStatus
  const projectStatusCounts = gridData.reduce((counts, item) => {
    const projectStatus = item.ProjectStatus; // Assuming the field name is 'ProjectStatus'
    counts[projectStatus] = (counts[projectStatus] || 0) + 1;
    return counts;
  }, {});

  // Prepare data for the chart
  const projectStatusChartData = Object.keys(projectStatusCounts).map(
    (projectStatus) => ({
      x: projectStatus,
      y: projectStatusCounts[projectStatus],
    })
  );

  // Count the occurrences of each BenStatus
  // Initialize an object to store the counts for each BenStatus
  const benStatusCounts = {};
  // Iterate through matching projects
  matchingProjects.forEach((project) => {
    const matchingBeneficiaries = beneficiaryData.filter(
      (beneficiary) => beneficiary.ProjNum === project.ProjectNum
    );
    // Count beneficiaries for each BenStatus
    matchingBeneficiaries.forEach((beneficiary) => {
      const benStatus = beneficiary.BenStatus; // Assuming the field name is 'BenStatus'
      benStatusCounts[benStatus] = (benStatusCounts[benStatus] || 0) + 1;
    });
  });
  // Prepare data for the chart
  const benStatusChartData = Object.keys(benStatusCounts).map((benStatus) => ({
    x: benStatus,
    y: benStatusCounts[benStatus],
  }));

  // Count the occurrences of each Region for projects
  const projectRegionCounts = gridData.reduce((counts, item) => {
    const region = item.Region;
    counts[region] = (counts[region] || 0) + 1;
    return counts;
  }, {});
  // Get unique regions from gridData
  const uniqueRegions = [...new Set(gridData?.map((item) => item.Region))];
  // Count beneficiaries for each region
  const beneficiaryRegionCounts = {};
  uniqueRegions.forEach((region) => {
    const matchingProjects = gridData.filter((item) => item.Region === region);
    // Initialize counts for both Beneficiaries
    beneficiaryRegionCounts[region] = 0;
    matchingProjects.forEach((project) => {
      const matchingBeneficiaries = beneficiaryData.filter(
        (item) => item.ProjNum === project.ProjectNum
      );
      // Count Beneficiaries
      beneficiaryRegionCounts[region] += matchingBeneficiaries.length; // Total Beneficiaries count
    });
  });
  // Prepare data for the chart
  const regionChartData = uniqueRegions.map((region) => ({
    x: region, // Use the region name as the x value
    yProjects: projectRegionCounts[region] || 0, // Total Projects count for the region
    yBeneficiaries: beneficiaryRegionCounts[region] || 0, // Total Beneficiaries count for the region
  }));

  // Calculate the total number of beneficiaries and the number of female beneficiaries
  let totalCount = 0;
  let femaleCount = 0;
  matchingProjects.forEach((project) => {
    const matchingBeneficiaries = beneficiaryData.filter(
      (beneficiary) => beneficiary.ProjNum === project.ProjectNum
    );
    totalCount += matchingBeneficiaries.length;
    femaleCount += matchingBeneficiaries.filter(
      (beneficiary) => beneficiary.Gender === "Female"
    ).length;
  });
  // Calculate the percentage of females and others
  const femalePercentage = ((femaleCount / totalCount) * 100).toFixed(0);
  const otherPercentage = 100 - femalePercentage;
  const formattedFemalePercentage = femalePercentage.toString() + "%";
  // Prepare data for the chart
  const femaleChartData = [
    { x: "Female", y: femalePercentage },
    { x: "Other", y: otherPercentage },
  ];

  let youthCount = 0;
  matchingProjects.forEach((project) => {
    const matchingBeneficiaries = beneficiaryData.filter(
      (beneficiary) => beneficiary.ProjNum === project.ProjectNum
    );
    matchingBeneficiaries.forEach((beneficiary) => {
      if (!beneficiary.D_O_B) {
        return; // Skip if Date of Birth is missing
      }
      const dob = new Date(beneficiary.D_O_B);
      if (!isNaN(dob.getTime())) {
        const currentDate = new Date();
        const ageInMilliseconds = currentDate - dob;
        const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
        if (ageInYears < 36) {
          youthCount++; // Increment the youth count
        }
      }
    });
  });

  // Calculate the percentage of youth and other beneficiaries
  const youthPercentage = ((youthCount / totalCount) * 100).toFixed(0);
  const formattedYouthPercentage = youthPercentage.toString() + "%";
  const olderPercentage = 100 - youthPercentage;
  // Prepare data for the chart
  const youthChartData = [
    { x: "Youth", y: youthPercentage },
    { x: "Older", y: olderPercentage },
  ];

  // Initialize the total count of pensioner beneficiaries
  let pensionersCount = 0;
  // Iterate through matching projects
  matchingProjects.forEach((project) => {
    // Filter beneficiaries for the current project
    const matchingBeneficiaries = beneficiaryData.filter(
      (beneficiary) => beneficiary.ProjNum === project.ProjectNum
    );
    // Check age for each beneficiary
    matchingBeneficiaries.forEach((beneficiary) => {
      if (!beneficiary.D_O_B) {
        return; // Skip if Date of Birth is missing
      }
      const dob = new Date(beneficiary.D_O_B);
      if (!isNaN(dob.getTime())) {
        const currentDate = new Date();
        const ageInMilliseconds = currentDate - dob;
        const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
        if (ageInYears >= 60) {
          pensionersCount++; // Increment the pensioner count
        }
      }
    });
  });
  // Calculate the percentage of youth and other beneficiaries
  const pensionersPercentage = ((pensionersCount / totalCount) * 100).toFixed(
    0
  );
  const formattedPensionersPercentage = pensionersPercentage.toString() + "%";
  const youngerPercentage = 100 - pensionersPercentage;
  // Prepare data for the chart
  const pensionersChartData = [
    { x: "Pensioners", y: pensionersPercentage },
    { x: "Younger", y: youngerPercentage },
  ];

  const onPointRender0 = (args) => {
    if (args.point.x.indexOf("Compliant") > -1) {
      args.fill = "green";
    } else {
      args.fill = "lightgray";
    }
  };

  const onPointRender00 = (args) => {
    if (args.point.x.indexOf("Uploaded") > -1) {
      args.fill = "rgb(96,172,86)";
    } else {
      args.fill = "lightgray";
    }
  };

  const onPointRender1 = (args) => {
    if (args.point.x.indexOf("Female") > -1) {
      args.fill = "#20B2AA";
    } else {
      args.fill = "lightgray";
    }
  };
  const onPointRender2 = (args) => {
    if (args.point.x.indexOf("Youth") > -1) {
      args.fill = "#008080";
    } else {
      args.fill = "lightgray";
    }
  };
  const onPointRender3 = (args) => {
    if (args.point.x.indexOf("Pensioners") > -1) {
      args.fill = "#00CED1";
    } else {
      args.fill = "lightgray";
    }
  };
  const chartWidth = "100%";
  const chartHeight = "80%";
  const primaryxAxis = {
    valueType: "Category",
    labelRotation: -45,
    labelStyle: {
      color: "rgb(96,172,86)",
      fontWeight: "bold",
      size: "14px",
      fontFamily: "Segoe UI",
    },
  };
  const primaryyAxis = { visible: false };
  const marker = {
    dataLabel: {
      visible: true,
      font: {
        color: "rgb(96,172,86)",
        background: "white",
        fontWeight: "bold",
        size: "16px",
        fontFamily: "Segoe UI",
      },
    },
  };
  const tooltip = { enable: false, shared: false };
  const varyingColors = [
    "#008080",
    "#00CED1",
    "#20B2AA",
    "rgb(96,172,86)",
    "#1565C0",
    "#FF5722",
    "#E91E63",
    "#9C27B0",
    "#009688",
    "#FF9800",
  ];

  return (
    <div className="home-provincial-reports-container1">
      <div className="summary-container-project">
        <div className="decicion_requester1">
          <ComboBoxComponent
            id="comboelement"
            value={user.AccessProvince}
            enabled={false}
            placeholder="Selected Province"
            floatLabelType="Always"
            dataSource={provinces}
            change={handleProvinceChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Region"
            floatLabelType="Always"
            dataSource={regions}
            change={handleRegionChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Sector"
            floatLabelType="Always"
            dataSource={sectors}
            change={handleSectorChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Sub-Sector"
            floatLabelType="Always"
            dataSource={subSectors}
            change={handleSubSectorChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Project Status"
            floatLabelType="Always"
            dataSource={status}
            change={handleStatusChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Project"
            floatLabelType="Always"
            dataSource={projectNames}
            change={handleProjectChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Phase"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Phase 1: Coordinators",
              "Phase 2: Facilitattors",
              "Phase 3: NF",
            ]}
            change={handlePhaseChange}
          />
        </div>
      </div>
      <div className="summary-container-project">
        <div className="decicion_requester1">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Stage"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Identification",
              "Capacity Building",
              "Funding",
              "Monitoring",
            ]}
            change={handleStageChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Overall Compliant"
            floatLabelType="Always"
            dataSource={["All", "Compliant", "Non-Compliant"]}
            change={handleNonComliantChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Beneficiary Compliant"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Sufficiant Beneficiaries",
              "Insufficiant Beneficiaries",
            ]}
            change={handleBeneficiaryChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Bank Compliant"
            floatLabelType="Always"
            dataSource={["All", "With Bank Account", "No Bank Account"]}
            change={handleBankChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="CIPC Compliant"
            floatLabelType="Always"
            dataSource={["All", "CIPC Compliant", "CIPC Non-Compliant"]}
            change={handleCipcChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Identification Date"
            floatLabelType="Always"
            dataSource={identifiedDataSource}
            change={handleIdentificationChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Funding Date"
            floatLabelType="Always"
            dataSource={FundedDataSource}
            change={handleFundingChange}
          />
        </div>
      </div>
      <div className="prochart-row5">
        <div className="summary-container3">
          <h2>Projects</h2>
          <h1>{formattedTotalProjects}</h1>
        </div>
        <div className="summary-container3">
          <h2>Beneficiaries</h2>
          <h1>{formattedTotalBeneficiaries}</h1>
        </div>
        <div className="summary-container3">
          <h2>Attachments</h2>
          <h1>{formattedtotalAttachmentsCount}</h1>
        </div>
        <div className="summary-container4">
          <h2>Total Budget</h2>
          <h1>{formattedTotalBudget}</h1>
        </div>
        <div className="summary-container4">
          <h2>Total Cost</h2>
          <h1>{formattedTotalCost}</h1>
        </div>
        <div className="summary-container4">
          <h2>Remaining</h2>
          <h1>
            <div style={{ color: textColor }}>{formattedRemainingBudget}</div>
          </h1>
        </div>
      </div>

      <div className="prochart-row6">
        <div className="chart-cont10">
          <h2>Compliant %</h2>
          <AccumulationChartComponent
            id="compliant-chart"
            centerLabel={{
              text: formattedCompliantPercentage,
              textStyle: {
                fontWeight: "900",
                size: "35px",
                color: "green",
                fontFamily: "Roboto",
                textAlignment: "center",
              },
            }}
            width={chartWidth}
            height={chartHeight}
            primaryXAxis={primaryxAxis}
            primaryYAxis={primaryyAxis}
            tooltip={tooltip}
            pointRender={onPointRender0}
          >
            <Inject
              services={[PieSeries, Tooltip, Category, AccumulationDataLabel]}
            />
            <AccumulationSeriesCollectionDirective>
              <AccumulationSeriesDirective
                dataSource={compliantChartData}
                xName="x"
                yName="y"
                type="Pie"
                name="Compliant"
                innerRadius="50%"
                startAngle="270"
              />
            </AccumulationSeriesCollectionDirective>
          </AccumulationChartComponent>
        </div>

        <div className="chart-cont11">
          <h2>Female %</h2>
          <AccumulationChartComponent
            id="female-chart"
            centerLabel={{
              text: formattedFemalePercentage,
              textStyle: {
                fontWeight: "900",
                size: "35px",
                color: "green",
                fontFamily: "Roboto",
                textAlignment: "center",
              },
            }}
            width={chartWidth}
            height={chartHeight}
            primaryXAxis={primaryxAxis}
            primaryYAxis={primaryyAxis}
            tooltip={tooltip}
            pointRender={onPointRender1}
          >
            <Inject
              services={[PieSeries, Tooltip, Category, AccumulationDataLabel]}
            />
            <AccumulationSeriesCollectionDirective>
              <AccumulationSeriesDirective
                dataSource={femaleChartData}
                xName="x"
                yName="y"
                type="Pie"
                name="Female"
                innerRadius="50%"
                startAngle="270"
              />
            </AccumulationSeriesCollectionDirective>
          </AccumulationChartComponent>
        </div>
        <div className="chart-cont12">
          <h2>Youth %</h2>
          <AccumulationChartComponent
            id="youth-chart"
            centerLabel={{
              text: formattedYouthPercentage,
              textStyle: {
                fontWeight: "900",
                size: "35px",
                color: "#008080",
                fontFamily: "Roboto",
                textAlignment: "center",
              },
            }}
            width={chartWidth}
            height={chartHeight}
            primaryXAxis={primaryxAxis}
            primaryYAxis={primaryyAxis}
            tooltip={tooltip}
            pointRender={onPointRender2}
          >
            <Inject
              services={[PieSeries, Tooltip, Category, AccumulationDataLabel]}
            />
            <AccumulationSeriesCollectionDirective>
              <AccumulationSeriesDirective
                dataSource={youthChartData}
                xName="x"
                yName="y"
                type="Pie"
                name="Youth"
                innerRadius="50%"
                startAngle="270"
              />
            </AccumulationSeriesCollectionDirective>
          </AccumulationChartComponent>
        </div>
        <div className="chart-cont13">
          <h2>Pensioners %</h2>
          <AccumulationChartComponent
            id="pensioners-chart"
            centerLabel={{
              text: formattedPensionersPercentage,
              textStyle: {
                fontWeight: "900",
                size: "35px",
                color: "#00CED1",
                fontFamily: "Roboto",
                textAlignment: "center",
              },
            }}
            width={chartWidth}
            height={chartHeight}
            primaryXAxis={primaryxAxis}
            primaryYAxis={primaryyAxis}
            tooltip={tooltip}
            pointRender={onPointRender3}
          >
            <Inject
              services={[PieSeries, Tooltip, Category, AccumulationDataLabel]}
            />
            <AccumulationSeriesCollectionDirective>
              <AccumulationSeriesDirective
                dataSource={pensionersChartData}
                xName="x"
                yName="y"
                type="Pie"
                name="Pensioners"
                innerRadius="50%"
                startAngle="270"
              />
            </AccumulationSeriesCollectionDirective>
          </AccumulationChartComponent>
        </div>
        <div className="chart-cont14">
          <h2>Attachment %</h2>
          <AccumulationChartComponent
            id="Attach-chart"
            centerLabel={{
              text: formattedAttachmentPercentage,
              textStyle: {
                fontWeight: "900",
                size: "35px",
                color: "green",
                fontFamily: "Roboto",
                textAlignment: "center",
              },
            }}
            width={chartWidth}
            height={chartHeight}
            primaryXAxis={primaryxAxis}
            primaryYAxis={primaryyAxis}
            tooltip={tooltip}
            pointRender={onPointRender00}
          >
            <Inject
              services={[PieSeries, Tooltip, Category, AccumulationDataLabel]}
            />
            <AccumulationSeriesCollectionDirective>
              <AccumulationSeriesDirective
                dataSource={attachmentChartData}
                xName="x"
                yName="y"
                type="Pie"
                name="Attachment"
                innerRadius="50%"
                startAngle="270"
              />
            </AccumulationSeriesCollectionDirective>
          </AccumulationChartComponent>
        </div>
      </div>

      <div className="prochart-row7">
        <div className="chart-cont15">
          <h2>Projects & Beneficiaries by Region</h2>
          <ChartComponent
            id="regions-chart"
            width={chartWidth}
            height={chartHeight}
            primaryXAxis={primaryxAxis}
            primaryYAxis={primaryyAxis}
            tooltip={tooltip}
          >
            <Inject
              services={[
                ColumnSeries,
                LineSeries,
                Tooltip,
                Legend,
                Category,
                DataLabel,
              ]}
            />
            <SeriesCollectionDirective>
              <SeriesDirective
                dataSource={regionChartData}
                xName="x"
                yName="yProjects"
                type="Column"
                name="Projects"
                marker={marker}
                fill={varyingColors[8]}
              />
              <SeriesDirective
                dataSource={regionChartData}
                xName="x"
                yName="yBeneficiaries"
                type="Column"
                name="Beneficiaries"
                marker={marker}
                fill={varyingColors[7]}
              />
            </SeriesCollectionDirective>
          </ChartComponent>
        </div>
        <div className="chart-cont16">
          <h2>Projects by Status</h2>
          <ChartComponent
            id="projstatus-chart"
            width={chartWidth}
            height={chartHeight}
            primaryXAxis={primaryxAxis}
            primaryYAxis={primaryyAxis}
            tooltip={tooltip}
          >
            <Inject services={[ColumnSeries, Tooltip, Category, DataLabel]} />
            <SeriesCollectionDirective>
              <SeriesDirective
                dataSource={projectStatusChartData}
                xName="x"
                yName="y"
                type="Column"
                name="Budget"
                marker={marker}
                fill={varyingColors[6]}
              />
            </SeriesCollectionDirective>
          </ChartComponent>
        </div>
        <div className="chart-cont17">
          <h2>Beneficiaries by Status</h2>
          <ChartComponent
            id="benstatus-chart"
            width={chartWidth}
            height={chartHeight}
            primaryXAxis={primaryxAxis}
            primaryYAxis={primaryyAxis}
            tooltip={tooltip}
          >
            <Inject services={[ColumnSeries, Tooltip, Category, DataLabel]} />
            <SeriesCollectionDirective>
              <SeriesDirective
                dataSource={benStatusChartData}
                xName="x"
                yName="y"
                type="Column"
                name="Budget"
                marker={marker}
                fill={varyingColors[5]}
              />
            </SeriesCollectionDirective>
          </ChartComponent>
        </div>
      </div>
    </div>
  );
}

export default ProDashboard;
