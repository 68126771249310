import React, { useEffect, useRef, useState } from "react";
import "./MessageBox.css";
import axios from "../../../config/axios";
import useUserStore from "../../../app/user";
import { Send } from "@mui/icons-material";
import socket from "../../../utils/socket";

function MessageBox({ users, chatID, type, chatUsers }) {
  //Define an array of JSON data
  const user = useUserStore((state) => state.user);
  const [messageLoading, setMessageLoading] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [filterChatMessages, setFilterChatMessages] = useState([]);
  const [showTypingNotification, setShowTypingNotification] = useState(false);

  // console.log("users: ", users);
  // console.log("chatID: ", chatID);
  // console.log("type: ", type);
  // console.log("chatUsers: ", chatUsers);

  const messageRef = useRef();

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollTop = messageRef.current.scrollHeight;
    }
  }, [messageRef, filterChatMessages]);

  useEffect(() => {
    if (chatMessages?.length) {
      setFilterChatMessages(removeDuplicates(chatMessages, "SentAt"));
    } else setFilterChatMessages([]);
  }, [chatMessages, chatID]);

  useEffect(() => {
    const getMesssages = async () => {
      setMessageLoading(() => true);

      axios
        .get(process.env.REACT_APP_SERVER_URL + `/api/chat?type=${type}&id=${chatID}`)
        .then(({ data }) => {
          setChatMessages(data.items);
          setMessageLoading(() => false);
        })
        .catch((err) => {
          setMessageLoading(() => false);
          console.log(err);
        });
    };
    if (chatID) getMesssages();
  }, [chatID]);

  useEffect(() => {
    if (chatID) {
      socket.emit("join-chat", chatID.toString() + "-" + type, (ackMessage) => {
        console.log("Acknowledgment received:", ackMessage);
      });

      socket.on("receiveMessage", (data) => {
        // console.log("Receive message : ", data);
        setChatMessages((prev) => [...prev, data]);
      });

      socket.on("showTyping", () => {
        setShowTypingNotification(true);
      });

      socket.on("hideTyping", () => {
        setShowTypingNotification(false);
      });

      // Clean up the socket connection when component is unmounted
      // return () => {
      //   console.log("disconnected User.....");
      // };
    }
  }, [chatID]);

  const handleMessage = (e) => {
    const target = e.target;

    if (!target) return;
    if (!showTypingNotification && socket) {
      socket.emit("typing");
      setTimeout(() => {
        socket.emit("stop-typing");
      }, 2000); // Automatically emit 'stop typing' after 2 seconds of inactivity
    }
    setMessageText(target.value);
  };

  const handleMessageSubmit = (e) => {
    e.preventDefault();

    if (socket && messageText) {
      socket.emit("sendMessage", user.id, type, chatID?.toString(), messageText, new Date(), (users = chatUsers?.map((user) => user.id)));
    }

    setMessageText("");
  };

  return (
    <div className="container">
      <div className="chat-page">
        <div className="msg-inbox">
          <div className="chats" ref={messageRef}>
            {filterChatMessages?.map((message, index) => {
              if (message.SenderId !== user.id)
                return (
                  <div className="received-chats" key={index}>
                    <div className="received-chats-img">{getUser(users, message.SenderId)?.FullName?.split(" ")[0]}</div>
                    <div className="received-msg">
                      <div className="received-msg-inbox">
                        <p>{message.MessageText}</p>
                        <span className="time">{formatDateToCustomString(new Date(message.SentAt))}</span>
                      </div>
                    </div>
                  </div>
                );
              else
                return (
                  <div className="outgoing-chats" key={index}>
                    <div className="outgoing-msg">
                      <div className="outgoing-chats-msg">
                        <p className="multi-msg">{message.MessageText}</p>

                        <span className="time">{formatDateToCustomString(new Date(message.SentAt))}</span>
                      </div>
                    </div>
                  </div>
                );
            })}
          </div>

          <div className="msg-bottom">
            <div className="msg-input-group">
              <textarea className="msg-form-control" placeholder="Write message..." onChange={handleMessage} value={messageText} />
              <div className="input-group-text send-icon" onClick={handleMessageSubmit}>
                Send
                <Send />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MessageBox;

function formatDateToCustomString(date) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  const formattedTime = `${(hours % 12).toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${amOrPm}`;

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${formattedTime} | ${month} ${day}, ${year}`;
}

const getUser = (users, id) => {
  return users?.find((user) => user.id === id);
};

const removeDuplicates = (arr, prop) => {
  const seen = new Map();
  return arr.filter((item) => {
    const key = prop ? item[prop] : JSON.stringify(item);
    if (!seen.has(key)) {
      seen.set(key, true);
      return true;
    }
    return false;
  });
};
