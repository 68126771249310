import React from "react";
import "./Home.css";
import NatDashboard from "./NatDashboard";
import ProDashboard from "./ProDashboard";
import RegDashboard from "./RegDashboard";
import useUserStore from "../../../app/user";

function Home() {
  const user = useUserStore((state) => state.user);
  return (
    <div>
      {/*National Dashboard*/}
      {user?.Access === "National" && (
        <div>
          <NatDashboard />
        </div>
      )}

      {/*Provincial Dashboard*/}
      {user?.Access === "Provincial" && (
        <div>
          <ProDashboard />
        </div>
      )}

      {/*Regional Dashboard*/}
      {user?.Access === "Regional" && (
        <div>
          <RegDashboard />
        </div>
      )}
    </div>
  );
}

export default Home;
