import { create } from "zustand";
import { persist } from "zustand/middleware";

const useUserStore = create()(
  persist(
    (set) => ({
      user: null,
      jwt: null,
      setUser: (user) => set(() => ({ user: user })),
      setJwt: (token) => set(() => ({ jwt: token })),
      clearUser: () => set(() => ({ user: null, jwt: null })),
    }),
    {
      name: "nmrw",
    }
  )
);

export default useUserStore;
