import React from 'react'
import '../Sidebar.css'
import CompliancyOption from './CompliancyOption';
import ListIcon from '@mui/icons-material/List';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import NoBackpackIcon from '@mui/icons-material/NoBackpack';
import NoSimIcon from '@mui/icons-material/NoSim';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';
import { NavLink } from 'react-router-dom';

function Compliancy() {
  return (
    <div className='sidebar'>
        <NavLink to="dashboard">{<CompliancyOption Icon = {AssessmentIcon} title="Dashboard"/>}</NavLink>
        <NavLink to="listview">{<CompliancyOption Icon = {ListIcon} title="List of All Projects"/>}</NavLink>
        <NavLink to="notactive">{<CompliancyOption Icon = {AirplanemodeInactiveIcon} title= "Not Active"/>}</NavLink>
        <NavLink to="insuffientben">{<CompliancyOption Icon = {PersonOffIcon} title="With <5 Active Beneficiaries"/>}</NavLink>
        <NavLink to="nobankaccount">{<CompliancyOption Icon = {NoBackpackIcon} title="With No Bank Account"/>}</NavLink>
        <NavLink to="cipcnotcompliant">{<CompliancyOption Icon = {NoSimIcon} title="CIPC Not Compliant"/>}</NavLink>
        <NavLink to="cipcnotinbusiness">{<CompliancyOption Icon = {WorkOffIcon} title="Not in Business"/>}</NavLink>
        <NavLink to="notcompliant">{<CompliancyOption Icon = {ReportProblemIcon} title="Not Compliant"/>}</NavLink>
                
    </div>
  )
}

export default Compliancy