import React, { useState, useEffect} from "react";
import "./TaskDashboard.css";
import useProjectDataFetch from "../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../shared/useOtherDataFetch"; // Import the custom hook
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  DataLabel,
  Category,
  Tooltip,
  ColumnSeries,
} from "@syncfusion/ej2-react-charts"; // Import Syncfusion chart components
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import useUserStore from "../../../../app/user";
import { toast } from "react-toastify";

function TaskDashboard() {
  const user = useUserStore((state) => state.user);
  const {projectData, isProjectLoaded} = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { taskData, isTaskLoaded} = useOtherDataFetch();
  const [filteredTaskData, setFilteredTaskData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const handleComboBoxChange = (e) => {
    const finalProjectData = projectData.filter(
      (project) =>
        project.ProjectStatus !== "Deleted"
    );
    const matchingTasks = taskData.filter((task) => {
      const matchingProject = finalProjectData.find(
        (project) => project.id === task.ProjectID
      );
      return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
    });
    let filteredData = matchingTasks;
    if (e.value === "All Tasks") {
      filteredData = matchingTasks.filter((task) => {
        const taskDuration = task.Duration;     
        return (taskDuration > 0);
      });
    } else if (e.value === "Due This Year") {
      filteredData = matchingTasks.filter((task) => {
        const taskProgress = task.Progress;
        const taskDuration = task.Duration;
        const taskStartDate = new Date(task.StartDate);
        const taskEndDate = new Date(taskStartDate);
        taskEndDate.setDate(taskEndDate.getDate() + taskDuration);
        const currentDate = new Date();
        const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
        const lastDayOfYear = new Date(currentDate.getFullYear(), 11, 31);
        return (taskEndDate >= firstDayOfYear && taskEndDate <= lastDayOfYear && taskProgress < 100 && taskDuration > 0);
      });
    } else if (e.value === "Due This Quarter") {
      filteredData = matchingTasks.filter((task) => {
        const taskProgress = task.Progress;
        const taskDuration = task.Duration;
        const taskStartDate = new Date(task.StartDate);
        const taskEndDate = new Date(taskStartDate);
        taskEndDate.setDate(taskEndDate.getDate() + taskDuration);
        const currentDate = new Date();
        const currentQuarterMonth = Math.floor(currentDate.getMonth() / 3) * 3;
        const firstDayOfQuarter = new Date(currentDate.getFullYear(), currentQuarterMonth, 1);
        const lastDayOfQuarter = new Date(currentDate.getFullYear(), currentQuarterMonth + 3, 0);
        return (taskEndDate >= firstDayOfQuarter && taskEndDate <= lastDayOfQuarter && taskProgress < 100 && taskDuration > 0
          );
      });
    } else if (e.value === "Due This Month") {
      filteredData = matchingTasks.filter((task) => {
        const taskProgress = task.Progress;
        const taskDuration = task.Duration;
        const taskStartDate = new Date(task.StartDate);
        const taskEndDate = new Date(taskStartDate);
        taskEndDate.setDate(taskEndDate.getDate() + taskDuration);
        const currentDate = new Date();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        return (taskEndDate >= firstDayOfMonth && taskEndDate <= lastDayOfMonth && taskProgress < 100 && taskDuration > 0);
      });
    }else if (e.value === "Due This Week") {
      filteredData = matchingTasks.filter((task) => {
        const taskProgress = task.Progress;
        const taskDuration = task.Duration;
        const taskStartDate = new Date(task.StartDate);
        const taskEndDate = new Date(taskStartDate);
        taskEndDate.setDate(taskEndDate.getDate() + taskDuration);
        const currentDate = new Date();
        const beginningOfWeek = new Date(currentDate);
        beginningOfWeek.setDate(currentDate.getDate() - currentDate.getDay() + 1);
        const endOfWeek = new Date(currentDate);
        endOfWeek.setDate(currentDate.getDate() + (7 - currentDate.getDay()));
        return (taskEndDate >= beginningOfWeek && taskEndDate <= endOfWeek && taskProgress < 100 && taskDuration > 0);
      });
    }else if (e.value === "Due Today") {
      filteredData = matchingTasks.filter((task) => {
        const taskProgress = task.Progress;
        const taskDuration = task.Duration;
        const taskStartDate = new Date(task.StartDate);
        const taskEndDate = new Date(taskStartDate);
        taskEndDate.setDate(taskEndDate.getDate() + taskDuration);
        const currentDate = new Date();
        return (taskEndDate === currentDate && taskProgress < 100 && taskDuration > 0);
      });
    }else if (e.value === "Passed Due Date") {
      filteredData = matchingTasks.filter((task) => {
        const taskProgress = task.Progress;
        const taskDuration = task.Duration;
        const taskStartDate = new Date(task.StartDate);
        const taskEndDate = new Date(taskStartDate);
        taskEndDate.setDate(taskEndDate.getDate() + taskDuration);
        const currentDate = new Date();
        return (taskEndDate < currentDate && taskProgress < 100 && taskDuration > 0);
      });
    }
    
    setFilteredTaskData(filteredData); // Update state to trigger re-render
  };

  useEffect(() => {
    if (isProjectLoaded && isTaskLoaded){
      const finalProjectData = projectData.filter(
        (project) =>
          project.ProjectStatus !== "Deleted"
      );
      const matchingTasks = taskData.filter((task) => {
        const matchingProject = finalProjectData.find(
          (project) => project.id === task.ProjectID
        );
        return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
      });
      setFilteredTaskData(matchingTasks);
      setIsDataLoaded(true)
    }
  }, [projectData, taskData, isTaskLoaded, isProjectLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  // Get unique provinces from projectData
  const uniqueProvinces = [
    ...new Set(projectData?.map((item) => item.Province)),
  ];
  // Count tasks for each province
  const provinceCounts = {};
  uniqueProvinces.forEach((province) => {
    const matchingProjects = projectData.filter(
      (item) => item.Province === province
    );
    // Initialize count for tasks
    provinceCounts[province] = 0;
    matchingProjects.forEach((project) => {
      const matchingTasks = filteredTaskData.filter(
        (item) => item.ProjectID === project.id
      );
      // Count tasks
      matchingTasks.forEach(() => {
        provinceCounts[province]++; // Total tasks count        
      });
    });
  });
  // Prepare data for the chart
  const provinceChartData = Object.keys(provinceCounts).map((province) => ({
    x: province,
    y: provinceCounts[province],
  }));

  // Get unique regions from projectData
  const uniqueRegions = [...new Set(projectData?.map((item) => item.Region))];
  // Count beneficiaries for each region
  const regionCounts = {};
  uniqueRegions.forEach((region) => {
    const matchingProjects = projectData.filter(
      (item) => item.Region === region
    );
    // Initialize counts for both Beneficiaries and Youth Beneficiaries
    regionCounts[region] = 0;
    matchingProjects.forEach((project) => {
      const matchingTasks = filteredTaskData.filter(
        (item) => item.ProjectID === project.id
      );
      // Count tasks
      matchingTasks.forEach(() => {
        regionCounts[region]++; // Total Beneficiaries count
      });
    });
  });
  // Prepare data for the chart
  const regionChartData = Object.keys(regionCounts).map((region) => ({
    x: region,
    y: regionCounts[region],
  }));

  
  // Get unique sectors from projectData
  const uniqueSectors = [...new Set(projectData?.map((item) => item.Sector))];
  // Count beneficiaries for each sector
  const sectorCounts = {};
  uniqueSectors.forEach((sector) => {
    const matchingProjects = projectData.filter(
      (item) => item.Sector === sector
    );
    // Initialize counts for tasks
    sectorCounts[sector] = 0;
    matchingProjects.forEach((project) => {
      const matchingTasks = filteredTaskData.filter(
        (item) => item.ProjectID === project.id
      );
      // Count Beneficiaries and Youth Beneficiaries separately
      matchingTasks.forEach(() => {
        sectorCounts[sector]++; // Total Beneficiaries count
      });
    });
  });
  // Prepare data for the chart
  const sectorChartData = Object.keys(sectorCounts).map((sector) => ({
    x: sector,
    y: sectorCounts[sector],
  }));

  // Count tasks for all projects nationally
  const totalTasks = filteredTaskData.length;
  // Prepare data for the chart
  const taskChartData = [
    {
      x: "National",
      y: totalTasks,
    },
  ];

  // Get unique subsectors from projectData
  const uniqueSubSectors = [
    ...new Set(projectData?.map((item) => item.SubSector)),
  ];
  // Count tasks for each subsector
  const subSectorCounts = {};
  uniqueSubSectors.forEach((subSector) => {
    const matchingProjects = projectData.filter(
      (item) => item.SubSector === subSector
    );
    // Initialize counts for tasks
    subSectorCounts[subSector] = 0;
    matchingProjects.forEach((project) => {
      const matchingTasks = filteredTaskData.filter(
        (item) => item.ProjectID === project.id
      );
      // Count Tasks
      matchingTasks.forEach((beneficiary) => {
        subSectorCounts[subSector]++; // Total Tasks count
      });
    });
  });
  // Prepare data for the chart
  const subSectorChartData = Object.keys(subSectorCounts).map((subSector) => ({
    x: subSector,
    y: subSectorCounts[subSector],
  }));

  const chartWidth = "100%"; // Set the desired chart width (e.g., "100%", "80px", etc.)
  const chartHeight = "80%"; // Set the desired chart width (e.g., "100%", "80px", etc.)
  const primaryxAxis = {
    valueType: "Category",
    labelRotation: -45,
    labelStyle: {
      color: "rgb(96,172,86)",
      fontWeight: "bold",
      size: "16px",
      fontFamily: "Segoe UI",
    },
  };
  const primaryyAxis = { visible: false };
  const marker = {
    dataLabel: {
      visible: true,
      font: {
        color: "rgb(96,172,86)",
        background: "white",
        fontWeight: "bold",
        size: "14px",
        fontFamily: "Segoe UI",
      },
    },
  };
  const tooltip = { enable: false, shared: false };
  // Define an array of varying colors for each series
  const varyingColors = [
    "rgb(96,172,86)",
    "#1565C0",
    "#FF5722",
    "#E91E63",
    "#9C27B0",
    "#009688",
    "#FF9800",
  ];

  return (
    <div className="reports-task-container">
      <div className="title-group">
        <ComboBoxComponent
          dataSource={[
            "All Tasks",
            "Passed Due Date",
            "Due Today",
            "Due This Week",
            "Due This Month",
            "Due This Quarter",
            "Due This Year",
          ]}
          value="All Tasks"
          change={handleComboBoxChange}
          placeholder="Make your selection"
          className="combo-box"
        />
      </div>
      {/*National Dashboard*/}
      {user?.Access === "National" && (
        <div className="national-reports-task-container">
          <div className="chart-task-row">
            <div className="chart-task-container1">
              <h2>Tasks by National</h2>
              <ChartComponent
                id="national-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={taskChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-task-container2">
              <h2>Tasks by Province</h2>
              <ChartComponent
                id="province-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={provinceChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-task-container3">
              <h2>Tasks by Sector</h2>
              <ChartComponent
                id="sector-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={sectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[2]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-task-row">
            <div className="chart-task-container4">
              <h2>Tasks by Sub-Sector</h2>
              <ChartComponent
                id="sub-sector-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={subSectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[3]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-task-row">
            <div className="chart-task-container5">
              <h2>Tasks by Region</h2>
              <ChartComponent
                id="region-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Region"
                    marker={marker}
                    fill={varyingColors[4]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      )}

      {/*Provincial Dashboard*/}
      {user?.Access === "Provincial" && (
        <div className="provincial-reports-task-container">
          <div className="chart-task-row">
            <div className="chart-task-container6">
              <h2>Tasks by Province</h2>
              <ChartComponent
                id="province-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={provinceChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>

            <div className="chart-task-container7">
              <h2>Tasks by Region</h2>
              <ChartComponent
                id="region-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[2]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-task-row">
            <div className="chart-task-container8">
              <h2>Tasks by Sector</h2>
              <ChartComponent
                id="sector-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={sectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[3]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-task-container9">
              <h2>Tasks by Sub-Sector</h2>
              <ChartComponent
                id="sub-sector-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={subSectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[4]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      )}

      {/*Regional Dashboard*/}
      {user?.Access === "Regional" && (
        <div className="regional-reports-task-container">
          <div className="chart-task-row">
            <div className="chart-task-container10">
              <h2>Tasks by Region</h2>
              <ChartComponent
                id="region-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-task-container11">
              <h2>Tasks by Sector</h2>
              <ChartComponent
                id="sector-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={sectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[2]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-task-row">
            <div className="chart-task-container12">
              <h2>Tasks by Sub-Sector</h2>
              <ChartComponent
                id="sub-sector-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={subSectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[3]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TaskDashboard;
