import React from 'react'
import { Outlet } from 'react-router-dom';
import Compliancy from './Compliancy';
import '../MainBody.css'


function CompliancyLayout() {
  return (
    <div className='projects_container'>
      <div className='projects_sidebar'>
        <Compliancy />
      </div>
      <div className='projects_main'>
        <Outlet />
      </div>
    </div>
  )
}

export default CompliancyLayout