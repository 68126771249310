import { useEffect, useState } from "react";
import axios from "../../config/axios";

const useBeneficiaryStatusDataFetch = () => {
  const [beneficiaryStatusData, setBeneficiaryStatusData] = useState([]);
  const [isBeneficiaryStatusLoaded, setIsBeneficiaryStatusLoaded] = useState(false);

  // Define the fetchData function for project status change
  const fetchBeneficiaryStatusData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/benstatuschange"
      );
      setIsBeneficiaryStatusLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching status data:", error);
      setIsBeneficiaryStatusLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch status change
    fetchBeneficiaryStatusData().then((InitialData) => {
      setBeneficiaryStatusData(InitialData);
    });
  }, []);

  // Return an object containing all the fetched data
  return {
    beneficiaryStatusData,
    isBeneficiaryStatusLoaded,
  }
};

export default useBeneficiaryStatusDataFetch;
