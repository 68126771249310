import React, { useEffect, useState, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import "../../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import "../../../../../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
import "../../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../../../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import axios from "../../../../../config/axios";
import "./ToDoAttachments.css";
import useOtherDataFetch from "../../../../shared/useOtherDataFetch"; // Import the custom hook
import useProjectDataFetch from "../../../../shared/useProjectDataFetch"; // Import the custom hook
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AttachmentDialog from "../../../../shared/AttachmentDialog";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useUserStore from "../../../../../app/user";
import { toast } from "react-toastify";

function ToDoAttachments() {
  const user = useUserStore((state) => state.user);
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { attachmentData, isAttachmentLoaded } = useOtherDataFetch();
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [filteredProjectData, setFilteredProjectData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const currentStage = "Capacity Building";

  useEffect(() => {
    if (isProjectLoaded && isAttachmentLoaded) {
      const finalProjectData = projectData.filter(
        (project) =>
          project.Stage === currentStage &&
          project.CurrentUser === user?.FullName
      );
      setFilteredProjectData(finalProjectData);
      const matchingAttachments = attachmentData.filter((attachment) => {
        const matchingProject = finalProjectData.find(
          (project) =>
            project.ProjectNum === attachment.ProjectNum &&
            attachment.Stage === currentStage
        );
        return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
      });
      setGridData(matchingAttachments);
      setIsDataLoaded(true);
    }
  }, [attachmentData, isAttachmentLoaded, projectData, isProjectLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  const [selectedID, setSelectedID] = useState("");
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedProjectNum, setSelectedProjectNum] = useState("");
  const [selectedAttachName, setSelectedAttachName] = useState("");
  const [selectedAttachPath, setSelectedAttachPath] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedProjectNum(args.data.ProjectNum);
    setSelectedAttachName(args.data.AttachName);
    setSelectedStage(args.data.Stage);
    setSelectedAttachPath(args.data.AttachPath);
    setSelectedFile("");
    if (args.data.ProjectNum) {
      const project = projectData.find(
        (project) => project.ProjectNum === args.data.ProjectNum
      );
      const projectName = project.ProjectName;
      setSelectedProjectName(projectName);
    } else {
      setSelectedProjectName("");
    }
  };

  const handleDialogClose = () => {
    setIsDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsDialogVisible(!isDialogVisible);
  };

  const handleUpdateAttachmentButton = async (selectedID, selectedFile) => {
    // Send the updated data to the API for updating the records
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    if (selectedAttachPath)
      await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/attachment/delete-file",
        {
          path: selectedAttachPath,
        }
      );
    if (!selectedFile) {
      try {
        const AttachmentResponse2 = await axios.put(
          process.env.REACT_APP_SERVER_URL +
            `/api/attachment/${numericSelectedID}`,
          {
            AttachPath: "",
            LastUpdatedBy: user?.FullName,
          }
        );
        console.log("Project response:", AttachmentResponse2.data);
        if (AttachmentResponse2.data.status === "success") {
          toast.success("Attachment updated successfully.");

          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/api/attachment"
          );
          const attachments = updatedResponse.data.items;
          // Filter attachmentData to get tasks that have a matching ProjectNum in gridData
          const matchingAttachments = attachments.filter((attachment) => {
            const matchingProject = filteredProjectData.find(
              (project) =>
                project.ProjectNum === attachment.ProjectNum &&
                attachment.Stage === currentStage
            );
            return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
          });
          setGridData(matchingAttachments);
          toast.dismiss(loadingToastId);
        } else {
          console.error(
            "Error updating attachment record:",
            AttachmentResponse2.data.message
          );
          toast.dismiss(loadingToastId);
          toast.error("Error updating attachment");
        }
      } catch (error) {
        console.error("Error updating attachment:", error);
        toast.dismiss(loadingToastId);
        toast.error("Error updating attachment");
      }

      return;
    }
    const formData = new FormData();
    formData.append("media", selectedFile);
    const AttachmentResponse = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/api/attachment/upload",
      formData
    );
    console.log("Project response:", AttachmentResponse.data);
    if (AttachmentResponse.data.status === "success") {
      console.log(
        "Attachment record updated successfully.",
        AttachmentResponse
      );
      setSelectedFile(AttachmentResponse.data.items);
    }
    try {
      const AttachmentResponse2 = await axios.put(
        process.env.REACT_APP_SERVER_URL +
          `/api/attachment/${numericSelectedID}`,
        {
          AttachPath: AttachmentResponse.data.items,
          LastUpdatedBy: user?.FullName,
        }
      );
      console.log("Project response:", AttachmentResponse2.data);
      if (AttachmentResponse2.data.status === "success") {
        toast.success("Attachment updated successfully.");

        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/attachment"
        );
        const attachments = updatedResponse.data.items;
        // Filter attachmentData to get tasks that have a matching ProjectNum in gridData
        const matchingAttachments = attachments.filter((attachment) => {
          const matchingProject = filteredProjectData.find(
            (project) =>
              project.ProjectNum === attachment.ProjectNum &&
              attachment.Stage === currentStage
          );
          return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
        });
        setGridData(matchingAttachments);
        toast.dismiss(loadingToastId);
      } else {
        console.error(
          "Error updating attachment record:",
          AttachmentResponse2.data.message
        );
        toast.dismiss(loadingToastId);
        toast.error("Error updating attachment");
      }
    } catch (error) {
      console.error("Error updating attachment:", error);
      toast.dismiss(loadingToastId);
      toast.error("Error updating attachment");
    }
  };

  const viewAttachment = (media) => {
    window.open(media, "_blank");
  };

  const statusTemplate = (props) => {
    const status = props.ProjectStatus; // Assuming the status field is named "Status"
    let colorClass = "";

    if (status === "Active") {
      colorClass = "status-active";
    } else if (status === "Non-active") {
      colorClass = "status-inactive";
    } else if (status === "Progress delayed") {
      colorClass = "status-delayed";
    }
    return <div className={`status-cell ${colorClass}`}>{status}</div>;
  };

  const projectNameTemplate = (props) => {
    const projectNum = props.ProjectNum; // Assuming the field name is "ProjectNum"
    // Lookup the project name in projectData based on the provided projectNum
    const project = projectData.find(
      (project) => project.ProjectNum === projectNum
    );
    // Check if a project with the given projectNum was found
    if (project) {
      const projectName = project.ProjectName;
      return <div>{projectName}</div>;
    } else {
      // Handle the case where no matching project was found
      return <div>Project Not Found</div>;
    }
  };

  // Custom template for the "Attachments" column
  const attachmentTemplate = (props) => {
    const lastUpdated = new Date(props.LastUpdated);
    const validityInYears = props.Validity;
    if (
      !isNaN(lastUpdated) &&
      !isNaN(validityInYears) &&
      props.AttachPath !== ""
    ) {
      const nextUpdateDate = new Date(lastUpdated);
      if (validityInYears === 0.0192307692307692) {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 7);
      } else if (validityInYears === 0.0833333333333333) {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 30);
      } else {
        nextUpdateDate.setDate(
          nextUpdateDate.getDate() + 365.25 * validityInYears
        );
      }
      const today = new Date();
      // Calculate the difference between nextUpdateDate and today's date in milliseconds
      const timeDifference = nextUpdateDate - today;
      // Calculate the difference in days
      const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      let requiredDays = 30;
      if (validityInYears === 0.0192307692307692) {
        requiredDays = 0;
      } else if (validityInYears === 0.0833333333333333) {
        requiredDays = 5;
      } else if (validityInYears === 0.25) {
        requiredDays = 10;
      } else {
        requiredDays = 30;
      }
      if (remainingDays < 0) {
        // Display a negative value to indicate that the date has passed
        return (
          <span>
            <CancelIcon style={{ color: "red" }} />
          </span>
        );
      } else if (
        remainingDays < requiredDays &&
        (remainingDays > 0 || remainingDays === 0)
      ) {
        return (
          <span>
            <CheckCircleIcon style={{ color: "Orange" }} />
          </span>
        );
      } else {
        return (
          <span>
            <CheckCircleIcon style={{ color: "green" }} />
          </span>
        );
      }
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  // Custom template for the "Attachments" column
  const buttonTemplate = (props) => {
    const media =
      process.env.REACT_APP_SERVER_URL + "/public/" + props.AttachPath;
    if (props.AttachPath !== "") {
      return (
        <div className="button-container1">
          <button className="view-button" onClick={() => viewAttachment(media)}>
            <VisibilityIcon className="view-icon" />
            View
          </button>
        </div>
      );
    }
  };

  console.log("Path: ", selectedAttachPath);

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];
  const loadingIndicator = { indicatorType: "Spinner" };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button
            className="edit-button"
            onClick={handleEditButtonClick}
            disabled={selectedID === ""}
          >
            <EditIcon className="send-icon" /> Edit{" "}
          </button>
        </div>
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Upload",
        cssClass: "e-flat",
        isPrimary: false,
      },
      click: () => {
        handleUpdateAttachmentButton(selectedID, selectedFile);
        setIsDialogVisible(false);
      },
    },
    selectedAttachPath && {
      buttonModel: {
        content: "Remove",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        setSelectedFile(null);
        handleUpdateAttachmentButton(selectedID, selectedFile);
        setIsDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsDialogVisible(false);
      },
    },
  ];
  console.log("Attachments: ", gridData);
  return (
    <div className="my-to-do-attachments">
      <h3>To Do List of Attachments</h3>
      <br />
      <div className="field-line-my-attachments">
        {isDataLoaded && (
          <GridComponent
            dataSource={gridData}
            childMapping="items"
            ref={GridRef}
            classClass="custom-treegrid-class"
            height="650"
            allowPaging={true}
            allowSorting={true}
            allowFiltering={true}
            showColumnChooser={true}
            filterSettings={FilterOptions}
            pageSettings={pageSettings}
            editSettings={editOptions}
            toolbar={updatedToolbarOptions}
            loadingIndicator={loadingIndicator}
            allowSelection={true}
            rowSelected={handleRowSelect}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="id"
                headerText="ID"
                width="60"
                textAlign="Left"
                isPrimaryKey={true}
              />
              <ColumnDirective
                field="ProjectNum"
                headerText="Project #"
                width="120"
                textAlign="Left"
                editType="dropdownedit"
              />
              <ColumnDirective
                headerText="Project Name"
                width="160"
                textAlign="Left"
                template={projectNameTemplate}
              />
              <ColumnDirective
                field="NatureOfAttach"
                headerText="Nature of Attachment"
                width="120"
                textAlign="Left"
              />
              <ColumnDirective
                field="AttachName"
                headerText="Attachment Name"
                width="150"
                textAlign="Left"
              />
              <ColumnDirective
                headerText="Valid?"
                width="65"
                textAlign="Left"
                template={attachmentTemplate}
              />
              <ColumnDirective
                headerText="View"
                width="60"
                textAlign="Left"
                template={buttonTemplate}
              />
              <ColumnDirective
                field="LastUpdated"
                headerText="Last Updated On"
                width="120"
                format="yyyy/MM/dd"
                textAlign="Left"
                type="date"
              />
              <ColumnDirective
                field="LastUpdatedBy"
                headerText="Last Updated By"
                width="100"
                textAlign="Left"
              />
            </ColumnsDirective>
            <Inject
              services={[Page, Sort, Filter, Edit, Toolbar, ColumnChooser]}
            />
          </GridComponent>
        )}
      </div>
      <div>
        {isDialogVisible && (
          <DialogComponent
            visible={isDialogVisible}
            width="700px"
            height="485px"
            header="Upload Attachment"
            allowDragging={true}
            showCloseIcon={true}
            close={handleDialogClose}
            buttons={editButtons}
          >
            <AttachmentDialog
              selectedID={selectedID}
              selectedStage={selectedStage}
              selectedProjectNum={selectedProjectNum}
              selectedProjectName={selectedProjectName}
              setSelectedAttachName={selectedAttachName}
              selectedAttachPath={selectedAttachPath}
              setSelectedFile={setSelectedFile}
              selectedFile={selectedFile}
            />
          </DialogComponent>
        )}
      </div>
    </div>
  );
}
export default ToDoAttachments;
