import React from 'react'
import '../Sidebar.css'
import FinancialOption from './FinancialOption';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AssessmentIcon from '@mui/icons-material/Assessment';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SavingsIcon from '@mui/icons-material/Savings';
import PageviewIcon from '@mui/icons-material/Pageview';
import { NavLink } from 'react-router-dom';


function Financials() {
  return (
    <div className='sidebar'>
        <NavLink to="dashboard">{<FinancialOption Icon = {AssessmentIcon} title="Dashbord"/>}</NavLink>
        <NavLink to="payments">{<FinancialOption Icon = {CreditCardIcon} title="Payments"/>}</NavLink>
        <NavLink to="budget">{<FinancialOption Icon = {AccountBalanceWalletIcon} title= "Project's Budget"/>}</NavLink>
        <NavLink to="upto350k">{<FinancialOption Icon = {SavingsIcon} title="Up to R350k"/>}</NavLink>
        <NavLink to="upto650k">{<FinancialOption Icon = {SavingsIcon} title="Up to R650K"/>}</NavLink>
        <NavLink to="upto1200k">{<FinancialOption Icon = {SavingsIcon} title="Up To R1.2m"/>}</NavLink>
        <NavLink to="quotations">{<FinancialOption Icon = {RequestQuoteIcon} title="Quotation Files"/>}</NavLink>
        <NavLink to="paymentfiles">{<FinancialOption Icon = {PageviewIcon} title="Payment Files"/>}</NavLink>
                
    </div>
  )
}

export default Financials