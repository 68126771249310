import React from 'react'
import '../Sidebar.css'
import TaskOption from './TaskOption';
import EventIcon from '@mui/icons-material/Event';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import StorageIcon from '@mui/icons-material/Storage';
import WarningIcon from '@mui/icons-material/Warning';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { NavLink } from 'react-router-dom';

function Tasks() {
  return (
    <div className='sidebar'>
        <NavLink to="dashboard">{<TaskOption Icon = {AssessmentIcon} title="Dashboard"/>}</NavLink>
        <NavLink to="projecttasks">{<TaskOption Icon = {AccountTreeIcon} title= "Project Tasks"/>}</NavLink>
        <NavLink to="overdue">{<TaskOption Icon = {WarningIcon} title="Passed Due Date"/>}</NavLink>
        <NavLink to="duetoday">{<TaskOption Icon = {EventIcon} title="Due Today"/>}</NavLink>
        <NavLink to="duethisweek">{<TaskOption Icon = {DateRangeIcon} title="Due This Week"/>}</NavLink>
        <NavLink to="duethismonth">{<TaskOption Icon = {CalendarMonthIcon} title="Due This Month"/>}</NavLink>
        <NavLink to="duethisquarter">{<TaskOption Icon = {CalendarTodayIcon} title="Due this Quarter"/>}</NavLink>
        <NavLink to="duethisyear">{<TaskOption Icon = {StorageIcon} title="Due This Year"/>}</NavLink>                
    </div>
  )
}
export default Tasks