import React, { useEffect, useState, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import "../../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import axios from "../../../../../config/axios";
import "./ChangeModules.css";
import BeneficiaryStatusChange from "./components/BeneficiaryStatusChange";
import useProjectDataFetch from "../../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../../shared/useOtherDataFetch"; // Import the custom hook
import useBeneficiaryStatusDataFetch from "../../../../shared/useBeneficiaryStatusDataFetch"; // Import the custom hook
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import useUserStore from "../../../../../app/user";
import { toast } from "react-toastify";
import { getUpdateBeneficiaryData } from "../../../../../utils/project";

function BeneficiaryStatus() {
  const user = useUserStore((state) => state.user);
  const { projectData, isProjectLoaded } = useProjectDataFetch(user);
  const { beneficiaryStatusData, isBeneficiaryStatusLoaded } =
    useBeneficiaryStatusDataFetch([]);
  const { beneficiaryData, isBeneficiaryLoaded } = useOtherDataFetch([]);
  const uniqueProjects = [
    ...new Set(
      projectData?.map((item) => (item.ProjectName ? item.ProjectName : ""))
    ),
  ];
  const [openData, setOpenData] = useState([]);
  const [closeData, setCloseData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const currentDateTime = new Date(); // Get the current date and time
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
  const [fieldHeader, setFieldHeader] = useState(""); // Initial state
  const requestor = user?.FullName;
  const [newName, setNewName] = useState("");
  const [newBenName, setBenNewName] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [futureStatus, setFutureStatus] = useState("");

  const [projectID, setProjectID] = useState("");
  const [notes, setNotes] = useState("");
  const GridRef = useRef(null); // Create the treeGridRef reference

  const [selectedID, setSelectedID] = useState("");
  const [selectedProjectID, setSelectedProjectID] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    if (isBeneficiaryStatusLoaded && isProjectLoaded && isBeneficiaryLoaded) {
      const updateBeneficiaryData = getUpdateBeneficiaryData(
        projectData,
        beneficiaryData,
        beneficiaryStatusData
      );
      const openChanges = updateBeneficiaryData.filter(
        (record) => record.Status === "Open"
      );
      setOpenData(openChanges);
      const closeChanges = updateBeneficiaryData.filter(
        (record) => record.Status === "Closed"
      );
      setCloseData(closeChanges);
      setIsDataLoaded(true);
    }
  }, [
    beneficiaryStatusData,
    isBeneficiaryStatusLoaded,
    isProjectLoaded,
    isBeneficiaryLoaded,
  ]);

  let loadingToastId;
  useEffect(() => {
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  const handleRowSelect = async (args) => {
    setSelectedID(args.data.Id);
    setSelectedProjectID(args.data.BeneficiaryID);
    setSelectedStatus(args.data.NewStatus);
  };

  const handleCoordinatorButtonClick = async () => {
    setFieldHeader("Coordinator");
    setIsDialogVisible(!isDialogVisible);
  };

  const handleAdministratorButtonClick = async () => {
    setFieldHeader("Administrator");
    setIsDialogVisible(!isDialogVisible);
  };

  const handleFacilitatorButtonClick = async () => {
    setFieldHeader("Facilitator");
    setIsDialogVisible(!isDialogVisible);
  };

  const handleAssistantNFButtonClick = async () => {
    setFieldHeader("AssistantNF");
    setIsDialogVisible(!isDialogVisible);
  };

  const handleNationalFacilitatorButtonClick = async () => {
    setFieldHeader("NationalF");
    setIsDialogVisible(!isDialogVisible);
  };

  const handleAccountantButtonClick = async () => {
    setFieldHeader("Accountant");
    setIsDialogVisible(!isDialogVisible);
  };

  const handleFinancialAccountantButtonClick = async () => {
    setFieldHeader("FinAccountant");
    setIsDialogVisible(!isDialogVisible);
  };

  const handleDialogClose = () => {
    setIsDialogVisible(false);
  };
  const handleAddDialogClose = () => {
    setIsAddDialogVisible(false);
  };

  const handleAddButtonClick = () => {
    setIsAddDialogVisible(!isAddDialogVisible);
    setNewName("");
    setBenNewName("");
    setProjectID("");
    setFutureStatus("");
    setCurrentStatus("");
    setNotes("");
  };

  const handleAddRequestButton = async () => {
    const loadingToastId = toast.loading("Please wait...");
    const requestData = {
      BeneficiaryID: projectID,
      ProjectName: newName,
      BenName: newBenName,
      CurrentStatus: currentStatus,
      NewStatus: futureStatus,
      DateTime: currentDateTime,
      Notes: notes,
      Status: "Open",
      Administrator: "Yes",
    };
    // Make a POST request to add the workflow record
    try {
      const RequestResponse = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/benstatuschange",
        requestData
      );
      console.log("Request response:", RequestResponse.data);
      if (RequestResponse.data.status === "success") {
        toast.success("Request was added successfully.");
        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/benstatuschange"
        );
        const requests = updatedResponse.data.items;
        const updateBeneficiaryData = getUpdateBeneficiaryData(
          projectData,
          beneficiaryData,
          requests
        );
        const updatedGridData = updateBeneficiaryData.filter(
          (request) => request.Status === "Open"
        );
        setOpenData(updatedGridData);
      } else {
        console.error("Error adding request:", RequestResponse.data.message);
        toast.error("Error adding the request");
      }
    } catch (error) {
      console.error("Error adding request:", error);
      toast.error("Error adding the request");
    }
    toast.dismiss(loadingToastId);
  };

  const handleChangeRequestApproval = async () => {
    // Send the updated data to the API for updating the records
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    const numericProjectID = parseInt(selectedProjectID);
    try {
      const data = {};
      data[fieldHeader] = "Yes";

      const ChangeResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL +
          `/api/benstatuschange/${numericSelectedID}`,
        data
      );
      console.log("Project response:", ChangeResponse.data);
      if (ChangeResponse.data.status === "success") {
        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL +
            `/api/benstatuschange/${numericSelectedID}`
        );
        const record = updatedResponse.data.items;
        if (
          record.Coordinator === "Yes" &&
          record.Administrator === "Yes" &&
          record.Facilitator === "Yes" &&
          record.AssistantNF === "Yes" &&
          record.NationalF === "Yes" &&
          record.Accountant === "Yes" &&
          record.FinAccountant === "Yes"
        ) {
          try {
            const ProjectResponse = await axios.put(
              process.env.REACT_APP_SERVER_URL +
                `/api/beneficiary/${numericProjectID}`,
              {
                BenStatus: selectedStatus,
              }
            );
            console.log("Project response:", ProjectResponse.data);
            toast.success("Bank Change was Approved.");
          } catch (error) {
            console.error("Error changing Project Bank:", error);
            toast.error("Error changing Project Bank.");
          }
          try {
            const RecordResponse = await axios.put(
              process.env.REACT_APP_SERVER_URL +
                `/api/benstatuschange/${numericSelectedID}`,
              {
                Status: "Closed",
              }
            );
            console.log("Record Response:", RecordResponse.data);
          } catch (error) {
            console.error("Error changing Bank:", error);
            toast.error("Error changing Bank.");
          }
        } else if (
          record.Coordinator !== "" &&
          record.Administrator !== "" &&
          record.Facilitator !== "" &&
          record.AssistantNF !== "" &&
          record.NationalF !== "" &&
          record.Accountant !== "" &&
          record.FinAccountant !== ""
        ) {
          try {
            const RecordResponse = await axios.put(
              process.env.REACT_APP_SERVER_URL +
                `/api/benstatuschange/${numericSelectedID}`,
              {
                Status: "Closed",
              }
            );
            console.log("Record Response:", RecordResponse.data);
          } catch (error) {
            console.error("Error changing Bank:", error);
            toast.error("Error changing Bank.");
          }
        }

        const changeResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/benstatuschange"
        );
        const changeRecords = changeResponse.data.items;
        const updateBeneficiaryData = getUpdateBeneficiaryData(
          projectData,
          beneficiaryData,
          changeRecords
        );
        const openChanges = updateBeneficiaryData.filter(
          (record) => record.Status === "Open"
        );
        setOpenData(openChanges);
        const closeChanges = updateBeneficiaryData.filter(
          (record) => record.Status === "Closed"
        );
        setCloseData(closeChanges);
      }
    } catch (error) {
      console.error("Error changing Bank:", error);
      toast.error("Error changing Bank");
    }
    toast.dismiss(loadingToastId);
  };

  const handleChangeRequestRejection = async (selectedID) => {
    // Send the updated data to the API for updating the records
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    try {
      const data = {};
      data[fieldHeader] = "No";

      const ChangeResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL +
          `/api/benstatuschange/${numericSelectedID}`,
        data
      );
      console.log("Project response:", ChangeResponse.data);
      if (ChangeResponse.data.status === "success") {
        toast.success("Rejection added successfully.");

        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL +
            `/api/benstatuschange/${numericSelectedID}`
        );
        const record = updatedResponse.data.items;
        if (
          record.Coordinator !== "" &&
          record.Administrator !== "" &&
          record.Facilitator !== "" &&
          record.AssistantNF !== "" &&
          record.NationalF !== "" &&
          record.Accountant !== "" &&
          record.FinAccountant !== ""
        ) {
          try {
            const RecordResponse = await axios.put(
              process.env.REACT_APP_SERVER_URL +
                `/api/benstatuschange/${numericSelectedID}`,
              {
                Status: "Closed",
              }
            );
            console.log("Record Response:", RecordResponse.data);
          } catch (error) {
            console.error("Error changing Bank:", error);
            toast.error("Error changing Bank.");
          }
        }
        const changeResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/benstatuschange"
        );
        const changeRecords = changeResponse.data.items;
        const updateBeneficiaryData = getUpdateBeneficiaryData(
          projectData,
          beneficiaryData,
          changeRecords
        );
        const openChanges = updateBeneficiaryData.filter(
          (record) => record.Status === "Open"
        );
        setOpenData(openChanges);
        const closeChanges = updateBeneficiaryData.filter(
          (record) => record.Status === "Closed"
        );
        setCloseData(closeChanges);
      }
    } catch (error) {
      console.error("Error changing Bank:", error);
      toast.error("Error changing Bank");
    }
    toast.dismiss(loadingToastId);
  };

  const administratorTemplate = (props) => {
    const outcome = props.Administrator;
    if (outcome === "Yes") {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else if (outcome === "No") {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const coordinatorTemplate = (props) => {
    const outcome = props.Coordinator;
    if (outcome === "Yes") {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else if (outcome === "No") {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const facilitatorTemplate = (props) => {
    const outcome = props.Facilitator;
    if (outcome === "Yes") {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else if (outcome === "No") {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const assistantNFTemplate = (props) => {
    const outcome = props.AssistantNF;
    if (outcome === "Yes") {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else if (outcome === "No") {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const nationalFacilitatorTemplate = (props) => {
    const outcome = props.NationalF;
    if (outcome === "Yes") {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else if (outcome === "No") {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const accountantTemplate = (props) => {
    const outcome = props.Accountant;
    if (outcome === "Yes") {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else if (outcome === "No") {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const financialAccountantTemplate = (props) => {
    const outcome = props.FinAccountant;
    if (outcome === "Yes") {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else if (outcome === "No") {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  let approveButtons = [
    {
      buttonModel: {
        content: "Approve",
        cssClass: "e-flat",
        isPrimary: false,
      },
      click: () => {
        handleChangeRequestApproval(
          selectedID,
          selectedStatus,
          selectedProjectID
        );
        setIsDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Reject",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleChangeRequestRejection(selectedID);
        setIsDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsDialogVisible(false);
      },
    },
  ];

  let addButtons = [
    {
      buttonModel: {
        content: "Add",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleAddRequestButton(
          projectID,
          newName,
          newBenName,
          requestor,
          futureStatus,
          currentStatus,
          notes
        );
        setIsAddDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsAddDialogVisible(false);
      },
    },
  ];

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
    mode: "Dialog",
  };
  const toolbarOptions = ["ColumnChooser"];
  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          {user?.JobTitle === "Administrator" && (
            <button className="add-button" onClick={handleAddButtonClick}>
              <EditIcon className="send-icon" /> Add New{" "}
            </button>
          )}
        </div>
        <div className="button-container-subA2">
          {user?.JobTitle === "Coordinator" && (
            <button
              className="coordinator-button"
              onClick={handleCoordinatorButtonClick}
              disabled={selectedID === ""}
            >
              <ThumbUpIcon className="send-icon" /> Process Change{" "}
            </button>
          )}
          {user?.JobTitle === "Administrator" && (
            <button
              className="administrator-button"
              onClick={handleAdministratorButtonClick}
              disabled={selectedID === ""}
            >
              <ThumbUpIcon className="send-icon" /> Process Change
            </button>
          )}
          {user?.JobTitle === "Facilitator" && (
            <button
              className="facilitator-button"
              onClick={handleFacilitatorButtonClick}
              disabled={selectedID === ""}
            >
              <ThumbUpIcon className="send-icon" /> Process Change
            </button>
          )}
          {user?.JobTitle === "Assistant National Facilitator" && (
            <button
              className="assistant-nf-button"
              onClick={handleAssistantNFButtonClick}
              disabled={selectedID === ""}
            >
              <ThumbUpIcon className="send-icon" /> Process Change
            </button>
          )}
          {user?.JobTitle === "National Facilitator" && (
            <button
              className="national-facilitator-button"
              onClick={handleNationalFacilitatorButtonClick}
              disabled={selectedID === ""}
            >
              <ThumbUpIcon className="send-icon" /> Process Change
            </button>
          )}
          {user?.JobTitle === "Accountant" && (
            <button
              className="administrator-button"
              onClick={handleAccountantButtonClick}
              disabled={selectedID === ""}
            >
              <ThumbUpIcon className="send-icon" /> Process Change
            </button>
          )}
          {user?.JobTitle === "Financial accountant" && (
            <button
              className="coordinator-button"
              onClick={handleFinancialAccountantButtonClick}
              disabled={selectedID === ""}
            >
              <ThumbUpIcon className="send-icon" /> Process Change
            </button>
          )}
        </div>
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  return (
    <div className="main-change-containers">
      <div className="outer-change-container">
        <h3>Open Change Requests</h3>
        <br />
        <div className="inner-change-container">
          {isDataLoaded && (
            <GridComponent
              dataSource={openData}
              childMapping="items"
              ref={GridRef}
              classClass="custom-treegrid-class"
              height="550"
              width="100%"
              allowPaging={true}
              allowSorting={true}
              allowFiltering={true}
              showColumnChooser={true}
              filterSettings={FilterOptions}
              pageSettings={pageSettings}
              editSettings={editOptions}
              toolbar={updatedToolbarOptions}
              allowSelection={true}
              rowSelected={handleRowSelect}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="id"
                  headerText="ID"
                  width="100"
                  textAlign="Left"
                  isPrimaryKey={true}
                />
                <ColumnDirective
                  field="BeneficiaryID"
                  headerText="Beneficiary ID"
                  width="120"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="BenName"
                  headerText="Beneficiary Name"
                  width="200"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="ProjectName"
                  headerText="Project Name"
                  width="200"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="CurrentStatus"
                  headerText="Current Status"
                  width="150"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="NewStatus"
                  headerText="New Status"
                  width="150"
                  textAlign="Left"
                />
                <ColumnDirective
                  headerText="Administrator"
                  width="120"
                  textAlign="Left"
                  template={administratorTemplate}
                />
                <ColumnDirective
                  headerText="Coordinator"
                  width="120"
                  textAlign="Left"
                  template={coordinatorTemplate}
                />
                <ColumnDirective
                  headerText="Facilitator"
                  width="120"
                  textAlign="Left"
                  template={facilitatorTemplate}
                />
                <ColumnDirective
                  headerText="Assistant NF"
                  width="120"
                  textAlign="Left"
                  template={assistantNFTemplate}
                />
                <ColumnDirective
                  headerText="National Facilitator"
                  width="120"
                  textAlign="Left"
                  template={nationalFacilitatorTemplate}
                />
                <ColumnDirective
                  headerText="Accountant"
                  width="120"
                  textAlign="Left"
                  template={accountantTemplate}
                />
                <ColumnDirective
                  headerText="Fin Accountant"
                  width="120"
                  textAlign="Left"
                  template={financialAccountantTemplate}
                />
                <ColumnDirective
                  field="Notes"
                  headerText="Notes"
                  width="520"
                  textAlign="Left"
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Sort, Filter, Edit, Toolbar, ColumnChooser]}
              />
            </GridComponent>
          )}
        </div>
      </div>
      <div className="outer-change-container">
        <h3>Closed Change Requests</h3>
        <br />
        <div className="inner-change-container">
          {isDataLoaded && (
            <GridComponent
              dataSource={closeData}
              childMapping="items"
              ref={GridRef}
              classClass="custom-treegrid-class"
              height="550"
              width="100%"
              allowPaging={true}
              allowSorting={true}
              allowFiltering={true}
              showColumnChooser={true}
              filterSettings={FilterOptions}
              pageSettings={pageSettings}
              editSettings={editOptions}
              allowSelection={true}
              rowSelected={handleRowSelect}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="id"
                  headerText="ID"
                  width="100"
                  textAlign="Left"
                  isPrimaryKey={true}
                />
                <ColumnDirective
                  field="BeneficiaryID"
                  headerText="Beneficiary ID"
                  width="120"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="BenName"
                  headerText="Beneficiary Name"
                  width="200"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="ProjectName"
                  headerText="Project Name"
                  width="200"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="CurrentStatus"
                  headerText="From Status"
                  width="150"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="NewStatus"
                  headerText="To Status"
                  width="150"
                  textAlign="Left"
                />
                <ColumnDirective
                  headerText="Administrator"
                  width="120"
                  textAlign="Left"
                  template={administratorTemplate}
                />
                <ColumnDirective
                  headerText="Coordinator"
                  width="120"
                  textAlign="Left"
                  template={coordinatorTemplate}
                />
                <ColumnDirective
                  headerText="Facilitator"
                  width="120"
                  textAlign="Left"
                  template={facilitatorTemplate}
                />
                <ColumnDirective
                  headerText="Assistant NF"
                  width="120"
                  textAlign="Left"
                  template={assistantNFTemplate}
                />
                <ColumnDirective
                  headerText="National Facilitator"
                  width="120"
                  textAlign="Left"
                  template={nationalFacilitatorTemplate}
                />
                <ColumnDirective
                  headerText="Accountant"
                  width="120"
                  textAlign="Left"
                  template={accountantTemplate}
                />
                <ColumnDirective
                  headerText="Fin Accountant"
                  width="120"
                  textAlign="Left"
                  template={financialAccountantTemplate}
                />
                <ColumnDirective
                  field="Notes"
                  headerText="Notes"
                  width="520"
                  textAlign="Left"
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Sort, Filter, Edit, Toolbar, ColumnChooser]}
              />
            </GridComponent>
          )}
        </div>
      </div>
      <div>
        {isDialogVisible && (
          <DialogComponent
            visible={isDialogVisible}
            width="300px"
            height="200px"
            header="Project Bank Change"
            content={`Do you want to approve or reject the change of Bank for this project to ${selectedStatus}?`}
            showCloseIcon={true}
            allowDragging={true}
            close={handleDialogClose}
            buttons={approveButtons}
          ></DialogComponent>
        )}
      </div>
      <div>
        {isAddDialogVisible && (
          <DialogComponent
            visible={isAddDialogVisible}
            width="700px"
            height="650px"
            header="Change Beneficiary Status"
            allowDragging={true}
            showCloseIcon={true}
            close={handleAddDialogClose}
            buttons={addButtons}
          >
            <BeneficiaryStatusChange
              projectData={projectData}
              beneficiaryData={beneficiaryData}
              uniqueProjects={uniqueProjects}
              requestor={requestor}
              setNewName={setNewName}
              setBenNewName={setBenNewName}
              setProjectID={setProjectID}
              setCurrentStatus={setCurrentStatus}
              setFutureStatus={setFutureStatus}
              setNotes={setNotes}
            />
          </DialogComponent>
        )}
      </div>
    </div>
  );
}

export default BeneficiaryStatus;
