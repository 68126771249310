import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useProjectDataFetch from "./useProjectDataFetch";
import useUserStore from "../../app/user";

const useWorkflowDataFetch = () => {
  const user = useUserStore((state) => state.user);
  const {projectData} = useProjectDataFetch(user);
  const [workflowData, setWorkflowData] = useState([]);
  const [isWorkflowLoaded, setIsWorkflowLoaded] = useState(false);

  const fetchWorkflowData = async () => {
    try {
      if (projectData.length === 0) {
        setIsWorkflowLoaded(true)
        return [];
      }
      const projectNums = projectData?.map((project) => project.id ? project.id : "");
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/workflow",
        {
          params: {
            projectNums: projectNums.join(","),
          },
        }
      );

      // Filter Workflows based on matching ProjectNum
      const filteredWorkflows = response.data.items.filter((workflow) =>
        projectNums.includes(workflow.ProjectID)
      );
      setIsWorkflowLoaded(true);
      return filteredWorkflows;
    } catch (error) {
      console.error("Error fetching Workflow data:", error);
      setIsWorkflowLoaded(true);

      return [];
    }
  };

  useEffect(() => {
    fetchWorkflowData().then((initialData) => {
      setWorkflowData(initialData);
    });
  }, [projectData]);

  return {
    workflowData,
    isWorkflowLoaded,
  };

};

export default useWorkflowDataFetch;


