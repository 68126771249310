import React, { useEffect, useState, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import { getValue } from "@syncfusion/ej2-base";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import "../../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import axios from "../../../../../config/axios";
import "./MyProjects.css";
import WorkflowDialog from "../../../../shared/WorkflowDialog";
import useProjectDataFetch from "../../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../../shared/useOtherDataFetch"; // Import the custom hook
import useWorkflowDataFetch from "../../../../shared/useWorkflowDataFetch";
import useUserDataFetch from "../../../../shared/useUserDataFetch";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import useUserStore from "../../../../../app/user";
import { toast } from "react-toastify";

function MyProjects() {
  const user = useUserStore((state) => state.user);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const [gridData, setGridData] = useState([]);
  const {
    taskData,
    attachmentData,
    beneficiaryData,
    isAttachmentLoaded,
    isTaskLoaded,
    isBeneficiaryLoaded,
  } = useOtherDataFetch();
  const { allUserData, isAllUserLoaded } = useUserDataFetch();
  const { workflowData, isWorkflowLoaded } = useWorkflowDataFetch();
  const [updatedWorkflowData, setUpdatedWorkflowData] = useState([]);
  const [allWorkflowData, setAllWorkflowData] = useState([]);
  const [receiver, setReceiver] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedID, setSelectedID] = useState("");
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
  }
  const currentDateTime = formatDate(new Date());
  const [notes, setNotes] = useState("");
  const sender = user?.FullName;
  const GridRef = useRef(null); // Create the treeGridRef reference
  const currentStage = "Funding";

  useEffect(() => {
    if (
      isProjectLoaded &&
      isTaskLoaded &&
      isAttachmentLoaded &&
      isWorkflowLoaded &&
      isAllUserLoaded &&
      isBeneficiaryLoaded
    ) {
      setAllWorkflowData(workflowData);
      setUpdatedWorkflowData([]);
      const filteredProjectData = projectData.filter(
        (project) =>
          project.Stage === currentStage && project.CreatedBy === user?.FullName
      );
      setGridData(filteredProjectData);
      setIsDataLoaded(true);
    }
  }, [
    projectData,
    workflowData,
    isProjectLoaded,
    isWorkflowLoaded,
    isTaskLoaded,
    isAttachmentLoaded,
    isAllUserLoaded,
    isBeneficiaryLoaded,
  ]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedStage(args.data.Stage);
    setSelectedProvince(args.data.Province);
    setSelectedRegion(args.data.Region);
    setSelectedProjectName(args.data.ProjectName);
    const updatedGridData = allWorkflowData.filter(
      (workflow) => workflow.ProjectID === Number(args.data.id)
    );
    setUpdatedWorkflowData(updatedGridData);
  };

  const handleRecallButtonClick = async () => {
    setReceiver(user?.FullName);
    setIsDialogVisible(!isDialogVisible);
  };

  const handleCoordinatorButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const coordinator = allUserData?.filter(
      (user) =>
        user?.JobTitle === "Coordinator" &&
        user?.AccessRegion === selectedRegion
    );
    // Assuming you want to set the first user found as the receiver
    if (coordinator.length > 0) {
      setReceiver(coordinator[0]?.FullName);
    }
  };

  const handleAdministratorButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const administrator = allUserData?.filter(
      (user) =>
        user?.JobTitle === "Administrator" &&
        user?.AccessProvince === selectedProvince
    );
    // Assuming you want to set the first user found as the receiver
    if (administrator.length > 0) {
      setReceiver(administrator[0]?.FullName);
    }
  };

  const handleFacilitatorButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const facilitator = allUserData.filter(
      (user) =>
        user?.JobTitle === "Facilitator" &&
        user?.AccessProvince === selectedProvince
    );
    // Assuming you want to set the first user found as the receiver
    if (facilitator.length > 0) {
      setReceiver(facilitator[0]?.FullName);
    }
  };

  const handleAssistantNFButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const assistantnationalFacilitator = allUserData.filter(
      (user) => user?.JobTitle === "Assistant National Facilitator"
    );
    // Assuming you want to set the first user found as the receiver
    if (assistantnationalFacilitator.length > 0) {
      setReceiver(assistantnationalFacilitator[0]?.FullName);
    }
  };

  const handleNationalFacilitatorButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const nationalFacilitator = allUserData.filter(
      (user) => user?.JobTitle === "National Facilitator"
    );
    // Assuming you want to set the first user found as the receiver
    if (nationalFacilitator.length > 0) {
      setReceiver(nationalFacilitator[0]?.FullName);
    }
  };

  const handleAccountantButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const accountant = allUserData.filter(
      (user) => user?.JobTitle === "Accountant"
    );
    // Assuming you want to set the first user found as the receiver
    if (accountant.length > 0) {
      setReceiver(accountant[0]?.FullName);
    }
  };

  const handleFinancialAccountantButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const financialAccountant = allUserData.filter(
      (user) => user?.JobTitle === "Financial accountant"
    );
    // Assuming you want to set the first user found as the receiver
    if (financialAccountant.length > 0) {
      setReceiver(financialAccountant[0]?.FullName);
    }
  };

  const handleDialogClose = () => {
    setIsDialogVisible(false);
  };

  const handleChangeCurrentUser = async (selectedID) => {
    const loadingToastId = toast.loading("Please wait...");
    try {
      const numericSelectedID = parseInt(selectedID);
      // Update the CurrentUser of the project
      const projectResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/project/${numericSelectedID}`,
        {
          CurrentUser: receiver,
        }
      );
      if (projectResponse.data.status === "success") {
        toast.success(`The message was sent to ${receiver} successfully.`);
        // Create a new workflow record
        const currentDateTime = new Date(); // Get the current date and time
        const workflowData = {
          ProjectID: numericSelectedID,
          ProjectStage: selectedStage,
          Sender: sender,
          Receiver: receiver,
          DateTime: currentDateTime,
          Notes: notes,
        };
        // Make a POST request to add the workflow record
        const workflowResponse = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/workflow",
          workflowData
        );
        console.log("Workflow response:", workflowResponse.data);
        if (workflowResponse.data.status === "success") {
          toast.success("Workflow record added successfully.");
          // Update the grid data
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/api/project"
          );
          const projects = updatedResponse.data.items;
          const updatedGridData = projects.filter(
            (project) =>
              project.Stage === currentStage &&
              project.CreatedBy === user?.FullName
          );
          setGridData(updatedGridData);
          try {
            const updatedResponse = await axios.get(
              process.env.REACT_APP_SERVER_URL + "/api/workflow"
            );
            const workflows = updatedResponse.data.items;
            setAllWorkflowData(workflows);
            const updatedGridData = workflows.filter(
              (workflow) => workflow.ProjectID === numericSelectedID
            );
            setUpdatedWorkflowData(updatedGridData);
          } catch (error) {
            console.error("Error fetching workflows:", error);
          }
        } else {
          console.error(
            "Error adding workflow record:",
            workflowResponse.data.message
          );
          toast.error("Error adding workflow");
        }
      } else {
        console.error(
          "Error updating CurrentUser:",
          projectResponse.data.message
        );
        toast.error("Error sending the message");
      }
    } catch (error) {
      console.error("Error updating CurrentUser:", error);
      toast.error("Error sending the message");
    }
    toast.dismiss(loadingToastId);
  };

  const statusTemplate = (props) => {
    const status = props.ProjectStatus; // Assuming the status field is named "Status"
    let colorClass = "";

    if (status === "Active") {
      colorClass = "status-active";
    } else if (status === "Non-active") {
      colorClass = "status-inactive";
    } else if (status === "Progress delayed") {
      colorClass = "status-delayed";
    }

    return <div className={`status-cell ${colorClass}`}>{status}</div>;
  };

  // Custom template for the "Attachments" column
  const attachmentTemplate = (props) => {
    const projectNum = props.ProjectNum;
    const allAttachments = attachmentData.filter(
      (attachment) =>
        attachment.ProjectNum === projectNum &&
        attachment.Stage === currentStage
    );
    const filteredAttachments = allAttachments.filter(
      (attachment) => attachment.AttachPath !== ""
    );
    if (filteredAttachments.length === allAttachments.length) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  // Custom template for the "Attachments" column
  const taskTemplate = (props) => {
    const projectID = props.id;
    const parentIDs = taskData?.map((task) => task.ParentID);
    const allTasks = taskData.filter(
      (task) =>
        task.ProjectID === projectID &&
        (task.ProjectStage === currentStage || task.ProjectStage === "") &&
        !parentIDs.includes(task.id)
    );
    const filteredTasks = allTasks.filter((task) => task.Progress === 100);
    if (filteredTasks.length === allTasks.length) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  // Custom template for the "Beneficiaries" column
  const beneficiaryTemplate = (props) => {
    const projectNum = props.ProjectNum;
    const allBeneficiaries = beneficiaryData.filter(
      (beneficiary) => beneficiary.ProjNum === projectNum
    );
    const filteredBeneficiaries = allBeneficiaries.filter(
      (beneficiary) =>
        beneficiary.BenStatus === "Active" &&
        beneficiary.FirstNames !== "" &&
        beneficiary.LastName !== "" &&
        beneficiary.ID_Num !== ""
    );
    if (filteredBeneficiaries.length === allBeneficiaries.length) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  const dateFormatter = (field, data, column) => {
    const dateValue = getValue("DateTime", data); // Replace "DateFieldName" with your actual date field name
    // Format the date with the desired options
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(new Date(dateValue));
    return formattedDate;
  };

  let workflowButtons = [
    {
      buttonModel: {
        content: "Send",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleChangeCurrentUser(
          selectedID,
          selectedStage,
          sender,
          receiver,
          notes
        ); // Replace with your actual logic
        setIsDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsDialogVisible(false);
      },
    },
  ];

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
    mode: "Dialog",
  };
  const toolbarOptions = ["ColumnChooser"];
  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-sub2">
          <button
            className="recall-button"
            onClick={handleRecallButtonClick}
            disabled={selectedID === ""}
          >
            <SubdirectoryArrowLeftIcon className="send-icon" /> Recall{" "}
          </button>
          {user?.JobTitle !== "Coordinator" && (
            <button
              className="coordinator-button"
              onClick={handleCoordinatorButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Coordinator{" "}
            </button>
          )}
          {user?.JobTitle !== "Administrator" && (
            <button
              className="administrator-button"
              onClick={handleAdministratorButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Administrator
            </button>
          )}
          {user?.JobTitle !== "Facilitator" && (
            <button
              className="facilitator-button"
              onClick={handleFacilitatorButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Facilitator
            </button>
          )}
          {user?.JobTitle !== "Assistant National Facilitator" && (
            <button
              className="assistant-nf-button"
              onClick={handleAssistantNFButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Assistant NF
            </button>
          )}
          {user?.JobTitle !== "National Facilitator" && (
            <button
              className="national-facilitator-button"
              onClick={handleNationalFacilitatorButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> National Facilitator
            </button>
          )}
          {user?.JobTitle !== "Accountant" && (
            <button
              className="administrator-button"
              onClick={handleAccountantButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Accountant
            </button>
          )}
          {user?.JobTitle !== "Financial accountant" && (
            <button
              className="coordinator-button"
              onClick={handleFinancialAccountantButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Fin. Accountant
            </button>
          )}
        </div>
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];
  console.log(gridData);
  return (
    <div className="main-my-workflows-container">
      <div className="project-my-workflows-container">
        <div className="my-workflows">
          <h3>Projects Identified/Created by Me</h3>
          <br />
          <div className="field-line-my-workflows">
            {isDataLoaded && (
              <GridComponent
                dataSource={gridData}
                childMapping="items"
                ref={GridRef}
                classClass="custom-treegrid-class"
                height="550"
                width="100%"
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                showColumnChooser={true}
                allowSelection={true}
                rowSelected={handleRowSelect}
                filterSettings={FilterOptions}
                pageSettings={pageSettings}
                editSettings={editOptions}
                toolbar={updatedToolbarOptions}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="id"
                    headerText="ID"
                    width="100"
                    textAlign="Left"
                    isPrimaryKey={true}
                  />
                  <ColumnDirective
                    field="ProjectNum"
                    headerText="Project #"
                    width="120"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="ProjectName"
                    headerText="Project Name"
                    width="200"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    headerText="Ben's"
                    width="90"
                    textAlign="Left"
                    template={beneficiaryTemplate}
                  />
                  <ColumnDirective
                    headerText="Tasks"
                    width="90"
                    textAlign="Left"
                    template={taskTemplate}
                  />
                  <ColumnDirective
                    headerText="Attach's"
                    width="90"
                    textAlign="Left"
                    template={attachmentTemplate}
                  />
                  <ColumnDirective
                    field="CurrentUser"
                    headerText="With Who?"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Sector"
                    headerText="Sector"
                    width="120"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="SubSector"
                    headerText="Sub Sector"
                    width="120"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="Type"
                    headerText="Type"
                    width="150"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="Phase"
                    headerText="Phase"
                    width="150"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="Stage"
                    headerText="Stage"
                    width="150"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="ProjectStatus"
                    headerText="Status"
                    width="90"
                    textAlign="Left"
                    template={statusTemplate}
                  />
                  <ColumnDirective
                    field="Administrator"
                    headerText="Administrator"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Facilitator"
                    headerText="Facilitator"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Coordinator"
                    headerText="Coordinator"
                    width="150"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="Region"
                    headerText="Region"
                    width="150"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="Province"
                    headerText="Province"
                    width="150"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="GeneralRemarks"
                    headerText="General Remarks"
                    width="350"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="IdentificationDate"
                    headerText="Identification Date"
                    width="220"
                    format="yyyy/MM/dd"
                    textAlign="Right"
                    type="date"
                  />
                  <ColumnDirective
                    field="VerificationDate"
                    headerText="Verification Date"
                    width="220"
                    format="yyyy/MM/dd"
                    textAlign="Right"
                    type="date"
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Sort, Filter, Edit, Toolbar, ColumnChooser]}
                />
              </GridComponent>
            )}
          </div>
        </div>
      </div>
      <div className="workflow-history-my-workflows">
        <h3>{selectedProjectName}: Workflow History</h3>
        <br />
        <div className="field_line-my-workflows">
          {isDataLoaded && (
            <GridComponent
              dataSource={updatedWorkflowData}
              childMapping="items"
              ref={GridRef}
              classClass="custom-treegrid-class"
              height="550"
              width="100%"
              allowPaging={true}
              allowSorting={true}
              allowFiltering={true}
              showColumnChooser={true}
              allowTextWrap={true}
              filterSettings={FilterOptions}
              pageSettings={pageSettings}
              editSettings={editOptions}
              toolbar={toolbarOptions}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="Sender"
                  headerText="From"
                  width="150"
                  textAlign="Left"
                  wrapText={true}
                />
                <ColumnDirective
                  field="Receiver"
                  headerText="To"
                  width="150"
                  textAlign="Left"
                  wrapText={true}
                />
                <ColumnDirective
                  field="Notes"
                  headerText="Note"
                  width="400"
                  textAlign="Left"
                  wrapText={true}
                />
                <ColumnDirective
                  field="DateTime"
                  headerText="When?"
                  width="130"
                  textAlign="Left"
                  valueAccessor={dateFormatter}
                  Type="date"
                />
                <ColumnDirective
                  field="ProjectStage"
                  headerText="Stage"
                  width="150"
                  textAlign="Left"
                  wrapText={true}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Sort, Filter, Edit, Toolbar, ColumnChooser]}
              />
            </GridComponent>
          )}
        </div>
      </div>
      <div>
        {isDialogVisible && (
          <DialogComponent
            visible={isDialogVisible}
            width="700px"
            height="650px"
            header="Workflow"
            showCloseIcon={true}
            close={handleDialogClose}
            buttons={workflowButtons}
          >
            <WorkflowDialog
              selectedID={selectedID}
              selectedStage={selectedStage}
              sender={sender}
              receiver={receiver}
              currentDateTime={currentDateTime}
              setNotes={setNotes}
            />
          </DialogComponent>
        )}
      </div>
    </div>
  );
}

export default MyProjects;
