import "./App.css";
import React from "react";
import "../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
import Login from "./components/body/login/Login";
import Home from "./components/body/home/Home";
//PROJECT PAGES
import ListView from "./components/body/projects/children/ListView";
import ChangeManager from "./components/body/projects/children/ChangeManager";
import Identification from "./components/body/projects/children/Identification";
import Dashboard from "./components/body/projects/children/Dashboard";
import CapacityBuilding from "./components/body/projects/children/CapacityBuilding";
import Funding from "./components/body/projects/children/Funding";
import Monitoring from "./components/body/projects/children/Monitoring";
//BENEFICIARY PAGES
import BenListView from "./components/body/beneficiaries/children/BenListView";
import BenYouthDashboard from "./components/body/beneficiaries/children/BenYouthDashboard";
import BenFemaleDashboard from "./components/body/beneficiaries/children/BenFemaleDashboard";
import BenResignedBeneficiaries from "./components/body/beneficiaries/children/BenResignedBeneficiaries";
import BenDeceasedBeneficiaries from "./components/body/beneficiaries/children/BenDeceasedBeneficiaries";
import BenNoGenderSpecified from "./components/body/beneficiaries/children/BenNoGenderSpecified";
import BenNoIdNumber from "./components/body/beneficiaries/children/BenNoIdNumber";
import BenActiveBeneficiaries from "./components/body/beneficiaries/children/BenActiveBeneficiaries";
//COMPLIANCY PAGES
import ComListView from "./components/body/compliancy/children/ComListView";
import ComNotActive from "./components/body/compliancy/children/ComNotActive";
import ComDashboard from "./components/body/compliancy/children/ComDashboard";
import InsuffientBen from "./components/body/compliancy/children/InsuffientBen";
import NoBankAccount from "./components/body/compliancy/children/NoBankAccount";
import CipcNotCompliant from "./components/body/compliancy/children/CipcNotCompliant";
import CipcNotInBusiness from "./components/body/compliancy/children/CipcNotInBusiness";
import NotCompliant from "./components/body/compliancy/children/NotCompliant";
//TASK PAGES
import TaskDashboard from "./components/body/tasks/children/TaskDashboard";
import TaskLists from "./components/body/tasks/children/TaskLists";
import TaskOverdue from "./components/body/tasks/children/TaskOverdue";
import TaskDueToday from "./components/body/tasks/children/TaskDueToday";
import TaskDueThisWeek from "./components/body/tasks/children/TaskDueThisWeek";
import TaskDueThisMonth from "./components/body/tasks/children/TaskDueThisMonth";
import TaskDueThisQuarter from "./components/body/tasks/children/TaskDueThisQuarter";
import TaskDueThisYear from "./components/body/tasks/children/TaskDueThisYear";
//Attachment PAGES
import AttachmentListView from "./components/body/Attachments/children/AttachmentListView";
import AttachmentUpToDate from "./components/body/Attachments/children/AttachmentUpToDate";
import AttachmentDashboard from "./components/body/Attachments/children/AttachmentDashboard";
import AttachmentDueForUpdate from "./components/body/Attachments/children/AttachmentDueForUpdate";
import AttachmentOverdue from "./components/body/Attachments/children/AttachmentOverdue";
import AttachmentBankAccountDue from "./components/body/Attachments/children/AttachmentBankAccountDue";
import AttachmentIDCopiesDue from "./components/body/Attachments/children/AttachmentIDCopiesDue";
//Messages PAGES
import ChatAttachments from "./components/body/messages/children/ChatAttachments";
import ChatBeneficiaries from "./components/body/messages/children/ChatBeneficiaries";
import ChatPayments from "./components/body/messages/children/ChatPayments";
import ChatProjects from "./components/body/messages/children/ChatProjects";
import ChatTasks from "./components/body/messages/children/ChatTasks";
//Financial PAGES
import FinLists from "./components/body/financials/children/FinLists";
import FinDetails from "./components/body/financials/children/FinDetails";
import FinPhase1 from "./components/body/financials/children/FinPhase1";
import FinDashboard from "./components/body/financials/children/FinDashbord";
import FinPhase2 from "./components/body/financials/children/FinPhase2";
import FinPhase3 from "./components/body/financials/children/FinPhase3";
import FinPhase4 from "./components/body/financials/children/FinPhase4";
import FinQuotations from "./components/body/financials/children/FinQuotations";
import PaymentFiles from "./components/body/financials/children/PaymentFiles";
//SMTS PAGES
import SmtsLists from "./components/body/smts/children/SmtsLists";
import SmtsPeople from "./components/body/smts/children/SmtsPeople";
import SmtsLocation from "./components/body/smts/children/SmtsLocation";
import SmtsWhereabouts from "./components/body/smts/children/SmtsWhereabouts";

import NotFound from "./NotFound";
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import MainLayout from "./MainLayout";
import ProjectLayout from "./components/body/projects/ProjectLayout";
import BeneficiaryLayout from "./components/body/beneficiaries/BeneficiaryLayout";
import CompliancyLayout from "./components/body/compliancy/CompliancyLayout";
import TaskLayout from "./components/body/tasks/TaskLayout";
import AttachmentLayout from "./components/body/Attachments/AttachmentLayout";
import FinancialLayout from "./components/body/financials/FinancialLayout";
import SmtsLayout from "./components/body/smts/SmtsLayout";
import MessageLayout from "./components/body/messages/MessageLayout";
import WithAuthProtection from "./components/auth.protection";
import { ToastContainer} from 'react-toastify';
import '../node_modules/react-toastify/dist/ReactToastify.css';
const ProtectedMainLayout = WithAuthProtection("/login")(MainLayout);

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="login" element={<Login />} />
      <Route path="/" element={<ProtectedMainLayout />}>
        <Route index element={<Home />} />
        <Route path="projects" element={<ProjectLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="listview" element={<ListView />} />
          <Route path="changemanager" element={<ChangeManager />} />
          <Route path="identification" element={<Identification />} />
          <Route path="capacitybuilding" element={<CapacityBuilding />} />
          <Route path="funding" element={<Funding />} />
          <Route path="monitoring" element={<Monitoring />} />
        </Route>
        <Route path="compliancy" element={<CompliancyLayout />}>
          <Route index element={<ComDashboard />} />
          <Route path="listview" element={<ComListView />} />
          <Route path="notactive" element={<ComNotActive />} />
          <Route path="dashboard" element={<ComDashboard />} />
          <Route path="insuffientben" element={<InsuffientBen />} />
          <Route path="nobankaccount" element={<NoBankAccount />} />
          <Route path="cipcnotcompliant" element={<CipcNotCompliant />} />
          <Route path="cipcnotinbusiness" element={<CipcNotInBusiness />} />
          <Route path="notcompliant" element={<NotCompliant />} />
        </Route>
        <Route path="beneficiaries" element={<BeneficiaryLayout />}>
          <Route index element={<BenFemaleDashboard />} />
          <Route path="listview" element={<BenListView />} />
          <Route path="youthdashboard" element={<BenYouthDashboard />} />
          <Route path="femaledashboard" element={<BenFemaleDashboard />} />
          <Route path="resignedbeneficiaries" element={<BenResignedBeneficiaries />}/>
          <Route
            path="deceasedbeneficiaries"
            element={<BenDeceasedBeneficiaries />}
          />
          <Route path="nogenderspecified" element={<BenNoGenderSpecified />} />
          <Route path="noidnumber" element={<BenNoIdNumber />} />
          <Route
            path="activebeneficiaries"
            element={<BenActiveBeneficiaries />}
          />
        </Route>
        <Route path="tasks" element={<TaskLayout />}>
          <Route index element={<TaskDashboard />} />
          <Route path="dashboard" element={<TaskDashboard />} />
          <Route path="projecttasks" element={<TaskLists />} />
          <Route path="overdue" element={<TaskOverdue />} />
          <Route path="duetoday" element={<TaskDueToday />} />
          <Route path="duethisweek" element={<TaskDueThisWeek />} />
          <Route path="duethismonth" element={<TaskDueThisMonth />} />
          <Route path="duethisquarter" element={<TaskDueThisQuarter />} />
          <Route path="duethisyear" element={<TaskDueThisYear />} />
        </Route>
        <Route path="Attachments" element={<AttachmentLayout />}>
          <Route index element={<AttachmentDashboard />} />
          <Route path="listview" element={<AttachmentListView />} />
          <Route path="uptodate" element={<AttachmentUpToDate />} />
          <Route path="dashboard" element={<AttachmentDashboard />} />
          <Route path="dueforupdate" element={<AttachmentDueForUpdate />} />
          <Route path="overdue" element={<AttachmentOverdue />} />
          <Route path="accountdue" element={<AttachmentBankAccountDue />} />
          <Route path="idcopiesdue" element={<AttachmentIDCopiesDue />} />
        </Route>

        <Route path="financials" element={<FinancialLayout />}>
          <Route index element={<FinDashboard />} />
          <Route path="payments" element={<FinLists />} />
          <Route path="budget" element={<FinDetails />} />
          <Route path="upto350k" element={<FinPhase1 />} />
          <Route path="dashboard" element={<FinDashboard />} />
          <Route path="upto650k" element={<FinPhase2 />} />
          <Route path="upto1200k" element={<FinPhase3 />} />
          <Route path="above1200k" element={<FinPhase4 />} />
          <Route path="quotations" element={<FinQuotations />} />
          <Route path="paymentfiles" element={<PaymentFiles />} />
        </Route>

        <Route path="messages" element={<MessageLayout />}>
          <Route index element={<ChatProjects />} />
          <Route path="projects" element={<ChatProjects />} />
          <Route path="beneficiaries" element={<ChatBeneficiaries />} />
          <Route path="attachments" element={<ChatAttachments />} />
          <Route path="tasks" element={<ChatTasks />} />
          <Route path="payments" element={<ChatPayments />} />
        </Route>

        <Route path="smts" element={<SmtsLayout />}>
          <Route index element={<SmtsPeople />} />
          <Route path="lists" element={<SmtsLists />} />
          <Route path="people" element={<SmtsPeople />} />
          <Route path="location" element={<SmtsLocation />} />
          <Route path="whereabouts" element={<SmtsWhereabouts />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </>
  )
);

function App() {
  console.log("env: ", process.env.REACT_APP_SERVER_URL)
  return (
    <div>
      <RouterProvider router={router} />;
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="dark"
      />;
    </div>
  )

}

export default App;
