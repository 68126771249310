import React, { useEffect, useState, useRef } from "react";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Sort, Filter, Edit, Toolbar } from "@syncfusion/ej2-react-grids";
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import "./MainComp.css";
import useProjectDataFetch from "../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../shared/useOtherDataFetch"; // Import the custom hook
import useUserDataFetch from "../../../shared/useUserDataFetch"; // Import the custom hook
import useUserChatsDataFetch from "../../../shared/useUserChatsDataFetch"; // Import the custom hook
import useUserStore from "../../../../app/user";
import MessageBox from "../MessageBox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import socket from "../../../../utils/socket";
import axios from "../../../../config/axios";

function ChatAttachments() {
  const user = useUserStore((state) => state.user);
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { userChatsData, isUserChatsLoaded, setUserChatsData } = useUserChatsDataFetch([]); // Use the custom hook to fetch data
  const { allUserData, isAllUserLoaded } = useUserDataFetch([]); // Use the custom hook to fetch data
  const { attachmentData, isAttachmentLoaded } = useOtherDataFetch();
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [commonUserData, setCommonUserData] = useState([]);
  const [selectedAttachName, setSelectedAttachName] = useState("");
  const [selectedID, setSelectedID] = useState("");
  const [selectedChatUsers, setSelectedChatUsers] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [gridData, setGridData] = useState([]);
  // const [isNewEntry, setIsNewEntry] = useState(false);

  const chatAdmin = user?.FullName;

  useEffect(() => {
    if (isProjectLoaded && isAttachmentLoaded && isAllUserLoaded && isUserChatsLoaded) {
      const finalProjectData = projectData.filter((project) => project.ProjectStatus !== "Deleted");
      const matchingAttachments = attachmentData.filter((attachment) => {
        const matchingProject = finalProjectData.find((project) => project.ProjectNum === attachment.ProjectNum);
        return matchingProject !== undefined;
      });
      setGridData(matchingAttachments);

      const filteredUserData = allUserData.filter(
        (user) =>
          user.AccessProvince === "All" &&
          user.FullName !== "Vusi Shongwe" &&
          user.FullName !== "Fana Mkwanazi" &&
          user.FullName !== "Nqobile Dlamini"
      );
      setCommonUserData(filteredUserData);
      setIsDataLoaded(true);
    }
  }, [attachmentData, isAttachmentLoaded, projectData, isProjectLoaded, allUserData, userChatsData, isAllUserLoaded, isUserChatsLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  const handleRowSelect = async (args) => {
    setSelectedAttachName(args.data.AttachName);
    setSelectedID(args.data.id);

    const projectNum = args.data.ProjectNum;
    const project = projectData.find((project) => project.ProjectNum === projectNum);
    const selectedProvince = project.Province;
    const selectedRegion = project.Region;

    const selectedUserData = allUserData.filter(
      (user) =>
        (user.AccessProvince === selectedProvince && user.JobTitle === "Facilitator") ||
        (user.AccessProvince === selectedProvince && user.JobTitle === "Administrator") ||
        user.AccessRegion === selectedRegion
    );
    const combinedList = commonUserData.concat(selectedUserData);
    const filteredUserData = combinedList.filter((item) => item.FullName !== user.FullName);
    setSelectedChatUsers(filteredUserData);
    axios
      .patch(process.env.REACT_APP_SERVER_URL + `/api/chat/read`, {
        type: "attachment_id",
        id: args.data.id,
      })
      .then(({ data }) => {
        console.log("Read : ", data);
        setUserChatsData((prev) => prev?.filter((notif) => notif.attachment_id !== args.data.id));
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  };

  const chatCountTemplate = (props) => {
    const ID = props.id;
    const chatCount = userChatsData.filter((chat) => chat.attachment_id === ID).length;
    const fontColor = chatCount > 0 ? "darkgreen" : "darkred";
    const backgroundColor = chatCount > 0 ? "lightgreen" : "pink";
    const fontWeight = chatCount > 0 ? "bold" : "normal";

    const style = {
      color: fontColor,
      backgroundColor: backgroundColor,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: fontWeight,
    };

    return (
      <div style={style}>
        <span>{chatCount}</span>
      </div>
    );
  };

  const viewAttachment = (media) => {
    window.open(media, "_blank");
  };

  const projectNameTemplate = (props) => {
    const projectNum = props.ProjectNum; // Assuming the field name is "ProjectNum"
    // Lookup the project name in projectData based on the provided projectNum
    const project = projectData.find((project) => project.ProjectNum === projectNum);
    // Check if a project with the given projectNum was found
    if (project) {
      const projectName = project.ProjectName;
      return <div>{projectName}</div>;
    } else {
      // Handle the case where no matching project was found
      return <div>Project Not Found</div>;
    }
  };

  // Custom template for the "Attachments" column
  const attachmentTemplate = (props) => {
    const lastUpdated = new Date(props.LastUpdated);
    const validityInYears = props.Validity;
    if (!isNaN(lastUpdated) && !isNaN(validityInYears) && props.AttachPath !== "") {
      const nextUpdateDate = new Date(lastUpdated);
      if (validityInYears === 0.0192307692307692) {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 7);
      } else if (validityInYears === 0.0833333333333333) {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 30);
      } else {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 365.25 * validityInYears);
      }
      const today = new Date();
      // Calculate the difference between nextUpdateDate and today's date in milliseconds
      const timeDifference = nextUpdateDate - today;
      // Calculate the difference in days
      const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      let requiredDays = 30;
      if (validityInYears === 0.0192307692307692) {
        requiredDays = 0;
      } else if (validityInYears === 0.0833333333333333) {
        requiredDays = 5;
      } else if (validityInYears === 0.25) {
        requiredDays = 10;
      } else {
        requiredDays = 30;
      }
      if (remainingDays < 0) {
        // Display a negative value to indicate that the date has passed
        return (
          <span>
            <CancelIcon style={{ color: "red" }} />
          </span>
        );
      } else if (remainingDays < requiredDays && (remainingDays > 0 || remainingDays === 0)) {
        return (
          <span>
            <CheckCircleIcon style={{ color: "Orange" }} />
          </span>
        );
      } else {
        return (
          <span>
            <CheckCircleIcon style={{ color: "green" }} />
          </span>
        );
      }
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  // Custom template for the "Attachments" column
  const buttonTemplate = (props) => {
    const media = process.env.REACT_APP_SERVER_URL + "/public/" + props.AttachPath;
    if (props.AttachPath !== "") {
      return (
        <div className="button-container1">
          <button className="view-button" onClick={() => viewAttachment(media)}>
            <VisibilityIcon className="view-icon" />
            View
          </button>
        </div>
      );
    }
  };

  const nextUpdateTemplate = (props) => {
    const lastUpdated = new Date(props.LastUpdated);
    const validityInYears = props.Validity;
    if (!isNaN(lastUpdated) && !isNaN(validityInYears) && props.AttachPath !== "") {
      const nextUpdateDate = new Date(lastUpdated);
      if (validityInYears === 0.0192307692307692) {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 7);
      } else if (validityInYears === 0.0833333333333333) {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 30);
      } else {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 365.25 * validityInYears);
      }
      const options = { year: "numeric", month: "short", day: "numeric" };
      const formattedNextUpdate = nextUpdateDate.toLocaleDateString("en-US", options);
      return <div>{formattedNextUpdate}</div>;
    } else {
      return <div>Now</div>;
    }
  };

  const remainingDaysTemplate = (props) => {
    const lastUpdated = new Date(props.LastUpdated);
    const validityInYears = props.Validity;
    if (!isNaN(lastUpdated) && !isNaN(validityInYears) && props.AttachPath !== "") {
      const nextUpdateDate = new Date(lastUpdated);
      if (validityInYears === 0.0192307692307692) {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 7);
      } else if (validityInYears === 0.0833333333333333) {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 30);
      } else {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 365.25 * validityInYears);
      }
      const today = new Date();
      // Calculate the difference between nextUpdateDate and today's date in milliseconds
      const timeDifference = nextUpdateDate - today;
      // Calculate the difference in days
      const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      if (remainingDays < 0) {
        // Display a negative value to indicate that the date has passed
        return <div style={{ color: "red" }}>{Math.abs(remainingDays)} days (past)</div>;
      } else {
        return <div style={{ color: "green" }}>{remainingDays} days</div>;
      }
    } else {
      return <div style={{ color: "red" }}>0 days</div>;
    }
  };

  useEffect(() => {
    socket.on("notification", (data) => {
      console.log("Receive Notification: ", data);
      const isTrue = userChatsData.find((chat) => chat.ID === data.ID);
      if (!isTrue) setUserChatsData((prev) => (prev ? [...prev, data] : [data]));
      // setIsNewEntry((prev) => !prev);
    });
  }, []);

  // useEffect(() => {
  //   if (userChatsData?.length)
  //     setUserChatsData((prev) => removeDuplicates(prev, "ID"));
  //   console.log("Is Newt : ", isNewEntry);
  // }, [isNewEntry]);

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };

  return (
    <div className="main-chat-container">
      <div className="item-chat-container">
        <h3>All Attachments in my Area</h3>
        <br />
        <div className="inner_container">
          {isDataLoaded && (
            <GridComponent
              dataSource={gridData}
              childMapping="items"
              ref={GridRef}
              classClass="custom-treegrid-class"
              height="650"
              width="100%"
              allowPaging={true}
              allowSorting={true}
              allowFiltering={true}
              allowSelection={true}
              rowSelected={handleRowSelect}
              filterSettings={FilterOptions}
              pageSettings={pageSettings}>
              <ColumnsDirective>
                <ColumnDirective field="AttachName" headerText="Attachment Name" width="170" textAlign="Left" />
                <ColumnDirective headerText="Chats" width="90" textAlign="Left" template={chatCountTemplate} />
                <ColumnDirective headerText="Valid?" width="90" textAlign="Center" template={attachmentTemplate} />
                <ColumnDirective headerText="View" width="100" textAlign="Left" template={buttonTemplate} />
                <ColumnDirective headerText="Project" width="150" textAlign="Left" editType="dropdownedit" template={projectNameTemplate} />
                <ColumnDirective
                  field="LastUpdated"
                  headerText="Last Updated On"
                  width="120"
                  format="MMM dd, yyyy"
                  textAlign="Left"
                  type="date"
                  allowFiltering={false}
                />
                <ColumnDirective
                  headerText="Next Update"
                  width="120"
                  textAlign="Left"
                  format="yyyy/MM/dd"
                  template={nextUpdateTemplate}
                  type="date"
                />
                <ColumnDirective headerText="Remaining Days" width="120" textAlign="Left" template={remainingDaysTemplate} />
              </ColumnsDirective>
              <Inject services={[Page, Sort, Filter, Edit, Toolbar]} />
            </GridComponent>
          )}
        </div>
      </div>

      <div className="chat-container">
        <h3>{selectedAttachName}: Chat Details</h3>
        <br />
        <div className="inner_container">
          {selectedID && <MessageBox users={allUserData} chatID={selectedID} chatUsers={selectedChatUsers} type="attachment_id" />}
        </div>
      </div>
    </div>
  );
}

export default ChatAttachments;

const removeDuplicates = (arr, prop) => {
  const seen = new Map();
  return arr.filter((item) => {
    const key = prop ? item[prop] : JSON.stringify(item);
    if (!seen.has(key)) {
      seen.set(key, true);
      return true;
    }
    return false;
  });
};
