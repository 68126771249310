import React, { useEffect, useState, useRef } from "react";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Sort, Filter, Edit, Toolbar } from "@syncfusion/ej2-react-grids";
import { getValue } from "@syncfusion/ej2-base";
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import "./MainComp.css";
import useProjectDataFetch from "../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../shared/useOtherDataFetch"; // Import the custom hook
import useUserDataFetch from "../../../shared/useUserDataFetch"; // Import the custom hook
import useUserChatsDataFetch from "../../../shared/useUserChatsDataFetch"; // Import the custom hook
import useUserStore from "../../../../app/user";
import MessageBox from "../MessageBox";
import { toast } from "react-toastify";
import socket from "../../../../utils/socket";
import axios from "../../../../config/axios";

function ChatPayments() {
  const user = useUserStore((state) => state.user);
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { userChatsData, isUserChatsLoaded, setUserChatsData } = useUserChatsDataFetch([]); // Use the custom hook to fetch data
  const { allUserData, isAllUserLoaded } = useUserDataFetch([]); // Use the custom hook to fetch data
  const { paymentData, isPaymentLoaded } = useOtherDataFetch();
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [commonUserData, setCommonUserData] = useState([]);
  const [selectedReferenceNum, setSelectedReferenceNum] = useState("");
  const [selectedID, setSelectedID] = useState("");
  const [selectedChatUsers, setSelectedChatUsers] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [gridData, setGridData] = useState([]);
  // const [isNewEntry, setIsNewEntry] = useState(false);

  const chatAdmin = user?.FullName;

  useEffect(() => {
    if (isProjectLoaded && isPaymentLoaded && isAllUserLoaded && isUserChatsLoaded) {
      const finalProjectData = projectData.filter((project) => project.ProjectStatus !== "Deleted");
      const matchingPayments = paymentData.filter((payment) => {
        const matchingProject = finalProjectData.find((project) => project.ProjectNum === payment.ProjectNum);
        return matchingProject !== undefined;
      });
      setGridData(matchingPayments);
      const filteredUserData = allUserData.filter(
        (user) =>
          user.AccessProvince === "All" &&
          user.FullName !== "Vusi Shongwe" &&
          user.FullName !== "Fana Mkwanazi" &&
          user.FullName !== "Nqobile Dlamini"
      );
      setCommonUserData(filteredUserData);
      setIsDataLoaded(true);
    }
  }, [paymentData, isPaymentLoaded, projectData, isProjectLoaded, allUserData, userChatsData, isAllUserLoaded, isUserChatsLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  const handleRowSelect = async (args) => {
    setSelectedReferenceNum(args.data.ReferenceNum);
    setSelectedID(args.data.id);

    const projectNum = args.data.ProjectNum;
    const project = projectData.find((project) => project.ProjectNum === projectNum);
    const selectedProvince = project.Province;
    const selectedRegion = project.Region;

    const selectedUserData = allUserData.filter(
      (user) =>
        (user.AccessProvince === selectedProvince && user.JobTitle === "Facilitator") ||
        (user.AccessProvince === selectedProvince && user.JobTitle === "Administrator") ||
        user.AccessRegion === selectedRegion
    );
    const combinedList = commonUserData.concat(selectedUserData);
    const filteredUserData = combinedList.filter((item) => item.FullName !== user.FullName);
    setSelectedChatUsers(filteredUserData);
    axios
      .patch(process.env.REACT_APP_SERVER_URL + `/api/chat/read`, {
        type: "payment_id",
        id: args.data.id,
      })
      .then(({ data }) => {
        console.log("Read : ", data);
        setUserChatsData((prev) => prev?.filter((notif) => notif.payment_id !== args.data.id));
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  };
  //console.log("Users: ", selectedChatUsers);

  const cumulativeAmountTemplate = (props) => {
    const cumulativeAmount = props.CumulativeAmounts; // Assuming the field name is "CumulativeAmounts"
    const fontColor = cumulativeAmount < 0 ? "red" : "inherit"; // Change font color to red if cumulativeAmount is negative

    // Format the amount with the desired currency symbol, space separator, and decimal places
    const formattedAmount = new Intl.NumberFormat("en-ZA", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 2,
    }).format(cumulativeAmount);

    return <div style={{ color: fontColor }}>{formattedAmount}</div>;
  };

  const currencyFormatter1 = (field, data, column) => {
    const debitAmount = getValue("DebitAmount", data); // Assuming the field name is "DebitAmount"

    // Format the amount with the desired currency symbol, space separator, and decimal places
    const formattedAmount = new Intl.NumberFormat("en-ZA", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 2,
    }).format(debitAmount);

    return formattedAmount;
  };

  const currencyFormatter2 = (field, data, column) => {
    const creditAmount = getValue("CreditAmount", data); // Assuming the field name is "DebitAmount"

    // Format the amount with the desired currency symbol, space separator, and decimal places
    const formattedAmount = new Intl.NumberFormat("en-ZA", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 2,
    }).format(creditAmount);

    return formattedAmount;
  };

  const chatCountTemplate = (props) => {
    const ID = props.id;
    const chatCount = userChatsData.filter((chat) => chat.payment_id === ID).length;
    const fontColor = chatCount > 0 ? "darkgreen" : "darkred";
    const backgroundColor = chatCount > 0 ? "lightgreen" : "pink";
    const fontWeight = chatCount > 0 ? "bold" : "normal";

    const style = {
      color: fontColor,
      backgroundColor: backgroundColor,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: fontWeight,
    };

    return (
      <div style={style}>
        <span>{chatCount}</span>
      </div>
    );
  };

  const projectNameTemplate = (props) => {
    const projectNum = props.ProjectNum; // Assuming the field name is "ProjectNum"
    // Lookup the project name in projectData based on the provided projectNum
    const project = projectData.find((project) => project.ProjectNum === projectNum);
    // Check if a project with the given projectNum was found
    if (project) {
      const projectName = project.ProjectName;
      return <div>{projectName}</div>;
    } else {
      // Handle the case where no matching project was found
      return <div>Project Not Found</div>;
    }
  };

  useEffect(() => {
    socket.on("notification", (data) => {
      console.log("Receive Notification: ", data);
      const isTrue = userChatsData.find((chat) => chat.ID === data.ID);
      if (!isTrue) setUserChatsData((prev) => (prev ? [...prev, data] : [data]));
      // setIsNewEntry((prev) => !prev);
    });
  }, []);

  // useEffect(() => {
  //   if (userChatsData?.length)
  //     setUserChatsData((prev) => removeDuplicates(prev, "ID"));
  //   console.log("Is Newt : ", isNewEntry);
  // }, [isNewEntry]);

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };

  return (
    <div className="main-chat-container">
      <div className="item-chat-container">
        <h3>All Payments in my Area</h3>
        <br />
        <div className="inner_container">
          {isDataLoaded && (
            <GridComponent
              dataSource={gridData}
              childMapping="items"
              ref={GridRef}
              classClass="custom-treegrid-class"
              height="650"
              width="100%"
              allowPaging={true}
              allowSorting={true}
              allowFiltering={true}
              allowSelection={true}
              rowSelected={handleRowSelect}
              filterSettings={FilterOptions}
              pageSettings={pageSettings}>
              <ColumnsDirective>
                <ColumnDirective
                  field="PaymentDate"
                  headerText="Payment Date"
                  width="130"
                  format="MMM dd, yyyy"
                  textAlign="Left"
                  type="date"
                  allowFiltering={false}
                />
                <ColumnDirective field="ReferenceNum" headerText="Reference #" width="120" textAlign="Left" />
                <ColumnDirective headerText="Chats" width="90" textAlign="Left" template={chatCountTemplate} />
                <ColumnDirective headerText="Project" width="150" textAlign="Left" editType="dropdownedit" template={projectNameTemplate} />
                <ColumnDirective field="ContraAcc" headerText="Contra Acc" width="100" textAlign="Left" />
                <ColumnDirective
                  field="DebitAmount"
                  headerText="Debit Amount"
                  width="150"
                  textAlign="Left"
                  valueAccessor={currencyFormatter1}
                />
                <ColumnDirective
                  field="CreditAmount"
                  headerText="Credit Amount"
                  width="150"
                  textAlign="Left"
                  valueAccessor={currencyFormatter2}
                />
                <ColumnDirective
                  field="CumulativeAmounts"
                  headerText="Cumulative Amount"
                  width="150"
                  type="N2"
                  textAlign="Left"
                  template={cumulativeAmountTemplate}
                />
              </ColumnsDirective>
              <Inject services={[Page, Sort, Filter, Edit, Toolbar]} />
            </GridComponent>
          )}
        </div>
      </div>

      <div className="chat-container">
        <h3>{selectedReferenceNum}: Chat Details</h3>
        <br />
        <div className="inner_container">
          {selectedID && <MessageBox users={allUserData} chatID={selectedID} chatUsers={selectedChatUsers} type="payment_id" />}
        </div>
      </div>
    </div>
  );
}

export default ChatPayments;

const removeDuplicates = (arr, prop) => {
  const seen = new Map();
  return arr.filter((item) => {
    const key = prop ? item[prop] : JSON.stringify(item);
    if (!seen.has(key)) {
      seen.set(key, true);
      return true;
    }
    return false;
  });
};
