import React, { useState, useEffect } from "react";
import "./AttachmentDashboard.css";
import useProjectDataFetch from "../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../shared/useOtherDataFetch"; // Import the custom hook
import useDateCalculator from "../../../shared/useDateCalculator";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  DataLabel,
  Category,
  Tooltip,
  ColumnSeries,
} from "@syncfusion/ej2-react-charts"; // Import Syncfusion chart components
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import useUserStore from "../../../../app/user";
import { toast } from "react-toastify";
import {
  FundedDataSource,
  getUpdateProjectGridData,
  identifiedDataSource,
} from "../../../../utils/project";

function AttachmentDashboard() {
  const user = useUserStore((state) => state.user);
  const { attachmentData, isAttachmentLoaded } = useOtherDataFetch();
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { beneficiaryData, isBeneficiaryLoaded } = useOtherDataFetch();
  const [gridData, setGridData] = useState([]);

  const dates = useDateCalculator();
  const [projectNames, setProjectNames] = useState();
  const [status, setStatus] = useState();
  const [provinces, setProvinces] = useState();
  const [regions, setRegions] = useState();
  const [sectors, setSectors] = useState();
  const [subSectors, setSubSectors] = useState();

  const [projectFilter, setProjectFilter] = useState("All");
  const [provinceFilter, setProvinceFilter] = useState("All");
  const [regionFilter, setRegionFilter] = useState("All");
  const [sectorFilter, setSectorFilter] = useState("All");
  const [subSectorFilter, setSubSectorFilter] = useState("All");
  const [statusFilter, setStatusFilter] = useState("All");
  const [stageFilter, setStageFilter] = useState("All");
  const [phaseFilter, setPhaseFilter] = useState("All");
  const [noCompliantFilter, setNoCompliantFilter] = useState("All");
  const [bankAccountFilter, setBankAccountFilter] = useState("All");
  const [cipcCompliantFilter, setCipcCompliantFilter] = useState("All");
  const [beneficiaryFilter, setBeneficiaryFilter] = useState("All");
  const [identifiedDateFilter, setIdentifiedDateFilter] = useState("All");
  const [fundedDateFilter, setFundedDateFilter] = useState("All");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isApiRequest, setIsApiRequest] = useState(true);
  const [updatedProjectData, setUpdatedProjectData] = useState([]);

  useEffect(() => {
    if (isProjectLoaded && isAttachmentLoaded && isBeneficiaryLoaded) {
      const finalProjectData = projectData.filter(
        (project) => project.ProjectStatus !== "Deleted"
      );
      setUpdatedProjectData(finalProjectData);
      setGridData(finalProjectData);
      setIsDataLoaded(true);
      setIsDataChanged(true);
    }
  }, [projectData, isProjectLoaded, isAttachmentLoaded, isBeneficiaryLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded && !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  useEffect(() => {
    //Get DropdownData
    const uniqueProvinces = [
      ...new Set(
        projectData?.map((item) => (item.Province ? item.Province : ""))
      ),
    ];
    const formattedUniqueProvinces = ["All", ...uniqueProvinces];
    setProvinces(formattedUniqueProvinces);

    const uniqueProjects = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.ProjectName ? item.ProjectName : ""
        )
      ),
    ];
    const formattedUniqueProjects = ["All", ...uniqueProjects];
    setProjectNames(formattedUniqueProjects);

    const uniqueregions = [
      ...new Set(
        updatedProjectData?.map((item) => (item.Region ? item.Region : ""))
      ),
    ];
    const formattedUniqueregions = ["All", ...uniqueregions];
    setRegions(formattedUniqueregions);

    const uniqueProjectstatus = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.ProjectStatus ? item.ProjectStatus : ""
        )
      ),
    ];
    const formattedUniqueProjectstatus = ["All", ...uniqueProjectstatus];
    setStatus(formattedUniqueProjectstatus);

    const uniqueSectors = [
      ...new Set(
        updatedProjectData?.map((item) => (item.Sector ? item.Sector : ""))
      ),
    ];
    const formattedUniqueSectors = ["All", ...uniqueSectors];
    setSectors(formattedUniqueSectors);

    const uniqueSubSectors = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.SubSector ? item.SubSector : ""
        )
      ),
    ];
    const formattedUniqueSubSectors = ["All", ...uniqueSubSectors];
    setSubSectors(formattedUniqueSubSectors);
  }, [updatedProjectData]);

  useEffect(() => {
    if (!isApiRequest) {
      const updatedData = getUpdateProjectGridData(
        updatedProjectData,
        beneficiaryData,
        provinceFilter,
        regionFilter,
        statusFilter,
        sectorFilter,
        subSectorFilter,
        stageFilter,
        phaseFilter,
        noCompliantFilter,
        bankAccountFilter,
        cipcCompliantFilter,
        beneficiaryFilter,
        identifiedDateFilter,
        fundedDateFilter,
        projectFilter,
        dates
      );
      setGridData(updatedData);

      setIsDataChanged(true);
      setIsApiRequest(true);
    }
  }, [
    updatedProjectData,
    beneficiaryData,
    provinceFilter,
    regionFilter,
    statusFilter,
    sectorFilter,
    subSectorFilter,
    stageFilter,
    phaseFilter,
    noCompliantFilter,
    bankAccountFilter,
    cipcCompliantFilter,
    beneficiaryFilter,
    identifiedDateFilter,
    fundedDateFilter,
    projectFilter,
    dates,
  ]);

  const handleProvinceChange = (e) => {
    if (e && e.value) {
      let provinceFilteredData;
      if (e.value !== "All") {
        provinceFilteredData = updatedProjectData?.filter(
          (item) => item.Province === e.value
        );
      } else {
        provinceFilteredData = updatedProjectData;
      }
      const uniqueregions = [
        ...new Set(
          provinceFilteredData?.map((item) => (item.Region ? item.Region : ""))
        ),
      ];
      const formattedUniqueregions = ["All", ...uniqueregions];
      setRegions(formattedUniqueregions);

      const uniqueProjects = [
        ...new Set(
          provinceFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setProvinceFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleRegionChange = (e) => {
    if (e && e.value) {
      let regionFilteredData;
      if (e.value !== "All") {
        regionFilteredData = updatedProjectData?.filter(
          (item) => item.Region === e.value
        );
      } else {
        regionFilteredData = updatedProjectData;
      }
      const uniqueProjects = [
        ...new Set(
          regionFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setRegionFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleStatusChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setStatusFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleSectorChange = (e) => {
    if (e && e.value) {
      let sectorFilteredData;
      if (e.value !== "All") {
        sectorFilteredData = updatedProjectData?.filter(
          (item) => item.Sector === e.value
        );
      } else {
        sectorFilteredData = updatedProjectData;
      }
      const uniqueSubSectors = [
        ...new Set(
          sectorFilteredData?.map((item) =>
            item.SubSector ? item.SubSector : ""
          )
        ),
      ];
      const formattedUniqueSubSectors = ["All", ...uniqueSubSectors];
      setSubSectors(formattedUniqueSubSectors);

      const uniqueProjects = [
        ...new Set(
          sectorFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setSectorFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleSubSectorChange = (e) => {
    if (e && e.value) {
      let subSectorFilteredData;
      if (e.value !== "All") {
        subSectorFilteredData = updatedProjectData?.filter(
          (item) => item.SubSector === e.value
        );
      } else {
        subSectorFilteredData = updatedProjectData;
      }

      const uniqueProjects = [
        ...new Set(
          subSectorFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setSubSectorFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleProjectChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setProjectFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleStageChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setStageFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handlePhaseChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setPhaseFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleNonComliantChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setNoCompliantFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleBankChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setBankAccountFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleCipcChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setCipcCompliantFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleBeneficiaryChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setBeneficiaryFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleIdentificationChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setIdentifiedDateFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleFundingChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setFundedDateFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const uniqueProjectNumbers = [
    ...new Set(
      gridData?.map((item) => (item.ProjectNum ? item.ProjectNum : ""))
    ),
  ];
  const filteredAttachmentData = attachmentData.filter((item) =>
    uniqueProjectNumbers.includes(item.ProjectNum)
  );

  // Get unique provinces from projectData
  const uniqueProvinces = [...new Set(gridData?.map((item) => item.Province))];
  // Count Attachments for each province
  const provinceCounts = {};
  const provinceCountsLoaded = {}; // For Loaded data
  uniqueProvinces.forEach((province) => {
    const matchingProjects = gridData.filter(
      (item) => item.Province === province
    );
    // Initialize counts for both Required and Loaded
    provinceCounts[province] = 0;
    provinceCountsLoaded[province] = 0;
    matchingProjects.forEach((project) => {
      const matchingAttachments = filteredAttachmentData.filter(
        (item) => item.ProjectNum === project.ProjectNum
      );

      // Count attachments and loaded attachments separately
      provinceCounts[province] += matchingAttachments.length; // Required count

      // Count loaded attachments (where AttachPath is not empty)
      provinceCountsLoaded[province] += matchingAttachments.filter(
        (item) => item.AttachPath !== ""
      ).length; // Loaded count
    });
  });
  // Prepare data for the chart
  const provinceChartData = Object.keys(provinceCounts).map((province) => ({
    x: province,
    yRequired: provinceCounts[province],
    yLoaded: provinceCountsLoaded[province], // Loaded data
  }));

  // Get unique regions from projectData
  const uniqueRegions = [...new Set(gridData?.map((item) => item.Region))];
  // Count Attachments for each region
  const regionCounts = {};
  const regionCountsLoaded = {}; // For Loaded data
  uniqueRegions.forEach((region) => {
    const matchingProjects = gridData.filter((item) => item.Region === region);
    // Initialize counts for both Required and Loaded
    regionCounts[region] = 0;
    regionCountsLoaded[region] = 0;
    matchingProjects.forEach((project) => {
      const matchingAttachments = filteredAttachmentData.filter(
        (item) => item.ProjectNum === project.ProjectNum
      );
      // Count attachments and loaded attachments separately
      regionCounts[region] += matchingAttachments.length; // Required count
      // Count loaded attachments (where AttachPath is not empty)
      regionCountsLoaded[region] += matchingAttachments.filter(
        (item) => item.AttachPath !== ""
      ).length; // Loaded count
    });
  });
  // Prepare data for the chart
  const regionChartData = Object.keys(regionCounts).map((region) => ({
    x: region,
    yRequired: regionCounts[region],
    yLoaded: regionCountsLoaded[region], // Loaded data
  }));

  // Get unique ProjectStatus values from projectData
  const uniqueProjectStatus = [
    ...new Set(projectData?.map((item) => item.ProjectStatus)),
  ];
  // Count Attachments for each ProjectStatus
  const projectStatusCounts = {};
  const projectStatusCountsLoaded = {}; // For Loaded data
  uniqueProjectStatus.forEach((status) => {
    const matchingProjects = projectData.filter(
      (item) => item.ProjectStatus === status
    );
    // Initialize counts for both Required and Loaded
    projectStatusCounts[status] = 0;
    projectStatusCountsLoaded[status] = 0;
    matchingProjects.forEach((project) => {
      const matchingAttachments = filteredAttachmentData.filter(
        (item) => item.ProjectNum === project.ProjectNum
      );
      // Count attachments and loaded attachments separately
      projectStatusCounts[status] += matchingAttachments.length; // Required count
      // Count loaded attachments (where AttachPath is not empty)
      projectStatusCountsLoaded[status] += matchingAttachments.filter(
        (item) => item.AttachPath !== ""
      ).length; // Loaded count
    });
  });
  // Prepare data for the chart
  const projectStatusChartData = Object.keys(projectStatusCounts).map(
    (status) => ({
      x: status,
      yRequired: projectStatusCounts[status],
      yLoaded: projectStatusCountsLoaded[status], // Loaded data
    })
  );

  // Count Attachments for each Project
  const projectAttachmentCounts = {};
  const projectLoadedAttachmentCounts = {}; // For Loaded data
  gridData.forEach((project) => {
    const matchingAttachments = filteredAttachmentData.filter(
      (item) => item.ProjectNum === project.ProjectNum
    );
    // Count attachments and loaded attachments separately
    projectAttachmentCounts[project.ProjectNum] = matchingAttachments.length; // Required count
    // Count loaded attachments (where AttachPath is not empty)
    projectLoadedAttachmentCounts[project.ProjectNum] =
      matchingAttachments.filter((item) => item.AttachPath !== "").length; // Loaded count
  });
  // Prepare data for the chart
  const projectAttachmentChartData = gridData?.map((project) => ({
    x: project.ProjectName,
    yRequired: projectAttachmentCounts[project.ProjectNum] || 0,
    yLoaded: projectLoadedAttachmentCounts[project.ProjectNum] || 0, // Loaded data
  }));

  // Get matching attachments based on filtered projects
  const matchingAttachmentProjectNums = gridData?.map(
    (project) => project.ProjectNum
  );
  const matchingAttachments = filteredAttachmentData.filter((attachment) =>
    matchingAttachmentProjectNums.includes(attachment.ProjectNum)
  );
  // Count Attachments for matching projects
  const totalAttachments = matchingAttachments.length;
  const loadedAttachments = matchingAttachments.filter(
    (attachment) => attachment.AttachPath !== ""
  ).length;
  // Prepare data for the chart
  const attachmentChartData = [
    {
      x: "National",
      totalAttachments: totalAttachments,
      loadedAttachments: loadedAttachments,
    },
  ];

  const chartWidth = "100%"; // Set the desired chart width (e.g., "100%", "80px", etc.)
  const chartHeight = "80%"; // Set the desired chart width (e.g., "100%", "80px", etc.)
  const primaryxAxis = {
    valueType: "Category",
    labelRotation: -45,
    labelStyle: {
      color: "rgb(96,172,86)",
      fontWeight: "bold",
      size: "14px",
      fontFamily: "Segoe UI",
    },
  };
  const primaryyAxis = { visible: false };
  const marker = {
    dataLabel: {
      visible: true,
      font: {
        color: "rgb(96,172,86)",
        background: "white",
        fontWeight: "bold",
        size: "16px",
        fontFamily: "Segoe UI",
      },
    },
  };
  const tooltip = { enable: false, shared: false };
  // Define an array of varying colors for each series
  const varyingColors = [
    "red",
    "green",
    "rgb(96,172,86)",
    "#1565C0",
    "#FF5722",
    "#E91E63",
    "#9C27B0",
    "#009688",
    "#FF9800",
  ];

  return (
    <div className="reports-container">
      <div className="summary-container-project">
        <div className="decicion_requester1">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Province"
            floatLabelType="Always"
            dataSource={provinces}
            change={handleProvinceChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Region"
            floatLabelType="Always"
            dataSource={regions}
            change={handleRegionChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Sector"
            floatLabelType="Always"
            dataSource={sectors}
            change={handleSectorChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Sub-Sector"
            floatLabelType="Always"
            dataSource={subSectors}
            change={handleSubSectorChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Project Status"
            floatLabelType="Always"
            dataSource={status}
            change={handleStatusChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Project"
            floatLabelType="Always"
            dataSource={projectNames}
            change={handleProjectChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Phase"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Phase 1: Coordinators",
              "Phase 2: Facilitattors",
              "Phase 3: NF",
            ]}
            change={handlePhaseChange}
          />
        </div>
      </div>
      <div className="summary-container-project">
        <div className="decicion_requester1">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Stage"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Identification",
              "Capacity Building",
              "Funding",
              "Monitoring",
            ]}
            change={handleStageChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Overall Compliant"
            floatLabelType="Always"
            dataSource={["All", "Compliant", "Non-Compliant"]}
            change={handleNonComliantChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Beneficiary Compliant"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Sufficiant Beneficiaries",
              "Insufficiant Beneficiaries",
            ]}
            change={handleBeneficiaryChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Bank Compliant"
            floatLabelType="Always"
            dataSource={["All", "With Bank Account", "No Bank Account"]}
            change={handleBankChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="CIPC Compliant"
            floatLabelType="Always"
            dataSource={["All", "CIPC Compliant", "CIPC Non-Compliant"]}
            change={handleCipcChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Identification Date"
            floatLabelType="Always"
            dataSource={identifiedDataSource}
            change={handleIdentificationChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Funding Date"
            floatLabelType="Always"
            dataSource={FundedDataSource}
            change={handleFundingChange}
          />
        </div>
      </div>
      {/*National Dashboard*/}
      {user?.Access === "National" && (
        <div className="national-reports-container">
          <div className="chart-row">
            <div className="chart-container">
              <h2>Attachment Count by Status</h2>
              <ChartComponent
                id="status-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={projectStatusChartData}
                    xName="x"
                    yName="yRequired"
                    type="Column"
                    name="Budget"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={projectStatusChartData}
                    xName="x"
                    yName="yLoaded"
                    type="Column"
                    name="Cost"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>

            <div className="chart-container">
              <h2>Attachment Count by National</h2>
              <ChartComponent
                id="overall-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={attachmentChartData}
                    xName="x"
                    yName="totalAttachments"
                    type="Column"
                    name="Budget"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={attachmentChartData}
                    xName="x"
                    yName="loadedAttachments"
                    type="Column"
                    name="Cost"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>

            <div className="chart-container">
              <h2>Attachment Count by Province</h2>
              <ChartComponent
                id="province-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={provinceChartData}
                    xName="x"
                    yName="yRequired"
                    type="Column"
                    name="Budget"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={provinceChartData}
                    xName="x"
                    yName="yLoaded"
                    type="Column"
                    name="Cost"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-row">
            <div className="chart-container">
              <h2>Attachment Count by Region</h2>
              <ChartComponent
                id="region-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="yRequired"
                    type="Column"
                    name="Budget"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="yLoaded"
                    type="Column"
                    name="Cost"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      )}

      {/*Provincial Dashboard*/}
      {user?.Access === "Provincial" && (
        <div className="provincial-reports-container">
          <div className="chart-row">
            <div className="chart-container">
              <h2>Attachment Count by Status</h2>
              <ChartComponent
                id="status-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={projectStatusChartData}
                    xName="x"
                    yName="yRequired"
                    type="Column"
                    name="Required Attachments"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={projectStatusChartData}
                    xName="x"
                    yName="yLoaded"
                    type="Column"
                    name="Loaded Attachments"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container">
              <h2>Attachment Count by Province</h2>
              <ChartComponent
                id="province-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={provinceChartData}
                    xName="x"
                    yName="yRequired"
                    type="Column"
                    name="Required Attachments"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={provinceChartData}
                    xName="x"
                    yName="yLoaded"
                    type="Column"
                    name="Loaded Attachments"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-row1">
            <div className="chart-container">
              <h2>Attachment Count by Region</h2>
              <ChartComponent
                id="region-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="yRequired"
                    type="Column"
                    name="Required Attachments"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="yLoaded"
                    type="Column"
                    name="Loaded Attachments"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      )}

      {/*Regional Dashboard*/}
      {user?.Access === "Regional" && (
        <div className="regional-reports-container">
          <div className="chart-row">
            <div className="chart-container">
              <h2>Attachment Count by Status</h2>
              <ChartComponent
                id="status-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={projectStatusChartData}
                    xName="x"
                    yName="yRequired"
                    type="Column"
                    name="Required Attachments"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={projectStatusChartData}
                    xName="x"
                    yName="yLoaded"
                    type="Column"
                    name="Loaded Attachments"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container">
              <h2>Attachment Count by Region</h2>
              <ChartComponent
                id="region-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="yRequired"
                    type="Column"
                    name="Required Attachments"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="yLoaded"
                    type="Column"
                    name="Loaded Attachments"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-row1">
            <div className="chart-container">
              <h2>Attachment Count by Project</h2>
              <ChartComponent
                id="project-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={projectAttachmentChartData}
                    xName="x"
                    yName="yRequired"
                    type="Column"
                    name="Required Attachments"
                    marker={marker}
                    fill={varyingColors[0]}
                  />
                  <SeriesDirective
                    dataSource={projectAttachmentChartData}
                    xName="x"
                    yName="yLoaded"
                    type="Column"
                    name="Loaded Attachments"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AttachmentDashboard;
