import React, { useState } from "react";
import {
  TextBoxComponent,
  UploaderComponent,
} from "@syncfusion/ej2-react-inputs";
import "./AttachmentDialog.css";
import wordFile from "./AttachDoc.png";
import excelFile from "./AttachXls.png";
import pdfFile from "./AttachPdf.png";
import imageFile from "./AttachPic.png";

function AttachmentDialog({
  selectedID,
  selectedStage,
  selectedProjectNum,
  selectedProjectName,
  setSelectedAttachName,
  selectedAttachPath,
  setSelectedFile,
}) {
  const onFileSelected = async ({ event }) => {
    // console.log("File: ", event);
    const file = event.target.files[0];
    setSelectedFile(file);
  };

// Extract the file extension from selectedAttachPath
const fileExtension = selectedAttachPath.slice(((selectedAttachPath.lastIndexOf(".") - 1) >>> 0) + 2);
let filetype = "";
// Check the file extension and set the appropriate filetype
if (fileExtension === "pdf") {
  filetype = pdfFile;
} else if (fileExtension === "doc" || fileExtension === "docx") {
  filetype = wordFile;
} else if (fileExtension === "xls" || fileExtension === "xlsx") {
  filetype = excelFile;
} else if (fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg") {
  filetype = imageFile;
} else {
  filetype = "";
}


  return (
    <table className="main-container-att-dialog">
      <tbody className="project-field-att-dialog">
        <tr className="my-att-dialog-row1">
          <td className="field_att-projectid">
            <TextBoxComponent
              id="id"
              name="id"
              value={selectedID}
              placeholder=" Proj. ID"
              floatLabelType="Always"
              disabled={true}
            />
          </td>
          <td className="field_att-projectstage">
            <TextBoxComponent
              id="Stage"
              name="Stage"
              value={selectedStage}
              placeholder=" Proj. Stage"
              floatLabelType="Always"
              disabled={true}
            />
          </td>
          <td className="field_att-project-number">
            <TextBoxComponent
              id="date-time"
              name="date-time"
              value={selectedProjectNum}
              placeholder=" Proj. Number"
              floatLabelType="Always"
              disabled={true}
            />
          </td>
        </tr>
        <tr className="my-att-dialog-row2">
          <td className="field_att-type">
            <TextBoxComponent
              id="from"
              name="from"
              value={selectedProjectName}
              placeholder="Proj. Name"
              floatLabelType="Always"
              disabled={true}
            />
          </td>
          <td className="field_att-name">
            <TextBoxComponent
              id="to"
              name="to"
              value={setSelectedAttachName}
              placeholder=" Attachment Name"
              floatLabelType="Always"
              disabled={true}
            />
          </td>
        </tr>
        <tr className="my-att-dialog-row3">
          <td className="field_att-uploader">
            <UploaderComponent
              multiple={false}
              value={selectedAttachPath}
              selected={onFileSelected}
              allowedExtensions=".pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg"
            />
          </td>
          <td className="field_att-image">
          {selectedAttachPath && (<img src={filetype} alt="Attachment" />)}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default AttachmentDialog;
