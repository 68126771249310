import React from 'react'
import { Outlet } from 'react-router-dom';
import Beneficiaries from './Beneficiaries';
import '../MainBody.css'


function BeneficiaryLayout() {
  return (
    <div className='projects_container'>
      <div className='projects_sidebar'>
        <Beneficiaries />
      </div>
      <div className='projects_main'>
        <Outlet />
      </div>
    </div>
  )
}

export default BeneficiaryLayout