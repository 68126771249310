import "./Header.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import logo from "./logo.png";
import HeaderOption from "./HeaderOption";
import HomeIcon from "@mui/icons-material/Home";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleIcon from "@mui/icons-material/People";
import PlaceIcon from "@mui/icons-material/Place";
import AddTaskIcon from "@mui/icons-material/AddTask";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PaymentsIcon from "@mui/icons-material/Payments";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import ForumIcon from "@mui/icons-material/Forum";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import useUserStore from "../../app/user";
import socket from "../../utils/socket";
import { Badge } from "@mui/material";
import useUserChatsDataFetch from "../shared/useUserChatsDataFetch";
import {
  getAttachmentChatUsers,
  getBeneficiaryChatUsers,
  getCommonUsers,
  getPaymentChatUsers,
  getProjectChatUsers,
  getProjectName,
} from "../../utils";
import useProjectDataFetch from "../shared/useProjectDataFetch";
import { timeSince } from "../../utils/formats";
import MessageBox from "../body/messages/MessageBox";
import CloseIcon from "@mui/icons-material/Close";
import useUserDataFetch from "../shared/useUserDataFetch";
import axios from "../../config/axios";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  height: "50%",
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 2,
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "90%",
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 2,
};
// import useUserChatsDataFetch from "../shared/useUserChatsDataFetch";

function Header() {
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);
  const { setJwt } = useUserStore();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { projectData } = useProjectDataFetch(user);
  const { userChatsData, setUserChatsData } = useUserChatsDataFetch([]); // Use the custom hook to fetch data
  const navigate = useNavigate();
  const profile = process.env.REACT_APP_SERVER_URL + "/public/uploads/" + user?.UserName + ".jpg";
  const [isMessagesVisible, setIsMessagesVisible] = useState(false);
  // const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedChatUsers, setSelectedChatUsers] = useState([]);
  const [selectedID, setSelectedID] = useState("");
  const { allUserData } = useUserDataFetch([]);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");

  const handleLogout = () => {
    // Perform logout actions
    // Redirect to the login page
    setUser(null);
    setJwt(null);
    navigate("/login");
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false); // Close the dialog
  };

  const handleCloseNotification = () => {
    setIsNotificationOpen(false); // Close the notification dialog
  };

  const handleOpenMessage = (notif, project_name, name2, name3, project_num) => {
    const commonUserData = getCommonUsers(allUserData);
    let notif_type = "";
    if (notif.project_id) {
      notif_type = "project_id";
      setTitle(() => `Chats about ${project_name} Project`);
      const chatUsers = getProjectChatUsers(notif.projectdetails.Province, notif.projectdetails.Region, allUserData, commonUserData, user);
      setSelectedChatUsers(() => chatUsers);
    }
    if (notif.benif_id) {
      notif_type = "benif_id";
      const chatUsers = getBeneficiaryChatUsers(projectData, project_num, allUserData, commonUserData, user);
      setTitle(() => `Chats about ${name2} ${name3} of ${project_name} Project`);
      setSelectedChatUsers(() => chatUsers);
    }
    if (notif.payment_id) {
      notif_type = "payment_id";
      setTitle(() => `Chats about Payment (Ref#: ${notif.projectpayments.ReferenceNum}) of ${project_name} Project`);
      const chatUsers = getPaymentChatUsers(projectData, notif.projectpayments.ProjectNum, allUserData, commonUserData, user);
      setSelectedChatUsers(() => chatUsers);
    }
    // if (notif.task_id) {
    //   setType(() => "task_id");
    //   setSelectedID(() => notif.task_id)
    //   setUserChatsData((prev) => prev.filter((item) => item.task_id !== notif.task_id));
    //   setTitle(() => ``);

    // }
    if (notif.attachment_id) {
      notif_type = "attachment_id";
      setTitle(() => `Chats about ${name2} of ${project_name} Project`);
      const chatUsers = getAttachmentChatUsers(projectData, notif.projectattachments.ProjectNum, allUserData, commonUserData, user);

      setSelectedChatUsers(() => chatUsers);
    }

    setSelectedID(() => notif[notif_type]);
    setType(() => notif_type);
    axios
      .patch(process.env.REACT_APP_SERVER_URL + `/api/chat/read`, {
        type: notif_type,
        id: notif[notif_type],
      })
      .then(({ data }) => {
        console.log("Read : ", data);
        setUserChatsData((prev) => prev?.filter((item) => item[notif_type] !== notif[notif_type]));
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
    setIsMessagesVisible(() => true);
    handleCloseNotification();
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    socket.on("notification", (data) => {
      console.log("Receive Notification: ", data);
      setUserChatsData((prev) => (prev ? [...prev, data] : [data]));
    });

    socket.emit("set-online-user", user.id);
    return () => {
      console.log("disconnected User.....");
      socket?.emit("disconnected", user.id, new Date());
    };
  }, []);

  const getNotifMessage = (notif, index) => {
    if (notif?.project_id) {
      const project_name = notif?.projectdetails?.ProjectName;
      return (
        <button onClick={() => handleOpenMessage(notif, project_name)} key={index}>
          {notif?.messages?.users?.FullName} left a message for you in {project_name} Project {timeSince(notif.created_at)}
        </button>
      );
    }

    if (notif?.attachment_id) {
      const project_name = getProjectName(projectData, notif?.projectattachments?.ProjectNum);
      const attach_name = notif?.projectattachments?.AttachName;
      return (
        <button onClick={() => handleOpenMessage(notif, project_name, attach_name)} key={index}>
          {notif?.messages?.users?.FullName} left a message for you in {attach_name} of {project_name} {timeSince(notif.created_at)}
        </button>
      );
    }

    if (notif?.benif_id) {
      const project_name = getProjectName(projectData, notif?.projectbeneficiaries?.ProjNum);
      const first_name = notif?.projectbeneficiaries?.FirstNames;
      const last_name = notif?.projectbeneficiaries?.LastName;
      const project_num = notif?.projectbeneficiaries?.ProjNum;
      return (
        <button onClick={() => handleOpenMessage(notif, project_name, first_name, last_name, project_num)} key={index}>
          {notif?.messages?.users?.FullName} left a message for you on {first_name} {last_name} of {project_name}{" "}
          {timeSince(notif.created_at)}
        </button>
      );
    }

    if (notif?.payment_id) {
      const project_name = getProjectName(projectData, notif?.projectpayments?.ProjectNum);
      return (
        <button onClick={() => handleOpenMessage(notif, project_name)} key={index}>
          {notif?.messages?.users?.FullName} left a message for you in the Payment of {project_name} {timeSince(notif.created_at)}
        </button>
      );
    }
    if (notif?.task_id) {
      const project_name = notif?.projecttasks?.projectdetails?.ProjectName;
      const task_name = notif?.projecttasks?.TaskName;
      return (
        <button onClick={() => handleOpenMessage(notif, project_name, task_name)} key={index}>
          {notif?.messages?.users?.FullName} left a message for you in {task_name} of {project_name} {timeSince(notif.created_at)}
        </button>
      );
    }
  };

  const handleMessageDialogClose = () => {
    setIsMessagesVisible(false);
  };

  return (
    <div className="header">
      <div className="header_left">
        <img src={logo} alt="LOGO" />
        <h1>NEW NAME</h1>
      </div>
      <div className="header_right">
        <NavLink to="/">{<HeaderOption Icon={HomeIcon} title="Home" />}</NavLink>
        <NavLink to="/projects">{<HeaderOption Icon={ApartmentIcon} title="Projects" />}</NavLink>
        <NavLink to="/compliancy">{<HeaderOption Icon={AssuredWorkloadIcon} title="Compliancy" />}</NavLink>
        <NavLink to="/beneficiaries">{<HeaderOption Icon={PeopleIcon} title="Beneficiaries" />}</NavLink>
        <NavLink to="/Attachments">{<HeaderOption Icon={AttachFileIcon} title="Attachments" />}</NavLink>
        <NavLink to="/tasks">{<HeaderOption Icon={AddTaskIcon} title="Tasks" />}</NavLink>
        <NavLink to="/financials">{<HeaderOption Icon={PaymentsIcon} title="Financials" />}</NavLink>
        <NavLink to="/messages">{<HeaderOption Icon={ForumIcon} title="Messages" />}</NavLink>
        <NavLink to="/smts">{<HeaderOption Icon={PlaceIcon} title="SMTS" />}</NavLink>
      </div>
      <div className="header_user_section">
        <div onClick={() => setIsNotificationOpen(true)} className="notif-section">
          {/* {<HeaderOption Icon={NotificationsIcon} title="Notes" />} */}
          <Badge badgeContent={userChatsData?.length} color="secondary">
            <NotificationsIcon className="custom_icon" />
          </Badge>
        </div>
        <div className="header_user" onClick={() => setIsDialogOpen(true)}>
          <img src={profile} alt="User Pic" />
          <div className="userInfo">
            <h1>{user?.FullName}</h1>
            <p>{user?.JobTitle}</p>
            {user?.Access === "Regional" && <p>{user?.AccessRegion}</p>}
            {user?.Access === "Provincial" && <p>{user?.AccessProvince}</p>}
            {user?.Access !== "Regional" && user?.Access !== "Provincial" && <p>National</p>}
          </div>
        </div>
        <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
          <div className="logout_user" onClick={stopPropagation}>
            <img src={profile} alt="User Pic" />
            <div className="logoutInfo">
              <h1>{user?.FullName}</h1>
              <p>{user?.JobTitle}</p>
              {user?.Access === "Regional" && <p>{user?.AccessRegion}</p>}
              {user?.Access === "Provincial" && <p>{user?.AccessProvince}</p>}
              {user?.Access !== "Regional" && user?.Access !== "Provincial" && <p>National</p>}
              <button onClick={handleLogout}>Logout</button>
              <button onClick={handleCloseDialog}>Cancel</button>
            </div>
          </div>
        </Dialog>
        <Modal
          open={isNotificationOpen}
          onClick={stopPropagation}
          onClose={handleCloseNotification}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style}>
            <div className="noti_outer_container">
              <h1>Unread Messages</h1>
              {/* loop useUserChatsDataFetch */}
              {/*moment(created_at) = when, eg time ago*/}
              {/*UserID = WHO left the message*/}
              {/*project_id, task_id, etc = message left in WHAT*/}
              {/*project_id = message left in WHAT*/}
              <div className="noti_inner_container">{userChatsData.map((notif, index) => getNotifMessage(notif, index))}</div>
              <button onClick={handleCloseNotification}>Close</button>
            </div>
          </Box>
        </Modal>
        {isMessagesVisible && (
          <div>
            <Modal
              open={isMessagesVisible}
              onClose={handleMessageDialogClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Box sx={style2}>
                <div className="message_outer_container">
                  <h1>{title}</h1>
                  <div className="message_inner_container">
                    <MessageBox users={allUserData} chatID={selectedID} chatUsers={selectedChatUsers} type={type} />
                  </div>
                  <button className="add-button" onClick={handleMessageDialogClose}>
                    <CloseIcon className="send-icon" /> Close
                  </button>
                </div>
              </Box>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
