import React from 'react';
import '../Sidebar.css';
import SmtsOption from './SmtsOption';
import ListIcon from '@mui/icons-material/List';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleIcon from "@mui/icons-material/People";
import { NavLink } from 'react-router-dom';
import useUserStore from "../../../app/user";

function Smts() {
  const user = useUserStore((state) => state.user);
  const fullName = user?.FullName;
  const isAllowedUser = fullName === 'Vusi Shongwe' && fullName === 'Fana Mkwanazi';

  return (
    <div className='sidebar'>
      {isAllowedUser && (
        <NavLink to="lists">
          {<SmtsOption Icon={ListIcon} title="Lists" />}
        </NavLink>
      )}
      <NavLink to="people">{<SmtsOption Icon={PeopleIcon} title="People" />}</NavLink>
      <NavLink to="location">{<SmtsOption Icon={LocationOnIcon} title="Location" />}</NavLink>
      <NavLink to="whereabouts">{<SmtsOption Icon={PersonPinIcon} title="Check In Point" />}</NavLink>
    </div>
  );
}

export default Smts;
