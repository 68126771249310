import React, { useEffect, useState } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import "./Dashboard.css";
import useProjectDataFetch from "../../../shared/useProjectDataFetch";
import useDateCalculator from "../../../shared/useDateCalculator";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  DataLabel,
  Category,
  Tooltip,
  ColumnSeries,
} from "@syncfusion/ej2-react-charts";
import useUserStore from "../../../../app/user";
import { toast } from "react-toastify";
import {
  FundedDataSource,
  getUpdateProjectGridData,
  identifiedDataSource,
} from "../../../../utils/project";
import useOtherDataFetch from "../../../shared/useOtherDataFetch";

function Dashboard() {
  const user = useUserStore((state) => state.user);
  const dates = useDateCalculator();
  const { beneficiaryData } = useOtherDataFetch();
  const { projectData, isProjectLoaded } = useProjectDataFetch(user);
  const [projectNames, setProjectNames] = useState();
  const [status, setStatus] = useState();
  const [provinces, setProvinces] = useState();
  const [regions, setRegions] = useState();
  const [sectors, setSectors] = useState();
  const [subSectors, setSubSectors] = useState();

  const [projectFilter, setProjectFilter] = useState("All");
  const [provinceFilter, setProvinceFilter] = useState("All");
  const [regionFilter, setRegionFilter] = useState("All");
  const [sectorFilter, setSectorFilter] = useState("All");
  const [subSectorFilter, setSubSectorFilter] = useState("All");
  const [statusFilter, setStatusFilter] = useState("All");
  const [stageFilter, setStageFilter] = useState("All");
  const [phaseFilter, setPhaseFilter] = useState("All");
  const [noCompliantFilter, setNoCompliantFilter] = useState("All");
  const [bankAccountFilter, setBankAccountFilter] = useState("All");
  const [cipcCompliantFilter, setCipcCompliantFilter] = useState("All");
  const [beneficiaryFilter, setBeneficiaryFilter] = useState("All");
  const [identifiedDateFilter, setIdentifiedDateFilter] = useState("All");
  const [fundedDateFilter, setFundedDateFilter] = useState("All");

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isApiRequest, setIsApiRequest] = useState(true);
  const [updatedProjectData, setUpdatedProjectData] = useState([]);
  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    if (isProjectLoaded) {
      const finalProjectData = projectData.filter(
        (project) => project.ProjectStatus !== "Deleted"
      );
      setUpdatedProjectData(finalProjectData);
      setGridData(finalProjectData);
      setIsDataLoaded(true);
      setIsDataChanged(true);
    }
  }, [isProjectLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded && !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  useEffect(() => {
    //Get DropdownData
    const uniqueProvinces = [
      ...new Set(
        projectData?.map((item) => (item.Province ? item.Province : ""))
      ),
    ];
    const formattedUniqueProvinces = ["All", ...uniqueProvinces];
    setProvinces(formattedUniqueProvinces);

    const uniqueProjects = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.ProjectName ? item.ProjectName : ""
        )
      ),
    ];
    const formattedUniqueProjects = ["All", ...uniqueProjects];
    setProjectNames(formattedUniqueProjects);

    const uniqueregions = [
      ...new Set(
        updatedProjectData?.map((item) => (item.Region ? item.Region : ""))
      ),
    ];
    const formattedUniqueregions = ["All", ...uniqueregions];
    setRegions(formattedUniqueregions);

    const uniqueProjectstatus = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.ProjectStatus ? item.ProjectStatus : ""
        )
      ),
    ];
    const formattedUniqueProjectstatus = ["All", ...uniqueProjectstatus];
    setStatus(formattedUniqueProjectstatus);

    const uniqueSectors = [
      ...new Set(
        updatedProjectData?.map((item) => (item.Sector ? item.Sector : ""))
      ),
    ];
    const formattedUniqueSectors = ["All", ...uniqueSectors];
    setSectors(formattedUniqueSectors);

    const uniqueSubSectors = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.SubSector ? item.SubSector : ""
        )
      ),
    ];
    const formattedUniqueSubSectors = ["All", ...uniqueSubSectors];
    setSubSectors(formattedUniqueSubSectors);
  }, [updatedProjectData]);

  useEffect(() => {
    if (!isApiRequest) {
      const updatedData = getUpdateProjectGridData(
        updatedProjectData,
        beneficiaryData,
        provinceFilter,
        regionFilter,
        statusFilter,
        sectorFilter,
        subSectorFilter,
        stageFilter,
        phaseFilter,
        noCompliantFilter,
        bankAccountFilter,
        cipcCompliantFilter,
        beneficiaryFilter,
        identifiedDateFilter,
        fundedDateFilter,
        projectFilter,
        dates
      );

      setGridData(updatedData);
      setIsDataChanged(true);
      setIsApiRequest(true);
    }
  }, [
    updatedProjectData,
    beneficiaryData,
    provinceFilter,
    regionFilter,
    statusFilter,
    sectorFilter,
    subSectorFilter,
    stageFilter,
    phaseFilter,
    noCompliantFilter,
    bankAccountFilter,
    cipcCompliantFilter,
    beneficiaryFilter,
    identifiedDateFilter,
    fundedDateFilter,
    projectFilter,
    dates,
  ]);

  const handleProvinceChange = (e) => {
    if (e && e.value) {
      let provinceFilteredData;
      if (e.value !== "All") {
        provinceFilteredData = updatedProjectData?.filter(
          (item) => item.Province === e.value
        );
      } else {
        provinceFilteredData = updatedProjectData;
      }
      const uniqueregions = [
        ...new Set(
          provinceFilteredData?.map((item) => (item.Region ? item.Region : ""))
        ),
      ];
      const formattedUniqueregions = ["All", ...uniqueregions];
      setRegions(formattedUniqueregions);

      const uniqueProjects = [
        ...new Set(
          provinceFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setProvinceFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleRegionChange = (e) => {
    if (e && e.value) {
      let regionFilteredData;
      if (e.value !== "All") {
        regionFilteredData = updatedProjectData?.filter(
          (item) => item.Region === e.value
        );
      } else {
        regionFilteredData = updatedProjectData;
      }
      const uniqueProjects = [
        ...new Set(
          regionFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setRegionFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleStatusChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setStatusFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleSectorChange = (e) => {
    if (e && e.value) {
      let sectorFilteredData;
      if (e.value !== "All") {
        sectorFilteredData = updatedProjectData?.filter(
          (item) => item.Sector === e.value
        );
      } else {
        sectorFilteredData = updatedProjectData;
      }
      const uniqueSubSectors = [
        ...new Set(
          sectorFilteredData?.map((item) =>
            item.SubSector ? item.SubSector : ""
          )
        ),
      ];
      const formattedUniqueSubSectors = ["All", ...uniqueSubSectors];
      setSubSectors(formattedUniqueSubSectors);

      const uniqueProjects = [
        ...new Set(
          sectorFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setSectorFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleSubSectorChange = (e) => {
    if (e && e.value) {
      let subSectorFilteredData;
      if (e.value !== "All") {
        subSectorFilteredData = updatedProjectData?.filter(
          (item) => item.SubSector === e.value
        );
      } else {
        subSectorFilteredData = updatedProjectData;
      }

      const uniqueProjects = [
        ...new Set(
          subSectorFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setSubSectorFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleProjectChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setProjectFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleStageChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setStageFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handlePhaseChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setPhaseFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleNonComliantChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setNoCompliantFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleBankChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setBankAccountFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleCipcChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setCipcCompliantFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleBeneficiaryChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setBeneficiaryFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleIdentificationChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setIdentifiedDateFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleFundingChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setFundedDateFilter(e.value);
      setIsApiRequest(false);
    }
  };

  // Count the occurrences of each province
  const provinceCounts = gridData.reduce((counts, item) => {
    const province = item.Province; // Assuming the field name is 'Province'
    counts[province] = (counts[province] || 0) + 1;
    return counts;
  }, {});

  // Prepare data for the chart
  const provinceChartData = Object.keys(provinceCounts).map((province) => ({
    x: province,
    y: provinceCounts[province],
  }));

  // Count the occurrences of each status
  const regionCounts = gridData.reduce((counts, item) => {
    const region = item.Region; // Assuming the field name is 'Status'
    counts[region] = (counts[region] || 0) + 1;
    return counts;
  }, {});

  // Prepare data for the chart
  const regionChartData = Object.keys(regionCounts).map((region) => ({
    x: region,
    y: regionCounts[region],
  }));

  // Count the occurrences of each status
  const statusCounts = gridData.reduce((counts, item) => {
    const status = item.ProjectStatus; // Assuming the field name is 'Status'
    counts[status] = (counts[status] || 0) + 1;
    return counts;
  }, {});

  // Prepare data for the chart
  const statusChartData = Object.keys(statusCounts).map((status) => ({
    x: status,
    y: statusCounts[status],
  }));

  // Count the occurrences of each Sector
  const sectorCounts = gridData.reduce((counts, item) => {
    const sector = item.Sector; // Assuming the field name is 'Status'
    counts[sector] = (counts[sector] || 0) + 1;
    return counts;
  }, {});

  // Prepare data for the chart
  const sectorChartData = Object.keys(sectorCounts).map((sector) => ({
    x: sector,
    y: sectorCounts[sector],
  }));

  // Count the occurrences of each Stage
  const stageCounts = gridData.reduce((counts, item) => {
    const stage = item.Stage; // Assuming the field name is 'Status'
    counts[stage] = (counts[stage] || 0) + 1;
    return counts;
  }, {});

  // Prepare data for the chart
  const stageChartData = Object.keys(stageCounts).map((stage) => ({
    x: stage,
    y: stageCounts[stage],
  }));

  // Count the occurrences of each Type
  const typeCounts = gridData.reduce((counts, item) => {
    const type = item.Type; // Assuming the field name is 'Status'
    counts[type] = (counts[type] || 0) + 1;
    return counts;
  }, {});

  // Prepare data for the chart
  const typeChartData = Object.keys(typeCounts).map((type) => ({
    x: type,
    y: typeCounts[type],
  }));

  // Count the occurrences of each Phase
  const phaseCounts = gridData.reduce((counts, item) => {
    const phase = item.Phase; // Assuming the field name is 'Status'
    counts[phase] = (counts[phase] || 0) + 1;
    return counts;
  }, {});

  // Prepare data for the chart
  const phaseChartData = Object.keys(phaseCounts).map((phase) => ({
    x: phase,
    y: phaseCounts[phase],
  }));

  // Count the occurrences of each Sub-Sector
  const subsectorCounts = gridData.reduce((counts, item) => {
    const subsector = item.SubSector; // Assuming the field name is 'Status'
    counts[subsector] = (counts[subsector] || 0) + 1;
    return counts;
  }, {});

  // Prepare data for the chart
  const subsectorChartData = Object.keys(subsectorCounts).map((subsector) => ({
    x: subsector,
    y: subsectorCounts[subsector],
  }));

  const chartWidth = "100%"; // Set the desired chart width (e.g., "100%", "80px", etc.)
  const chartHeight = "80%"; // Set the desired chart width (e.g., "100%", "80px", etc.)
  const primaryxAxis = {
    valueType: "Category",
    labelRotation: -45,
    labelStyle: {
      color: "rgb(96,172,86)",
      fontWeight: "bold",
      size: "14px",
      fontFamily: "Segoe UI",
    },
  };
  const primaryyAxis = { visible: false };
  const marker = {
    dataLabel: {
      visible: true,
      font: {
        color: "rgb(96,172,86)",
        background: "white",
        fontWeight: "bold",
        size: "16px",
        fontFamily: "Segoe UI",
      },
    },
  };
  const tooltip = { enable: false, shared: false };
  // Define an array of varying colors for each series
  const varyingColors = [
    "rgb(96,172,86)",
    "#1565C0",
    "#FF5722",
    "#E91E63",
    "#9C27B0",
    "#009688",
    "#FF9800",
  ];

  return (
    <div className="reports-container">
      <div className="summary-container-project">
        <div className="decicion_requester1">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Province"
            floatLabelType="Always"
            dataSource={provinces}
            change={handleProvinceChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Region"
            floatLabelType="Always"
            dataSource={regions}
            change={handleRegionChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Sector"
            floatLabelType="Always"
            dataSource={sectors}
            change={handleSectorChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Sub-Sector"
            floatLabelType="Always"
            dataSource={subSectors}
            change={handleSubSectorChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Project Status"
            floatLabelType="Always"
            dataSource={status}
            change={handleStatusChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Project"
            floatLabelType="Always"
            dataSource={projectNames}
            change={handleProjectChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Phase"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Phase 1: Coordinators",
              "Phase 2: Facilitattors",
              "Phase 3: NF",
            ]}
            change={handlePhaseChange}
          />
        </div>
      </div>
      <div className="summary-container-project">
        <div className="decicion_requester1">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Stage"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Identification",
              "Capacity Building",
              "Funding",
              "Monitoring",
            ]}
            change={handleStageChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Overall Compliant"
            floatLabelType="Always"
            dataSource={["All", "Compliant", "Non-Compliant"]}
            change={handleNonComliantChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Beneficiary Compliant"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Sufficiant Beneficiaries",
              "Insufficiant Beneficiaries",
            ]}
            change={handleBeneficiaryChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Bank Compliant"
            floatLabelType="Always"
            dataSource={["All", "With Bank Account", "No Bank Account"]}
            change={handleBankChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="CIPC Compliant"
            floatLabelType="Always"
            dataSource={["All", "CIPC Compliant", "CIPC Non-Compliant"]}
            change={handleCipcChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Identification Date"
            floatLabelType="Always"
            dataSource={identifiedDataSource}
            change={handleIdentificationChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Funding Date"
            floatLabelType="Always"
            dataSource={FundedDataSource}
            change={handleFundingChange}
          />
        </div>
      </div>
      {/*National Dashboard*/}
      {user?.Access === "National" && (
        <div className="national-reports-container">
          <div className="chart-row">
            <div className="chart-container">
              <h2>Project Counts by Province</h2>
              <ChartComponent
                id="province-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={provinceChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[6]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container">
              <h2>Project Counts by Status</h2>
              <ChartComponent
                id="status-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={statusChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[5]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container">
              <h2>Project Counts by Sector</h2>
              <ChartComponent
                id="sector-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={sectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[4]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container">
              <h2>Project Counts by Stage</h2>
              <ChartComponent
                id="stage-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={stageChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[3]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-row1">
            <div className="chart-container1">
              <h2>Project Counts by Type</h2>
              <ChartComponent
                id="type-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={typeChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[2]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container2">
              <h2>Project Counts by Phase</h2>
              <ChartComponent
                id="phase-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={phaseChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container3">
              <h2>Project Counts by Sub-Sector</h2>
              <ChartComponent
                id="sub-sector-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={subsectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[6]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-row">
            <div className="chart-container">
              <h2>Project Counts by Region</h2>
              <ChartComponent
                id="region-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Region"
                    marker={marker}
                    fill={varyingColors[5]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      )}

      {/*Provincial Dashboard*/}
      {user?.Access === "Provincial" && (
        <div className="provincial-reports-container">
          <div className="chart-row">
            <div className="chart-container">
              <h2>Project Counts by Region</h2>
              <ChartComponent
                id="region-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[6]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container">
              <h2>Project Counts by Status</h2>
              <ChartComponent
                id="status-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={statusChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[5]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container">
              <h2>Project Counts by Sector</h2>
              <ChartComponent
                id="sector-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={sectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[4]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container">
              <h2>Project Counts by Stage</h2>
              <ChartComponent
                id="stage-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={stageChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[3]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-row1">
            <div className="chart-container1">
              <h2>Project Counts by Type</h2>
              <ChartComponent
                id="type-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={typeChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[2]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container2">
              <h2>Project Counts by Phase</h2>
              <ChartComponent
                id="phase-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={phaseChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container3">
              <h2>Project Counts by Sub-Sector</h2>
              <ChartComponent
                id="sub-sector-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={subsectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[6]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      )}

      {/*Regional Dashboard*/}
      {user?.Access === "Regional" && (
        <div className="regional-reports-container">
          <div className="chart-row">
            <div className="chart-container">
              <h2>Project Counts by Status</h2>
              <ChartComponent
                id="status-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={statusChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[6]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container">
              <h2>Project Counts by Sector</h2>
              <ChartComponent
                id="sector-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={sectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[5]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container">
              <h2>Project Counts by Stage</h2>
              <ChartComponent
                id="stage-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={stageChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[4]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-row1">
            <div className="chart-container1">
              <h2>Project Counts by Type</h2>
              <ChartComponent
                id="type-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={typeChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[3]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container2">
              <h2>Project Counts by Phase</h2>
              <ChartComponent
                id="phase-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={phaseChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[2]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container3">
              <h2>Project Counts by Sub-Sector</h2>
              <ChartComponent
                id="sub-sector-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}
              >
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={subsectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[1]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
