import React, { useEffect, useState, useRef } from "react";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Sort, Filter, Edit, Toolbar } from "@syncfusion/ej2-react-grids";
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import "./MainComp.css";
import useProjectDataFetch from "../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../shared/useOtherDataFetch"; // Import the custom hook
import useUserDataFetch from "../../../shared/useUserDataFetch"; // Import the custom hook
import useUserChatsDataFetch from "../../../shared/useUserChatsDataFetch"; // Import the custom hook
import useUserStore from "../../../../app/user";
import MessageBox from "../MessageBox";
import { toast } from "react-toastify";
import socket from "../../../../utils/socket";
import axios from "../../../../config/axios";

function ChatTasks() {
  const user = useUserStore((state) => state.user);
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { userChatsData, isUserChatsLoaded, setUserChatsData } = useUserChatsDataFetch([]); // Use the custom hook to fetch data
  const { allUserData, isAllUserLoaded } = useUserDataFetch([]); // Use the custom hook to fetch data
  const { taskData, isTaskLoaded } = useOtherDataFetch();
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [commonUserData, setCommonUserData] = useState([]);
  const [selectedTaskName, setSelectedTaskName] = useState("");
  const [selectedID, setSelectedID] = useState("");
  const [selectedChatUsers, setSelectedChatUsers] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [taskGridData, setTaskGridData] = useState([]);
  // const [isNewEntry, setIsNewEntry] = useState(false);

  const chatAdmin = user?.FullName;

  useEffect(() => {
    if (isProjectLoaded && isTaskLoaded && isAllUserLoaded && isUserChatsLoaded) {
      const finalProjectData = projectData.filter((project) => project.ProjectStatus !== "Deleted");
      const matchingTasks = taskData.filter((task) => {
        const matchingProject = finalProjectData.find((project) => project.id === task.ProjectID);
        return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
      });
      const filteredTaskData = matchingTasks.filter((task) => {
        const taskDuration = task.Duration;
        return taskDuration > 0;
      });
      setTaskGridData(filteredTaskData);
      const filteredUserData = allUserData.filter(
        (user) =>
          user.AccessProvince === "All" &&
          user.FullName !== "Vusi Shongwe" &&
          user.FullName !== "Fana Mkwanazi" &&
          user.FullName !== "Nqobile Dlamini"
      );
      setCommonUserData(filteredUserData);
      setIsDataLoaded(true);
    }
  }, [taskData, isTaskLoaded, projectData, isProjectLoaded, allUserData, userChatsData, isAllUserLoaded, isUserChatsLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  const handleRowSelect = async (args) => {
    setSelectedTaskName(args.data.TaskName);
    setSelectedID(args.data.id);

    const projectID = args.data.ProjectID;
    const project = projectData.find((project) => project.id === projectID);
    const selectedProvince = project.Province;
    const selectedRegion = project.Region;

    const selectedUserData = allUserData.filter(
      (user) =>
        (user.AccessProvince === selectedProvince && user.JobTitle === "Facilitator") ||
        (user.AccessProvince === selectedProvince && user.JobTitle === "Administrator") ||
        user.AccessRegion === selectedRegion
    );
    const combinedList = commonUserData.concat(selectedUserData);
    const filteredUserData = combinedList.filter((item) => item.FullName !== user.FullName);
    setSelectedChatUsers(filteredUserData);
    axios
      .patch(process.env.REACT_APP_SERVER_URL + `/api/chat/read`, {
        type: "task_id",
        id: args.data.id,
      })
      .then(({ data }) => {
        console.log("Read : ", data);
        setUserChatsData((prev) => prev?.filter((notif) => notif.task_id !== args.data.id));
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  };

  const chatCountTemplate = (props) => {
    const ID = props.id;
    const chatCount = userChatsData.filter((chat) => chat.task_id === ID).length;
    const fontColor = chatCount > 0 ? "darkgreen" : "darkred";
    const backgroundColor = chatCount > 0 ? "lightgreen" : "pink";
    const fontWeight = chatCount > 0 ? "bold" : "normal";

    const style = {
      color: fontColor,
      backgroundColor: backgroundColor,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: fontWeight,
    };

    return (
      <div style={style}>
        <span>{chatCount}</span>
      </div>
    );
  };

  const projectNameTemplate = (props) => {
    const projectID = props.ProjectID; // Assuming the field name is "ProjectNum"
    // Lookup the project name in projectData based on the provided projectNum
    const project = projectData.find((project) => project.id === projectID);
    // Check if a project with the given projectNum was found
    if (project) {
      const projectName = project.ProjectName;
      return <div>{projectName}</div>;
    } else {
      // Handle the case where no matching project was found
      return <div>Project Not Found</div>;
    }
  };

  const startDateTemplate = (props) => {
    const taskStartDate = new Date(props.StartDate);

    const year = taskStartDate.getFullYear();
    const month = (taskStartDate.getMonth() + 1).toString().padStart(2, "0");
    const day = taskStartDate.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    return <div>{formattedDate}</div>;
  };

  const endDateTemplate = (props) => {
    const taskDuration = props.Duration;
    const taskStartDate = new Date(props.StartDate);
    const taskEndDate = new Date(taskStartDate);
    taskEndDate.setDate(taskEndDate.getDate() + taskDuration);

    const year = taskEndDate.getFullYear();
    const month = (taskEndDate.getMonth() + 1).toString().padStart(2, "0");
    const day = taskEndDate.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    return <div>{formattedDate}</div>;
  };

  const progressTemplate = (props) => {
    const progress = props.Progress;
    return <div>{progress}%</div>;
  };

  useEffect(() => {
    socket.on("notification", (data) => {
      console.log("Receive Notification: ", data);
      const isTrue = userChatsData.find((chat) => chat.ID === data.ID);
      if (!isTrue) setUserChatsData((prev) => (prev ? [...prev, data] : [data]));
      // setIsNewEntry((prev) => !prev);
    });
  }, []);

  // useEffect(() => {
  //   if (userChatsData?.length)
  //     setUserChatsData((prev) => removeDuplicates(prev, "ID"));
  //   console.log("Is Newt : ", isNewEntry);
  // }, [isNewEntry]);

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };

  return (
    <div className="main-chat-container">
      <div className="item-chat-container">
        <h3>All Beneficiaries in my Area</h3>
        <br />
        <div className="inner_container">
          {isDataLoaded && (
            <GridComponent
              dataSource={taskGridData}
              childMapping="items"
              ref={GridRef}
              classClass="custom-treegrid-class"
              height="650"
              width="100%"
              allowPaging={true}
              allowSorting={true}
              allowFiltering={true}
              allowSelection={true}
              rowSelected={handleRowSelect}
              filterSettings={FilterOptions}
              pageSettings={pageSettings}>
              <ColumnsDirective>
                <ColumnDirective field="TaskName" headerText="Task Name" width="170" textAlign="Left" />
                <ColumnDirective field="ProjectStage" headerText="Stage" width="100" textAlign="Left" />
                <ColumnDirective headerText="Chats" width="90" textAlign="Left" template={chatCountTemplate} />
                <ColumnDirective headerText="Project" width="150" textAlign="Left" editType="dropdownedit" template={projectNameTemplate} />
                <ColumnDirective field="StartDate" headerText="Start Date" width="130" textAlign="Left" template={startDateTemplate} />
                <ColumnDirective headerText="End Date" width="130" textAlign="Left" template={endDateTemplate} />
                <ColumnDirective field="Progress" headerText="Progress" width="130" textAlign="Left" template={progressTemplate} />
              </ColumnsDirective>
              <Inject services={[Page, Sort, Filter, Edit, Toolbar]} />
            </GridComponent>
          )}
        </div>
      </div>

      <div className="chat-container">
        <h3>{selectedTaskName}: Chat Details</h3>
        <br />
        <div className="inner_container">
          {selectedID && <MessageBox users={allUserData} chatID={selectedID} chatUsers={selectedChatUsers} type="task_id" />}
        </div>
      </div>
    </div>
  );
}

export default ChatTasks;

const removeDuplicates = (arr, prop) => {
  const seen = new Map();
  return arr.filter((item) => {
    const key = prop ? item[prop] : JSON.stringify(item);
    if (!seen.has(key)) {
      seen.set(key, true);
      return true;
    }
    return false;
  });
};
