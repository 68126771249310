import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  Reorder,
  Resize,
  PdfExport,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import axios from "../../../../config/axios";
import "./ListView.css";
import "../../../shared/MessageDialog.css";
import ProjectDialogMain from "./ProjectDialogMain";
import useProjectDataFetch from "../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../shared/useOtherDataFetch"; // Import the custom hook
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import useUserStore from "../../../../app/user";
import { toast } from "react-toastify";
import { FundedDataSource, getUpdateProjectGridData, identifiedDataSource } from "../../../../utils/project";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import useDateCalculator from "../../../shared/useDateCalculator";
import { dateFormatter } from "../../../../utils/formats";
import ForumIcon from "@mui/icons-material/Forum";
import MessageBox from "../../messages/MessageBox";
import useUserDataFetch from "../../../shared/useUserDataFetch";
import { getCommonUsers, getProjectChatUsers, getUserDeleteRight, getUserLocation } from "../../../../utils";
import useUserChatsDataFetch from "../../../shared/useUserChatsDataFetch";
import socket from "../../../../utils/socket";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "90%",
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 2,
};

function ListView() {
  const user = useUserStore((state) => state.user);
  const dates = useDateCalculator();
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [userLocation, setUserLocation] = useState("");
  const { projectData, isProjectLoaded } = useProjectDataFetch(user);
  const { userChatsData, isUserChatsLoaded, setUserChatsData } = useUserChatsDataFetch([]); // Use the custom hook to fetch data
  const { allUserData, isAllUserLoaded } = useUserDataFetch([]);
  const [commonUserData, setCommonUserData] = useState([]);
  const [selectedChatUsers, setSelectedChatUsers] = useState([]);
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
  const [isMessagesVisible, setIsMessagesVisible] = useState(false);
  const { beneficiaryData, attachmentData, isAttachmentLoaded, isBeneficiaryLoaded } = useOtherDataFetch();

  const [projectNames, setProjectNames] = useState();
  const [status, setStatus] = useState();
  const [provinces, setProvinces] = useState();
  const [regions, setRegions] = useState();
  const [sectors, setSectors] = useState();
  const [subSectors, setSubSectors] = useState();

  const [projectFilter, setProjectFilter] = useState("All");
  const [provinceFilter, setProvinceFilter] = useState("All");
  const [regionFilter, setRegionFilter] = useState("All");
  const [sectorFilter, setSectorFilter] = useState("All");
  const [subSectorFilter, setSubSectorFilter] = useState("All");
  const [statusFilter, setStatusFilter] = useState("All");
  const [stageFilter, setStageFilter] = useState("All");
  const [phaseFilter, setPhaseFilter] = useState("All");
  const [noCompliantFilter, setNoCompliantFilter] = useState("All");
  const [bankAccountFilter, setBankAccountFilter] = useState("All");
  const [cipcCompliantFilter, setCipcCompliantFilter] = useState("All");
  const [beneficiaryFilter, setBeneficiaryFilter] = useState("All");
  const [identifiedDateFilter, setIdentifiedDateFilter] = useState("All");
  const [fundedDateFilter, setFundedDateFilter] = useState("All");

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isApiRequest, setIsApiRequest] = useState(true);
  const [updatedProjectData, setUpdatedProjectData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // Initial state
  const [selectedID, setSelectedID] = useState(null);
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedProjectNum, setSelectedProjectNum] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedSubSector, setSelectedSubSector] = useState("");
  const [selectedAdministrator, setSelectedAdministrator] = useState("");
  const [selectedFacilitator, setSelectedFacilitator] = useState("");
  const [selectedIdentificationDate, setSelectedIdentificationDate] = useState(null);
  const [selectedGeneralRemarks, setSelectedGeneralRemarks] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedPhase, setSelectedPhase] = useState("");
  const [selectedVerificationDate, setSelectedVerificationDate] = useState(null);
  const [selectedCoordinator, setSelectedCoordinator] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCapacityDate, setSelectedCapacityDate] = useState("");
  const [selectedNormalBudget, setSelectedNormalBudget] = useState("");
  const [selectedDiscretionaryRequired, setSelectedDiscretionaryRequired] = useState("");
  const [selectedAmountRequired, setSelectedAmountRequired] = useState("");
  const [selectedMotivationForDiscretionary, setSelectedMotivationForDiscretionary] = useState("");
  const [selectedFundingDate, setSelectedFundingDate] = useState("");
  const [selectedMonitoringDate, setSelectedMonitoringDate] = useState("");
  const [selectedDateApproval, setSelectedDateApproval] = useState("");

  const [newStage, setNewStage] = useState("");
  const [newProvince, setNewProvince] = useState();
  const [newRegion, setNewRegion] = useState();
  const [newProjectNum, setNewProjectNum] = useState("");
  const [newProjectName, setNewProjectName] = useState("");
  const [newSector, setNewSector] = useState("");
  const [newSubSector, setNewSubSector] = useState("");
  const [newAdministrator, setNewAdministrator] = useState("");
  const [newFacilitator, setNewFacilitator] = useState("");
  const [newIdentificationDate, setNewIdentificationDate] = useState(null);
  const [newGeneralRemarks, setNewGeneralRemarks] = useState("");
  const [newType, setNewType] = useState("");
  const [newPhase, setNewPhase] = useState("");
  const [newVerificationDate, setNewVerificationDate] = useState(null);
  const [newCoordinator, setNewCoordinator] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [newCapacityDate, setNewCapacityDate] = useState("");
  const [newNormalBudget, setNewNormalBudget] = useState("");
  const [newDiscretionaryRequired, setNewDiscretionaryRequired] = useState("");
  const [newAmountRequired, setNewAmountRequired] = useState("");
  const [newMotivationForDiscretionary, setNewMotivationForDiscretionary] = useState("");
  const [newDateApproval, setNewDateApproval] = useState("");
  const [newFundingDate, setNewFundingDate] = useState("");
  const [newMonitoringDate, setNewMonitoringDate] = useState("");

  useEffect(() => {
    if (isProjectLoaded && isAttachmentLoaded && isBeneficiaryLoaded && isAllUserLoaded && isUserChatsLoaded) {
      const finalProjectData = projectData.filter((project) => project.ProjectStatus !== "Deleted");
      setUpdatedProjectData(finalProjectData);
      setGridData(finalProjectData);

      const filteredUserData = getCommonUsers(allUserData);
      setCommonUserData(filteredUserData);

      setIsDataLoaded(true);
      setIsDataChanged(true);
    }
  }, [projectData, isProjectLoaded, isBeneficiaryLoaded, isAttachmentLoaded, isAllUserLoaded, isUserChatsLoaded]);

  useEffect(() => {
    socket.on("notification", (data) => {
      console.log("Receive Notification: ", data);
      const isTrue = userChatsData.find((chat) => chat.ID === data.ID);
      if (!isTrue) setUserChatsData((prev) => (prev ? [...prev, data] : [data]));
    });
  }, []);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded && !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  useEffect(() => {
    const deleteRights = getUserDeleteRight(user);
    setIsButtonEnabled(deleteRights);
    const location = getUserLocation(user);
    setUserLocation(location);
  }, [user]);

  useEffect(() => {
    //Get DropdownData
    const uniqueProvinces = [...new Set(projectData?.map((item) => (item.Province ? item.Province : "")))];
    const formattedUniqueProvinces = ["All", ...uniqueProvinces];
    setProvinces(formattedUniqueProvinces);

    const uniqueProjects = [...new Set(updatedProjectData?.map((item) => (item.ProjectName ? item.ProjectName : "")))];
    const formattedUniqueProjects = ["All", ...uniqueProjects];
    setProjectNames(formattedUniqueProjects);

    const uniqueregions = [...new Set(updatedProjectData?.map((item) => (item.Region ? item.Region : "")))];
    const formattedUniqueregions = ["All", ...uniqueregions];
    setRegions(formattedUniqueregions);

    const uniqueProjectstatus = [...new Set(updatedProjectData?.map((item) => (item.ProjectStatus ? item.ProjectStatus : "")))];
    const formattedUniqueProjectstatus = ["All", ...uniqueProjectstatus];
    setStatus(formattedUniqueProjectstatus);

    const uniqueSectors = [...new Set(updatedProjectData?.map((item) => (item.Sector ? item.Sector : "")))];
    const formattedUniqueSectors = ["All", ...uniqueSectors];
    setSectors(formattedUniqueSectors);

    const uniqueSubSectors = [...new Set(updatedProjectData?.map((item) => (item.SubSector ? item.SubSector : "")))];
    const formattedUniqueSubSectors = ["All", ...uniqueSubSectors];
    setSubSectors(formattedUniqueSubSectors);
  }, [updatedProjectData]);

  useEffect(() => {
    if (!isApiRequest) {
      const updatedData = getUpdateProjectGridData(
        updatedProjectData,
        beneficiaryData,
        provinceFilter,
        regionFilter,
        statusFilter,
        sectorFilter,
        subSectorFilter,
        stageFilter,
        phaseFilter,
        noCompliantFilter,
        bankAccountFilter,
        cipcCompliantFilter,
        beneficiaryFilter,
        identifiedDateFilter,
        fundedDateFilter,
        projectFilter,
        dates
      );

      setGridData(updatedData);
      setIsDataChanged(true);
      setIsApiRequest(true);
    }
  }, [
    updatedProjectData,
    beneficiaryData,
    provinceFilter,
    regionFilter,
    statusFilter,
    sectorFilter,
    subSectorFilter,
    stageFilter,
    phaseFilter,
    noCompliantFilter,
    bankAccountFilter,
    cipcCompliantFilter,
    beneficiaryFilter,
    identifiedDateFilter,
    fundedDateFilter,
    projectFilter,
    dates,
  ]);

  const handleProvinceChange = (e) => {
    if (e && e.value) {
      let provinceFilteredData;
      if (e.value !== "All") {
        provinceFilteredData = updatedProjectData?.filter((item) => item.Province === e.value);
      } else {
        provinceFilteredData = updatedProjectData;
      }
      const uniqueregions = [...new Set(provinceFilteredData?.map((item) => (item.Region ? item.Region : "")))];
      const formattedUniqueregions = ["All", ...uniqueregions];
      setRegions(formattedUniqueregions);

      const uniqueProjects = [...new Set(provinceFilteredData?.map((item) => (item.ProjectName ? item.ProjectName : "")))];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setProvinceFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleRegionChange = (e) => {
    if (e && e.value) {
      let regionFilteredData;
      if (e.value !== "All") {
        regionFilteredData = updatedProjectData?.filter((item) => item.Region === e.value);
      } else {
        regionFilteredData = updatedProjectData;
      }
      const uniqueProjects = [...new Set(regionFilteredData?.map((item) => (item.ProjectName ? item.ProjectName : "")))];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setRegionFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleStatusChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setStatusFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleSectorChange = (e) => {
    if (e && e.value) {
      let sectorFilteredData;
      if (e.value !== "All") {
        sectorFilteredData = updatedProjectData?.filter((item) => item.Sector === e.value);
      } else {
        sectorFilteredData = updatedProjectData;
      }
      const uniqueSubSectors = [...new Set(sectorFilteredData?.map((item) => (item.SubSector ? item.SubSector : "")))];
      const formattedUniqueSubSectors = ["All", ...uniqueSubSectors];
      setSubSectors(formattedUniqueSubSectors);

      const uniqueProjects = [...new Set(sectorFilteredData?.map((item) => (item.ProjectName ? item.ProjectName : "")))];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setSectorFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleSubSectorChange = (e) => {
    if (e && e.value) {
      let subSectorFilteredData;
      if (e.value !== "All") {
        subSectorFilteredData = updatedProjectData?.filter((item) => item.SubSector === e.value);
      } else {
        subSectorFilteredData = updatedProjectData;
      }

      const uniqueProjects = [...new Set(subSectorFilteredData?.map((item) => (item.ProjectName ? item.ProjectName : "")))];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setSubSectorFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleProjectChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setProjectFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleStageChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setStageFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handlePhaseChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setPhaseFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleNonComliantChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setNoCompliantFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleBankChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setBankAccountFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleCipcChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setCipcCompliantFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleBeneficiaryChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setBeneficiaryFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleIdentificationChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setIdentifiedDateFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleFundingChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setFundedDateFilter(e.value);
      setIsApiRequest(false);
    }
  };

  // Get unique provinces from projectData
  const uniqueProvinces = [...new Set(updatedProjectData?.map((item) => (item.Province ? item.Province : "")))];

  // Get unique subsectors from sectorProjectData
  const uniqueSubSectors = [...new Set(updatedProjectData?.map((item) => (item.SubSector ? item.SubSector : "")))];

  // Get unique subsectors from projectData
  const uniqueSectors = [...new Set(updatedProjectData?.map((item) => (item.Sector ? item.Sector : "")))];

  // Get unique provinces from projectData
  const uniqueProjects = [...new Set(updatedProjectData?.map((item) => (item.ProjectName ? item.ProjectName : "")))];
  // Get unique RegionS from provinceProjectData
  const uniqueRegions = [...new Set(updatedProjectData?.map((item) => (item.Region ? item.Region : "")))];
  // Get unique FacilitatorS from provinceProjectData
  const uniqueFacilitators = [...new Set(updatedProjectData?.map((item) => (item.Facilitator ? item.Facilitator : "")))];
  // Get unique AdministratorS from provinceProjectData
  const uniqueAdministrators = [...new Set(updatedProjectData?.map((item) => (item.Administrator ? item.Administrator : "")))];
  // Get unique AdministratorS from regionProjectData
  const uniqueCoordinators = [...new Set(updatedProjectData?.map((item) => (item.Coordinator ? item.Coordinator : "")))];

  const projectNewData = {
    Stage: newStage,
    Province: newProvince,
    Region: newRegion,
    ProjectNum: newProjectNum,
    ProjectName: newProjectName,
    Sector: newSector,
    SubSector: newSubSector,
    Administrator: newAdministrator,
    Facilitator: newFacilitator,
    IdentificationDate: newIdentificationDate,
    GeneralRemarks: newGeneralRemarks,
    Type: newType,
    Phase: newPhase,
    VerificationDate: newVerificationDate,
    Coordinator: newCoordinator,
    ProjectStatus: newStatus,
    CapacityDate: newCapacityDate,
    NormalBudget: newNormalBudget,
    DiscretionaryRequired: newDiscretionaryRequired,
    AmountRequired: newAmountRequired,
    MotivationForDiscretionary: newMotivationForDiscretionary,
    DateApproval: newDateApproval,
    FundingDate: newFundingDate,
    MonitoringDate: newMonitoringDate,
    LastUpdatedBy: user?.FullName,
    LastUpdated: new Date(),
  };

  const handleAddButtonClick = () => {
    setIsAddDialogVisible(!isAddDialogVisible);
    setSelectedID("");
    setSelectedStage("");
    setSelectedProvince(user.AccessProvince);
    setSelectedRegion("");
    setSelectedProjectNum("");
    setSelectedProjectName("");
    setSelectedSector("");
    setSelectedSubSector("");
    setSelectedAdministrator(user.FullName);
    setSelectedFacilitator("");
    setSelectedIdentificationDate("");
    setSelectedGeneralRemarks("");
    setSelectedType("");
    setSelectedPhase("");
    setSelectedVerificationDate("");
    setSelectedCoordinator("");
    setSelectedStatus("");
    setSelectedCapacityDate("");
    setSelectedNormalBudget("");
    setSelectedDiscretionaryRequired("");
    setSelectedAmountRequired("");
    setSelectedMotivationForDiscretionary("");
    setSelectedDateApproval("");
    setSelectedFundingDate("");
    setSelectedMonitoringDate("");
    if (GridRef.current) {
      GridRef.current.clearSelection();
    }
    setNewStage("");
    setNewProvince(user.AccessProvince);
    setNewRegion("");
    setNewProjectNum("");
    setNewProjectName("");
    setNewSector("");
    setNewSubSector("");
    setNewAdministrator(user.FullName);
    setNewFacilitator("");
    setNewIdentificationDate(null);
    setNewGeneralRemarks("");
    setNewType("");
    setNewPhase("");
    setNewVerificationDate(null);
    setNewCoordinator("");
    setNewStatus("");
    setNewCapacityDate("");
    setNewNormalBudget("");
    setNewDiscretionaryRequired("");
    setNewAmountRequired("");
    setNewMotivationForDiscretionary("");
    setNewDateApproval("");
    setNewFundingDate("");
    setNewMonitoringDate("");
  };

  const handleEditButtonClick = () => {
    setIsEditDialogVisible(!isEditDialogVisible);
    setNewStage(selectedStage);
    setNewProvince(selectedProvince);
    setNewRegion(selectedRegion);
    setNewProjectNum(selectedProjectNum);
    setNewProjectName(selectedProjectName);
    setNewSector(selectedSector);
    setNewSubSector(selectedSubSector);
    setNewAdministrator(selectedAdministrator);
    setNewFacilitator(selectedFacilitator);
    setNewIdentificationDate(selectedIdentificationDate);
    setNewGeneralRemarks(selectedGeneralRemarks);
    setNewType(selectedType);
    setNewPhase(selectedPhase);
    setNewVerificationDate(selectedVerificationDate);
    setNewCoordinator(selectedCoordinator);
    setNewStatus(selectedStatus);
    setNewCapacityDate(selectedCapacityDate);
    setNewNormalBudget(selectedNormalBudget);
    setNewDiscretionaryRequired(selectedDiscretionaryRequired);
    setNewAmountRequired(selectedAmountRequired);
    setNewMotivationForDiscretionary(selectedMotivationForDiscretionary);
    setNewDateApproval(selectedDateApproval);
    setNewFundingDate(selectedFundingDate);
    setNewMonitoringDate(selectedMonitoringDate);
  };

  const handleDeleteButtonClick = () => {};

  const handleRowSelect = async (args) => {
    setSelectedID(args.data.id);
    setSelectedStage(args.data.Stage);
    setSelectedProvince(args.data.Province);
    setSelectedRegion(args.data.Region);
    setSelectedProjectNum(args.data.ProjectNum);
    setSelectedProjectName(args.data.ProjectName);
    setSelectedSector(args.data.Sector);
    setSelectedSubSector(args.data.SubSector);
    setSelectedAdministrator(args.data.Administrator);
    setSelectedFacilitator(args.data.Facilitator);
    setSelectedIdentificationDate(args.data.IdentificationDate);
    setSelectedGeneralRemarks(args.data.GeneralRemarks);
    setSelectedType(args.data.Type);
    setSelectedPhase(args.data.Phase);
    setSelectedVerificationDate(args.data.VerificationDate);
    setSelectedCoordinator(args.data.Coordinator);
    setSelectedStatus(args.data.ProjectStatus);
    setSelectedCapacityDate(args.data.CapacityDate);
    setSelectedNormalBudget(args.data.NormalBudget);
    setSelectedDiscretionaryRequired(args.data.DiscretionaryRequired);
    setSelectedMotivationForDiscretionary(args.data.MotivationForDiscretionary);
    setSelectedAmountRequired(args.data.AmountRequired);
    setSelectedFundingDate(args.data.FundingDate);
    setSelectedMonitoringDate(args.data.MonitoringDate);
    setSelectedDateApproval(args.data.DateApproval);

    if (args.data.ProjectNum) {
      const chatUsers = getProjectChatUsers(args.data.Province, args.data.Region, allUserData, commonUserData, user);
      setSelectedChatUsers(chatUsers);
    } else {
      setSelectedChatUsers("");
    }
  };

  const handleAddProjectDetailsButton = async () => {
    const loadingToastId = toast.loading("Please wait...");
    try {
      const ProjectResponse = await axios.post(process.env.REACT_APP_SERVER_URL + "/api/project", projectNewData);
      console.log("Project response:", ProjectResponse.data);
      if (ProjectResponse.data.status === "success") {
        toast.success(`${newProjectName} was created successfully.`);
        const UpdatedResponse = await axios.get(process.env.REACT_APP_SERVER_URL + "/api/project");
        const projects = UpdatedResponse.data.items;
        const updatedGridData = projects.filter((project) => project.ProjectStatus !== "Deleted");
        setGridData(updatedGridData);
      } else {
        console.error("Error adding project record:", ProjectResponse.data.message);
        toast.error("Error adding the project");
      }
    } catch (error) {
      console.error("Error adding Project:", error);
      toast.error("Error adding the project");
    }
    toast.dismiss(loadingToastId);
  };

  const handleUpdateProjectDetailsButton = async (selectedID) => {
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    try {
      const ProjectResponse = await axios.put(process.env.REACT_APP_SERVER_URL + `/api/project/${numericSelectedID}`, {
        Stage: newStage,
        Province: newProvince,
        Region: newRegion,
        ProjectNum: newProjectNum,
        ProjectName: newProjectName,
        Sector: newSector,
        SubSector: newSubSector,
        Administrator: newAdministrator,
        Facilitator: newFacilitator,
        IdentificationDate: newIdentificationDate,
        GeneralRemarks: newGeneralRemarks,
        Type: newType,
        Phase: newPhase,
        VerificationDate: newVerificationDate,
        Coordinator: newCoordinator,
        ProjectStatus: newStatus,
        CapacityDate: newCapacityDate,
        NormalBudget: newNormalBudget,
        DiscretionaryRequired: newDiscretionaryRequired,
        AmountRequired: newAmountRequired,
        MotivationForDiscretionary: newMotivationForDiscretionary,
        DateApproval: newDateApproval,
        FundingDate: newFundingDate,
        MonitoringDate: newMonitoringDate,
        LastUpdatedBy: user?.FullName,
        LastUpdated: new Date(),
      });
      console.log("Project response:", ProjectResponse.data);
      if (ProjectResponse.data.status === "success") {
        toast.success(`${newProjectName} was updated successfully.`);
        const updatedResponse = await axios.get(process.env.REACT_APP_SERVER_URL + "/api/project");
        const projects = updatedResponse.data.items;
        const updatedGridData = projects.filter((project) => project.ProjectStatus !== "Deleted");
        setGridData(updatedGridData);
      } else {
        console.error("Error updating project record:", ProjectResponse.data.message);
        toast.error("Error updating the project");
      }
    } catch (error) {
      console.error("Error updating project:", error);
      toast.error("Error updating the project");
    }
    toast.dismiss(loadingToastId);
  };

  const statusTemplate = (props) => {
    const status = props.ProjectStatus; // Assuming the status field is named "Status"
    let colorClass = "";

    if (status === "Active") {
      colorClass = "status-active";
    } else if (status === "Non-active") {
      colorClass = "status-inactive";
    } else if (status === "Progress delayed") {
      colorClass = "status-delayed";
    }

    return <div className={`status-cell ${colorClass}`}>{status}</div>;
  };

  const beneficiaryCountTemplate = (rowData) => {
    if (!rowData || !beneficiaryData.length) {
      return null;
    }
    const projectNum = rowData.ProjectNum;
    const matchingBeneficiaries = beneficiaryData.filter((beneficiary) => beneficiary.ProjNum === projectNum);
    const beneficiaryCount = matchingBeneficiaries.length;

    return <span>{beneficiaryCount}</span>;
  };

  const activeBeneficiaryCountTemplate = (rowData) => {
    if (!rowData || !beneficiaryData.length) {
      return null;
    }
    const projectNum = rowData.ProjectNum;
    const matchingBeneficiaries = beneficiaryData.filter((beneficiary) => {
      return beneficiary.ProjNum === projectNum && beneficiary.BenStatus === "Active";
    });
    const beneficiaryCount = matchingBeneficiaries.length;

    return <span>{beneficiaryCount}</span>;
  };

  const attachmentCountTemplate = (rowData) => {
    if (!rowData || !attachmentData.length) {
      return null;
    }
    const projectNum = rowData.ProjectNum;
    const matchingAttachments = attachmentData.filter((attachment) => {
      return attachment.ProjectNum === projectNum && attachment.AttachType !== "";
    });
    const attachmentCount = matchingAttachments.length;

    return <span>{attachmentCount}</span>;
  };

  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };
  const handleAddDialogClose = () => {
    setIsAddDialogVisible(false);
  };

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleUpdateProjectDetailsButton(selectedID); // Replace with your actual logic
        setIsEditDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  let addButtons = [
    {
      buttonModel: {
        content: "Add New",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleAddProjectDetailsButton(); // Replace with your actual logic
        setIsAddDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsAddDialogVisible(false);
      },
    },
  ];

  let messageButtons = [
    {
      buttonModel: {
        content: "Close",
        cssClass: "e-flat",
      },
      click: () => {
        setIsMessagesVisible(false);
      },
    },
  ];

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];

  const currentTitleDate = dateFormatter(new Date());
  const handleExcelButtonClick = () => {
    if (GridRef.current && GridRef.current.excelExport) {
      const exportProperties = {
        pageOrientation: "Landscape",
        fileName: "List Printed by " + user.FullName + " on " + currentTitleDate + ".xlsx",
      };
      GridRef.current.excelExport(exportProperties);
    }
  };

  const handlePdfButtonClick = () => {
    if (GridRef.current && GridRef.current.pdfExport) {
      GridRef.current.pdfExport();
    }
  };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button
            className={`view-button ${userChatsData.length > 0 ? "chat-button" : ""}`}
            onClick={viewMessages}
            disabled={selectedID === ""}>
            <ForumIcon className="view-icon" /> Chats{" "}
          </button>
        </div>
        <div className="button-container-subA1">
          {user?.JobTitle === "Administrator" && (
            <button className="add-button" onClick={handleAddButtonClick}>
              <AddIcon className="send-icon" /> Add{" "}
            </button>
          )}
          {user?.JobTitle === "Administrator" && (
            <button className="edit-button" onClick={handleEditButtonClick} disabled={selectedID === ""}>
              <EditIcon className="send-icon" /> Edit{" "}
            </button>
          )}
          <button
            className={`delete-button-whereabouts ${isButtonEnabled ? "" : "disabled-button"}`}
            onClick={handleDeleteButtonClick}
            disabled={!isButtonEnabled}>
            <DeleteIcon className="but-icon-whereabouts" /> Delete{" "}
          </button>
        </div>
        <div className="button-container-subA2">
          <button className="add-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          {/* <button className="edit-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button> */}
        </div>
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [...toolbarOptions, { template: renderButtons }];

  //-------------=---------Messages--------------------------------//
  const viewMessages = () => {
    setIsMessagesVisible(true);
    axios
      .patch(process.env.REACT_APP_SERVER_URL + `/api/chat/read`, {
        type: "project_id",
        id: selectedID,
      })
      .then(({ data }) => {
        console.log("Read : ", data);
        setUserChatsData((prev) => prev?.filter((notif) => notif.project_id !== selectedID));
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  };

  // Custom template for the "message" column
  const chatCountTemplate = (props) => {
    const ID = props.id;
    const chatCount = userChatsData.filter((chat) => chat.project_id === ID).length;

    const fontColor = chatCount < 1 ? "darkgreen" : "darkred";
    const backgroundColor = chatCount < 1 ? "lightgreen" : "pink";
    const fontWeight = chatCount > 0 ? "bold" : "normal";

    const style = {
      color: fontColor,
      backgroundColor: backgroundColor,
      width: "fit-content",
      height: "100%",
      display: "inline-flex",
      justifyContent: "left",
      alignItems: "left",
      padding: "5px 10px",
      borderRadius: "10px",
      fontWeight: fontWeight,
    };

    return (
      <div style={style}>
        <span>{chatCount}</span>
      </div>
    );
  };

  const handleMessageDialogClose = () => {
    setIsMessagesVisible(false);
  };
  //-------------=---------Messages--------------------------------//

  return (
    <div className="all-main-project-containers">
      <div className="all-outer-project-containers">
        <div className="summary-container-project">
          <div className="decicion_requester1">
            <ComboBoxComponent
              id="comboelement"
              value="All"
              placeholder="Selected Province"
              floatLabelType="Always"
              dataSource={provinces}
              change={handleProvinceChange}
            />
          </div>
          <div className="decicion_requester">
            <ComboBoxComponent
              id="comboelement"
              value="All"
              placeholder="Selected Region"
              floatLabelType="Always"
              dataSource={regions}
              change={handleRegionChange}
            />
          </div>
          <div className="decicion_requester">
            <ComboBoxComponent
              id="comboelement"
              value="All"
              placeholder="Selected Sector"
              floatLabelType="Always"
              dataSource={sectors}
              change={handleSectorChange}
            />
          </div>
          <div className="decicion_approver">
            <ComboBoxComponent
              id="comboelement"
              value="All"
              placeholder="Selected Sub-Sector"
              floatLabelType="Always"
              dataSource={subSectors}
              change={handleSubSectorChange}
            />
          </div>
          <div className="decicion_approver">
            <ComboBoxComponent
              id="comboelement"
              value="All"
              placeholder="Selected Project Status"
              floatLabelType="Always"
              dataSource={status}
              change={handleStatusChange}
            />
          </div>
          <div className="decicion_approver">
            <ComboBoxComponent
              id="comboelement"
              value="All"
              placeholder="Selected Project"
              floatLabelType="Always"
              dataSource={projectNames}
              change={handleProjectChange}
            />
          </div>
          <div className="decicion_approver">
            <ComboBoxComponent
              id="comboelement"
              value="All"
              placeholder="Selected Phase"
              floatLabelType="Always"
              dataSource={["All", "Phase 1: Coordinators", "Phase 2: Facilitattors", "Phase 3: NF"]}
              change={handlePhaseChange}
            />
          </div>
        </div>
        <div className="summary-container-project">
          <div className="decicion_requester1">
            <ComboBoxComponent
              id="comboelement"
              value="All"
              placeholder="Selected Stage"
              floatLabelType="Always"
              dataSource={["All", "Identification", "Capacity Building", "Funding", "Monitoring"]}
              change={handleStageChange}
            />
          </div>
          <div className="decicion_requester">
            <ComboBoxComponent
              id="comboelement"
              value="All"
              placeholder="Overall Compliant"
              floatLabelType="Always"
              dataSource={["All", "Compliant", "Non-Compliant"]}
              change={handleNonComliantChange}
            />
          </div>
          <div className="decicion_requester">
            <ComboBoxComponent
              id="comboelement"
              value="All"
              placeholder="Beneficiary Compliant"
              floatLabelType="Always"
              dataSource={["All", "Sufficiant Beneficiaries", "Insufficiant Beneficiaries"]}
              change={handleBeneficiaryChange}
            />
          </div>
          <div className="decicion_approver">
            <ComboBoxComponent
              id="comboelement"
              value="All"
              placeholder="Bank Compliant"
              floatLabelType="Always"
              dataSource={["All", "With Bank Account", "No Bank Account"]}
              change={handleBankChange}
            />
          </div>
          <div className="decicion_approver">
            <ComboBoxComponent
              id="comboelement"
              value="All"
              placeholder="CIPC Compliant"
              floatLabelType="Always"
              dataSource={["All", "CIPC Compliant", "CIPC Non-Compliant"]}
              change={handleCipcChange}
            />
          </div>
          <div className="decicion_approver">
            <ComboBoxComponent
              id="comboelement"
              value="All"
              placeholder="Identification Date"
              floatLabelType="Always"
              dataSource={identifiedDataSource}
              change={handleIdentificationChange}
            />
          </div>
          <div className="decicion_approver">
            <ComboBoxComponent
              id="comboelement"
              value="All"
              placeholder="Funding Date"
              floatLabelType="Always"
              dataSource={FundedDataSource}
              change={handleFundingChange}
            />
          </div>
        </div>
        <br />
        <h3>List of All Projects in {userLocation}</h3>
        <div className="all-inner-project-containers">
          {isDataLoaded && (
            <GridComponent
              dataSource={gridData}
              childMapping="items"
              ref={GridRef}
              classClass="custom-treegrid-class"
              height="650px"
              width="99.5%"
              allowReordering={true}
              allowResizing={true}
              allowExcelExport={true}
              allowPdfExport={true}
              allowPaging={true}
              allowSorting={true}
              allowFiltering={true}
              showColumnChooser={true}
              filterSettings={FilterOptions}
              pageSettings={pageSettings}
              editSettings={editOptions}
              toolbar={updatedToolbarOptions}
              allowSelection={true}
              rowSelected={handleRowSelect}>
              <ColumnsDirective>
                {/* <ColumnDirective
                  field="id"
                  headerText="ID"
                  width="100"
                  textAlign="Left"
                  isPrimaryKey={true}
                /> */}
                <ColumnDirective headerText="Chats" width="100" textAlign="Left" template={chatCountTemplate} />
                <ColumnDirective field="ProjectNum" headerText="Project #" width="120" textAlign="Left" />
                <ColumnDirective field="ProjectName" headerText="Project Name" width="200" textAlign="Left" />
                <ColumnDirective headerText="All Ben's" width="90" textAlign="Left" template={beneficiaryCountTemplate} />
                <ColumnDirective headerText="Active Ben's" width="90" textAlign="Left" template={activeBeneficiaryCountTemplate} />
                <ColumnDirective headerText="Attach's" width="90" textAlign="Left" template={attachmentCountTemplate} />
                <ColumnDirective field="Sector" headerText="Sector" width="120" textAlign="Left" />
                <ColumnDirective field="SubSector" headerText="Sub Sector" width="120" textAlign="Left" />
                <ColumnDirective field="Type" headerText="Type" width="150" textAlign="Left" />
                <ColumnDirective field="Phase" headerText="Phase" width="150" textAlign="Left" />
                <ColumnDirective field="Stage" headerText="Stage" width="150" textAlign="Left" />
                <ColumnDirective field="ProjectStatus" headerText="Status" width="90" textAlign="Left" template={statusTemplate} />
                <ColumnDirective field="Administrator" headerText="Administrator" width="150" textAlign="Left" />
                <ColumnDirective field="Facilitator" headerText="Facilitator" width="150" textAlign="Left" />
                <ColumnDirective field="Coordinator" headerText="Coordinator" width="150" textAlign="Left" />
                <ColumnDirective field="Region" headerText="Region" width="150" textAlign="Left" />
                <ColumnDirective field="District" headerText="District" width="150" textAlign="Left" />
                <ColumnDirective field="Province" headerText="Province" width="150" textAlign="Left" />
                <ColumnDirective field="GeneralRemarks" headerText="General Remarks" width="350" textAlign="Left" />
                <ColumnDirective
                  field="IdentificationDate"
                  headerText="Identification Date"
                  width="220"
                  type="date"
                  format="yyyy/MM/dd"
                  textAlign="Left"
                  allowFiltering={false}
                />
                <ColumnDirective
                  field="VerificationDate"
                  headerText="Verification Date"
                  width="220"
                  type="date"
                  format="yyyy/MM/dd"
                  textAlign="Left"
                  allowFiltering={false}
                />
                <ColumnDirective
                  field="CapacityDate"
                  headerText="Capacity Building Date"
                  width="220"
                  type="date"
                  format="yyyy/MM/dd"
                  textAlign="Left"
                  allowFiltering={false}
                />
                <ColumnDirective
                  field="FundingDate"
                  headerText="Funding Date"
                  width="220"
                  type="date"
                  format="yyyy/MM/dd"
                  textAlign="Left"
                  allowFiltering={false}
                />
                <ColumnDirective
                  field="MonitoringDate"
                  headerText="Monitoring Stat Date"
                  width="220"
                  type="date"
                  format="yyyy/MM/dd"
                  textAlign="Left"
                  allowFiltering={false}
                />
                <ColumnDirective
                  field="DateApproval"
                  headerText="Monitoring End Date"
                  width="220"
                  type="date"
                  format="yyyy/MM/dd"
                  textAlign="Left"
                  allowFiltering={false}
                />
              </ColumnsDirective>
              <Inject services={[Page, Sort, Filter, Edit, Toolbar, ColumnChooser, Reorder, Resize, PdfExport, ExcelExport]} />
            </GridComponent>
          )}
        </div>
      </div>
      <div>
        {isAddDialogVisible && (
          <DialogComponent
            visible={isAddDialogVisible}
            width="80%"
            height="86%"
            header="Add New Project Details"
            showCloseIcon={true}
            allowDragging={true}
            close={handleAddDialogClose}
            buttons={addButtons}>
            <ProjectDialogMain
              projectData={projectData}
              selectedID={selectedID}
              selectedProjectNum={selectedProjectNum}
              selectedProjectName={selectedProjectName}
              selectedSector={selectedSector}
              selectedSubSector={selectedSubSector}
              selectedAdministrator={selectedAdministrator}
              selectedCoordinator={selectedCoordinator}
              selectedFacilitator={selectedFacilitator}
              selectedRegion={selectedRegion}
              selectedProvince={selectedProvince}
              selectedIdentificationDate={selectedIdentificationDate}
              selectedPhase={selectedPhase}
              selectedStage={selectedStage}
              selectedGeneralRemarks={selectedGeneralRemarks}
              selectedType={selectedType}
              selectedVerificationDate={selectedVerificationDate}
              selectedStatus={selectedStatus}
              selectedCapacityDate={selectedCapacityDate}
              selectedNormalBudget={selectedNormalBudget}
              selectedDiscretionaryRequired={selectedDiscretionaryRequired}
              selectedAmountRequired={selectedAmountRequired}
              selectedMotivationForDiscretionary={selectedMotivationForDiscretionary}
              selectedFundingDate={selectedFundingDate}
              selectedDateApproval={selectedDateApproval}
              selectedMonitoringDate={selectedMonitoringDate}
              setNewProjectNum={setNewProjectNum}
              setNewProjectName={setNewProjectName}
              setNewSector={setNewSector}
              setNewSubSector={setNewSubSector}
              setNewAdministrator={setNewAdministrator}
              setNewCoordinator={setNewCoordinator}
              setNewFacilitator={setNewFacilitator}
              setNewRegion={setNewRegion}
              setNewProvince={setNewProvince}
              setNewIdentificationDate={setNewIdentificationDate}
              setNewPhase={setNewPhase}
              setNewDateApproval={setNewDateApproval}
              setNewStage={setNewStage}
              setNewGeneralRemarks={setNewGeneralRemarks}
              setNewFundingDate={setNewFundingDate}
              setNewMonitoringDate={setNewMonitoringDate}
              setNewType={setNewType}
              setNewVerificationDate={setNewVerificationDate}
              setNewStatus={setNewStatus}
              setNewCapacityDate={setNewCapacityDate}
              setNewNormalBudget={setNewNormalBudget}
              setNewDiscretionaryRequired={setNewDiscretionaryRequired}
              setNewAmountRequired={setNewAmountRequired}
              setNewMotivationForDiscretionary={setNewMotivationForDiscretionary}
              uniqueProvinces={uniqueProvinces}
              uniqueSubSectors={uniqueSubSectors}
              uniqueSectors={uniqueSectors}
              uniqueProjects={uniqueProjects}
              uniqueRegions={uniqueRegions}
              uniqueFacilitators={uniqueFacilitators}
              uniqueAdministrators={uniqueAdministrators}
              uniqueCoordinators={uniqueCoordinators}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="80%"
            height="86%"
            header="Edit Project Details"
            showCloseIcon={true}
            allowDragging={true}
            close={handleEditDialogClose}
            buttons={editButtons}>
            <ProjectDialogMain
              projectData={projectData}
              selectedID={selectedID}
              selectedProjectNum={selectedProjectNum}
              selectedProjectName={selectedProjectName}
              selectedSector={selectedSector}
              selectedSubSector={selectedSubSector}
              selectedAdministrator={selectedAdministrator}
              selectedCoordinator={selectedCoordinator}
              selectedFacilitator={selectedFacilitator}
              selectedRegion={selectedRegion}
              selectedProvince={selectedProvince}
              selectedIdentificationDate={selectedIdentificationDate}
              selectedPhase={selectedPhase}
              selectedStage={selectedStage}
              selectedGeneralRemarks={selectedGeneralRemarks}
              selectedType={selectedType}
              selectedVerificationDate={selectedVerificationDate}
              selectedStatus={selectedStatus}
              selectedCapacityDate={selectedCapacityDate}
              selectedNormalBudget={selectedNormalBudget}
              selectedDiscretionaryRequired={selectedDiscretionaryRequired}
              selectedAmountRequired={selectedAmountRequired}
              selectedMotivationForDiscretionary={selectedMotivationForDiscretionary}
              selectedFundingDate={selectedFundingDate}
              selectedDateApproval={selectedDateApproval}
              selectedMonitoringDate={selectedMonitoringDate}
              setNewProjectNum={setNewProjectNum}
              setNewProjectName={setNewProjectName}
              setNewSector={setNewSector}
              setNewSubSector={setNewSubSector}
              setNewAdministrator={setNewAdministrator}
              setNewCoordinator={setNewCoordinator}
              setNewFacilitator={setNewFacilitator}
              setNewRegion={setNewRegion}
              setNewProvince={setNewProvince}
              setNewIdentificationDate={setNewIdentificationDate}
              setNewPhase={setNewPhase}
              setNewDateApproval={setNewDateApproval}
              setNewStage={setNewStage}
              setNewGeneralRemarks={setNewGeneralRemarks}
              setNewFundingDate={setNewFundingDate}
              setNewMonitoringDate={setNewMonitoringDate}
              setNewType={setNewType}
              setNewVerificationDate={setNewVerificationDate}
              setNewStatus={setNewStatus}
              setNewCapacityDate={setNewCapacityDate}
              setNewNormalBudget={setNewNormalBudget}
              setNewDiscretionaryRequired={setNewDiscretionaryRequired}
              setNewAmountRequired={setNewAmountRequired}
              setNewMotivationForDiscretionary={setNewMotivationForDiscretionary}
              uniqueProvinces={uniqueProvinces}
              uniqueSubSectors={uniqueSubSectors}
              uniqueSectors={uniqueSectors}
              uniqueProjects={uniqueProjects}
              uniqueRegions={uniqueRegions}
              uniqueFacilitators={uniqueFacilitators}
              uniqueAdministrators={uniqueAdministrators}
              uniqueCoordinators={uniqueCoordinators}
            />
          </DialogComponent>
        )}
      </div>
      {isMessagesVisible && (
        <div>
          <Modal
            open={isMessagesVisible}
            onClose={handleMessageDialogClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
              <div className="message_outer_container">
                <h1>{`Chats about ${selectedProjectName} Project`}</h1>
                <div className="message_inner_container">
                  <MessageBox users={allUserData} chatID={selectedID} chatUsers={selectedChatUsers} type="project_id" />
                </div>
                <button className="add-button" onClick={handleMessageDialogClose}>
                  <CloseIcon className="send-icon" /> Close
                </button>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
}
export default ListView;
