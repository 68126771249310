import React, { useEffect, useState, useRef } from "react";
import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-kanban";
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import "../../../../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
import "../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
import useSmtsDataFetch from "../../../shared/useSmtsDataFetch"; // Import the custom hook
import "./Location.css";
import { toast } from "react-toastify";

function NextWeekLocation() {
  const { smtsData, isSmtsLoaded } = useSmtsDataFetch();
  const [data, setData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (isSmtsLoaded) {
      const currentDate = new Date();
      const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)

      // Calculate the date for Sunday of the next week
      const beginningOfNextWeek = new Date(currentDate);
      beginningOfNextWeek.setDate(currentDate.getDate() - dayOfWeek + 7); // Adjust to next Sunday

      // Calculate the date for Saturday of the next week
      const endOfNextWeek = new Date(beginningOfNextWeek);
      endOfNextWeek.setDate(beginningOfNextWeek.getDate() + 6); // Adjust to Saturday

      // Filter smtsData for items within the next week (Sunday to Saturday)
      const finalSmtsData = smtsData.filter((item) => {
        const itemDate = new Date(item?.Date);
        return itemDate >= beginningOfNextWeek && itemDate <= endOfNextWeek;
      });

      // Sort the final data by date
      finalSmtsData.sort((a, b) => new Date(a.Date) - new Date(b.Date));
      setData(finalSmtsData);
      setIsDataLoaded(true);
    }
  }, [smtsData, isSmtsLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  return (
    <div className="this-week-users">
      <h3>Where is Everyone will be Next Week?</h3>
      <br />
      <div className="feed-line-this-week-users">
        {isDataLoaded && (
          <KanbanComponent
            id="kanban"
            dataSource={data}
            keyField="Province"
            width="100%"
            height="720px"
            allowDragAndDrop={false}
            cardSettings={{ contentField: "Location", headerField: "FullName" }}
            sortSettings={{ sortBy: "DataSourceOrder" }}
            swimlaneSettings={{
              keyField: "WeekDay",
              sortBy: "DataSourceOrder",
              showItemCount: false,
              enableFrozenRows: true,
            }}
          >
            <ColumnsDirective>
              <ColumnDirective headerText="Gauteng" keyField="Gauteng" />
              <ColumnDirective
                headerText="Kwazulu Natal"
                keyField="Kwazulu-Natal"
              />
              <ColumnDirective headerText="Limpopo" keyField="Limpopo" />
              <ColumnDirective headerText="Mpumalanga" keyField="Mpumalanga" />
              <ColumnDirective headerText="North West" keyField="North West" />
              <ColumnDirective
                headerText="Other Locations"
                keyField="Other Locations"
                allowToggle={true}
                isExpanded={false}
              />
              <ColumnDirective
                headerText="Training"
                keyField="Training"
                allowToggle={true}
                isExpanded={false}
              />
              <ColumnDirective
                headerText="On Leave"
                keyField="On Leave"
                allowToggle={true}
                isExpanded={false}
              />
            </ColumnsDirective>
          </KanbanComponent>
        )}
      </div>
    </div>
  );
}
export default NextWeekLocation;
