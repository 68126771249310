import React from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import './StageApprovalDialog.css'

function StageApprovalDialog({ selectedID, selectedStage, sender, nextStage, currentDateTime, declaration}) {

  return (
    <table className='main-container-dialog'>
      <tbody className='project-field-dialog'>
        <tr className='my-dialog-row1'>
          <td className='field_projectid'>
            <TextBoxComponent id='id' name='id' value={selectedID} placeholder=" Proj ID" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='field_projectstage'>
            <TextBoxComponent id='Stage' name='Stage' value={selectedStage} placeholder=" Current Stage" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='field_datetime'>
            <DateTimePickerComponent  id='datetimepicker' value={currentDateTime} placeholder=" Date & Time" floatLabelType="Always" disabled={true}/>
          </td>
        </tr>
        <tr className='my-dialog-row2'>
          <td className='field_from'>
            <TextBoxComponent  id='approve' name='approve' value={sender} placeholder="Approve By" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='field_to'>
            <TextBoxComponent  id='nextStage' name='nextStage' value={nextStage} placeholder=" Next Stage" floatLabelType="Always" disabled={true}/>
          </td>
        </tr>
        <tr className='my-dialog-row3'>
          <td className='field_notes'>
            <TextBoxComponent  id='notes' name='notes' value={declaration} multiline={true} placeholder=" Declaration" floatLabelType="Always" disabled={true} cssClass="multiline-textbox"/>
          </td>
        </tr>
      </tbody>
    </table>
    
  )
}

export default StageApprovalDialog
