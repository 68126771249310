import React, { useEffect, useState, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import "./MainComp.css";
import useProjectDataFetch from "../../../shared/useProjectDataFetch"; // Import the custom hook
import useUserDataFetch from "../../../shared/useUserDataFetch"; // Import the custom hook
import useUserChatsDataFetch from "../../../shared/useUserChatsDataFetch"; // Import the custom hook
import useUserStore from "../../../../app/user";
import MessageBox from "../MessageBox";
import { toast } from "react-toastify";
import socket from "../../../../utils/socket";
import axios from "../../../../config/axios";
function ChatProjects() {
  const user = useUserStore((state) => state.user);
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { userChatsData, isUserChatsLoaded, setUserChatsData } =
    useUserChatsDataFetch([]); // Use the custom hook to fetch data
  const { allUserData, isAllUserLoaded } = useUserDataFetch([]); // Use the custom hook to fetch data
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [commonUserData, setCommonUserData] = useState([]);
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedID, setSelectedID] = useState("");
  const [selectedChatUsers, setSelectedChatUsers] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  // const [isNewEntry, setIsNewEntry] = useState(false);
  const chatAdmin = user?.FullName;

  useEffect(() => {
    if (isProjectLoaded && isAllUserLoaded && isUserChatsLoaded) {
      const filteredUserData = allUserData.filter(
        (user) =>
          user.AccessProvince === "All" &&
          user.FullName !== "Vusi Shongwe" &&
          user.FullName !== "Fana Mkwanazi" &&
          user.FullName !== "Nqobile Dlamini"
      );
      setCommonUserData(filteredUserData);
      setIsDataLoaded(true);
    }
  }, [
    allUserData,
    userChatsData,
    isProjectLoaded,
    isAllUserLoaded,
    isUserChatsLoaded,
  ]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  const handleRowSelect = (args) => {
    setSelectedProjectName(args.data.ProjectName);
    setSelectedID(args.data.id);

    const selectedUserData = allUserData.filter(
      (user) =>
        (user.AccessProvince === args.data.Province &&
          user.JobTitle === "Facilitator") ||
        (user.AccessProvince === args.data.Province &&
          user.JobTitle === "Administrator") ||
        user.AccessRegion === args.data.Region
    );
    const combinedList = commonUserData.concat(selectedUserData);
    const filteredUserData = combinedList.filter(
      (item) => item.FullName !== user.FullName
    );
    setSelectedChatUsers(filteredUserData);

    axios
      .patch(process.env.REACT_APP_SERVER_URL + `/api/chat/read`, {
        type: "project_id",
        id: args.data.id,
      })
      .then(({ data }) => {
        console.log("Read : ", data);
        setUserChatsData((prev) =>
          prev?.filter((notif) => notif.project_id !== args.data.id)
        );
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  };

  const statusTemplate = (props) => {
    const status = props.ProjectStatus;
    let colorClass = "";
    if (status === "Active") {
      colorClass = "status-active";
    } else if (status === "Non-active") {
      colorClass = "status-inactive";
    } else if (status === "Progress delayed") {
      colorClass = "status-delayed";
    }
    return <div className={`status-cell ${colorClass}`}>{status}</div>;
  };

  const chatCountTemplate = (props) => {
    const ID = props.id;
    const chatCount = userChatsData.filter(
      (chat) => chat.project_id === ID
    ).length;
    const fontColor = chatCount < 1 ? "darkgreen" : "darkred";
    const backgroundColor = chatCount < 1 ? "lightgreen" : "pink";
    const fontWeight = chatCount > 0 ? "bold" : "normal";

    const style = {
      color: fontColor,
      backgroundColor: backgroundColor,
      width: "fit-content",
      height: "100%",
      display: "inline-flex",
      justifyContent: "left",
      alignItems: "left",
      padding: "5px 10px",
      borderRadius: "10px",
      fontWeight: fontWeight,
    };

    return (
      <div style={style}>
        <span>{chatCount}</span>
      </div>
    );
  };

  useEffect(() => {
    socket.on("notification", (data) => {
      console.log("Receive Notification: ", data);
      const isTrue = userChatsData.find((chat) => chat.ID === data.ID);
      if (!isTrue)
        setUserChatsData((prev) => (prev ? [...prev, data] : [data]));
      // setIsNewEntry((prev) => !prev);
    });
  }, []);

  // useEffect(() => {
  //   if (userChatsData?.length)
  //     setUserChatsData((prev) => removeDuplicates(prev, "ID"));
  //   console.log("Is Newt : ", isNewEntry);
  // }, [isNewEntry]);

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };

  return (
    <div className="main-chat-container">
      <div className="item-chat-container">
        <h3>All Projects in my Area</h3>
        <br />
        <div className="inner_container">
          {isDataLoaded && (
            <GridComponent
              dataSource={projectData}
              childMapping="items"
              ref={GridRef}
              classClass="custom-treegrid-class"
              height="650"
              width="100%"
              allowPaging={true}
              allowSorting={true}
              allowFiltering={true}
              allowSelection={true}
              rowSelected={handleRowSelect}
              filterSettings={FilterOptions}
              pageSettings={pageSettings}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="ProjectName"
                  headerText="Project Name"
                  width="200"
                  textAlign="Left"
                />
                <ColumnDirective
                  headerText="Chats"
                  width="90"
                  textAlign="Left"
                  template={chatCountTemplate}
                />
                <ColumnDirective
                  field="Region"
                  headerText="Region"
                  width="150"
                  textAlign="Left"
                  editType="dropdownedit"
                />
                <ColumnDirective
                  field="Province"
                  headerText="Province"
                  width="150"
                  textAlign="Left"
                  editType="dropdownedit"
                />
                <ColumnDirective
                  field="ProjectStatus"
                  headerText="Status"
                  width="100"
                  textAlign="Left"
                  template={statusTemplate}
                />
                <ColumnDirective
                  field="ProjectNum"
                  headerText="Project #"
                  width="120"
                  textAlign="Left"
                />
              </ColumnsDirective>
              <Inject services={[Page, Sort, Filter, Edit, Toolbar]} />
            </GridComponent>
          )}
        </div>
      </div>

      <div className="chat-container">
        <h3>{selectedProjectName}: Chat Details</h3>
        <br />
        <div className="inner_container">
          {selectedID && (
            <MessageBox
              users={allUserData}
              chatID={selectedID}
              chatUsers={selectedChatUsers}
              type="project_id"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatProjects;

const removeDuplicates = (arr, prop) => {
  const seen = new Map();
  return arr.filter((item) => {
    const key = prop ? item[prop] : JSON.stringify(item);
    if (!seen.has(key)) {
      seen.set(key, true);
      return true;
    }
    return false;
  });
};
