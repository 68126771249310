import React, { useEffect, useState, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import { getValue } from "@syncfusion/ej2-base";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import "../../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import axios from "../../../../../config/axios";
import "./ToDoProjects.css";
import DetailTemplate from "./DetailTemplate";
import WorkflowDialog from "../../../../shared/WorkflowDialog";
import StageApprovalDialog from "../../../../shared/StageApprovalDialog";
import useProjectDataFetch from "../../../../shared/useProjectDataFetch";
import useUserDataFetch from "../../../../shared/useUserDataFetch";
import useOtherDataFetch from "../../../../shared/useOtherDataFetch";
import useWorkflowDataFetch from "../../../../shared/useWorkflowDataFetch";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import useUserStore from "../../../../../app/user";
import { toast } from "react-toastify";
import { CheckNameDuplicates } from "../../../../../utils/project";

function ToDoProjects() {
  const user = useUserStore((state) => state.user);
  const { projectData, isProjectLoaded } = useProjectDataFetch(user);
  const { allUserData, isAllUserLoaded } = useUserDataFetch();
  const { workflowData, isWorkflowLoaded } = useWorkflowDataFetch();
  const { taskData, attachmentData, isAttachmentLoaded, isTaskLoaded } =
    useOtherDataFetch();
  const [gridData, setGridData] = useState([]);
  const [updatedWorkflowData, setUpdatedWorkflowData] = useState([]);
  const [allWorkflowData, setAllWorkflowData] = useState([]);
  const [accessProvince, setAccessProvince] = useState("");
  const [accessRegion, setAccessRegion] = useState("");
  const [accessFacilitator, setAccessFacilitator] = useState("");
  const [accessAdministrator, setAccessAdministrator] = useState("");
  const [accessCoordinator, setAccessCoordinator] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const currentStage = "Identification";

  useEffect(() => {
    if (
      isProjectLoaded &&
      isAllUserLoaded &&
      isWorkflowLoaded &&
      isAttachmentLoaded &&
      isTaskLoaded
    ) {
      setAllWorkflowData(workflowData);
      setUpdatedWorkflowData([]);
      const filteredProjectData = projectData.filter(
        (project) =>
          project.Stage === currentStage &&
          project.CurrentUser === user?.FullName
      );
      setGridData(filteredProjectData);
      if (user?.Access === "Regional") {
        setAccessProvince(user?.AccessProvince);
        setAccessRegion(user?.AccessRegion);
        setAccessCoordinator(user?.FullName);

        const facilitator = allUserData.filter(
          (item) =>
            item?.JobTitle === "Facilitator" &&
            item?.AccessProvince === user?.AccessProvince
        );
        setAccessFacilitator(facilitator[0]?.FullName);
        const administrator = allUserData.filter(
          (item) =>
            item?.JobTitle === "Administrator" &&
            item?.AccessProvince === user?.AccessProvince
        );
        setAccessAdministrator(administrator[0]?.FullName);
      }

      setIsDataLoaded(true);
    }
  }, [
    user,
    allUserData,
    isAllUserLoaded,
    workflowData,
    isWorkflowLoaded,
    projectData,
    isProjectLoaded,
    isAttachmentLoaded,
    isTaskLoaded,
  ]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  // Get unique provinces from projectData
  const uniqueProvinces = [
    ...new Set(
      projectData?.map((item) => (item.Province ? item.Province : ""))
    ),
  ];

  // Get unique subsectors from sectorProjectData
  const uniqueSubSectors = [
    ...new Set(
      projectData?.map((item) => (item.SubSector ? item.SubSector : ""))
    ),
  ];

  // Get unique subsectors from projectData
  const uniqueSectors = [
    ...new Set(projectData?.map((item) => (item.Sector ? item.Sector : ""))),
  ];

  // Get unique provinces from projectData
  const uniqueProjects = [
    ...new Set(
      projectData?.map((item) => (item.ProjectName ? item.ProjectName : ""))
    ),
  ];
  // Get unique RegionS from provinceProjectData
  const uniqueRegions = [
    ...new Set(projectData?.map((item) => (item.Region ? item.Region : ""))),
  ];
  // Get unique FacilitatorS from provinceProjectData
  const uniqueFacilitators = [
    ...new Set(
      projectData?.map((item) => (item.Facilitator ? item.Facilitator : ""))
    ),
  ];
  // Get unique AdministratorS from provinceProjectData
  const uniqueAdministrators = [
    ...new Set(
      projectData?.map((item) => (item.Administrator ? item.Administrator : ""))
    ),
  ];
  // Get unique AdministratorS from regionProjectData
  const uniqueCoordinators = [
    ...new Set(
      projectData?.map((item) => (item.Coordinator ? item.Coordinator : ""))
    ),
  ];

  // Get unique AdministratorS from regionProjectData
  const uniqueTowns = [
    ...new Set(projectData?.map((item) => (item.Town ? item.Town : ""))),
  ];

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
  const [isStageDialogVisible, setIsStageDialogVisible] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // Initial state

  const [selectedID, setSelectedID] = useState("");
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedProjectNum, setSelectedProjectNum] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedSubSector, setSelectedSubSector] = useState("");
  const [selectedAdministrator, setSelectedAdministrator] = useState("");
  const [selectedFacilitator, setSelectedFacilitator] = useState("");
  const [selectedIdentificationDate, setSelectedIdentificationDate] =
    useState(null);
  const [selectedGeneralRemarks, setSelectedGeneralRemarks] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedPhase, setSelectedPhase] = useState("");
  const [selectedVerificationDate, setSelectedVerificationDate] =
    useState(null);
  const [selectedCoordinator, setSelectedCoordinator] = useState("");
  const [selectedPhysicalAddress, setSelectedPhysicalAddress] = useState("");
  const [selectedPostalAddress, setSelectedPostalAddress] = useState("");
  const [selectedTown, setSelectedTown] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [newStage, setNewStage] = useState("");
  const [newProvince, setNewProvince] = useState();
  const [newRegion, setNewRegion] = useState();
  const [newProjectNum, setNewProjectNum] = useState("");
  const [newProjectName, setNewProjectName] = useState("");
  const [newSector, setNewSector] = useState("");
  const [newSubSector, setNewSubSector] = useState("");
  const [newAdministrator, setNewAdministrator] = useState("");
  const [newFacilitator, setNewFacilitator] = useState("");
  const [newIdentificationDate, setNewIdentificationDate] = useState(null);
  const [newGeneralRemarks, setNewGeneralRemarks] = useState("");
  const [newType, setNewType] = useState("");
  const [newPhase, setNewPhase] = useState("");
  const [newVerificationDate, setNewVerificationDate] = useState(null);
  const [newCoordinator, setNewCoordinator] = useState("");
  const [newPhysicalAddress, setNewPhysicalAddress] = useState("");
  const [newPostalAddress, setNewPostalAddress] = useState("");
  const [newTown, setNewTown] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [receiver, setReceiver] = useState("");
  const [isDuplicate, setIsDuplicate] = useState(false);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
  }
  const currentDateTime = formatDate(new Date());
  //const currentDateTime = new Date(); // Get the current date and time

  const newCapacityBuildingDate = new Date();
  const declaration = `I, ${user?.FullName}, hereby declare that this project is prepared for advancement to the next stage. I can affirm that all the requisite attachments for this stage have been successfully uploaded, and the mandatory tasks have been satisfactorily completed by the coordinator and facilitator.`;
  const nextStage = "Capacity Building";
  const [notes, setNotes] = useState("");
  const sender = user?.FullName;
  const GridRef = useRef(null); // Create the treeGridRef reference

  const projectNewData = {
    Stage: newStage,
    Province: newProvince,
    Region: newRegion,
    ProjectNum: newProjectNum,
    ProjectName: newProjectName,
    Sector: newSector,
    SubSector: newSubSector,
    Administrator: newAdministrator,
    Facilitator: newFacilitator,
    IdentificationDate: newIdentificationDate,
    GeneralRemarks: newGeneralRemarks,
    Type: newType,
    Phase: newPhase,
    VerificationDate: newVerificationDate,
    Coordinator: newCoordinator,
    PhysicalAddress: newPhysicalAddress,
    PostalAddress: newPostalAddress,
    Town: newTown,
    ProjectStatus: newStatus,
    CurrentUser: user?.FullName,
    CreatedBy: user?.FullName,
  };

  const attachmentsData = [
    {
      AttachName: "Project Profile",
      Stage: "Identification",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Project Verification Checklists",
      Stage: "Identification",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    // {
    //   AttachName: "Other Identification Docs",
    //   Stage: "Identification",
    //   NatureOfAttach: "Project",
    //   Validity: 7,
    // },
    {
      AttachName: "Capacity Building Checklists",
      Stage: "Capacity Building",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    // {
    //   AttachName: "Other Capacity Building Docs",
    //   Stage: "Capacity Building",
    //   NatureOfAttach: "Project",
    //   Validity: 7,
    // },
    {
      AttachName: "Agreement Letter",
      Stage: "Capacity Building",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Capacity Building Progress Reports",
      Stage: "Capacity Building",
      NatureOfAttach: "Project",
      Validity: 0.0833333333333333,
    },
    {
      AttachName: "Constitution",
      Stage: "Capacity Building",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Registration",
      Stage: "Capacity Building",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Site Letter",
      Stage: "Capacity Building",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Certified ID Copies",
      Stage: "Capacity Building",
      NatureOfAttach: "Beneficiaries",
      Validity: 0.5,
    },
    {
      AttachName: "ManCo Letter",
      Stage: "Funding",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Budget",
      Stage: "Funding",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Three Quotations from Suppliers",
      Stage: "Funding",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Recommendation Letter",
      Stage: "Funding",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Approval Letter",
      Stage: "Funding",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Approved Price Checklists",
      Stage: "Funding",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Proof of Bank Accounts of qualifying Suppliers",
      Stage: "Funding",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "CIPC docs of qualifying Suppliers",
      Stage: "Funding",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Directors ID copies of qualifying Supplier",
      Stage: "Funding",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Proof of Address of qualifying Supplier",
      Stage: "Funding",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Suppliers Evaluation Checklists",
      Stage: "Funding",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Bid Evaluation Checklists",
      Stage: "Funding",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    {
      AttachName: "Project Monitoring Checklists",
      Stage: "Monitoring",
      NatureOfAttach: "Project",
      Validity: 7,
    },
    // {
    //   AttachName: "Other Monitoring Docs",
    //   Stage: "Monitoring",
    //   NatureOfAttach: "Project",
    //   Validity: 7,
    // },
    {
      AttachName: "Progress Reports",
      Stage: "Monitoring",
      NatureOfAttach: "Project",
      Validity: 0.5,
    },
    {
      AttachName: "Bank Account Proof",
      Stage: "Monitoring",
      NatureOfAttach: "Project",
      Validity: 1,
    },
    {
      AttachName: "Payments",
      Stage: "Funding",
      NatureOfAttach: "Project",
      Validity: 7,
    },
  ];

  const stageTaskData = [
    {
      TaskName: "Identification Stage",
      ProjectStage: "Identification",
      Progress: 0,
      isExpand: true,
    },
    {
      TaskName: "Capacity Building Stage",
      ProjectStage: "Capacity Building",
      Progress: 0,
      isExpand: true,
    },
    {
      TaskName: "Funding Stage",
      ProjectStage: "Funding",
      Progress: 0,
      isExpand: true,
    },
    {
      TaskName: "Monitoring Stage",
      ProjectStage: "Monitoring",
      Progress: 0,
      isExpand: true,
    },
  ];

  const beneficiaryData = [
    {
      FirstNames: "1st Name",
      LastName: "1st Surname",
      Gender: "Female",
      Race: "African",
      BenStatus: "Active",
    },
    {
      FirstNames: "2nd Name",
      LastName: "2nd Surname",
      Gender: "Female",
      Race: "African",
      BenStatus: "Active",
    },
    {
      FirstNames: "3rd Name",
      LastName: "3rd Surname",
      Gender: "Female",
      Race: "African",
      BenStatus: "Active",
    },
    {
      FirstNames: "4th Name",
      LastName: "4th Surname",
      Gender: "Female",
      Race: "African",
      BenStatus: "Active",
    },
    {
      FirstNames: "5th Name",
      LastName: "5th Surname",
      Gender: "Female",
      Race: "African",
      BenStatus: "Active",
    },
  ];

  const newVerificationStartDate = new Date(newIdentificationDate);
  newVerificationStartDate.setDate(newVerificationStartDate.getDate() + 42);

  const identificationTaskData = [
    {
      TaskName: "Collect Project Profile",
      ProjectStage: currentStage,
      StartDate: newIdentificationDate,
      Duration: 168,
      Progress: 0,
    },
    {
      TaskName: "Project Verification",
      ProjectStage: currentStage,
      StartDate: newVerificationStartDate,
      Duration: 5,
      Progress: 0,
    },
    // {
    //   TaskName: "Other Additional Tasks",
    //   ProjectStage: currentStage,
    //   StartDate: newIdentificationDate,
    //   Duration: 168,
    //   Progress: 0,
    // },
  ];

  const capacityBuildingTaskData = [
    {
      TaskName: "Develop Capacity building plan",
      ProjectStage: "Capacity Building",
      StartDate: newCapacityBuildingDate,
      Duration: 14,
      Progress: 0,
    },
    {
      TaskName: "Collect Constitution of the project",
      ProjectStage: "Capacity Building",
      StartDate: newCapacityBuildingDate,
      Duration: 168,
      Progress: 0,
    },
    {
      TaskName: "Collect Agreement letter",
      ProjectStage: "Capacity Building",
      StartDate: newCapacityBuildingDate,
      Duration: 168,
      Progress: 0,
    },
    {
      TaskName: "Collect Registration documents with CIPC",
      ProjectStage: "Capacity Building",
      StartDate: newCapacityBuildingDate,
      Duration: 168,
      Progress: 0,
    },
    {
      TaskName: "Collect ID copies of members",
      ProjectStage: "Capacity Building",
      StartDate: newCapacityBuildingDate,
      Duration: 168,
      Progress: 0,
    },
    {
      TaskName: "Collect Site letter",
      ProjectStage: "Capacity Building",
      StartDate: newCapacityBuildingDate,
      Duration: 168,
      Progress: 0,
    },
    {
      TaskName: "Collect Lease agreement of +20years",
      ProjectStage: "Capacity Building",
      StartDate: newCapacityBuildingDate,
      Duration: 168,
      Progress: 0,
    },
    {
      TaskName: "Capacity Building Tasks",
      ProjectStage: "Capacity Building",
      StartDate: newCapacityBuildingDate,
      Duration: 168,
      Progress: 0,
    },
  ];

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedStage(args.data.Stage);
    setSelectedProvince(args.data.Province);
    setSelectedRegion(args.data.Region);
    setSelectedProjectNum(args.data.ProjectNum);
    setSelectedProjectName(args.data.ProjectName);
    setSelectedSector(args.data.Sector);
    setSelectedSubSector(args.data.SubSector);
    setSelectedAdministrator(args.data.Administrator);
    setSelectedFacilitator(args.data.Facilitator);
    setSelectedIdentificationDate(args.data.IdentificationDate);
    setSelectedGeneralRemarks(args.data.GeneralRemarks);
    setSelectedType(args.data.Type);
    setSelectedPhase(args.data.Phase);
    setSelectedVerificationDate(args.data.VerificationDate);
    setSelectedCoordinator(args.data.Coordinator);
    setSelectedPhysicalAddress(args.data.PhysicalAddress);
    setSelectedPostalAddress(args.data.PostalAddress);
    setSelectedTown(args.data.Town);
    setSelectedStatus(args.data.ProjectStatus);

    const updatedGridData = allWorkflowData.filter(
      (workflow) => workflow.ProjectID === Number(args.data.id)
    );
    setUpdatedWorkflowData(updatedGridData);

    //check attachments
    const projectNum = args.data.ProjectNum;
    const allAttachments = attachmentData.filter(
      (attachment) =>
        attachment.ProjectNum === projectNum &&
        attachment.Stage === currentStage
    );
    const filteredAttachments = allAttachments.filter(
      (attachment) => attachment.AttachPath !== ""
    );
    // check Tasks
    const projectID = args.data.id;
    const parentIDs = taskData?.map((task) => task.ParentID);
    const allTasks = taskData.filter(
      (task) =>
        task.ProjectID === projectID &&
        (task.ProjectStage === currentStage || task.ProjectStage === "") &&
        !parentIDs.includes(task.id)
    );
    const filteredTasks = allTasks.filter((task) => task.Progress === 100);
    // Detemine the state
    if (
      filteredAttachments.length === allAttachments.length &&
      filteredTasks.length === allTasks.length
    ) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };

  const handleChangeCurrentUser = async (selectedID) => {
    const loadingToastId = toast.loading("Please wait...");
    try {
      const numericSelectedID = parseInt(selectedID);
      // Update the CurrentUser of the project
      const projectResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/project/${numericSelectedID}`,
        {
          CurrentUser: receiver,
        }
      );
      if (projectResponse.data.status === "success") {
        toast.success(`The message was sent to ${receiver} successfully.`);
        // Create a new workflow record
        const currentDateTime = new Date(); // Get the current date and time
        const workflowData = {
          ProjectID: numericSelectedID,
          ProjectStage: selectedStage,
          Sender: sender,
          Receiver: receiver,
          DateTime: currentDateTime,
          Notes: notes,
        };
        // Make a POST request to add the workflow record
        const workflowResponse = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/workflow",
          workflowData
        );
        console.log("Workflow response:", workflowResponse.data);
        if (workflowResponse.data.status === "success") {
          toast.success("Workflow record added successfully.");
          // Update the grid data

          const updatedGridData = gridData.filter(
            (project) => project.id !== numericSelectedID
          );
          setGridData(updatedGridData);
          try {
            const updatedResponse = await axios.get(
              process.env.REACT_APP_SERVER_URL + "/api/workflow"
            );
            const workflows = updatedResponse.data.items;
            setAllWorkflowData(workflows);
            const updatedGridData = workflows.filter(
              (workflow) => workflow.ProjectID === numericSelectedID
            );
            setUpdatedWorkflowData(updatedGridData);
          } catch (error) {
            console.error("Error fetching workflows:", error);
          }
        } else {
          console.error(
            "Error adding workflow record:",
            workflowResponse.data.message
          );
          toast.error("Error adding workflow");
        }
      } else {
        console.error(
          "Error updating CurrentUser:",
          projectResponse.data.message
        );
        toast.error("Error sending the message");
      }
    } catch (error) {
      console.error("Error updating CurrentUser:", error);
      toast.error("Error sending the message");
    }
    toast.dismiss(loadingToastId);
  };

  const handleCoordinatorButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const coordinator = allUserData?.filter(
      (user) =>
        user?.JobTitle === "Coordinator" &&
        user?.AccessRegion === selectedRegion
    );
    // Assuming you want to set the first user found as the receiver
    if (coordinator.length > 0) {
      setReceiver(coordinator[0]?.FullName);
    }
  };

  const handleAdministratorButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const administrator = allUserData?.filter(
      (user) =>
        user?.JobTitle === "Administrator" &&
        user?.AccessProvince === selectedProvince
    );
    // Assuming you want to set the first user found as the receiver
    if (administrator.length > 0) {
      setReceiver(administrator[0]?.FullName);
    }
  };

  const handleFacilitatorButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const facilitator = allUserData.filter(
      (user) =>
        user?.JobTitle === "Facilitator" &&
        user?.AccessProvince === selectedProvince
    );
    // Assuming you want to set the first user found as the receiver
    if (facilitator.length > 0) {
      setReceiver(facilitator[0]?.FullName);
    }
  };

  const handleAssistantNFButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const assistantnationalFacilitator = allUserData.filter(
      (user) => user?.JobTitle === "Assistant National Facilitator"
    );
    // Assuming you want to set the first user found as the receiver
    if (assistantnationalFacilitator.length > 0) {
      setReceiver(assistantnationalFacilitator[0]?.FullName);
    }
  };

  const handleNationalFacilitatorButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const nationalFacilitator = allUserData.filter(
      (user) => user?.JobTitle === "National Facilitator"
    );
    // Assuming you want to set the first user found as the receiver
    if (nationalFacilitator.length > 0) {
      setReceiver(nationalFacilitator[0]?.FullName);
    }
  };

  const handleStageApprovalButtonClick = () => {
    const coordinator = allUserData?.filter(
      (user) =>
        user?.JobTitle === "Coordinator" &&
        user?.AccessRegion === selectedRegion
    );
    if (coordinator.length > 0) {
      setReceiver(coordinator[0]?.FullName);
    }
    //setReceiver(user?.FullName);
    setIsStageDialogVisible(!isStageDialogVisible);
  };

  const handleDialogClose = () => {
    setIsDialogVisible(false);
  };
  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };
  const handleAddDialogClose = () => {
    setIsAddDialogVisible(false);
  };
  const handleStageDialogClose = () => {
    setIsStageDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsEditDialogVisible(!isEditDialogVisible);
    setNewStage(selectedStage);
    setNewProvince(selectedProvince);
    setNewRegion(selectedRegion);
    setNewProjectNum(selectedProjectNum);
    setNewProjectName(selectedProjectName);
    setNewSector(selectedSector);
    setNewSubSector(selectedSubSector);
    setNewAdministrator(selectedAdministrator);
    setNewFacilitator(selectedFacilitator);
    setNewIdentificationDate(selectedIdentificationDate);
    setNewGeneralRemarks(selectedGeneralRemarks);
    setNewType(selectedType);
    setNewPhase(selectedPhase);
    setNewVerificationDate(selectedVerificationDate);
    setNewCoordinator(selectedCoordinator);
    setNewPhysicalAddress(selectedPhysicalAddress);
    setNewPostalAddress(selectedPostalAddress);
    setNewTown(selectedTown);
    setNewStatus(selectedStatus);
    setIsDuplicate(false);
  };

  const handleAddButtonClick = () => {
    setIsAddDialogVisible(!isAddDialogVisible);
    setSelectedID("");
    setSelectedStage("Identification");
    setSelectedProvince(accessProvince);
    setSelectedRegion(accessRegion);
    setSelectedProjectNum("4021/");
    setSelectedProjectName("");
    setSelectedSector("");
    setSelectedSubSector("");
    setSelectedAdministrator(accessAdministrator);
    setSelectedFacilitator(accessFacilitator);
    setSelectedIdentificationDate(newCapacityBuildingDate);
    setSelectedGeneralRemarks("");
    setSelectedType("New");
    setSelectedPhase("Phase 1: Coordinators");
    setSelectedVerificationDate("");
    setSelectedCoordinator(accessCoordinator);
    setSelectedPhysicalAddress("");
    setSelectedPostalAddress("");
    setSelectedTown("");
    setSelectedStatus("Active");
    if (GridRef.current) {
      GridRef.current.clearSelection();
    }
    setNewStage("Identification");
    setNewProvince(accessProvince);
    setNewRegion(accessRegion);
    setNewProjectNum("4021/");
    setNewProjectName("");
    setNewSector("");
    setNewSubSector("");
    setNewAdministrator(accessAdministrator);
    setNewFacilitator(accessFacilitator);
    setNewIdentificationDate(newCapacityBuildingDate);
    setNewGeneralRemarks("");
    setNewType("New");
    setNewPhase("Phase 1: Coordinators");
    setNewVerificationDate(null);
    setNewCoordinator(accessCoordinator);
    setNewPhysicalAddress("");
    setNewPostalAddress("");
    setNewTown("");
    setNewStatus("Active");
    setIsDuplicate(false);
  };

  const handleAddProjectDetailsButton = async (newPhase) => {
    // Make a POST request to add the project record
    const loadingToastId = toast.loading("Please wait...");
    try {
      const ProjectResponse = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/project",
        projectNewData
      );
      console.log("Project response:", ProjectResponse.data);
      if (ProjectResponse.data.status === "success") {
        toast.success(`${newProjectName} was created successfully.`);
        // Get the maximum project ID by making a GET request
        const maxIDResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/project"
        );
        const projects = maxIDResponse.data.items;
        // Calculate the maximum ID
        const maxID = projects.reduce((max, project) => {
          return project.id > max ? project.id : max;
        }, 1);
        console.log("Maximum ID:", maxID);
        toast.info("Wait for attachments and tasks.");
        const updatedProjectNum = newProjectNum + maxID;
        const ProjectResponse2 = await axios.put(
          process.env.REACT_APP_SERVER_URL + `/api/project/${maxID}`,
          {
            ProjectNum: updatedProjectNum,
          }
        );
        console.log("Project response:", ProjectResponse2.data);
        // Iterate through attachmentsData and create attachments for the new project

        for (const attachment of attachmentsData) {
          // Create a new attachment object with ProjectNum set to newProjectNum
          const newAttachment = {
            ...attachment,
            ProjectNum: updatedProjectNum,
          };
          // Make a POST request to add the attachment
          const attachmentResponse = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/attachment",
            newAttachment
          );
          console.log("Attachment response:", attachmentResponse.data);
          toast.success(
            `${attachmentResponse.data.items.AttachName} attachment was created successfully.`
          );
        }

        //Add main project task
        const newMainTask = {
          TaskName: newProjectName,
          ProjectID: maxID,
          isExpand: true,
        };
        try {
          // Make a POST request to add the new task
          const mainTaskResponse = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/task",
            newMainTask
          );
          console.log("Main Task response:", mainTaskResponse.data);
          toast.success(
            `${newProjectName} main task was created successfully.`
          );
        } catch (error) {
          console.error("Error adding task:", error);
        }
        // Get the Main Task ID by making a GET request
        const mainTaskIDResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/task"
        );
        const mainTasks = mainTaskIDResponse.data.items;
        // Find the TaskID where TaskName matches newProjectName
        const matchingMainTask = mainTasks.find(
          (task) => task.TaskName === newProjectName
        );
        // Extract the TaskID from the matching task or set it to a default value (e.g., 1 if not found)
        const mainTaskID = matchingMainTask ? matchingMainTask.id : 1;
        console.log("Main Task ID:", mainTaskID);

        // Iterate through stageTaskData and create tasks for the new project
        for (const stageTask of stageTaskData) {
          // Create a new task
          const newStageTask = {
            ...stageTask,
            ProjectID: maxID, // Assuming maxID holds the maximum project ID
            ParentID: mainTaskID,
          };
          // Make a POST request to add the attachment
          const stageTaskResponse = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/task",
            newStageTask
          );
          console.log("Stage Task response:", stageTaskResponse.data);
          toast.success(
            `${stageTaskResponse.data.items.TaskName} task was created successfully.`
          );
        }
        // Get the Identification Task ID by making a GET request
        const stageTaskIDResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/task"
        );
        const stageTasks = stageTaskIDResponse.data.items;
        // Find the TaskID where TaskName matches newProjectName
        const matchingStageTask = stageTasks.find(
          (task) =>
            task.TaskName === "Identification Stage" && task.ProjectID === maxID
        );
        // Extract the TaskID from the matching task or set it to a default value (e.g., 1 if not found)
        const stageTaskID = matchingStageTask ? matchingStageTask.id : 1;
        console.log("Identification Task ID:", stageTaskID);

        // Iterate through identificationTaskData and create tasks for the new project
        for (const identificationTask of identificationTaskData) {
          // Create a new task object
          const newidentificationTask = {
            ...identificationTask,
            ProjectID: maxID,
            ParentID: stageTaskID,
          };
          // Make a POST request to add the task
          const identificationTaskResponse = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/task",
            newidentificationTask
          );
          console.log(
            "Identification Task response:",
            identificationTaskResponse.data
          );
          toast.success(
            `${identificationTaskResponse.data.items.TaskName} task was created successfully.`
          );
        }

        // Get the maximum project ID by making a GET request
        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/project"
        );
        const updatedProjects = updatedResponse.data.items;
        const updatedGridData = updatedProjects.filter(
          (project) =>
            project.Stage === "Identification" &&
            project.CurrentUser === user?.FullName
        );
        setGridData(updatedGridData);
      } else {
        console.error(
          "Error adding project record:",
          ProjectResponse.data.message
        );
        toast.error("Error adding the project");
      }
    } catch (error) {
      console.error("Error adding Project:", error);
      toast.error("Error adding the project");
    }
    toast.dismiss(loadingToastId);
  };

  const handleStageApprovalButton = async (selectedID, newPhase) => {
    // Make a POST request to add the project record
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    try {
      const ProjectResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/project/${numericSelectedID}`,
        {
          Stage: nextStage,
          CapacityDate: newCapacityBuildingDate,
          CurrentUser: receiver,
        }
      );
      console.log("Project response:", ProjectResponse.data);
      if (ProjectResponse.data.status === "success") {
        toast.success("Project stage has been changed successfully.");
        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/project"
        );
        const projects = updatedResponse.data.items;
        toast.info("Wait for beneficiaries and tasks.");
        // Iterate through beneficiaryData and create beneficiaries for the new project

        for (const beneficiary of beneficiaryData) {
          // Create a new beneficiary object
          const newBeneficiary = {
            ...beneficiary,
            ProjNum: selectedProjectNum,
          };
          // Make a POST request to add the beneficiary
          const newBeneficiaryResponse = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/beneficiary",
            newBeneficiary
          );
          console.log("Beneficiary response:", newBeneficiaryResponse.data);
          toast.success(
            `${newBeneficiaryResponse.data.items.FirstNames} beneficiary was created successfully.`
          );
        }

        // Get the Identification Task ID by making a GET request
        const stageTaskIDResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/task"
        );
        const stageTasks = stageTaskIDResponse.data.items;
        // Find the TaskID where TaskName matches newProjectName
        const matchingStageTask = stageTasks.find(
          (task) =>
            task.TaskName === "Capacity Building Stage" &&
            task.ProjectID === numericSelectedID
        );
        // Extract the TaskID from the matching task or set it to a default value (e.g., 1 if not found)
        const stageTaskID = matchingStageTask ? matchingStageTask.id : 1;
        console.log("Capacity Building Task ID:", stageTaskID);
        // Iterate through identificationTaskData and create tasks for the new project
        for (const capacityBuildingTask of capacityBuildingTaskData) {
          // Create a new task object
          const newcapacityBuildingTask = {
            ...capacityBuildingTask,
            ProjectID: numericSelectedID,
            ParentID: stageTaskID,
          };
          // Make a POST request to add the task
          const capacityBuildingTaskResponse = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/task",
            newcapacityBuildingTask
          );
          console.log(
            "Capacity Building Task response:",
            capacityBuildingTaskResponse.data
          );
          toast.success(
            `${capacityBuildingTaskResponse.data.items.TaskName} task was created successfully.`
          );
        }
        const updatedGridData = projects.filter(
          (project) =>
            project.Stage === currentStage &&
            project.CurrentUser === user?.FullName
        );
        setGridData(updatedGridData);
        const currentDateTime = new Date(); // Get the current date and time
        const workflowData = {
          ProjectID: numericSelectedID,
          ProjectStage: selectedStage,
          Sender: sender,
          Receiver: receiver,
          DateTime: currentDateTime,
          Notes: declaration,
        };
        // Make a POST request to add the workflow record
        const workflowResponse = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/workflow",
          workflowData
        );
        console.log("Workflow response:", workflowResponse.data);
        if (workflowResponse.data.status === "success") {
          toast.success("Workflow added successfully.");
          // Update the grid data
          try {
            const updatedResponse = await axios.get(
              process.env.REACT_APP_SERVER_URL + "/api/workflow"
            );
            const workflows = updatedResponse.data.items;
            setAllWorkflowData(workflows);
            const updatedGridData = workflows.filter(
              (workflow) => workflow.ProjectID === numericSelectedID
            );
            setUpdatedWorkflowData(updatedGridData);
          } catch (error) {
            console.error("Error fetching workflows:", error);
          }
        } else {
          console.error(
            "Error adding workflow record:",
            workflowResponse.data.message
          );
          toast.error("Error adding workflow.");
        }
      } else {
        console.error(
          "Error updating project record:",
          ProjectResponse.data.message
        );
        toast.error("Error updating project");
      }
    } catch (error) {
      console.error("Error updating Project:", error);
      toast.error("Error updating project");
    }
    toast.dismiss(loadingToastId);
  };

  const handleUpdateProjectDetailsButton = async (selectedID) => {
    // Send the updated data to the API for updating the records
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    try {
      const ProjectResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/project/${numericSelectedID}`,
        {
          Stage: newStage,
          Province: newProvince,
          Region: newRegion,
          ProjectNum: newProjectNum,
          ProjectName: newProjectName,
          Sector: newSector,
          SubSector: newSubSector,
          Administrator: newAdministrator,
          Facilitator: newFacilitator,
          IdentificationDate: newIdentificationDate,
          GeneralRemarks: newGeneralRemarks,
          Type: newType,
          Phase: newPhase,
          VerificationDate: newVerificationDate,
          Coordinator: newCoordinator,
          PhysicalAddress: newPhysicalAddress,
          PostalAddress: newPostalAddress,
          Town: newTown,
          ProjectStatus: newStatus,
        }
      );
      console.log("Project response:", ProjectResponse.data);
      if (ProjectResponse.data.status === "success") {
        toast.success(`${newProjectName} was updated successfully.`);
        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/project"
        );
        const projects = updatedResponse.data.items;
        const updatedGridData = projects.filter(
          (project) =>
            project.Stage === currentStage &&
            project.CurrentUser === user?.FullName
        );
        setGridData(updatedGridData);
      } else {
        console.error(
          "Error updating project record:",
          ProjectResponse.data.message
        );
        toast.error("Error updating the project");
      }
    } catch (error) {
      console.error("Error updating project:", error);
      toast.error("Error updating the project");
    }
    toast.dismiss(loadingToastId);
  };

  const statusTemplate = (props) => {
    const status = props.ProjectStatus; // Assuming the status field is named "Status"
    let colorClass = "";

    if (status === "Active") {
      colorClass = "status-active";
    } else if (status === "Non-active") {
      colorClass = "status-inactive";
    } else if (status === "Progress delayed") {
      colorClass = "status-delayed";
    }
    return <div className={`status-cell ${colorClass}`}>{status}</div>;
  };

  // Custom template for the "Attachments" column
  const attachmentTemplate = (props) => {
    const projectNum = props.ProjectNum;
    const allAttachments = attachmentData.filter(
      (attachment) =>
        attachment.ProjectNum === projectNum &&
        attachment.Stage === currentStage
    );
    const filteredAttachments = allAttachments.filter(
      (attachment) => attachment.AttachPath !== ""
    );
    if (filteredAttachments.length === allAttachments.length) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };
  // Custom template for the "tasks" column
  const taskTemplate = (props) => {
    const projectID = props.id;
    const parentIDs = taskData?.map((task) => task.ParentID);
    const allTasks = taskData.filter(
      (task) =>
        task.ProjectID === projectID &&
        (task.ProjectStage === currentStage || task.ProjectStage === "") &&
        !parentIDs.includes(task.id)
    );
    const filteredTasks = allTasks.filter((task) => task.Progress === 100);
    if (filteredTasks.length === allTasks.length) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  const dateFormatter = (field, data, column) => {
    const dateValue = getValue("DateTime", data); // Replace "DateFieldName" with your actual date field name
    // Format the date with the desired options
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(new Date(dateValue));
    return formattedDate;
  };

  let workflowButtons = [
    {
      buttonModel: {
        content: "Send",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleChangeCurrentUser(
          selectedID,
          selectedStage,
          sender,
          receiver,
          notes
        ); // Replace with your actual logic
        setIsDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsDialogVisible(false);
      },
    },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        if (CheckNameDuplicates(projectData, newProjectName, selectedID)) {
          console.log("Testing");
          setIsDuplicate(true);
        } else {
          handleUpdateProjectDetailsButton(selectedID);
          setIsEditDialogVisible(false);
        }
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  let addButtons = [
    {
      buttonModel: {
        content: "Add New",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        if (CheckNameDuplicates(projectData, newProjectName, selectedID)) {
          setIsDuplicate(true);
        } else {
          handleAddProjectDetailsButton();
          setIsAddDialogVisible(false);
        }
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsAddDialogVisible(false);
      },
    },
  ];

  let stageButtons = [
    {
      buttonModel: {
        content: "Approve",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleStageApprovalButton(
          selectedID,
          selectedStage,
          sender,
          receiver,
          declaration,
          nextStage,
          newCapacityBuildingDate
        ); // Replace with your actual logic
        setIsStageDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsStageDialogVisible(false);
      },
    },
  ];

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
    mode: "Dialog",
  };
  const toolbarOptions = ["ColumnChooser"];
  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          {user?.JobTitle === "Coordinator" && (
            <button className="add-button" onClick={handleAddButtonClick}>
              <AddIcon className="send-icon" /> Add New{" "}
            </button>
          )}
          <button
            className="edit-button"
            onClick={handleEditButtonClick}
            disabled={selectedID === ""}
          >
            <EditIcon className="send-icon" /> Edit{" "}
          </button>
        </div>
        <div className="button-container-subA2">
          {user?.JobTitle !== "Coordinator" && (
            <button
              className="coordinator-button"
              onClick={handleCoordinatorButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Coordinator{" "}
            </button>
          )}
          {user?.JobTitle !== "Administrator" && (
            <button
              className="administrator-button"
              onClick={handleAdministratorButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Administrator
            </button>
          )}
          {user?.JobTitle !== "Facilitator" && (
            <button
              className="facilitator-button"
              onClick={handleFacilitatorButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Facilitator
            </button>
          )}
          {user?.JobTitle !== "Assistant National Facilitator" && (
            <button
              className="assistant-nf-button"
              onClick={handleAssistantNFButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Assistant NF
            </button>
          )}
          {user?.JobTitle !== "National Facilitator" && (
            <button
              className="national-facilitator-button"
              onClick={handleNationalFacilitatorButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> National Facilitator
            </button>
          )}
        </div>
        <div className="button-container-subA3">
          {user?.JobTitle === "National Facilitator" && (
            <button
              className={`stage-approval-button ${
                isButtonEnabled ? "" : "disabled-button"
              }`}
              onClick={handleStageApprovalButtonClick}
              disabled={!isButtonEnabled}
            >
              <ThumbUpIcon className="send-icon" /> Stage Approval
            </button>
          )}
        </div>
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  return (
    <div className="main-ide-to-do-projects-containers">
      <div className="project-ide-to-do-projects-container">
        <div className="ide-to-do-projects">
          <h3>Projects on My To Do List</h3>
          <br />
          <div className="field-line-my-actions">
            {isDataLoaded && (
              <GridComponent
                dataSource={gridData}
                childMapping="items"
                ref={GridRef}
                classClass="custom-treegrid-class"
                height="550"
                width="100%"
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                showColumnChooser={true}
                filterSettings={FilterOptions}
                pageSettings={pageSettings}
                editSettings={editOptions}
                toolbar={updatedToolbarOptions}
                allowSelection={true}
                rowSelected={handleRowSelect}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="id"
                    headerText="ID"
                    width="100"
                    textAlign="Left"
                    isPrimaryKey={true}
                  />
                  <ColumnDirective
                    field="ProjectNum"
                    headerText="Project #"
                    width="120"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="ProjectName"
                    headerText="Project Name"
                    width="200"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    headerText="Tasks"
                    width="90"
                    textAlign="Left"
                    template={taskTemplate}
                  />
                  <ColumnDirective
                    headerText="Attach's"
                    width="90"
                    textAlign="Left"
                    template={attachmentTemplate}
                  />
                  <ColumnDirective
                    field="CreatedBy"
                    headerText="Created By"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Sector"
                    headerText="Sector"
                    width="120"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="SubSector"
                    headerText="Sub Sector"
                    width="120"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Type"
                    headerText="Type"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Phase"
                    headerText="Phase"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Stage"
                    headerText="Stage"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="ProjectStatus"
                    headerText="Status"
                    width="90"
                    textAlign="Left"
                    template={statusTemplate}
                  />
                  <ColumnDirective
                    field="Administrator"
                    headerText="Administrator"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Facilitator"
                    headerText="Facilitator"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Coordinator"
                    headerText="Coordinator"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Region"
                    headerText="Region"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Province"
                    headerText="Province"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="GeneralRemarks"
                    headerText="General Remarks"
                    width="350"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="IdentificationDate"
                    headerText="Identification Date"
                    width="220"
                    format="yyyy/MM/dd"
                    textAlign="Right"
                    type="date"
                  />
                  <ColumnDirective
                    field="VerificationDate"
                    headerText="Verification Date"
                    width="220"
                    format="yyyy/MM/dd"
                    textAlign="Right"
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Sort, Filter, Edit, Toolbar, ColumnChooser]}
                />
              </GridComponent>
            )}
          </div>
        </div>
      </div>
      <div className="workflow-history-ide-to-do-projects">
        <h3>{selectedProjectName}: Workflow History</h3>
        <br />
        <div className="field-line-my-actions">
          {isDataLoaded && (
            <GridComponent
              dataSource={updatedWorkflowData}
              childMapping="items"
              ref={GridRef}
              classClass="custom-treegrid-class"
              height="550"
              width="100%"
              allowPaging={true}
              allowSorting={true}
              allowFiltering={true}
              showColumnChooser={true}
              allowTextWrap={true}
              filterSettings={FilterOptions}
              pageSettings={pageSettings}
              editSettings={editOptions}
              toolbar={toolbarOptions}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="Sender"
                  headerText="From"
                  width="150"
                  textAlign="Left"
                  wrapText={true}
                />
                <ColumnDirective
                  field="Receiver"
                  headerText="To"
                  width="150"
                  textAlign="Left"
                  wrapText={true}
                />
                <ColumnDirective
                  field="Notes"
                  headerText="Note"
                  width="400"
                  textAlign="Left"
                  wrapText={true}
                />
                <ColumnDirective
                  field="DateTime"
                  headerText="When?"
                  width="130"
                  textAlign="Left"
                  Type="date"
                  valueAccessor={dateFormatter}
                />
                <ColumnDirective
                  field="ProjectStage"
                  headerText="Stage"
                  width="150"
                  textAlign="Left"
                  wrapText={true}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Sort, Filter, Edit, Toolbar, ColumnChooser]}
              />
            </GridComponent>
          )}
        </div>
      </div>
      <div>
        {isDialogVisible && (
          <DialogComponent
            visible={isDialogVisible}
            width="700px"
            height="650px"
            header="Workflow"
            showCloseIcon={true}
            allowDragging={true}
            close={handleDialogClose}
            buttons={workflowButtons}
          >
            <WorkflowDialog
              selectedID={selectedID}
              selectedStage={selectedStage}
              sender={sender}
              receiver={receiver}
              currentDateTime={currentDateTime}
              setNotes={setNotes}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isStageDialogVisible && (
          <DialogComponent
            visible={isStageDialogVisible}
            width="700px"
            height="650px"
            header="Stage Approval"
            showCloseIcon={true}
            allowDragging={true}
            close={handleStageDialogClose}
            buttons={stageButtons}
          >
            <StageApprovalDialog
              selectedID={selectedID}
              selectedStage={selectedStage}
              sender={sender}
              nextStage={nextStage}
              currentDateTime={currentDateTime}
              declaration={declaration}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="80%"
            height="90%"
            header="Edit Project Details"
            showCloseIcon={true}
            allowDragging={true}
            close={handleEditDialogClose}
            buttons={editButtons}
          >
            <DetailTemplate
              selectedID={selectedID}
              selectedProjectNum={selectedProjectNum}
              selectedProjectName={selectedProjectName}
              selectedSector={selectedSector}
              selectedSubSector={selectedSubSector}
              selectedAdministrator={selectedAdministrator}
              selectedCoordinator={selectedCoordinator}
              selectedFacilitator={selectedFacilitator}
              selectedRegion={selectedRegion}
              selectedProvince={selectedProvince}
              selectedIdentificationDate={selectedIdentificationDate}
              selectedPhase={selectedPhase}
              selectedPhysicalAddress={selectedPhysicalAddress}
              selectedPostalAddress={selectedPostalAddress}
              selectedStage={selectedStage}
              selectedGeneralRemarks={selectedGeneralRemarks}
              selectedTown={selectedTown}
              selectedType={selectedType}
              selectedVerificationDate={selectedVerificationDate}
              selectedStatus={selectedStatus}
              projectData={projectData}
              setNewProjectNum={setNewProjectNum}
              setNewProjectName={setNewProjectName}
              setNewSector={setNewSector}
              setNewSubSector={setNewSubSector}
              setNewAdministrator={setNewAdministrator}
              setNewCoordinator={setNewCoordinator}
              setNewFacilitator={setNewFacilitator}
              setNewRegion={setNewRegion}
              setNewProvince={setNewProvince}
              setNewIdentificationDate={setNewIdentificationDate}
              setNewPhase={setNewPhase}
              setNewPhysicalAddress={setNewPhysicalAddress}
              setNewPostalAddress={setNewPostalAddress}
              setNewStage={setNewStage}
              setNewGeneralRemarks={setNewGeneralRemarks}
              setNewTown={setNewTown}
              setNewType={setNewType}
              setNewVerificationDate={setNewVerificationDate}
              setNewStatus={setNewStatus}
              uniqueProvinces={uniqueProvinces}
              uniqueSubSectors={uniqueSubSectors}
              uniqueSectors={uniqueSectors}
              uniqueProjects={uniqueProjects}
              uniqueRegions={uniqueRegions}
              uniqueFacilitators={uniqueFacilitators}
              uniqueAdministrators={uniqueAdministrators}
              uniqueCoordinators={uniqueCoordinators}
              uniqueTowns={uniqueTowns}
              isDuplicate={isDuplicate}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isAddDialogVisible && (
          <DialogComponent
            visible={isAddDialogVisible}
            width="80%"
            height="90%"
            header="Add New Project Details"
            showCloseIcon={true}
            allowDragging={true}
            close={handleAddDialogClose}
            buttons={addButtons}
          >
            <DetailTemplate
              selectedID={selectedID}
              selectedProjectNum={selectedProjectNum}
              selectedProjectName={selectedProjectName}
              selectedSector={selectedSector}
              selectedSubSector={selectedSubSector}
              selectedAdministrator={selectedAdministrator}
              selectedCoordinator={selectedCoordinator}
              selectedFacilitator={selectedFacilitator}
              selectedRegion={selectedRegion}
              selectedProvince={selectedProvince}
              selectedIdentificationDate={selectedIdentificationDate}
              selectedPhase={selectedPhase}
              selectedPhysicalAddress={selectedPhysicalAddress}
              selectedPostalAddress={selectedPostalAddress}
              selectedStage={selectedStage}
              selectedGeneralRemarks={selectedGeneralRemarks}
              selectedTown={selectedTown}
              selectedType={selectedType}
              selectedVerificationDate={selectedVerificationDate}
              selectedStatus={selectedStatus}
              projectData={projectData}
              setNewProjectNum={setNewProjectNum}
              setNewProjectName={setNewProjectName}
              setNewSector={setNewSector}
              setNewSubSector={setNewSubSector}
              setNewAdministrator={setNewAdministrator}
              setNewCoordinator={setNewCoordinator}
              setNewFacilitator={setNewFacilitator}
              setNewRegion={setNewRegion}
              setNewProvince={setNewProvince}
              setNewIdentificationDate={setNewIdentificationDate}
              setNewPhase={setNewPhase}
              setNewPhysicalAddress={setNewPhysicalAddress}
              setNewPostalAddress={setNewPostalAddress}
              setNewStage={setNewStage}
              setNewGeneralRemarks={setNewGeneralRemarks}
              setNewTown={setNewTown}
              setNewType={setNewType}
              setNewVerificationDate={setNewVerificationDate}
              setNewStatus={setNewStatus}
              uniqueProvinces={uniqueProvinces}
              uniqueSubSectors={uniqueSubSectors}
              uniqueSectors={uniqueSectors}
              uniqueProjects={uniqueProjects}
              uniqueRegions={uniqueRegions}
              uniqueFacilitators={uniqueFacilitators}
              uniqueAdministrators={uniqueAdministrators}
              uniqueCoordinators={uniqueCoordinators}
              uniqueTowns={uniqueTowns}
              isDuplicate={isDuplicate}
            />
          </DialogComponent>
        )}
      </div>
    </div>
  );
}

export default ToDoProjects;
