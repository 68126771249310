import React from 'react';
import ProjectName from './changes/ProjectName';
import ProjectStatus from './changes/ProjectStatus';
import ProjectSector from './changes/ProjectSector';
import ProjectSubSector from './changes/ProjectSubSector';
import ProjectRegNumber from './changes/ProjectRegNumber';
import ProjectBank from './changes/ProjectBank';
import BeneficiaryBank from './changes/BeneficiaryBank';
import BeneficiaryStatus from './changes/BeneficiaryStatus';
import '../../../shared/PageTabs.css';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';

function ChangeManager() {
  let headerText = [{ text: "Project Name" }, 
  {text: "Project Status" }, 
  {text: "Project Sector" }, 
  {text: "Project Subsector" },
  { text: "Project CIPC Reg. Number" }, 
  { text: "Project Bank Account" }, 
  { text: "Beneficiary Status" },
  { text: "Beneficiary Bank Account" }];
  
  return (
    <div className='main-tab-container'>
      <TabComponent >
        <TabItemsDirective >
          <TabItemDirective header={headerText[0]} content= {ProjectName} />
          <TabItemDirective header={headerText[1]} content={ProjectStatus} />
          <TabItemDirective header={headerText[2]} content={ProjectSector} />
          <TabItemDirective header={headerText[3]} content={ProjectSubSector} />
          <TabItemDirective header={headerText[4]} content={ProjectRegNumber} />
          <TabItemDirective header={headerText[5]} content={ProjectBank} />
          <TabItemDirective header={headerText[6]} content={BeneficiaryStatus} />
          <TabItemDirective header={headerText[7]} content={BeneficiaryBank} />
        </TabItemsDirective>
      </TabComponent>
    </div>
    
  )
}

export default ChangeManager