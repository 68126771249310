import React from 'react'
import '../Sidebar.css'
import ProjectOption from './ProjectOption';
import ListIcon from '@mui/icons-material/List';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import IsoIcon from '@mui/icons-material/Iso';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { NavLink } from 'react-router-dom';

function Projects() {
  return (
    <div className='sidebar'>
        <NavLink to="dashboard">{<ProjectOption Icon = {AssessmentIcon} title="Dashboard"/>}</NavLink>
        <NavLink to="listview">{<ProjectOption Icon = {ListIcon} title="List of All Projects"/>}</NavLink>
        <NavLink to="changemanager">{<ProjectOption Icon = {IsoIcon } title= "Change Management"/>}</NavLink>
        <NavLink to="identification">{<ProjectOption Icon = {ShoppingCartIcon} title="Identification Workflows"/>}</NavLink>
        <NavLink to="capacitybuilding">{<ProjectOption Icon = {FitnessCenterIcon} title="Capacity Building Workflows"/>}</NavLink>
        <NavLink to="funding">{<ProjectOption Icon = {PointOfSaleIcon} title="Funding Workflows"/>}</NavLink>
        <NavLink to="monitoring">{<ProjectOption Icon = {MonitorHeartIcon} title="Monitoring Workflows"/>}</NavLink>   
    </div>
  )
}

export default Projects