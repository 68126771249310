import React, { useState} from "react";
import { TextBoxComponent, NumericTextBoxComponent  } from "@syncfusion/ej2-react-inputs";
import {
  ComboBoxComponent,
  AutoCompleteComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import "./ProjectDialogMain.css";
import "../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
//import "../../../../../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";
//import "../../../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";

function ProjectDialogMain({
  projectData,
  selectedID,
  selectedProjectNum,
  selectedProjectName,
  selectedSector,
  selectedSubSector,
  selectedAdministrator,
  selectedCoordinator,
  selectedFacilitator,
  selectedRegion,
  selectedProvince,
  selectedIdentificationDate,
  selectedPhase,
  selectedStage,
  selectedGeneralRemarks,
  selectedType,
  selectedVerificationDate,
  selectedStatus,
  selectedCapacityDate,
  selectedNormalBudget,
  selectedDiscretionaryRequired,
  selectedAmountRequired,
  selectedMotivationForDiscretionary,
  selectedFundingDate,
  selectedDateApproval,
  selectedMonitoringDate,
  setNewProjectNum,
  setNewProjectName,
  setNewSector,
  setNewSubSector,
  setNewAdministrator,
  setNewCoordinator,
  setNewFacilitator,
  setNewRegion,
  setNewProvince,
  setNewIdentificationDate,
  setNewPhase,
  setNewDateApproval,
  setNewStage,
  setNewFundingDate,
  setNewGeneralRemarks,
  setNewMonitoringDate,
  setNewType,
  setNewVerificationDate,
  setNewStatus,
  setNewCapacityDate,
  setNewNormalBudget,
  setNewDiscretionaryRequired,
  setNewAmountRequired,
  setNewMotivationForDiscretionary,
  uniqueProvinces,
  uniqueSubSectors,
  uniqueProjects,
  uniqueRegions,
  uniqueSectors,
  uniqueFacilitators,
  uniqueAdministrators,
  uniqueCoordinators,
}) {
  const usedCurrenncy ='R # ###.00'
  const [regions, setRegions] = useState(uniqueRegions);
  const [coordinators, setCoordinators] = useState(uniqueCoordinators);
  const [subSectors, setSubSectors] = useState(uniqueSubSectors);


  const handleProvinceChange = (e) => {
    if (e && e.value) {
      setNewProvince(e.value);
    }
    const selectProvince = e.value; // Use the selected value from the event
    // Perform filtering based on the selected ComboBox value
    const filteredData = projectData?.filter(
      (item) => item.Province === selectProvince
    );
    // Get unique Region
    const uniqueRegions = [
      ...new Set(filteredData?.map((item) => item.Region ? item.Region : "")),
    ];
    setRegions(uniqueRegions);
    // Get unique Coordinators
    const uniqueCoordinators = [
      ...new Set(filteredData?.map((item) => item.Coordinator ? item.Coordinator : "")),
    ];
    setCoordinators(uniqueCoordinators);
  };

  const handleRegionChange = (e) => {
    if (e && e.value) {
      setNewRegion(e.value);
    }
    const selectRegion = e.value; // Use the selected value from the event
    const filteredData = projectData?.filter(
      (item) => item.Region === selectRegion
    );
    // Get unique Coordinators
    const uniqueCoordinators = [
      ...new Set(filteredData?.map((item) => item.Coordinator ? item.Coordinator : "")),
    ];
    setCoordinators(uniqueCoordinators);
  };

  const handleFacilitatorChange = (e) => {
    if (e && e.value) {
      setNewFacilitator(e.value);
    }
  };
  const handleAdministratorChange = (e) => {
    if (e && e.value) {
      setNewAdministrator(e.value);
    }
  };
  const handleCoordinatorChange = (e) => {
    if (e && e.value) {
      setNewCoordinator(e.value);
    }
  };
  const handleIdentificationDateChange = (e) => {
    if (e && e.value) {
      setNewIdentificationDate(e.value);
    }
  };
  const handleVerificationDateChange = (e) => {
    if (e && e.value) {
      setNewVerificationDate(e.value);
    }
  };
  const handleStatusChange = (e) => {
    if (e && e.value) {
      setNewStatus(e.value);
    }
  };
  const handleSectorChange = (e) => {
    if (e && e.value) {
      setNewSector(e.value);
    }
    const selectSector = e.value; // Use the selected value from the event
    if (selectSector !== "") {
      const filteredData = projectData?.filter(
        (item) => item.Sector === selectSector
      );
      // Get unique sub sectors
      const uniqueSubSectors = [
        ...new Set(filteredData?.map((item) => item.SubSector ? item.SubSector : "")),
      ];
      setSubSectors(uniqueSubSectors);
    }
  };
  const handleSubSectorChange = (e) => {
    if (e && e.value) {
      setNewSubSector(e.value);
    }
  };
  const handleFundingDateChange = (e) => {
    if (e && e.value) {
      setNewFundingDate(e.value);
    }
  };
  const handleMonitoringDateChange = (e) => {
    if (e && e.value) {
      setNewMonitoringDate(e.value);
    }
  };
  const handleDateApprovalChange = (e) => {
    if (e && e.value) {
      setNewDateApproval(e.value);
    }
  };
  const handleGeneralRemarksChange = (e) => {
    if (e && e.value) {
      setNewGeneralRemarks(e.value);
    }
  };
  const handleProjectNameChange = (e) => {
    if (e && e.value) {
      setNewProjectName(e.value);
    }
  };
  const handleProjectNumChange = (e) => {
    if (e && e.value) {
      setNewProjectNum(e.value);
    }
  };
  const handleTypeChange = (e) => {
    if (e && e.value) {
      setNewType(e.value);
    }
  };
  const handlePhaseChange = (e) => {
    if (e && e.value) {
      setNewPhase(e.value);
    }
  };
  const handleStageChange = (e) => {
    if (e && e.value) {
      setNewStage(e.value);
    }
  };
  const handleCapacityDateChange = (e) => {
    if (e && e.value) {
      setNewCapacityDate(e.value);
    }
  };

  const handleNormalBudgetChange = (e) => {
    if (e && e.value) {
      setNewNormalBudget(e.value);
    }
  };
  const handleDiscretionaryRequiredChange = (e) => {
    if (e && e.value) {
      setNewDiscretionaryRequired(e.value);
    }
  };
  const handleAmountRequiredChange = (e) => {
    if (e && e.value) {
      setNewAmountRequired(e.value);
    }
  };
  const handleMotivationForDiscretionaryChange = (e) => {
    if (e && e.value) {
      setNewMotivationForDiscretionary(e.value);
    }
  };

  return (
    <div className="main-outer-container-detail1">
      <div className="main-inner-container-detail1">
        <div className="my-project-main">
          <div className="main-proj-name">
            <AutoCompleteComponent
              id="atcelement"
              value={selectedProjectName}
              placeholder=" Project Name"
              floatLabelType="Always"
              dataSource={uniqueProjects}
              change={handleProjectNameChange}
              //enabled={selectedProjectName ? false : true}
            />
          </div>
          <div className="main-proj-num">
            <TextBoxComponent
              value={selectedProjectNum}
              placeholder=" Project Number"
              floatLabelType="Always"
              change={handleProjectNumChange}
              //disabled={selectedID ? true : false}
            />
          </div>
          <div className="main-proj-type">
            <ComboBoxComponent
              id="comboelement"
              autofill={true}
              allowCustom={true}
              value={selectedType}
              placeholder=" Type"
              floatLabelType="Always"
              dataSource={["New", "Revived", "Legacy"]}
              change={handleTypeChange}
            />
          </div>
          <div className="main-proj-phase">
            <ComboBoxComponent
              id="comboelement"
              autofill={true}
              allowCustom={true}
              value={selectedPhase}
              placeholder=" Phase"
              floatLabelType="Always"
              dataSource={[
                "Phase 1: Coordinators",
                "Phase 2: Facilitators",
                "Phase 3: NF",
              ]}
              change={handlePhaseChange}
              enabled={selectedPhase ? false : true}
            />
          </div>
        </div>
        <div className="my-project-main">
          <div className="main-proj-stage">
            <ComboBoxComponent
              id="comboelement"
              autofill={true}
              allowCustom={true}
              value={selectedStage}
              placeholder=" Stage"
              floatLabelType="Always"
              dataSource={[
                "Identification",
                "Capacity Building",
                "Funding",
                "Monitoring",
              ]}
              change={handleStageChange}
              enabled={selectedStage ? false : true}
            />
          </div>
          <div className="main-proj-provice">
            <ComboBoxComponent
              id="comboelement"
              autofill={true}
              allowCustom={true}
              value={selectedProvince}
              placeholder=" Province"
              floatLabelType="Always"
              dataSource={uniqueProvinces}
              change={handleProvinceChange}
            />
          </div>
          <div className="main-proj-region">
            <ComboBoxComponent
              id="comboelement"
              autofill={true}
              allowCustom={true}
              value={selectedRegion}
              placeholder=" Region"
              floatLabelType="Always"
              dataSource={regions}
              change={handleRegionChange}
            />
          </div>
          <div className="main-proj-facilitator">
            <ComboBoxComponent
              id="comboelement"
              autofill={true}
              allowCustom={true}
              value={selectedFacilitator}
              placeholder="Facilitator"
              floatLabelType="Always"
              dataSource={uniqueFacilitators}
              change={handleFacilitatorChange}
            />
          </div>
        </div>
        <div className="my-project-main">
          <div className="main-proj-administrator">
            <ComboBoxComponent
              id="comboelement"
              autofill={true}
              allowCustom={true}
              value={selectedAdministrator}
              placeholder="Administrator"
              floatLabelType="Always"
              dataSource={uniqueAdministrators}
              change={handleAdministratorChange}
            />
          </div>
          <div className="main-proj-coordinator">
            <ComboBoxComponent
              id="comboelement"
              autofill={true}
              allowCustom={true}
              value={selectedCoordinator}
              placeholder=" Coordinator"
              floatLabelType="Always"
              dataSource={coordinators}
              change={handleCoordinatorChange}
            />
          </div>
          <div className="main-proj-status">
            <ComboBoxComponent
              id="comboelement"
              autofill={true}
              allowCustom={true}
              value={selectedStatus}
              placeholder=" Status"
              floatLabelType="Always"
              dataSource={[
                "Active",
                "Non-active",
                "Canceled",
                "On-hold",
                "Suspended",
                "Expelled",
              ]}
              change={handleStatusChange}
              //enabled={selectedStatus ? false : true}
            />
          </div>
          <div className="main-proj-sector">
            <ComboBoxComponent
              id="comboelement"
              autofill={true}
              allowCustom={true}
              value={selectedSector}
              placeholder=" Sector"
              floatLabelType="Always"
              dataSource={uniqueSectors}
              change={handleSectorChange}
              //enabled={selectedSector ? false : true}
            />
          </div>
          <div className="main-proj-subsector">
            <ComboBoxComponent
              id="comboelement"
              autofill={true}
              allowCustom={true}
              value={selectedSubSector}
              placeholder=" Sub Sector"
              floatLabelType="Always"
              dataSource={subSectors}
              change={handleSubSectorChange}
              //enabled={selectedSubSector ? false : true}
            />
          </div>
        </div>
        <div className="my-project-main">
          <div className="main-proj-comment">
            <TextBoxComponent
              value={selectedGeneralRemarks}
              multiline={true}
              placeholder=" General Comment"
              floatLabelType="Always"
              change={handleGeneralRemarksChange}
            />
          </div>
          <div className="main-proj-id">
            <TextBoxComponent
              value={selectedID}
              placeholder=" ID"
              floatLabelType="Always"
              disabled={true}
            />
          </div>
        </div>
        <div className="my-project-main">
          <div className="main-proj-normal_budget">
            <NumericTextBoxComponent 
              value={selectedNormalBudget}
              placeholder="Normal Budget"
              floatLabelType="Always"
              change={handleNormalBudgetChange}
              format={usedCurrenncy}
              showSpinButton={false}
            />
          </div>
          <div className="main-proj-dis_required">
            <ComboBoxComponent
              id="comboelement"
              autofill={true}
              allowCustom={true}
              value={selectedDiscretionaryRequired}
              placeholder="Discr. Required?"
              floatLabelType="Always"
              dataSource={["Yes", "No"]}
              change={handleDiscretionaryRequiredChange}
            />
          </div>
          <div className="main-proj-amount_required">
            <NumericTextBoxComponent 
              value={selectedAmountRequired}
              placeholder="Discretionary Amount"
              floatLabelType="Always"
              change={handleAmountRequiredChange}
              format={usedCurrenncy}
              showSpinButton={false}
            />
          </div>
          <div className="main-proj-dis_motivation">
            <TextBoxComponent
              value={selectedMotivationForDiscretionary}
              placeholder="Motivation For Discretionary"
              floatLabelType="Always"
              multiline={true}
              change={handleMotivationForDiscretionaryChange}
            />
          </div>
        </div>
        <div className="my-project-main">
          <div className="main-proj-identification_date">
            <DatePickerComponent
              id="datepicker"
              value={selectedIdentificationDate}
              placeholder=" Identification Date"
              floatLabelType="Always"
              format="dd-MMMM-yyyy"
              change={handleIdentificationDateChange}
            />
          </div>
          <div className="main-proj-verification_date">
            <DatePickerComponent
              id="datepicker"
              value={selectedVerificationDate}
              placeholder=" Verification Date"
              floatLabelType="Always"
              format="dd-MMMM-yyyy"
              change={handleVerificationDateChange}
            />
          </div>
          <div className="main-proj-capacity_date">
            <DatePickerComponent
              id="datepicker"
              value={selectedCapacityDate}
              placeholder="Capacity Building Date"
              floatLabelType="Always"
              format="dd-MMMM-yyyy"
              change={handleCapacityDateChange}
            />
          </div>
          <div className="main-proj-funding_date">
            <DatePickerComponent
              id="datepicker"
              value={selectedFundingDate}
              placeholder="Funding Date"
              floatLabelType="Always"
              format="dd-MMMM-yyyy"
              change={handleFundingDateChange}
            />
          </div>
          <div className="main-proj-monitoring_date">
            <DatePickerComponent
              id="datepicker"
              value={selectedMonitoringDate}
              placeholder="Monitoring Start Date"
              floatLabelType="Always"
              format="dd-MMMM-yyyy"
              change={handleMonitoringDateChange}
            />
          </div>
          <div className="main-proj-monitoring_date">
            <DatePickerComponent
              id="datepicker"
              value={selectedDateApproval}
              placeholder="Monitoring End Date"
              floatLabelType="Always"
              format="dd-MMMM-yyyy"
              change={handleDateApprovalChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProjectDialogMain;
