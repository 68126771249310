import React, { useState, useEffect } from "react";
import "./FinDashboard.css";
import useProjectDataFetch from "../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../shared/useOtherDataFetch"; // Import the custom hook
import useDateCalculator from "../../../shared/useDateCalculator";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  DataLabel,
  Category,
  Tooltip,

  ColumnSeries,
} from "@syncfusion/ej2-react-charts"; // Import Syncfusion chart components
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import useUserStore from "../../../../app/user";
import { toast } from "react-toastify";

function FinDashboard() {
  const user = useUserStore((state) => state.user);
  const dates = useDateCalculator();
  const {projectData, isProjectLoaded} = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { paymentData, isPaymentLoaded } = useOtherDataFetch();
  const [filteredProjectData, setfilteredProjectData] = useState(projectData); // Use useState for filtered data
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const handleComboBoxChange = (e) => {
    // Perform filtering based on the selected ComboBox value
    let filteredData = projectData;
    if (e.value === "Up to R350k Projects") {
      filteredData = projectData.filter((project) => {
        const projectNum = project.ProjectNum;
        // Calculate totalCost for the project
        const matchingPayments = paymentData.filter(
          (payment) => payment.ProjectNum === projectNum
        );
        const totalCost = matchingPayments.reduce(
          (total, payment) =>
            Number(total) +
            (Number(payment.DebitAmount) - Number(payment.CreditAmount || 0)),
          0
        );
        // Calculate totalBudget for the project
        const totalBudget = Number(project.NormalBudget) + Number(project.AmountRequired);
        // Apply the filter condition
        return totalBudget <= 350100 && totalCost <= 350100;
      });
    } else if (e.value === "Up to R650k Projects") {
      filteredData = projectData.filter((project) => {
        const projectNum = project.ProjectNum;
        // Calculate totalCost for the project
        const matchingPayments = paymentData.filter(
          (payment) => payment.ProjectNum === projectNum
        );
        const totalCost = matchingPayments.reduce(
          (total, payment) =>
            Number(total) +
            (Number(payment.DebitAmount) - Number(payment.CreditAmount || 0)),
          0
        );
        // Calculate totalBudget for the project
        const totalBudget = Number(project.NormalBudget) + Number(project.AmountRequired);
        // Apply the filter condition
        return (
          (totalBudget > 350100 && totalBudget <= 650100) ||
          (totalCost > 350100 && totalCost <= 650100 && totalBudget <= 650100)
        );
      });
    } else if (e.value === "Up to R1.2M Projects") {
      filteredData = projectData.filter((project) => {
        const projectNum = project.ProjectNum;
        // Calculate totalCost for the project
        const matchingPayments = paymentData.filter(
          (payment) => payment.ProjectNum === projectNum
        );
        const totalCost = matchingPayments.reduce(
          (total, payment) =>
            Number(total) +
            (Number(payment.DebitAmount) - Number(payment.CreditAmount || 0)),
          0
        );
        // Calculate totalBudget for the project
        const totalBudget = Number(project.NormalBudget) + Number(project.AmountRequired);
        // Apply the filter condition
        return (
          (totalBudget > 650100 && totalBudget <= 1200100) ||
          (totalCost > 650100 && totalCost <= 1200100 && totalBudget <= 1200100)
        );
      });
    } else if (e.value === "Above R1.2M Projects") {
      filteredData = projectData.filter((project) => {
        const projectNum = project.ProjectNum;
        // Calculate totalCost for the project
        const matchingPayments = paymentData.filter(
          (payment) => payment.ProjectNum === projectNum
        );
        const totalCost = matchingPayments.reduce(
          (total, payment) =>
            Number(total) +
            (Number(payment.DebitAmount) - Number(payment.CreditAmount || 0)),
          0
        );
        // Calculate totalBudget for the project
        const totalBudget = Number(project.NormalBudget) + Number(project.AmountRequired);
        // Apply the filter condition
        return totalBudget > 1200100 || totalCost > 1200100;
      });
    }
    setfilteredProjectData(filteredData); // Update state to trigger re-render
  };

  useEffect(() => {
    if (isProjectLoaded && isPaymentLoaded){
      setfilteredProjectData(projectData);
      setIsDataLoaded(true)
    }
  }, [projectData, isPaymentLoaded, isProjectLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  // Initialize provinceCounts as an empty object
  const provinceCounts = {};
  // Get unique provinces from filteredProjectData
  const uniqueProvinces = [
    ...new Set(filteredProjectData?.map((item) => item.Province)),
  ];
  // Calculate project counts for each province
  uniqueProvinces.forEach((province) => {
    const matchingProjects = filteredProjectData.filter(
      (item) => item.Province === province
    );
    provinceCounts[province] = matchingProjects.length; // Count projects for the current province
  });
  // Prepare data for the chart
  const provinceChartData = Object.keys(provinceCounts).map((province) => ({
    x: province,
    y: provinceCounts[province],
  }));

  // Initialize regionCounts as an empty object
  const regionCounts = {};
  // Get unique regions from filteredProjectData
  const uniqueRegions = [
    ...new Set(filteredProjectData?.map((item) => item.Region)),
  ];
  // Calculate project counts for each region
  uniqueRegions.forEach((region) => {
    const matchingProjects = filteredProjectData.filter(
      (item) => item.Region === region
    );
    regionCounts[region] = matchingProjects.length; // Count projects for the current region
  });
  // Prepare data for the chart
  const regionChartData = Object.keys(regionCounts).map((region) => ({
    x: region,
    y: regionCounts[region], // Total projects count for the current region
  }));

  // Initialize sectorCounts as an empty object
  const sectorCounts = {};
  // Get unique sectors from filteredProjectData
  const uniqueSectors = [
    ...new Set(filteredProjectData?.map((item) => item.Sector)),
  ];
  // Calculate project counts for each sector
  uniqueSectors.forEach((sector) => {
    const matchingProjects = filteredProjectData.filter(
      (item) => item.Sector === sector
    );
    sectorCounts[sector] = matchingProjects.length; // Count projects for the current sector
  });
  // Prepare data for the chart
  const sectorChartData = Object.keys(sectorCounts).map((sector) => ({
    x: sector,
    y: sectorCounts[sector], // Total projects count for the current sector
  }));

  // Count Beneficiaries for all projects
  const totalProjects = filteredProjectData.length;
  // Prepare data for the chart
  const projectChartData = [{ x: "National", y: totalProjects }];

  // Initialize subSectorCounts as an empty object
  const subSectorCounts = {};
  // Get unique subsectors from filteredProjectData
  const uniqueSubSectors = [
    ...new Set(filteredProjectData?.map((item) => item.SubSector)),
  ];
  // Calculate project counts for each subsector
  uniqueSubSectors.forEach((subsector) => {
    const matchingProjects = filteredProjectData.filter(
      (item) => item.SubSector === subsector
    );
    subSectorCounts[subsector] = matchingProjects.length; // Count projects for the current subsector
  });
  // Prepare data for the chart
  const subSectorChartData = Object.keys(subSectorCounts).map((subsector) => ({
    x: subsector,
    y: subSectorCounts[subsector], // Total projects count for the current subsector
  }));

  // Initialize projectStatusCounts as an empty object
  const projectStatusCounts = {};
  // Get unique ProjectStatus values from filteredProjectData
  const uniqueProjectStatuses = [
    ...new Set(filteredProjectData?.map((item) => item.ProjectStatus)),
  ];
  // Calculate projectStatusCounts for each ProjectStatus
  uniqueProjectStatuses.forEach((projectStatus) => {
    const matchingProjects = filteredProjectData.filter(
      (item) => item.ProjectStatus === projectStatus
    );
    // Count projects for the current ProjectStatus
    projectStatusCounts[projectStatus] = matchingProjects.length;
  });
  // Prepare data for the chart
  const statusChartData = Object.keys(projectStatusCounts).map(
    (projectStatus) => ({
      x: projectStatus,
      y: projectStatusCounts[projectStatus],
    })
  );

  const chartWidth = "100%"; // Set the desired chart width (e.g., "100%", "80px", etc.)
  const chartHeight = "80%"; // Set the desired chart width (e.g., "100%", "80px", etc.)
  const primaryxAxis = {
    valueType: "Category",
    labelRotation: -45,
    labelStyle: {
      color: "rgb(96,172,86)",
      fontWeight: "bold",
      size: "16px",
      fontFamily: "Segoe UI",
    },
  };
  const primaryyAxis = { visible: false };
  const marker = {
    dataLabel: {
      visible: true,
      font: {
        color: "rgb(96,172,86)",
        background: "white",
        fontWeight: "bold",
        size: "14px",
        fontFamily: "Segoe UI",
      },
    },
  };
  const tooltip = { enable: false, shared: false };
  // Define an array of varying colors for each series
  const varyingColors = [
    "red",
    "green",
    "rgb(96,172,86)",
    "#1565C0",
    "#FF5722",
    "#E91E63",
    "#9C27B0",
    "#009688",
    "#FF9800",
  ];

  return (
    <div className="reports-container">
      <div className="title-group">
        <ComboBoxComponent
          dataSource={[
            "All Projects",
            "Up to R350k Projects",
            "Up to R650k Projects",
            "Up to R1.2M Projects",
            "Above R1.2M Projects",
          ]}
          value="All Projects"
          change={handleComboBoxChange}
          placeholder="Make your selection"
          className="combo-box"
        />
      </div>
      {/*National Dashboard*/}
      {user?.Access === "National" && (
        <div className="national-reports-container1">
          <div className="chart-row">
            <div className="chart-container1">
              <h2>Count by National</h2>
              <ChartComponent
                id="national-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={projectChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[7]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container2">
              <h2>Female Count by Status</h2>
              <ChartComponent
                id="status-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={statusChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[8]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container3">
              <h2>Count by Province</h2>
              <ChartComponent
                id="province-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={provinceChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[6]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-row">
            <div className="chart-container4">
              <h2>Count by Sector</h2>
              <ChartComponent
                id="sector-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={sectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[5]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container5">
              <h2>Count by Sub-Sector</h2>
              <ChartComponent
                id="sub-sector-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={subSectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[4]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-row">
            <div className="chart-container6">
              <h2>Count by Region</h2>
              <ChartComponent
                id="region-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Region"
                    marker={marker}
                    fill={varyingColors[3]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      )}

      {/*Provincial Dashboard*/}
      {user?.Access === "Provincial" && (
        <div className="provincial-reports-container1">
          <div className="chart-row">
            <div className="chart-container7">
              <h2>Count by Province</h2>
              <ChartComponent
                id="province-chart"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={provinceChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[8]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container8">
              <h2>Count by Status</h2>
              <ChartComponent
                id="status-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={statusChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[7]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>

            <div className="chart-container9">
              <h2>Count by Region</h2>
              <ChartComponent
                id="region-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[6]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-row">
            <div className="chart-container10">
              <h2>Count by Sector</h2>
              <ChartComponent
                id="sector-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={sectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[5]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container11">
              <h2>Count by Sub-Sector</h2>
              <ChartComponent
                id="sub-sector-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={subSectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[4]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      )}

      {/*Regional Dashboard*/}
      {user?.Access === "Regional" && (
        <div className="regional-reports-container1">
          <div className="chart-row">
            <div className="chart-container12">
              <h2>Count by Region</h2>
              <ChartComponent
                id="region-chart2"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={regionChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Province"
                    marker={marker}
                    fill={varyingColors[8]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container13">
              <h2>Count by Status</h2>
              <ChartComponent
                id="status-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={statusChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[7]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
            <div className="chart-container14">
              <h2>Count by Sector</h2>
              <ChartComponent
                id="sector-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={sectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[6]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
          <div className="chart-row1">
            <div className="chart-container15">
              <h2>Count by Sub-Sector</h2>
              <ChartComponent
                id="sub-sector-chart1"
                width={chartWidth}
                height={chartHeight}
                primaryXAxis={primaryxAxis}
                primaryYAxis={primaryyAxis}
                tooltip={tooltip}>
                <Inject
                  services={[ColumnSeries, Tooltip, Category, DataLabel]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={subSectorChartData}
                    xName="x"
                    yName="y"
                    type="Column"
                    name="Status"
                    marker={marker}
                    fill={varyingColors[5]}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FinDashboard;
