import React from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import './WorkflowDialog.css'

function WorkflowDialog({ selectedID, selectedStage, sender, receiver, currentDateTime, setNotes}) {
  const handleNotesChange = (event) => {
    // Update the 'notes' state with the new value when the input changes
    setNotes(event.target.value);
  };

  return (
    <table className='main-container-dialog'>
      <tbody className='project-field-dialog'>
        <tr className='my-dialog-row1'>
          <td className='field_projectid'>
            <TextBoxComponent id='id' name='id' value={selectedID} placeholder=" Proj ID" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='field_projectstage'>
            <TextBoxComponent id='Stage' name='Stage' value={selectedStage} placeholder=" Proj Stage" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='field_datetime'>
            <DateTimePickerComponent  id='datetimepicker' format='yyyy-MM-dd hh:mm:ss a' value={currentDateTime} placeholder=" Date & Time" floatLabelType="Always" disabled={true}/>
          </td>
        </tr>
        <tr className='my-dialog-row2'>
          <td className='field_from'>
            <TextBoxComponent  id='from' name='from' value={sender} placeholder="From" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='field_to'>
            <TextBoxComponent  id='to' name='to' value={receiver} placeholder=" To" floatLabelType="Always" disabled={true}/>
          </td>
        </tr>
        <tr className='my-dialog-row3'>
          <td className='field_notes'>
            <TextBoxComponent  id='notes' name='notes' onChange={handleNotesChange} multiline={true} placeholder=" Notes" floatLabelType="Always" />
          </td>
        </tr>
      </tbody>
    </table>
    
  )
}

export default WorkflowDialog