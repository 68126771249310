import React from "react";
import { MaskedTextBoxComponent, TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  ComboBoxComponent,
  AutoCompleteComponent,
} from "@syncfusion/ej2-react-dropdowns";
import "./CompliancyDialogMain.css";

function CompliancyDialogMain({
  selectedID,
  selectedProjectNum,
  selectedProjectName,
  selectedGeneralComment,
  selectedPhysicalAddress,
  selectedPostalAddress,
  selectedTown,
  selectedStatus,
  selectedRegNum,
  selectedTaxNum,
  selectedVatNum,
  selectedRegStatus,
  selectedCompanyWebsite,
  selectedContactPerson,
  selectedEmailAddress,
  selectedTelephone,
  selectedActiveBankAccount,
  selectedAccountHolder,
  selectedAccountNum,
  selectedAccountType,
  selectedBankName,
  selectedBranchName,
  selectedBranchCode,
  setNewGeneralComment,
  setNewPhysicalAddress,
  setNewPostalAddress,
  setNewTown,
  setNewRegNum,
  setNewTaxNum,
  setNewVatNum,
  setNewRegStatus,
  setNewCompanyWebsite,
  setNewContactPerson,
  setNewEmailAddress,
  setNewTelephone,
  setNewActiveBankAccount,
  setNewAccountHolder,
  setNewAccountNum,
  setNewAccountType,
  setNewBankName,
  setNewBranchName,
  setNewBranchCode,
  uniqueTowns,
  uniqueBanks,
  uniqueBranches,
  uniqueTypes,
}) {
  
  const handlePhysicalAddressChange = (e) => {
    setNewPhysicalAddress(e.value);
  };
  const handlePostalAddressChange = (e) => {
    setNewPostalAddress(e.value);
  };
  const handleTownChange = (e) => {
    setNewTown(e.value);
  };
  const handleGeneralCommentChange = (e) => {
    setNewGeneralComment(e.value);
  };
  const handleRegNumChange = (e) => {
    setNewRegNum(e.value);
  };
  const handleRegStatusChange = (e) => {
    setNewRegStatus(e.value);
  };
  const handleTaxNumChange = (e) => {
    setNewTaxNum(e.value);
  };
  const handleVatNumChange = (e) => {
    setNewVatNum(e.value);
  };
  const handleContactPersonChange = (e) => {
    setNewContactPerson(e.value);
  };
  const handleTelephoneChange = (e) => {
    setNewTelephone(e.value);
  };
  const handleCompanyWebsiteChange = (e) => {
    setNewCompanyWebsite(e.value);
  };
  const handleEmailAddressChange = (e) => {
    setNewEmailAddress(e.value);
  };
  const handleActiveBankAccountChange = (e) => {
    setNewActiveBankAccount(e.value);
  };
  const handleAccountHolderChange = (e) => {
    setNewAccountHolder(e.value);
  };
  const handleAccountNumChange = (e) => {
    setNewAccountNum(e.value);
  };
  const handleAccountTypeChange = (e) => {
    setNewAccountType(e.value);
  };
  const handleBankNameChange = (e) => {
    setNewBankName(e.value);
  };
  const handleBranchNameChange = (e) => {
    setNewBranchName(e.value);
  };
  const handleBranchCodeChange = (e) => {
    setNewBranchCode(e.value);
  };
  
  return (
    <div className="compli-outer-container">
      <div className="compli-inner-container">
        <div className="compli-row1">
          <div className="compli-id">
            <TextBoxComponent
              id="id"
              value={selectedID}
              placeholder=" ID"
              floatLabelType="Always"
              disabled={true}
            />
          </div>
          <div className="compli-name">
            <TextBoxComponent
              value={selectedProjectName}
              placeholder=" Project Name"
              floatLabelType="Always"
              disabled={true}
            />
          </div>
          <div className="compli-num">
            <TextBoxComponent
              value={selectedProjectNum}
              placeholder=" Project Number"
              floatLabelType="Always"
              disabled={true}
            />
          </div>
          <div className="compli-status">
            <TextBoxComponent
              value={selectedStatus}
              placeholder=" Status"
              floatLabelType="Always"
              disabled={true}
            />
          </div>      
        </div>
        <div className="compli-row4">
          <div className="compli-reg_number">
            <TextBoxComponent
              value={selectedRegNum}
              placeholder=" CIPC Reg Number"
              floatLabelType="Always"
              change={handleRegNumChange}
              //disabled={selectedRegNum ? true : false}
            />
          </div>
          <div className="compli-reg_status">
            <ComboBoxComponent
              value={selectedRegStatus}
              placeholder="CIPC Reg Status"
              floatLabelType="Always"
              dataSource={[
                "In Business",
                "De-registering",
                "De-registered",
                "Not Registered",
              ]}
              change={handleRegStatusChange}  
            />
          </div>
          <div className="compli-tax_number">
            <TextBoxComponent
              value={selectedTaxNum}
              placeholder=" Tax Number"
              floatLabelType="Always"
              change={handleTaxNumChange}
            />
          </div>
          <div className="compli-vat_number">
            <TextBoxComponent
              value={selectedVatNum}
              placeholder="Vat Number"
              floatLabelType="Always"
              change={handleVatNumChange}
            />
          </div>
        </div>
        <div className="compli-row5">
          <div className="compli-contact_person">
            <TextBoxComponent
              value={selectedContactPerson}
              placeholder="Contact Person"
              floatLabelType="Always"
              change={handleContactPersonChange}
            />
          </div>
          <div className="compli-telephone">
            <MaskedTextBoxComponent
              value={selectedTelephone}
              placeholder="Telephone"
              floatLabelType="Always"
              mask="000 000 0000"
              change={handleTelephoneChange}
            />
          </div>
          <div className="compli-email_address1">
            <TextBoxComponent
              value={selectedEmailAddress}
              placeholder="Email Address"
              floatLabelType="Always"
              change={handleEmailAddressChange}
            />
          </div>
          <div className="compli-company_website">
            <TextBoxComponent
              value={selectedCompanyWebsite}
              placeholder="Company Website"
              floatLabelType="Always"
              change={handleCompanyWebsiteChange}
            />
          </div>
        </div>
        <div className="compli-row1">
          <div className="compli-active_bank">
            <ComboBoxComponent
              value={selectedActiveBankAccount}
              placeholder="Active Bank Account"
              floatLabelType="Always"
              dataSource={[
                "Yes",
                "No",
              ]}
              change={handleActiveBankAccountChange}
              //enabled={selectedActiveBankAccount ? false : true}
            />
          </div>
          <div className="compli-account_holder">
            <TextBoxComponent
              value={selectedAccountHolder}
              placeholder="Account Holder"
              floatLabelType="Always"
              change={handleAccountHolderChange}
              //disabled={selectedAccountHolder ? true : false}
            />
          </div>
          <div className="compli-bank_Name">
            <AutoCompleteComponent
              value={selectedBankName}
              placeholder="Bank Name"
              floatLabelType="Always"
              dataSource={uniqueBanks}
              change={handleBankNameChange}
              //enabled={selectedBankName ? false : true}
            />
          </div>
          <div className="compli-account_number">
            <TextBoxComponent
              value={selectedAccountNum}
              placeholder="Account Number"
              floatLabelType="Always"
              change={handleAccountNumChange}
              //disabled={selectedAccountNum ? true : false}
            />
          </div>
        </div>
        <div className="compli-row2">
          <div className="compli-account_type">
            <AutoCompleteComponent
              value={selectedAccountType}
              placeholder="Account Type"
              floatLabelType="Always"
              dataSource={uniqueTypes}
              change={handleAccountTypeChange}
              //enabled={selectedAccountType ? false : true}
            />
          </div>
          <div className="compli-branch_name">
            <AutoCompleteComponent
              value={selectedBranchName}
              placeholder="Branch Name"
              floatLabelType="Always"
              dataSource={uniqueBranches}
              change={handleBranchNameChange}
              //enabled={selectedBankName ? false : true}
            />
          </div>
          <div className="compli-branch_code">
            <TextBoxComponent
              value={selectedBranchCode}
              placeholder="Branch Code"
              floatLabelType="Always"
              change={handleBranchCodeChange}
              //disabled={selectedBranchCode ? true : false}
            />
          </div>
          <div className="compli-town">
            <AutoCompleteComponent
              value={selectedTown}
              placeholder="Town"
              floatLabelType="Always"
              dataSource={uniqueTowns}
              change={handleTownChange}
            />
          </div>
        </div>
        <div className="compli-row2">
          <div className="compli-physcal_address">
            <TextBoxComponent
              value={selectedPhysicalAddress}
              placeholder="Physcal Address"
              floatLabelType="Always"
              change={handlePhysicalAddressChange}
            />
          </div>
          <div className="compli-postal_address">
            <TextBoxComponent
              value={selectedPostalAddress}
              placeholder=" Postal Address"
              floatLabelType="Always"
              change={handlePostalAddressChange}
            />
          </div>
        </div>
        <div className="compli-row3">
          <div className="compli-general_comment">
            <TextBoxComponent
              value={selectedGeneralComment}
              multiline={true}
              placeholder=" General Comment"
              floatLabelType="Always"
              change={handleGeneralCommentChange}
            />
          </div> 
        </div>
      </div>
    </div>
  );
}

export default CompliancyDialogMain;
