import React, { useEffect, useState, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import { getValue } from "@syncfusion/ej2-base";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import "../../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import axios from "../../../../../config/axios";
import "./ToDoProjects.css";
import DetailTemplate from "./DetailTemplate";
import WorkflowDialog from "../../../../shared/WorkflowDialog";
import PhaseApprovalDialog from "../../../../shared/PhaseApprovalDialog";
import useProjectDataFetch from "../../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../../shared/useOtherDataFetch"; // Import the custom hook
import useWorkflowDataFetch from "../../../../shared/useWorkflowDataFetch";
import useUserDataFetch from "../../../../shared/useUserDataFetch";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import useUserStore from "../../../../../app/user";
import { toast } from "react-toastify";

function ToDoProjects() {
  const user = useUserStore((state) => state.user);
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const [gridData, setGridData] = useState([]);
  const {
    taskData,
    attachmentData,
    beneficiaryData,
    isAttachmentLoaded,
    isTaskLoaded,
    isBeneficiaryLoaded,
  } = useOtherDataFetch();
  const { allUserData, isAllUserLoaded } = useUserDataFetch();
  const { workflowData, isWorkflowLoaded } = useWorkflowDataFetch();
  const [updatedWorkflowData, setUpdatedWorkflowData] = useState([]);
  const [allWorkflowData, setAllWorkflowData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [nextPhase, setNextPhase] = useState("");
  const [nextPhaseType, setNextPhaseType] = useState("");
  const newPhaseIdentificationDate = new Date();
  const newVerificationStartDate = new Date(newPhaseIdentificationDate);
  newVerificationStartDate.setDate(newVerificationStartDate.getDate() + 42);
  const currentStage = "Monitoring";
  const nextStage = "Identification";

  useEffect(() => {
    if (
      isProjectLoaded &&
      isTaskLoaded &&
      isAttachmentLoaded &&
      isWorkflowLoaded &&
      isAllUserLoaded &&
      isBeneficiaryLoaded
    ) {
      setAllWorkflowData(workflowData);
      setUpdatedWorkflowData([]);
      const filteredProjectData = projectData.filter(
        (project) =>
          project.Stage === currentStage &&
          project.CurrentUser === user?.FullName
      );
      setGridData(filteredProjectData);
      setIsDataLoaded(true);
    }
  }, [
    projectData,
    workflowData,
    isProjectLoaded,
    isWorkflowLoaded,
    isTaskLoaded,
    isAttachmentLoaded,
    isAllUserLoaded,
    isBeneficiaryLoaded,
  ]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isStageDialogVisible, setIsStageDialogVisible] = useState(false);
  const [isFaButtonEnabled, setIsFaButtonEnabled] = useState(false); // Initial state
  const [isNfButtonEnabled, setIsNfButtonEnabled] = useState(false); // Initial state

  const [selectedID, setSelectedID] = useState("");
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedProjectNum, setSelectedProjectNum] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedSubSector, setSelectedSubSector] = useState("");
  const [selectedAdministrator, setSelectedAdministrator] = useState("");
  const [selectedFacilitator, setSelectedFacilitator] = useState("");
  const [selectedIdentificationDate, setSelectedIdentificationDate] =
    useState(null);
  const [selectedGeneralRemarks, setSelectedGeneralRemarks] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedPhase, setSelectedPhase] = useState("");
  const [selectedVerificationDate, setSelectedVerificationDate] =
    useState(null);
  const [selectedCoordinator, setSelectedCoordinator] = useState("");
  const [selectedPhysicalAddress, setSelectedPhysicalAddress] = useState("");
  const [selectedPostalAddress, setSelectedPostalAddress] = useState("");
  const [selectedTown, setSelectedTown] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedRegNum, setSelectedRegNum] = useState("");
  const [selectedTaxNum, setSelectedTaxNum] = useState("");
  const [selectedVatNum, setSelectedVatNum] = useState("");
  const [selectedRegBeneficiaries, setSelectedRegBeneficiaries] =
    useState(null);
  const [selectedRegStatus, setSelectedRegStatus] = useState("");
  const [selectedCompanyWebsite, setSelectedCompanyWebsite] = useState("");
  const [selectedContactPerson, setSelectedContactPerson] = useState("");
  const [selectedEmailAddress, setSelectedEmailAddress] = useState("");
  const [selectedTelephone, setSelectedTelephone] = useState("");
  const [selectedCapacityDate, setSelectedCapacityDate] = useState("");
  const [selectedNormalBudget, setSelectedNormalBudget] = useState("");
  const [selectedDiscretionaryRequired, setSelectedDiscretionaryRequired] =
    useState("");
  const [selectedAmountRequired, setSelectedAmountRequired] = useState("");
  const [
    selectedMotivationForDiscretionary,
    setSelectedMotivationForDiscretionary,
  ] = useState("");
  const [selectedFundingDate, setSelectedFundingDate] = useState("");
  const [selectedActiveBankAccount, setSelectedActiveBankAccount] =
    useState("");
  const [selectedAccountHolder, setSelectedAccountHolder] = useState("");
  const [selectedAccountNum, setSelectedAccountNum] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const [selectedBankName, setSelectedBankName] = useState("");
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [selectedBranchCode, setSelectedBranchCode] = useState("");
  const [selectedMonitoringDate, setSelectedMonitoringDate] = useState("");
  const [selectedDateApproval, setSelectedDateApproval] = useState("");

  const [newStage, setNewStage] = useState("");
  const [newProvince, setNewProvince] = useState();
  const [newRegion, setNewRegion] = useState();
  const [newProjectNum, setNewProjectNum] = useState("");
  const [newProjectName, setNewProjectName] = useState("");
  const [newSector, setNewSector] = useState("");
  const [newSubSector, setNewSubSector] = useState("");
  const [newAdministrator, setNewAdministrator] = useState("");
  const [newFacilitator, setNewFacilitator] = useState("");
  const [newIdentificationDate, setNewIdentificationDate] = useState(null);
  const [newGeneralRemarks, setNewGeneralRemarks] = useState("");
  const [newType, setNewType] = useState("");
  const [newPhase, setNewPhase] = useState("");
  const [newVerificationDate, setNewVerificationDate] = useState(null);
  const [newCoordinator, setNewCoordinator] = useState("");
  const [newPhysicalAddress, setNewPhysicalAddress] = useState("");
  const [newPostalAddress, setNewPostalAddress] = useState("");
  const [newTown, setNewTown] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [newRegNum, setNewRegNum] = useState("");
  const [newTaxNum, setNewTaxNum] = useState("");
  const [newVatNum, setNewVatNum] = useState("");
  const [newRegBeneficiaries, setNewRegBeneficiaries] = useState(null);
  const [newRegStatus, setNewRegStatus] = useState("");
  const [newCompanyWebsite, setNewCompanyWebsite] = useState("");
  const [newContactPerson, setNewContactPerson] = useState("");
  const [newEmailAddress, setNewEmailAddress] = useState("");
  const [newTelephone, setNewTelephone] = useState("");
  const [newCapacityDate, setNewCapacityDate] = useState("");
  const [newNormalBudget, setNewNormalBudget] = useState("");
  const [newDiscretionaryRequired, setNewDiscretionaryRequired] = useState("");
  const [newAmountRequired, setNewAmountRequired] = useState("");
  const [newMotivationForDiscretionary, setNewMotivationForDiscretionary] =
    useState("");
  const [newActiveBankAccount, setNewActiveBankAccount] = useState("");
  const [newAccountHolder, setNewAccountHolder] = useState("");
  const [newAccountNum, setNewAccountNum] = useState("");
  const [newAccountType, setNewAccountType] = useState("");
  const [newBankName, setNewBankName] = useState("");
  const [newBranchName, setNewBranchName] = useState("");
  const [newBranchCode, setNewBranchCode] = useState("");
  const [newDateApproval, setNewDateApproval] = useState("");

  // Get unique banks from projectData
  const uniqueBanks = [
    ...new Set(
      projectData?.map((item) => (item.BankName ? item.BankName : ""))
    ),
  ];
  // Get unique branches from projectData
  const uniqueBranches = [
    ...new Set(
      projectData?.map((item) => (item.BranchName ? item.BranchName : ""))
    ),
  ];
  // Get unique account types from projectData
  const uniqueTypes = [
    ...new Set(
      projectData?.map((item) => (item.AccountType ? item.AccountType : ""))
    ),
  ];

  const [receiver, setReceiver] = useState("");
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
  }
  const currentDateTime = formatDate(new Date());
  const newMonitoringDate = new Date();
  const declaration =
    "I have monitored the perfomance of this project. I have also consulted with all relavant stakeholders. All stakeholders are satisfied that this project can be identified for the next Phase.";
  const [notes, setNotes] = useState("");
  const sender = user?.FullName;
  const GridRef = useRef(null); // Create the treeGridRef reference

  const identificationTaskData = [
    {
      TaskName: "Collect Project Profile",
      ProjectStage: nextStage,
      StartDate: newPhaseIdentificationDate,
      Duration: 168,
      Progress: 0,
    },
    {
      TaskName: "Project Verification",
      ProjectStage: nextStage,
      StartDate: newVerificationStartDate,
      Duration: 5,
      Progress: 0,
    },
  ];

  const handleRowSelect = async (args) => {
    setSelectedID(args.data.id);
    setSelectedStage(args.data.Stage);
    setSelectedProvince(args.data.Province);
    setSelectedRegion(args.data.Region);
    setSelectedProjectNum(args.data.ProjectNum);
    setSelectedProjectName(args.data.ProjectName);
    setSelectedSector(args.data.Sector);
    setSelectedSubSector(args.data.SubSector);
    setSelectedAdministrator(args.data.Administrator);
    setSelectedFacilitator(args.data.Facilitator);
    setSelectedIdentificationDate(args.data.IdentificationDate);
    setSelectedGeneralRemarks(args.data.GeneralRemarks);
    setSelectedType(args.data.Type);
    setSelectedPhase(args.data.Phase);
    setSelectedVerificationDate(args.data.VerificationDate);
    setSelectedCoordinator(args.data.Coordinator);
    setSelectedPhysicalAddress(args.data.PhysicalAddress);
    setSelectedPostalAddress(args.data.PostalAddress);
    setSelectedTown(args.data.Town);
    setSelectedStatus(args.data.ProjectStatus);
    setSelectedRegNum(args.data.RegNum);
    setSelectedTaxNum(args.data.TaxNum);
    setSelectedVatNum(args.data.VatNum);
    setSelectedRegBeneficiaries(args.data.RegBeneficiaries);
    setSelectedRegStatus(args.data.RegStatus);
    setSelectedCompanyWebsite(args.data.CompanyWebsite);
    setSelectedContactPerson(args.data.ContactPerson);
    setSelectedEmailAddress(args.data.EmailAddress);
    setSelectedTelephone(args.data.Telephone);
    setSelectedCapacityDate(args.data.CapacityDate);
    setSelectedNormalBudget(args.data.NormalBudget);
    setSelectedDiscretionaryRequired(args.data.DiscretionaryRequired);
    setSelectedMotivationForDiscretionary(args.data.MotivationForDiscretionary);
    setSelectedAmountRequired(args.data.AmountRequired);
    setSelectedFundingDate(args.data.FundingDate);
    setSelectedDateApproval(args.data.DateApproval);
    setSelectedActiveBankAccount(args.data.ActiveBankAccount);
    setSelectedAccountHolder(args.data.AccountHolder);
    setSelectedAccountNum(args.data.AccountNum);
    setSelectedAccountType(args.data.AccountType);
    setSelectedBankName(args.data.BankName);
    setSelectedBranchName(args.data.BranchName);
    setSelectedBranchCode(args.data.BranchCode);
    setSelectedMonitoringDate(args.data.MonitoringDate);
    const currentPhase = args.data.Phase;
    const currentProjectStatus = args.data.ProjectStatus;

    if (currentPhase === "Phase 1: Coordinators") {
      setNextPhase("Phase 2: Facilitators");
    }
    if (currentPhase === "Phase 2: Facilitators") {
      setNextPhase("Phase 3: NF");
    }
    if (currentProjectStatus === "Active") {
      setNextPhaseType("Legacy");
    } else {
      setNextPhaseType("Revived");
    }

    const updatedGridData = allWorkflowData.filter(
      (workflow) => workflow.ProjectID === Number(args.data.id)
    );
    setUpdatedWorkflowData(updatedGridData);

    //check attachments
    const projectNum = args.data.ProjectNum;
    const allAttachments = attachmentData.filter(
      (attachment) =>
        attachment.ProjectNum === projectNum &&
        attachment.Stage === currentStage
    );
    const filteredAttachments = allAttachments.filter(
      (attachment) => attachment.AttachPath !== ""
    );
    // check Tasks
    const projectID = args.data.id;
    const parentIDs = taskData?.map((task) => task.ParentID);
    const allTasks = taskData.filter(
      (task) =>
        task.ProjectID === projectID &&
        (task.ProjectStage === currentStage || task.ProjectStage === "") &&
        !parentIDs.includes(task.id)
    );
    const filteredTasks = allTasks.filter((task) => task.Progress === 100);
    //check beneficiaries
    const allBeneficiaries = beneficiaryData.filter(
      (beneficiary) => beneficiary.ProjNum === projectNum
    );
    const filteredBeneficiaries = allBeneficiaries.filter(
      (beneficiary) =>
        beneficiary.BenStatus === "Active" &&
        beneficiary.FirstNames !== "" &&
        beneficiary.LastName !== "" &&
        beneficiary.ID_Num !== ""
    );

    // Detemine the F button state
    if (
      filteredAttachments.length === allAttachments.length &&
      filteredTasks.length === allTasks.length &&
      filteredBeneficiaries.length === allBeneficiaries.length &&
      currentPhase === "Phase 1: Coordinators"
    ) {
      setIsFaButtonEnabled(true);
    } else {
      setIsFaButtonEnabled(false);
    }
    // Detemine the NF button state
    if (
      filteredAttachments.length === allAttachments.length &&
      filteredTasks.length === allTasks.length &&
      filteredBeneficiaries.length === allBeneficiaries.length &&
      currentPhase === "Phase 2: Facilitators"
    ) {
      setIsNfButtonEnabled(true);
    } else {
      setIsNfButtonEnabled(false);
    }
  };

  const handleChangeCurrentUser = async (selectedID) => {
    const loadingToastId = toast.loading("Please wait...");
    try {
      const numericSelectedID = parseInt(selectedID);
      // Update the CurrentUser of the project
      const projectResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/project/${numericSelectedID}`,
        {
          CurrentUser: receiver,
        }
      );
      if (projectResponse.data.status === "success") {
        toast.success(`The message was sent to ${receiver} successfully.`);
        // Create a new workflow record
        const currentDateTime = new Date(); // Get the current date and time
        const workflowData = {
          ProjectID: numericSelectedID,
          ProjectStage: selectedStage,
          Sender: sender,
          Receiver: receiver,
          DateTime: currentDateTime,
          Notes: notes,
        };
        // Make a POST request to add the workflow record
        const workflowResponse = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/workflow",
          workflowData
        );
        console.log("Workflow response:", workflowResponse.data);
        if (workflowResponse.data.status === "success") {
          toast.success("Workflow record added successfully.");
          // Update the grid data
          const updatedGridData = gridData.filter(
            (project) => project.id !== numericSelectedID
          );
          setGridData(updatedGridData);
          try {
            const updatedResponse = await axios.get(
              process.env.REACT_APP_SERVER_URL + "/api/workflow"
            );
            const workflows = updatedResponse.data.items;
            setAllWorkflowData(workflows);
            const updatedGridData = workflows.filter(
              (workflow) => workflow.ProjectID === numericSelectedID
            );
            setUpdatedWorkflowData(updatedGridData);
          } catch (error) {
            console.error("Error fetching workflows:", error);
          }
        } else {
          console.error(
            "Error adding workflow record:",
            workflowResponse.data.message
          );
          toast.error("Error adding workflow");
        }
      } else {
        console.error(
          "Error updating CurrentUser:",
          projectResponse.data.message
        );
        toast.error("Error sending the message");
      }
    } catch (error) {
      console.error("Error updating CurrentUser:", error);
      toast.error("Error sending the message");
    }
    toast.dismiss(loadingToastId);
  };

  const handleCoordinatorButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const coordinator = allUserData?.filter(
      (user) =>
        user?.JobTitle === "Coordinator" &&
        user?.AccessRegion === selectedRegion
    );
    // Assuming you want to set the first user found as the receiver
    if (coordinator.length > 0) {
      setReceiver(coordinator[0]?.FullName);
    }
  };

  const handleAdministratorButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const administrator = allUserData?.filter(
      (user) =>
        user?.JobTitle === "Administrator" &&
        user?.AccessProvince === selectedProvince
    );
    // Assuming you want to set the first user found as the receiver
    if (administrator.length > 0) {
      setReceiver(administrator[0]?.FullName);
    }
  };

  const handleFacilitatorButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const facilitator = allUserData.filter(
      (user) =>
        user?.JobTitle === "Facilitator" &&
        user?.AccessProvince === selectedProvince
    );
    // Assuming you want to set the first user found as the receiver
    if (facilitator.length > 0) {
      setReceiver(facilitator[0]?.FullName);
    }
  };

  const handleAssistantNFButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const assistantnationalFacilitator = allUserData.filter(
      (user) => user?.JobTitle === "Assistant National Facilitator"
    );
    // Assuming you want to set the first user found as the receiver
    if (assistantnationalFacilitator.length > 0) {
      setReceiver(assistantnationalFacilitator[0]?.FullName);
    }
  };

  const handleNationalFacilitatorButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const nationalFacilitator = allUserData.filter(
      (user) => user?.JobTitle === "National Facilitator"
    );
    // Assuming you want to set the first user found as the receiver
    if (nationalFacilitator.length > 0) {
      setReceiver(nationalFacilitator[0]?.FullName);
    }
  };

  const handleAccountantButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const accountant = allUserData.filter(
      (user) => user?.JobTitle === "Accountant"
    );
    // Assuming you want to set the first user found as the receiver
    if (accountant.length > 0) {
      setReceiver(accountant[0]?.FullName);
    }
  };

  const handleFinancialAccountantButtonClick = async () => {
    setReceiver("");
    setIsDialogVisible(!isDialogVisible);
    const financialAccountant = allUserData.filter(
      (user) => user?.JobTitle === "Financial accountant"
    );
    // Assuming you want to set the first user found as the receiver
    if (financialAccountant.length > 0) {
      setReceiver(financialAccountant[0]?.FullName);
    }
  };

  const handleStageApprovalButtonClick = () => {
    const coordinator = allUserData?.filter(
      (user) =>
        user?.JobTitle === "Coordinator" &&
        user?.AccessRegion === selectedRegion
    );
    if (coordinator.length > 0) {
      setReceiver(coordinator[0]?.FullName);
    }
    //setReceiver(user?.FullName);
    setIsStageDialogVisible(!isStageDialogVisible);
  };

  const handleDialogClose = () => {
    setIsDialogVisible(false);
  };
  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };
  const handleStageDialogClose = () => {
    setIsStageDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsEditDialogVisible(!isEditDialogVisible);
    setNewStage(selectedStage);
    setNewProvince(selectedProvince);
    setNewRegion(selectedRegion);
    setNewProjectNum(selectedProjectNum);
    setNewProjectName(selectedProjectName);
    setNewSector(selectedSector);
    setNewSubSector(selectedSubSector);
    setNewAdministrator(selectedAdministrator);
    setNewFacilitator(selectedFacilitator);
    setNewIdentificationDate(selectedIdentificationDate);
    setNewGeneralRemarks(selectedGeneralRemarks);
    setNewType(selectedType);
    setNewPhase(selectedPhase);
    setNewVerificationDate(selectedVerificationDate);
    setNewCoordinator(selectedCoordinator);
    setNewPhysicalAddress(selectedPhysicalAddress);
    setNewPostalAddress(selectedPostalAddress);
    setNewTown(selectedTown);
    setNewStatus(selectedStatus);
    setNewRegNum(selectedRegNum);
    setNewTaxNum(selectedTaxNum);
    setNewVatNum(selectedVatNum);
    setNewRegBeneficiaries(selectedRegBeneficiaries);
    setNewRegStatus(selectedRegStatus);
    setNewCompanyWebsite(selectedCompanyWebsite);
    setNewContactPerson(selectedContactPerson);
    setNewEmailAddress(selectedEmailAddress);
    setNewTelephone(selectedTelephone);
    setNewCapacityDate(selectedCapacityDate);
    setNewNormalBudget(selectedNormalBudget);
    setNewDiscretionaryRequired(selectedDiscretionaryRequired);
    setNewMotivationForDiscretionary(selectedMotivationForDiscretionary);
    setNewAmountRequired(selectedAmountRequired);
    setNewDateApproval(selectedDateApproval);
    setNewActiveBankAccount(selectedActiveBankAccount);
    setNewAccountHolder(selectedAccountHolder);
    setNewAccountNum(selectedAccountNum);
    setNewAccountType(selectedAccountType);
    setNewBankName(selectedBankName);
    setNewBranchName(selectedBranchName);
    setNewBranchCode(selectedBranchCode);
  };

  const handleStageApprovalButton = async (selectedID) => {
    // Make a POST request to add the project record
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    try {
      const ProjectResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/project/${numericSelectedID}`,
        {
          Stage: nextStage,
          Phase: nextPhase,
          Type: nextPhaseType,
          CurrentUser: receiver,
          CreatedBy: receiver,
          IdentificationDate: newPhaseIdentificationDate,
          VerificationDate: null,
        }
      );
      console.log("Project response:", ProjectResponse.data);
      if (ProjectResponse.data.status === "success") {
        toast.success("Project phase has been changed successfully.");
        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/project"
        );
        const projects = updatedResponse.data.items;

        // Get the all Project Tasks ID by making a GET request
        const projectTasksResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/task"
        );
        const allTasks = projectTasksResponse.data.items;

        // Filter all tasks where Duration is greater than 0 and ProjectID matches
        const projectTasks = allTasks.filter(
          (task) => task.Duration > 0 && task.ProjectID === numericSelectedID
        );

        // Delete all stage tasks
        if (projectTasks.length > 0) {
          for (const projectTask of projectTasks) {
            // Get the Task ID from the task object
            const taskID = projectTask.id;
            // Make a DELETE request to delete the task
            try {
              const deleteResponse = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/api/task/${taskID}`
              );
              console.log("Delete response:", deleteResponse.data);
              toast.success(deleteResponse.data.message);
            } catch (error) {
              console.error("Error deleting record:", error);
              toast.error("Error deleting the record");
            }
          }
        }

        // Find the TaskID where TaskName matches Identification Stage
        const matchingStageTask = allTasks.find(
          (task) =>
            task.TaskName === "Identification Stage" &&
            task.ProjectID === numericSelectedID
        );
        // Extract the TaskID from the matching task or set it to a default value (e.g., 1 if not found)
        const stageTaskID = matchingStageTask ? matchingStageTask.id : 1;
        console.log("Identification Task ID:", stageTaskID);
        // Iterate through fundingTaskData and create tasks for the new project
        for (const identificationTask of identificationTaskData) {
          // Create a new task object
          const newIdentificationTask = {
            ...identificationTask,
            ProjectID: numericSelectedID,
            ParentID: stageTaskID,
          };
          // Make a POST request to add the task
          const IdentificationTaskResponse = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/task",
            newIdentificationTask
          );
          console.log(
            "Identification Task response:",
            IdentificationTaskResponse.data
          );
          toast.success(
            `${IdentificationTaskResponse.data.items.TaskName} task was created successfully.`
          );
        }
        const updatedGridData = projects.filter(
          (project) =>
            project.Stage === currentStage &&
            project.CurrentUser === user?.FullName
        );
        setGridData(updatedGridData);
        const currentDateTime = new Date();
        const workflowData = {
          ProjectID: numericSelectedID,
          ProjectStage: nextStage,
          Sender: sender,
          Receiver: receiver,
          DateTime: currentDateTime,
          Notes: declaration,
        };
        // Make a POST request to add the workflow record
        const workflowResponse = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/workflow",
          workflowData
        );
        console.log("Workflow response:", workflowResponse.data);
        if (workflowResponse.data.status === "success") {
          toast.success("Workflow added successfully.");
          // Update the grid data
          try {
            const updatedResponse = await axios.get(
              process.env.REACT_APP_SERVER_URL + "/api/workflow"
            );
            const workflows = updatedResponse.data.items;
            setAllWorkflowData(workflows);
            const updatedGridData = workflows.filter(
              (workflow) => workflow.ProjectID === numericSelectedID
            );
            setUpdatedWorkflowData(updatedGridData);
          } catch (error) {
            console.error("Error fetching workflows:", error);
          }
        } else {
          console.error(
            "Error adding workflow record:",
            workflowResponse.data.message
          );
          toast.error("Error adding workflow.");
        }
      } else {
        console.error(
          "Error updating project record:",
          ProjectResponse.data.message
        );
        toast.error("Error updating project");
      }
    } catch (error) {
      console.error("Error updating Project:", error);
      toast.error("Error updating project");
    }
    toast.dismiss(loadingToastId);
  };

  const handleUpdateProjectDetailsButton = async (selectedID) => {
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    try {
      const ProjectResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/project/${numericSelectedID}`,
        {
          Stage: newStage,
          Province: newProvince,
          Region: newRegion,
          ProjectNum: newProjectNum,
          ProjectName: newProjectName,
          Sector: newSector,
          SubSector: newSubSector,
          Administrator: newAdministrator,
          Facilitator: newFacilitator,
          IdentificationDate: newIdentificationDate,
          GeneralRemarks: newGeneralRemarks,
          Type: newType,
          Phase: newPhase,
          VerificationDate: newVerificationDate,
          Coordinator: newCoordinator,
          PhysicalAddress: newPhysicalAddress,
          PostalAddress: newPostalAddress,
          Town: newTown,
          ProjectStatus: newStatus,
          RegNum: newRegNum,
          TaxNum: newTaxNum,
          VatNum: newVatNum,
          RegBeneficiaries: newRegBeneficiaries,
          RegStatus: newRegStatus,
          CompanyWebsite: newCompanyWebsite,
          ContactPerson: newContactPerson,
          EmailAddress: newEmailAddress,
          Telephone: newTelephone,
          CapacityDate: newCapacityDate,
          NormalBudget: newNormalBudget,
          DiscretionaryRequired: newDiscretionaryRequired,
          AmountRequired: newAmountRequired,
          MotivationForDiscretionary: newMotivationForDiscretionary,
          DateApproval: newDateApproval,
          ActiveBankAccount: newActiveBankAccount,
          AccountHolder: newAccountHolder,
          AccountNum: newAccountNum,
          AccountType: newAccountType,
          BranchName: newBranchName,
          BranchCode: newBranchCode,
          MonitoringDate: newMonitoringDate,
          BankName: newBankName,
        }
      );
      console.log("Project response:", ProjectResponse.data);
      if (ProjectResponse.data.status === "success") {
        toast.success(`${newProjectName} was updated successfully.`);
        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/project"
        );
        const projects = updatedResponse.data.items;
        const updatedGridData = projects.filter(
          (project) =>
            project.Stage === currentStage &&
            project.CurrentUser === user?.FullName
        );
        setGridData(updatedGridData);
      } else {
        console.error(
          "Error updating project record:",
          ProjectResponse.data.message
        );
        toast.error("Error updating the project");
      }
    } catch (error) {
      console.error("Error updating project:", error);
      toast.error("Error updating the project");
    }
    toast.dismiss(loadingToastId);
  };

  const statusTemplate = (props) => {
    const status = props.ProjectStatus; // Assuming the status field is named "Status"
    let colorClass = "";

    if (status === "Active") {
      colorClass = "status-active";
    } else if (status === "Non-active") {
      colorClass = "status-inactive";
    } else if (status === "Progress delayed") {
      colorClass = "status-delayed";
    }
    return <div className={`status-cell ${colorClass}`}>{status}</div>;
  };

  // Custom template for the "Attachments" column
  const attachmentTemplate = (props) => {
    const projectNum = props.ProjectNum;
    const allAttachments = attachmentData.filter(
      (attachment) =>
        attachment.ProjectNum === projectNum &&
        attachment.Stage === currentStage
    );
    const filteredAttachments = allAttachments.filter(
      (attachment) => attachment.AttachPath !== ""
    );
    if (filteredAttachments.length === allAttachments.length) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  // Custom template for the "Tasks" column
  const taskTemplate = (props) => {
    const projectID = props.id;
    const parentIDs = taskData?.map((task) => task.ParentID);
    const allTasks = taskData.filter(
      (task) =>
        task.ProjectID === projectID &&
        (task.ProjectStage === currentStage || task.ProjectStage === "") &&
        !parentIDs.includes(task.id)
    );
    const filteredTasks = allTasks.filter((task) => task.Progress === 100);
    if (filteredTasks.length === allTasks.length) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  // Custom template for the "Beneficiaries" column
  const beneficiaryTemplate = (props) => {
    const projectNum = props.ProjectNum;
    const allBeneficiaries = beneficiaryData.filter(
      (beneficiary) => beneficiary.ProjNum === projectNum
    );
    const filteredBeneficiaries = allBeneficiaries.filter(
      (beneficiary) =>
        beneficiary.BenStatus === "Active" &&
        beneficiary.FirstNames !== "" &&
        beneficiary.LastName !== "" &&
        beneficiary.ID_Num !== ""
    );
    if (filteredBeneficiaries.length === allBeneficiaries.length) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  const dateFormatter = (field, data, column) => {
    const dateValue = getValue("DateTime", data); // Replace "DateFieldName" with your actual date field name
    // Format the date with the desired options
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(new Date(dateValue));
    return formattedDate;
  };

  let workflowButtons = [
    {
      buttonModel: {
        content: "Send",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleChangeCurrentUser(
          selectedID,
          selectedStage,
          sender,
          receiver,
          notes
        );
        setIsDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsDialogVisible(false);
      },
    },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleUpdateProjectDetailsButton(
          selectedID,
          newProjectNum,
          newProjectName,
          newSector,
          newSubSector,
          newAdministrator,
          newCoordinator,
          newFacilitator,
          newRegion,
          newProvince,
          newIdentificationDate,
          newPhase,
          newPhysicalAddress,
          newPostalAddress,
          newStage,
          newGeneralRemarks,
          newTown,
          newType,
          newVerificationDate,
          newStatus,
          newRegNum,
          newTaxNum,
          newVatNum,
          newRegBeneficiaries,
          newRegStatus,
          newCompanyWebsite,
          newContactPerson,
          newEmailAddress,
          newTelephone,
          newCapacityDate,
          newNormalBudget,
          newDiscretionaryRequired,
          newAmountRequired,
          newMotivationForDiscretionary,
          newDateApproval,
          newActiveBankAccount,
          newAccountHolder,
          newAccountNum,
          newAccountType,
          newBranchName,
          newBranchCode,
          newMonitoringDate,
          newBankName
        );
        setIsEditDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  let stageButtons = [
    {
      buttonModel: {
        content: "Identify",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleStageApprovalButton(
          selectedID,
          selectedStage,
          selectedPhase,
          declaration,
          nextStage,
          nextPhase
        );
        setIsStageDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsStageDialogVisible(false);
      },
    },
  ];

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
    mode: "Dialog",
  };
  const toolbarOptions = [""];
  const renderButtons = () => {
    return (
      <div className="button-container1">
        <div className="button-container-subA11">
          <button
            className="edit-button"
            onClick={handleEditButtonClick}
            disabled={selectedID === ""}
          >
            <EditIcon className="send-icon" /> Edit{" "}
          </button>
        </div>
        <div className="button-container-subA21">
          {user?.JobTitle !== "Coordinator" && (
            <button
              className="coordinator-button"
              onClick={handleCoordinatorButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Coordinator{" "}
            </button>
          )}
          {user?.JobTitle !== "Administrator" && (
            <button
              className="administrator-button"
              onClick={handleAdministratorButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Administrator
            </button>
          )}
          {user?.JobTitle !== "Facilitator" && (
            <button
              className="facilitator-button"
              onClick={handleFacilitatorButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Facilitator
            </button>
          )}
          {user?.JobTitle !== "Assistant National Facilitator" && (
            <button
              className="assistant-nf-button"
              onClick={handleAssistantNFButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Assistant NF
            </button>
          )}
          {user?.JobTitle !== "National Facilitator" && (
            <button
              className="national-facilitator-button"
              onClick={handleNationalFacilitatorButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> National Facilitator
            </button>
          )}
          {user?.JobTitle !== "Accountant" && (
            <button
              className="administrator-button"
              onClick={handleAccountantButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Accountant
            </button>
          )}
          {user?.JobTitle !== "Financial accountant" && (
            <button
              className="coordinator-button"
              onClick={handleFinancialAccountantButtonClick}
              disabled={selectedID === ""}
            >
              <SendIcon className="send-icon" /> Fin. Accountant
            </button>
          )}
        </div>
        <div className="button-container-subA31">
          {user?.JobTitle === "Facilitator" && (
            <button
              className={`stage-approval-button ${
                isFaButtonEnabled ? "" : "disabled-button"
              }`}
              onClick={handleStageApprovalButtonClick}
              disabled={!isFaButtonEnabled}
            >
              <ShoppingCartIcon className="send-icon" /> Identify the Project
            </button>
          )}
          {user?.JobTitle === "National Facilitator" && (
            <button
              className={`stage-approval-button ${
                isNfButtonEnabled ? "" : "disabled-button"
              }`}
              onClick={handleStageApprovalButtonClick}
              disabled={!isNfButtonEnabled}
            >
              <ShoppingCartIcon className="send-icon" /> Identify the Project
            </button>
          )}
        </div>
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  return (
    <div className="main-ide-to-do-projects-containers">
      <div className="project-ide-to-do-projects-container">
        <div className="ide-to-do-projects">
          <h3>Projects on My To Do List</h3>
          <br />
          <div className="field-line-my-actions">
            {isDataLoaded && (
              <GridComponent
                dataSource={gridData}
                childMapping="items"
                ref={GridRef}
                classClass="custom-treegrid-class"
                height="550"
                width="100%"
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                showColumnChooser={false}
                filterSettings={FilterOptions}
                pageSettings={pageSettings}
                editSettings={editOptions}
                toolbar={updatedToolbarOptions}
                allowSelection={true}
                rowSelected={handleRowSelect}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="id"
                    headerText="ID"
                    width="100"
                    textAlign="Left"
                    isPrimaryKey={true}
                  />
                  <ColumnDirective
                    field="ProjectNum"
                    headerText="Project #"
                    width="120"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="ProjectName"
                    headerText="Project Name"
                    width="200"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    headerText="Ben's"
                    width="90"
                    textAlign="Left"
                    template={beneficiaryTemplate}
                  />
                  <ColumnDirective
                    headerText="Tasks"
                    width="90"
                    textAlign="Left"
                    template={taskTemplate}
                  />
                  <ColumnDirective
                    headerText="Attach's"
                    width="90"
                    textAlign="Left"
                    template={attachmentTemplate}
                  />
                  <ColumnDirective
                    field="CreatedBy"
                    headerText="Created By"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Sector"
                    headerText="Sector"
                    width="120"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="SubSector"
                    headerText="Sub Sector"
                    width="120"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Type"
                    headerText="Type"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Phase"
                    headerText="Phase"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Stage"
                    headerText="Stage"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="ProjectStatus"
                    headerText="Status"
                    width="90"
                    textAlign="Left"
                    template={statusTemplate}
                  />
                  <ColumnDirective
                    field="Administrator"
                    headerText="Administrator"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Facilitator"
                    headerText="Facilitator"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Coordinator"
                    headerText="Coordinator"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Region"
                    headerText="Region"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Province"
                    headerText="Province"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="GeneralRemarks"
                    headerText="General Remarks"
                    width="350"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="IdentificationDate"
                    headerText="Identification Date"
                    width="220"
                    format="yyyy/MM/dd"
                    textAlign="Right"
                    type="date"
                  />
                  <ColumnDirective
                    field="VerificationDate"
                    headerText="Verification Date"
                    width="220"
                    format="yyyy/MM/dd"
                    textAlign="Right"
                  />
                </ColumnsDirective>
                <Inject services={[Page, Sort, Filter, Edit, Toolbar]} />
              </GridComponent>
            )}
          </div>
        </div>
      </div>
      <div className="workflow-history-ide-to-do-projects">
        <h3>{selectedProjectName}: Workflow History</h3>
        <br />
        <div className="field-line-my-actions">
          {isDataLoaded && (
            <GridComponent
              dataSource={updatedWorkflowData}
              childMapping="items"
              ref={GridRef}
              classClass="custom-treegrid-class"
              height="550"
              width="100%"
              allowPaging={true}
              allowSorting={true}
              allowFiltering={true}
              showColumnChooser={true}
              allowTextWrap={true}
              filterSettings={FilterOptions}
              pageSettings={pageSettings}
              editSettings={editOptions}
              toolbar={toolbarOptions}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="Sender"
                  headerText="From"
                  width="150"
                  textAlign="Left"
                  wrapText={true}
                />
                <ColumnDirective
                  field="Receiver"
                  headerText="To"
                  width="150"
                  textAlign="Left"
                  wrapText={true}
                />
                <ColumnDirective
                  field="Notes"
                  headerText="Note"
                  width="400"
                  textAlign="Left"
                  wrapText={true}
                />
                <ColumnDirective
                  field="DateTime"
                  headerText="When?"
                  width="130"
                  textAlign="Left"
                  valueAccessor={dateFormatter}
                  Type="date"
                />
                <ColumnDirective
                  field="ProjectStage"
                  headerText="Stage"
                  width="150"
                  textAlign="Left"
                  wrapText={true}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Sort, Filter, Edit, Toolbar, ColumnChooser]}
              />
            </GridComponent>
          )}
        </div>
      </div>
      <div>
        {isDialogVisible && (
          <DialogComponent
            visible={isDialogVisible}
            width="700px"
            height="650px"
            header="Workflow"
            showCloseIcon={true}
            allowDragging={true}
            close={handleDialogClose}
            buttons={workflowButtons}
          >
            <WorkflowDialog
              selectedID={selectedID}
              selectedStage={selectedStage}
              sender={sender}
              receiver={receiver}
              currentDateTime={currentDateTime}
              setNotes={setNotes}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isStageDialogVisible && (
          <DialogComponent
            visible={isStageDialogVisible}
            width="700px"
            height="650px"
            header="Identify Project for Next Phase"
            showCloseIcon={true}
            allowDragging={true}
            close={handleStageDialogClose}
            buttons={stageButtons}
          >
            <PhaseApprovalDialog
              selectedID={selectedID}
              selectedStage={selectedStage}
              selectedPhase={selectedPhase}
              nextPhase={nextPhase}
              nextStage={nextStage}
              declaration={declaration}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="80%"
            height="90%"
            header="Edit Project Details"
            showCloseIcon={true}
            allowDragging={true}
            close={handleEditDialogClose}
            buttons={editButtons}
          >
            <DetailTemplate
              selectedID={selectedID}
              selectedProjectNum={selectedProjectNum}
              selectedProjectName={selectedProjectName}
              selectedSector={selectedSector}
              selectedSubSector={selectedSubSector}
              selectedAdministrator={selectedAdministrator}
              selectedCoordinator={selectedCoordinator}
              selectedFacilitator={selectedFacilitator}
              selectedRegion={selectedRegion}
              selectedProvince={selectedProvince}
              selectedIdentificationDate={selectedIdentificationDate}
              selectedPhase={selectedPhase}
              selectedPhysicalAddress={selectedPhysicalAddress}
              selectedPostalAddress={selectedPostalAddress}
              selectedStage={selectedStage}
              selectedGeneralRemarks={selectedGeneralRemarks}
              selectedTown={selectedTown}
              selectedType={selectedType}
              selectedVerificationDate={selectedVerificationDate}
              selectedStatus={selectedStatus}
              selectedRegNum={selectedRegNum}
              selectedTaxNum={selectedTaxNum}
              selectedVatNum={selectedVatNum}
              selectedRegBeneficiaries={selectedRegBeneficiaries}
              selectedRegStatus={selectedRegStatus}
              selectedCompanyWebsite={selectedCompanyWebsite}
              selectedContactPerson={selectedContactPerson}
              selectedEmailAddress={selectedEmailAddress}
              selectedTelephone={selectedTelephone}
              selectedCapacityDate={selectedCapacityDate}
              selectedNormalBudget={selectedNormalBudget}
              selectedDiscretionaryRequired={selectedDiscretionaryRequired}
              selectedAmountRequired={selectedAmountRequired}
              selectedMotivationForDiscretionary={
                selectedMotivationForDiscretionary
              }
              selectedFundingDate={selectedFundingDate}
              selectedDateApproval={selectedDateApproval}
              selectedActiveBankAccount={selectedActiveBankAccount}
              selectedAccountHolder={selectedAccountHolder}
              selectedAccountNum={selectedAccountNum}
              selectedAccountType={selectedAccountType}
              selectedBankName={selectedBankName}
              selectedBranchName={selectedBranchName}
              selectedBranchCode={selectedBranchCode}
              selectedMonitoringDate={selectedMonitoringDate}
              setNewProjectNum={setNewProjectNum}
              setNewProjectName={setNewProjectName}
              setNewSector={setNewSector}
              setNewSubSector={setNewSubSector}
              setNewAdministrator={setNewAdministrator}
              setNewCoordinator={setNewCoordinator}
              setNewFacilitator={setNewFacilitator}
              setNewRegion={setNewRegion}
              setNewProvince={setNewProvince}
              setNewIdentificationDate={setNewIdentificationDate}
              setNewPhase={setNewPhase}
              setNewPhysicalAddress={setNewPhysicalAddress}
              setNewPostalAddress={setNewPostalAddress}
              setNewStage={setNewStage}
              setNewGeneralRemarks={setNewGeneralRemarks}
              setNewTown={setNewTown}
              setNewType={setNewType}
              setNewVerificationDate={setNewVerificationDate}
              setNewStatus={setNewStatus}
              setNewRegNum={setNewRegNum}
              setNewTaxNum={setNewTaxNum}
              setNewVatNum={setNewVatNum}
              setNewRegBeneficiaries={setNewRegBeneficiaries}
              setNewRegStatus={setNewRegStatus}
              setNewCompanyWebsite={setNewCompanyWebsite}
              setNewContactPerson={setNewContactPerson}
              setNewEmailAddress={setNewEmailAddress}
              setNewTelephone={setNewTelephone}
              setNewCapacityDate={setNewCapacityDate}
              setNewNormalBudget={setNewNormalBudget}
              setNewDiscretionaryRequired={setNewDiscretionaryRequired}
              setNewAmountRequired={setNewAmountRequired}
              setNewMotivationForDiscretionary={
                setNewMotivationForDiscretionary
              }
              setNewActiveBankAccount={setNewActiveBankAccount}
              setNewAccountHolder={setNewAccountHolder}
              setNewAccountNum={setNewAccountNum}
              setNewAccountType={setNewAccountType}
              setNewBankName={setNewBankName}
              setNewBranchName={setNewBranchName}
              setNewBranchCode={setNewBranchCode}
              uniqueBanks={uniqueBanks}
              uniqueBranches={uniqueBranches}
              uniqueTypes={uniqueTypes}
            />
          </DialogComponent>
        )}
      </div>
    </div>
  );
}

export default ToDoProjects;
