import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useUserChatsDataFetch = () => {
  const user = useUserStore((state) => state.user);
  const [userChatsData, setUserChatsData] = useState([]);
  const [isUserChatsLoaded, setIsUserChatsLoaded] = useState(false);

  // Define the fetchData function for users chat
  const fetchUserChatsData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + `/api/chat/unread`
      );
      setIsUserChatsLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching user chat data:", error);
      setIsUserChatsLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch chat data
    fetchUserChatsData().then((userChatInitialData) => {
      setUserChatsData(userChatInitialData);
    });
  }, []);

  // Return an object containing all the fetched data
  return {
    userChatsData,
    isUserChatsLoaded,
    setUserChatsData,
  };
};

export default useUserChatsDataFetch;
