import React, { useEffect, useState, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  PdfExport,
  ExcelExport,
  Reorder,
  Resize,
} from "@syncfusion/ej2-react-grids";
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import "../../../../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
import "../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
import useUserDataFetch from "../../../shared/useUserDataFetch";
import "./People.css";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CancelIcon from "@mui/icons-material/Cancel";
import useSmtsDataFetch from "../../../shared/useSmtsDataFetch"; // Import the custom hook
import { toast } from "react-toastify";
import useUserStore from "../../../../app/user";
import { dateFormatter } from "../../../../utils/formats";

function LastWeekPeople() {
  const user = useUserStore((state) => state.user);
  const { allUserData, isAllUserLoaded } = useUserDataFetch();
  const { smtsData, isSmtsLoaded } = useSmtsDataFetch();
  const [data, setData] = useState([]);
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (isSmtsLoaded && isAllUserLoaded) {
      const filteredUsers = allUserData.filter((user) => {
        const fullName = user.FullName;
        return (
          fullName !== "Vusi Shongwe" &&
          fullName !== "Fana Mkwanazi" &&
          fullName !== "Likhapha Mbatha"
        );
      });
      // Sort the filtered users first by AccessProvince and then by AccessRegion
      const sortedUsers = filteredUsers.sort((userA, userB) => {
        // Compare AccessProvince
        if (userA.AccessProvince < userB.AccessProvince) {
          return -1;
        } else if (userA.AccessProvince > userB.AccessProvince) {
          return 1;
        } else {
          // AccessProvinces are equal, so compare AccessRegion
          if (userA.AccessRegion < userB.AccessRegion) {
            return -1;
          } else if (userA.AccessRegion > userB.AccessRegion) {
            return 1;
          } else {
            return 0;
          }
        }
      });
      setData(sortedUsers);
      setIsDataLoaded(true);
    }
  }, [allUserData, isAllUserLoaded, isSmtsLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  const currentTitleDate = dateFormatter(new Date());
  const handleExcelButtonClick = () => {
    if (GridRef.current && GridRef.current.excelExport) {
      const exportProperties = {
        pageOrientation: "Landscape",
        fileName:
          "List Printed by " +
          user.FullName +
          " on " +
          currentTitleDate +
          ".xlsx",
      };
      GridRef.current.excelExport(exportProperties);
    }
  };

  const handlePdfButtonClick = () => {};

  const currentDate = new Date();
  const daysInWeek = 7; // A week has 7 days
  const beginningOfWeek = new Date(currentDate);
  beginningOfWeek.setDate(currentDate.getDate() - currentDate.getDay() - 6);

  const weekDates = [];

  for (let i = 0; i < daysInWeek; i++) {
    const currentDay = new Date(beginningOfWeek);
    currentDay.setDate(beginningOfWeek.getDate() + i);
    weekDates.push(currentDay);
  }

  const monday = weekDates[0]; // First day of the week
  const tuesday = weekDates[1]; // Second day of the week
  const wednesday = weekDates[2]; // Third day of the week
  const thursday = weekDates[3]; // Fourth day of the week
  const friday = weekDates[4]; // Fifth day of the week
  const saturday = weekDates[5]; // Sixth day of the week
  const sunday = weekDates[6]; // Seventh day of the week

  const options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const mondayFormatted = monday.toLocaleDateString("en-US", options);
  const tuesdayFormatted = tuesday.toLocaleDateString("en-US", options);
  const wednesdayFormatted = wednesday.toLocaleDateString("en-US", options);
  const thursdayFormatted = thursday.toLocaleDateString("en-US", options);
  const fridayFormatted = friday.toLocaleDateString("en-US", options);
  const saturdayFormatted = saturday.toLocaleDateString("en-US", options);
  const sundayFormatted = sunday.toLocaleDateString("en-US", options);

  const mondayTemplate = (props) => {
    const fullName = props.FullName;
    const selectedDate = new Date(monday);
    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth();
    const selectedDay = selectedDate.getDate();

    const regionData = smtsData.find((item) => {
      const itemDate = new Date(item.Date);
      const itemYear = itemDate.getFullYear();
      const itemMonth = itemDate.getMonth();
      const itemDay = itemDate.getDate();
      return (
        item.FullName === fullName &&
        itemYear === selectedYear &&
        itemMonth === selectedMonth &&
        itemDay === selectedDay
      );
    });

    if (regionData) {
      const region = regionData.Location;
      return <div>{region}</div>;
    } else {
      return (
        <div>
          <CancelIcon style={{ color: "red" }} />
        </div>
      );
    }
  };

  const tuesdayTemplate = (props) => {
    const fullName = props.FullName;
    const selectedDate = new Date(tuesday);
    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth();
    const selectedDay = selectedDate.getDate();

    const regionData = smtsData.find((item) => {
      const itemDate = new Date(item.Date);
      const itemYear = itemDate.getFullYear();
      const itemMonth = itemDate.getMonth();
      const itemDay = itemDate.getDate();
      return (
        item.FullName === fullName &&
        itemYear === selectedYear &&
        itemMonth === selectedMonth &&
        itemDay === selectedDay
      );
    });

    if (regionData) {
      const region = regionData.Location;
      return <div>{region}</div>;
    } else {
      return (
        <div>
          <CancelIcon style={{ color: "red" }} />
        </div>
      );
    }
  };

  const wednesdayTemplate = (props) => {
    const fullName = props.FullName;
    const selectedDate = new Date(wednesday);
    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth();
    const selectedDay = selectedDate.getDate();

    const regionData = smtsData.find((item) => {
      const itemDate = new Date(item.Date);
      const itemYear = itemDate.getFullYear();
      const itemMonth = itemDate.getMonth();
      const itemDay = itemDate.getDate();
      return (
        item.FullName === fullName &&
        itemYear === selectedYear &&
        itemMonth === selectedMonth &&
        itemDay === selectedDay
      );
    });
    if (regionData) {
      const region = regionData.Location;
      return <div>{region}</div>;
    } else {
      return (
        <div>
          <CancelIcon style={{ color: "red" }} />
        </div>
      );
    }
  };

  const thursdayTemplate = (props) => {
    const fullName = props.FullName;
    const selectedDate = new Date(thursday);
    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth();
    const selectedDay = selectedDate.getDate();

    const regionData = smtsData.find((item) => {
      const itemDate = new Date(item.Date);
      const itemYear = itemDate.getFullYear();
      const itemMonth = itemDate.getMonth();
      const itemDay = itemDate.getDate();
      return (
        item.FullName === fullName &&
        itemYear === selectedYear &&
        itemMonth === selectedMonth &&
        itemDay === selectedDay
      );
    });

    if (regionData) {
      const region = regionData.Location;
      return <div>{region}</div>;
    } else {
      return (
        <div>
          <CancelIcon style={{ color: "red" }} />
        </div>
      );
    }
  };

  const fridayTemplate = (props) => {
    const fullName = props.FullName;
    const selectedDate = new Date(friday);
    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth();
    const selectedDay = selectedDate.getDate();

    const regionData = smtsData.find((item) => {
      const itemDate = new Date(item.Date);
      const itemYear = itemDate.getFullYear();
      const itemMonth = itemDate.getMonth();
      const itemDay = itemDate.getDate();
      return (
        item.FullName === fullName &&
        itemYear === selectedYear &&
        itemMonth === selectedMonth &&
        itemDay === selectedDay
      );
    });

    if (regionData) {
      const region = regionData.Location;
      return <div>{region}</div>;
    } else {
      return (
        <div>
          <CancelIcon style={{ color: "red" }} />
        </div>
      );
    }
  };

  const saturdayTemplate = (props) => {
    const fullName = props.FullName;
    const selectedDate = new Date(saturday);
    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth();
    const selectedDay = selectedDate.getDate();

    const regionData = smtsData.find((item) => {
      const itemDate = new Date(item.Date);
      const itemYear = itemDate.getFullYear();
      const itemMonth = itemDate.getMonth();
      const itemDay = itemDate.getDate();
      return (
        item.FullName === fullName &&
        itemYear === selectedYear &&
        itemMonth === selectedMonth &&
        itemDay === selectedDay
      );
    });

    if (regionData) {
      const region = regionData.Location;
      return <div>{region}</div>;
    } else {
      return <div></div>;
    }
  };

  const sundayTemplate = (props) => {
    const fullName = props.FullName;
    const selectedDate = new Date(sunday);
    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth();
    const selectedDay = selectedDate.getDate();

    const regionData = smtsData.find((item) => {
      const itemDate = new Date(item.Date);
      const itemYear = itemDate.getFullYear();
      const itemMonth = itemDate.getMonth();
      const itemDay = itemDate.getDate();
      return (
        item.FullName === fullName &&
        itemYear === selectedYear &&
        itemMonth === selectedMonth &&
        itemDay === selectedDay
      );
    });

    if (regionData) {
      const region = regionData.Location;
      return <div>{region}</div>;
    } else {
      return <div></div>;
    }
  };

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];
  const loadingIndicator = { indicatorType: "Spinner" };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA2">
          <button className="add-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          {/* <button className="edit-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button> */}
        </div>
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  return (
    <div className="this-week-users">
      <h3>Where was Everyone Last Week?</h3>
      <br />
      <div className="feed-line-this-week-users">
        {data.length > 0 && (
          <GridComponent
            dataSource={data}
            childMapping="items"
            ref={GridRef}
            classClass="custom-treegrid-class"
            height="600"
            allowReordering={true}
            allowResizing={true}
            allowExcelExport={true}
            allowPdfExport={true}
            allowPaging={true}
            allowSorting={true}
            allowFiltering={true}
            showColumnChooser={true}
            filterSettings={FilterOptions}
            pageSettings={pageSettings}
            editSettings={editOptions}
            toolbar={updatedToolbarOptions}
            loadingIndicator={loadingIndicator}
            allowSelection={true}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="FullName"
                headerText="Full Name #"
                width="200"
                textAlign="Left"
              />
              <ColumnDirective
                field="JobTitle"
                headerText="Job Title"
                width="230"
                textAlign="Left"
              />
              <ColumnDirective
                field="AccessProvince"
                headerText="Province"
                width="160"
                textAlign="Left"
              />
              <ColumnDirective
                field="AccessRegion"
                headerText="Region"
                width="220"
                textAlign="Left"
              />
              <ColumnDirective
                headerText={mondayFormatted}
                width="180"
                textAlign="Left"
                template={mondayTemplate}
              />
              <ColumnDirective
                headerText={tuesdayFormatted}
                width="180"
                textAlign="Left"
                template={tuesdayTemplate}
              />
              <ColumnDirective
                headerText={wednesdayFormatted}
                width="180"
                textAlign="Left"
                template={wednesdayTemplate}
              />
              <ColumnDirective
                headerText={thursdayFormatted}
                width="180"
                textAlign="Left"
                template={thursdayTemplate}
              />
              <ColumnDirective
                headerText={fridayFormatted}
                width="180"
                textAlign="Left"
                template={fridayTemplate}
              />
              <ColumnDirective
                headerText={saturdayFormatted}
                width="180"
                textAlign="Left"
                template={saturdayTemplate}
              />
              <ColumnDirective
                headerText={sundayFormatted}
                width="180"
                textAlign="Left"
                template={sundayTemplate}
              />
            </ColumnsDirective>
            <Inject
              services={[
                Page,
                Sort,
                Filter,
                Edit,
                Toolbar,
                ColumnChooser,
                PdfExport,
                ExcelExport,
                Reorder,
                Resize,
              ]}
            />
          </GridComponent>
        )}
      </div>
    </div>
  );
}
export default LastWeekPeople;
