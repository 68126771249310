import React, { useEffect, useState, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  Reorder,
  Resize,
  PdfExport,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { getValue } from "@syncfusion/ej2-base";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import axios from "../../../../config/axios";
import "../../projects/children/ListView.css";
import ProjectDialogMain from "../../projects/children/ProjectDialogMain";
import useProjectDataFetch from "../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../shared/useOtherDataFetch"; // Import the custom hook
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import useUserStore from "../../../../app/user";
import { toast } from "react-toastify";
import { dateFormatter } from "../../../../utils/formats";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import ForumIcon from "@mui/icons-material/Forum";
import MessageBox from "../../messages/MessageBox";
import useUserDataFetch from "../../../shared/useUserDataFetch";
import socket from "../../../../utils/socket";
import {
  getCommonUsers,
  getProjectChatUsers,
  getUserDeleteRight,
  getUserLocation,
} from "../../../../utils";
import useUserChatsDataFetch from "../../../shared/useUserChatsDataFetch";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "90%",
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 2,
};

function FinPhase1() {
  const user = useUserStore((state) => state.user);
  const [gridData, setGridData] = useState([]);
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [userLocation, setUserLocation] = useState("");
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { paymentData, isPaymentLoaded } = useOtherDataFetch();
  const { userChatsData, isUserChatsLoaded, setUserChatsData } =
    useUserChatsDataFetch([]); // Use the custom hook to fetch data
  const { allUserData, isAllUserLoaded } = useUserDataFetch([]);
  const [commonUserData, setCommonUserData] = useState([]);
  const [selectedChatUsers, setSelectedChatUsers] = useState([]);
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isMessagesVisible, setIsMessagesVisible] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (isProjectLoaded && isPaymentLoaded) {
      const finalProjectData = projectData.filter(
        (project) => project.ProjectStatus !== "Deleted"
      );
      const filteredProjectData = finalProjectData.filter((project) => {
        const projectNum = project.ProjectNum;
        // Calculate totalCost for the project
        const matchingPayments = paymentData.filter(
          (payment) => payment.ProjectNum === projectNum
        );
        const totalCost = matchingPayments.reduce(
          (total, payment) =>
            Number(total) +
            (Number(payment.DebitAmount) - Number(payment.CreditAmount || 0)),
          0
        );
        // Calculate totalBudget for the project
        const totalBudget =
          Number(project.NormalBudget) + Number(project.AmountRequired);
        // Apply the filter condition: Both totalBudget and totalCost <= 350,000
        return totalBudget <= 350100 && totalCost <= 350100;
      });
      setGridData(filteredProjectData);

      const filteredUserData = getCommonUsers(allUserData);
      setCommonUserData(filteredUserData);

      setIsDataLoaded(true);
    }
  }, [
    projectData,
    isProjectLoaded,
    isPaymentLoaded,
    isAllUserLoaded,
    isUserChatsLoaded,
  ]);

  useEffect(() => {
    socket.on("notification", (data) => {
      console.log("Receive Notification: ", data);
      const isTrue = userChatsData.find((chat) => chat.ID === data.ID);
      if (!isTrue)
        setUserChatsData((prev) => (prev ? [...prev, data] : [data]));
    });
  }, []);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  useEffect(() => {
    const deleteRights = getUserDeleteRight(user);
    setIsButtonEnabled(deleteRights);
    const location = getUserLocation(user);
    setUserLocation(location);
  }, [user]);

  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // Initial state
  const [selectedID, setSelectedID] = useState("");
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedProjectNum, setSelectedProjectNum] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedSubSector, setSelectedSubSector] = useState("");
  const [selectedAdministrator, setSelectedAdministrator] = useState("");
  const [selectedFacilitator, setSelectedFacilitator] = useState("");
  const [selectedIdentificationDate, setSelectedIdentificationDate] =
    useState(null);
  const [selectedGeneralRemarks, setSelectedGeneralRemarks] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedPhase, setSelectedPhase] = useState("");
  const [selectedVerificationDate, setSelectedVerificationDate] =
    useState(null);
  const [selectedCoordinator, setSelectedCoordinator] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCapacityDate, setSelectedCapacityDate] = useState("");
  const [selectedNormalBudget, setSelectedNormalBudget] = useState("");
  const [selectedDiscretionaryRequired, setSelectedDiscretionaryRequired] =
    useState("");
  const [selectedAmountRequired, setSelectedAmountRequired] = useState("");
  const [
    selectedMotivationForDiscretionary,
    setSelectedMotivationForDiscretionary,
  ] = useState("");
  const [selectedFundingDate, setSelectedFundingDate] = useState("");
  const [selectedMonitoringDate, setSelectedMonitoringDate] = useState("");
  const [selectedDateApproval, setSelectedDateApproval] = useState("");

  const [newStage, setNewStage] = useState("");
  const [newProvince, setNewProvince] = useState();
  const [newRegion, setNewRegion] = useState();
  const [newProjectNum, setNewProjectNum] = useState("");
  const [newProjectName, setNewProjectName] = useState("");
  const [newSector, setNewSector] = useState("");
  const [newSubSector, setNewSubSector] = useState("");
  const [newAdministrator, setNewAdministrator] = useState("");
  const [newFacilitator, setNewFacilitator] = useState("");
  const [newIdentificationDate, setNewIdentificationDate] = useState(null);
  const [newGeneralRemarks, setNewGeneralRemarks] = useState("");
  const [newType, setNewType] = useState("");
  const [newPhase, setNewPhase] = useState("");
  const [newVerificationDate, setNewVerificationDate] = useState(null);
  const [newCoordinator, setNewCoordinator] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [newCapacityDate, setNewCapacityDate] = useState("");
  const [newNormalBudget, setNewNormalBudget] = useState("");
  const [newDiscretionaryRequired, setNewDiscretionaryRequired] = useState("");
  const [newAmountRequired, setNewAmountRequired] = useState("");
  const [newMotivationForDiscretionary, setNewMotivationForDiscretionary] =
    useState("");
  const [newDateApproval, setNewDateApproval] = useState("");
  const [newFundingDate, setNewFundingDate] = useState("");
  const [newMonitoringDate, setNewMonitoringDate] = useState("");

  const [provinceProjectData, setProvinceProjectData] = useState([]); // Use useState for filtered data
  const [regionProjectData, setRegionProjectData] = useState([]); // Use useState for filtered data
  const [subSectorProjectData, setSubSectorProjectData] = useState([]); // Use useState for filtered data

  // Get unique provinces from projectData
  const uniqueProvinces = [
    ...new Set(projectData?.map((item) => item.Province)),
  ];

  // Get unique subsectors from subSectorProjectData
  const uniqueSubSectors = [
    ...new Set(subSectorProjectData?.map((item) => item.SubSector)),
  ];
  // Get unique provinces from projectData
  const uniqueProjects = [
    ...new Set(
      projectData?.map((item) => (item.ProjectName ? item.ProjectName : ""))
    ),
  ];
  // Get unique RegionS from provinceProjectData
  const uniqueRegions = [
    ...new Set(provinceProjectData?.map((item) => item.Region)),
  ];
  // Get unique FacilitatorS from provinceProjectData
  const uniqueFacilitators = [
    ...new Set(provinceProjectData?.map((item) => item.Facilitator)),
  ];
  // Get unique AdministratorS from provinceProjectData
  const uniqueAdministrators = [
    ...new Set(provinceProjectData?.map((item) => item.Administrator)),
  ];
  // Get unique AdministratorS from regionProjectData
  const uniqueCoordinators = [
    ...new Set(regionProjectData?.map((item) => item.Coordinator)),
  ];

  const handleEditButtonClick = () => {
    setIsEditDialogVisible(!isEditDialogVisible);
    setNewStage(selectedStage);
    setNewProvince(selectedProvince);
    setNewRegion(selectedRegion);
    setNewProjectNum(selectedProjectNum);
    setNewProjectName(selectedProjectName);
    setNewSector(selectedSector);
    setNewSubSector(selectedSubSector);
    setNewAdministrator(selectedAdministrator);
    setNewFacilitator(selectedFacilitator);
    setNewIdentificationDate(selectedIdentificationDate);
    setNewGeneralRemarks(selectedGeneralRemarks);
    setNewType(selectedType);
    setNewPhase(selectedPhase);
    setNewVerificationDate(selectedVerificationDate);
    setNewCoordinator(selectedCoordinator);
    setNewStatus(selectedStatus);
    setNewCapacityDate(selectedCapacityDate);
    setNewNormalBudget(selectedNormalBudget);
    setNewDiscretionaryRequired(selectedDiscretionaryRequired);
    setNewAmountRequired(selectedAmountRequired);
    setNewMotivationForDiscretionary(selectedMotivationForDiscretionary);
    setNewDateApproval(selectedDateApproval);
    setNewFundingDate(selectedFundingDate);
    setNewMonitoringDate(selectedMonitoringDate);
  };

  const handleDeleteButtonClick = () => {};

  const currentTitleDate = dateFormatter(new Date());
  const handleExcelButtonClick = () => {
    if (GridRef.current && GridRef.current.excelExport) {
      const exportProperties = {
        pageOrientation: "Landscape",
        fileName:
          "List Printed by " +
          user.FullName +
          " on " +
          currentTitleDate +
          ".xlsx",
      };
      GridRef.current.excelExport(exportProperties);
    }
  };

  const handlePdfButtonClick = () => {};

  const handleRowSelect = async (args) => {
    setSelectedID(args.data.id);
    setSelectedStage(args.data.Stage);
    setSelectedProvince(args.data.Province);
    setSelectedRegion(args.data.Region);
    setSelectedProjectNum(args.data.ProjectNum);
    setSelectedProjectName(args.data.ProjectName);
    setSelectedSector(args.data.Sector);
    setSelectedSubSector(args.data.SubSector);
    setSelectedAdministrator(args.data.Administrator);
    setSelectedFacilitator(args.data.Facilitator);
    setSelectedIdentificationDate(args.data.IdentificationDate);
    setSelectedGeneralRemarks(args.data.GeneralRemarks);
    setSelectedType(args.data.Type);
    setSelectedPhase(args.data.Phase);
    setSelectedVerificationDate(args.data.VerificationDate);
    setSelectedCoordinator(args.data.Coordinator);
    setSelectedStatus(args.data.ProjectStatus);
    setSelectedCapacityDate(args.data.CapacityDate);
    setSelectedNormalBudget(args.data.NormalBudget);
    setSelectedDiscretionaryRequired(args.data.DiscretionaryRequired);
    setSelectedMotivationForDiscretionary(args.data.MotivationForDiscretionary);
    setSelectedAmountRequired(args.data.AmountRequired);
    setSelectedFundingDate(args.data.FundingDate);
    setSelectedMonitoringDate(args.data.MonitoringDate);
    setSelectedDateApproval(args.data.DateApproval);
    if (args.data.ProjectNum) {
      const chatUsers = getProjectChatUsers(
        args.data.Province,
        args.data.Region,
        allUserData,
        commonUserData,
        user
      );
      setSelectedChatUsers(chatUsers);
    } else {
      setSelectedChatUsers("");
    }
  };

  const handleUpdateProjectDetailsButton = async (selectedID) => {
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    try {
      const ProjectResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/project/${numericSelectedID}`,
        {
          Stage: newStage,
          Province: newProvince,
          Region: newRegion,
          ProjectNum: newProjectNum,
          ProjectName: newProjectName,
          Sector: newSector,
          SubSector: newSubSector,
          Administrator: newAdministrator,
          Facilitator: newFacilitator,
          IdentificationDate: newIdentificationDate,
          GeneralRemarks: newGeneralRemarks,
          Type: newType,
          Phase: newPhase,
          VerificationDate: newVerificationDate,
          Coordinator: newCoordinator,
          ProjectStatus: newStatus,
          CapacityDate: newCapacityDate,
          NormalBudget: newNormalBudget,
          DiscretionaryRequired: newDiscretionaryRequired,
          AmountRequired: newAmountRequired,
          MotivationForDiscretionary: newMotivationForDiscretionary,
          DateApproval: newDateApproval,
          FundingDate: newFundingDate,
          MonitoringDate: newMonitoringDate,
          LastUpdatedBy: user?.FullName,
          LastUpdated: new Date(),
        }
      );
      console.log("Project response:", ProjectResponse.data);
      if (ProjectResponse.data.status === "success") {
        toast.success(`${newProjectName} was updated successfully.`);
        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/project"
        );
        const projects = updatedResponse.data.items;
        const updatedGridData = projects.filter((project) => {
          return gridData.some((item) => item.id === project.id);
        });
        setGridData(updatedGridData);
      } else {
        console.error(
          "Error updating project record:",
          ProjectResponse.data.message
        );
        toast.error("Error updating the project");
      }
    } catch (error) {
      console.error("Error updating project:", error);
      toast.error("Error updating the project");
    }
    toast.dismiss(loadingToastId);
  };

  const totalBudgetTemplate = (props) => {
    const normalBudget = props.NormalBudget; // Assuming the field name is "NormalBudget"
    const amountRequired = props.AmountRequired; // Assuming the field name is "AmountRequired"

    const totalBudget = Number(normalBudget) + Number(amountRequired);
    const formattedTotalBudget = totalBudget.toLocaleString("en-ZA", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const fontColor = totalBudget < 0 ? "red" : "inherit";

    return (
      <div style={{ color: fontColor }}>
        {typeof formattedTotalBudget === "number"
          ? formattedTotalBudget.toFixed(2)
          : formattedTotalBudget}
      </div>
    );
  };

  const remainingBudgetTemplate = (data) => {
    if (!data || !paymentData.length) {
      return null;
    }
    const projectNum = data.ProjectNum;
    // Calculate total budget
    const totalBudget = Number(data.NormalBudget) + Number(data.AmountRequired);
    // Calculate total cost using a similar logic as in the totalCostTemplate
    const matchingPayments = paymentData.filter(
      (payment) => payment.ProjectNum === projectNum
    );
    const totalCost = matchingPayments.reduce(
      (total, payment) =>
        total + (payment.DebitAmount - payment.CreditAmount || 0),
      0
    );
    // Calculate remaining budget
    const remainingBudget = totalBudget - totalCost;
    const formattedRemainingBudget = remainingBudget.toLocaleString("en-ZA", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const fontColor = remainingBudget < 0 ? "red" : "inherit";
    return <span style={{ color: fontColor }}>{formattedRemainingBudget}</span>;
  };

  const totalCostTemplate = (rowData) => {
    if (!rowData || !paymentData.length) {
      return null;
    }
    const projectNum = rowData.ProjectNum;
    const matchingPayments = paymentData.filter(
      (payment) => payment.ProjectNum === projectNum
    );
    const totalCost = matchingPayments.reduce(
      (total, payment) =>
        Number(total) +
        (Number(payment.DebitAmount) - Number(payment.CreditAmount || 0)),
      0
    );

    const formattedTotalCost = totalCost.toLocaleString("en-ZA", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return <span>{formattedTotalCost}</span>;
  };

  const currencyFormatter1 = (field, data, column) => {
    const normalBudget = getValue("NormalBudget", data); // Assuming the field name is "DebitAmount"

    // Format the amount with the desired currency symbol, space separator, and decimal places
    const formattedAmount = new Intl.NumberFormat("en-ZA", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 2,
    }).format(normalBudget);

    return formattedAmount;
  };

  const currencyFormatter2 = (field, data, column) => {
    const amountRequired = getValue("AmountRequired", data); // Assuming the field name is "DebitAmount"

    // Format the amount with the desired currency symbol, space separator, and decimal places
    const formattedAmount = new Intl.NumberFormat("en-ZA", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 2,
    }).format(amountRequired);

    return formattedAmount;
  };

  const statusTemplate = (props) => {
    const status = props.ProjectStatus; // Assuming the status field is named "Status"
    let colorClass = "";

    if (status === "Active") {
      colorClass = "status-active";
    } else if (status === "Non-active") {
      colorClass = "status-inactive";
    } else if (status === "Progress delayed") {
      colorClass = "status-delayed";
    }

    return <div className={`status-cell ${colorClass}`}>{status}</div>;
  };

  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleUpdateProjectDetailsButton(selectedID); // Replace with your actual logic
        setIsEditDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button
            className={`view-button ${
              userChatsData.length > 0 ? "chat-button" : ""
            }`}
            onClick={viewMessages}
            disabled={selectedID === ""}
          >
            <ForumIcon className="view-icon" /> Chats{" "}
          </button>
        </div>
        <div className="button-container-subA1">
          {user?.JobTitle === "Administrator" && (
            <button
              className="edit-button"
              onClick={handleEditButtonClick}
              disabled={selectedID === ""}
            >
              <EditIcon className="send-icon" /> Edit{" "}
            </button>
          )}
          <button
            className={`delete-button-whereabouts ${
              isButtonEnabled ? "" : "disabled-button"
            }`}
            onClick={handleDeleteButtonClick}
            disabled={!isButtonEnabled}
          >
            <DeleteIcon className="but-icon-whereabouts" /> Delete{" "}
          </button>
        </div>
        <div className="button-container-subA2">
          <button className="add-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          {/* <button className="edit-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button> */}
        </div>
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  //-------------=---------Messages--------------------------------//
  const viewMessages = () => {
    setIsMessagesVisible(true);
    axios
      .patch(process.env.REACT_APP_SERVER_URL + `/api/chat/read`, {
        type: "project_id",
        id: selectedID,
      })
      .then(({ data }) => {
        console.log("Read : ", data);
        setUserChatsData((prev) =>
          prev?.filter((notif) => notif.project_id !== selectedID)
        );
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  };

  // Custom template for the "message" column
  const chatCountTemplate = (props) => {
    const ID = props.id;
    const chatCount = userChatsData.filter(
      (chat) => chat.project_id === ID
    ).length;

    const fontColor = chatCount < 1 ? "darkgreen" : "darkred";
    const backgroundColor = chatCount < 1 ? "lightgreen" : "pink";
    const fontWeight = chatCount > 0 ? "bold" : "normal";

    const style = {
      color: fontColor,
      backgroundColor: backgroundColor,
      width: "fit-content",
      height: "100%",
      display: "inline-flex",
      justifyContent: "left",
      alignItems: "left",
      padding: "5px 10px",
      borderRadius: "10px",
      fontWeight: fontWeight,
    };

    return (
      <div style={style}>
        <span>{chatCount}</span>
      </div>
    );
  };

  const handleMessageDialogClose = () => {
    setIsMessagesVisible(false);
  };
  //-------------=---------Messages--------------------------------//

  return (
    <div className="all-main-project-containers">
      <div className="all-outer-project-containers">
        <h3>List of Phase 1 Projects in {userLocation}</h3>
        <br />
        <div className="all-inner-project-containers">
          {isDataLoaded && (
            <GridComponent
              dataSource={gridData}
              childMapping="items"
              ref={GridRef}
              classClass="custom-treegrid-class"
              height="650px"
              width="99.5%"
              allowReordering={true}
              allowResizing={true}
              allowExcelExport={true}
              allowPdfExport={true}
              allowPaging={true}
              allowSorting={true}
              allowFiltering={true}
              showColumnChooser={true}
              filterSettings={FilterOptions}
              pageSettings={pageSettings}
              editSettings={editOptions}
              toolbar={updatedToolbarOptions}
              allowSelection={true}
              rowSelected={handleRowSelect}
            >
              <ColumnsDirective>
                {/* <ColumnDirective
                  field="id"
                  headerText="ID"
                  width="100"
                  textAlign="Left"
                  isPrimaryKey={true}
                /> */}
                <ColumnDirective
                  headerText="Chats"
                  width="100"
                  textAlign="Left"
                  template={chatCountTemplate}
                />
                <ColumnDirective
                  field="ProjectNum"
                  headerText="Project #"
                  width="120"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="ProjectName"
                  headerText="Project Name"
                  width="200"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="NormalBudget"
                  headerText="Normal Budget"
                  width="150"
                  textAlign="Left"
                  valueAccessor={currencyFormatter1}
                />
                <ColumnDirective
                  field="DiscretionaryRequired"
                  headerText="Discretionary?"
                  width="110"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="AmountRequired"
                  headerText="Discr. Budget"
                  width="150"
                  type="N2"
                  textAlign="Left"
                  valueAccessor={currencyFormatter2}
                />
                <ColumnDirective
                  headerText="Total Budget"
                  width="150"
                  textAlign="Left"
                  template={totalBudgetTemplate}
                />
                <ColumnDirective
                  headerText="Total Cost"
                  width="150"
                  textAlign="Left"
                  type="N2"
                  template={totalCostTemplate}
                />
                <ColumnDirective
                  headerText="Remaining"
                  width="150"
                  textAlign="Left"
                  type="N2"
                  template={remainingBudgetTemplate}
                />
                <ColumnDirective
                  field="FundingDate"
                  headerText="Funding Date"
                  width="220"
                  format="yyyy/MM/dd"
                  textAlign="Right"
                  type="date"
                />
                <ColumnDirective
                  field="Sector"
                  headerText="Sector"
                  width="120"
                  textAlign="Left"
                  editType="dropdownedit"
                />
                <ColumnDirective
                  field="SubSector"
                  headerText="Sub Sector"
                  width="120"
                  textAlign="Left"
                  editType="dropdownedit"
                />
                <ColumnDirective
                  field="Type"
                  headerText="Type"
                  width="150"
                  textAlign="Left"
                  editType="dropdownedit"
                />
                <ColumnDirective
                  field="Phase"
                  headerText="Phase"
                  width="150"
                  textAlign="Left"
                  editType="dropdownedit"
                />
                <ColumnDirective
                  field="ProjectStatus"
                  headerText="Status"
                  width="90"
                  textAlign="Left"
                  template={statusTemplate}
                />
                <ColumnDirective
                  field="Region"
                  headerText="Region"
                  width="150"
                  textAlign="Left"
                  editType="dropdownedit"
                />
                <ColumnDirective
                  field="District"
                  headerText="District"
                  width="150"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="Province"
                  headerText="Province"
                  width="150"
                  textAlign="Left"
                  editType="dropdownedit"
                />
              </ColumnsDirective>
              <Inject
                services={[
                  Page,
                  Sort,
                  Filter,
                  Edit,
                  Toolbar,
                  ColumnChooser,
                  Reorder,
                  Resize,
                  PdfExport,
                  ExcelExport,
                ]}
              />
            </GridComponent>
          )}
        </div>
      </div>
      <div>
        {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="80%"
            height="86%"
            header="Edit Project Details"
            showCloseIcon={true}
            allowDragging={true}
            close={handleEditDialogClose}
            buttons={editButtons}
          >
            <ProjectDialogMain
              projectData={projectData}
              selectedID={selectedID}
              selectedProjectNum={selectedProjectNum}
              selectedProjectName={selectedProjectName}
              selectedSector={selectedSector}
              selectedSubSector={selectedSubSector}
              selectedAdministrator={selectedAdministrator}
              selectedCoordinator={selectedCoordinator}
              selectedFacilitator={selectedFacilitator}
              selectedRegion={selectedRegion}
              selectedProvince={selectedProvince}
              selectedIdentificationDate={selectedIdentificationDate}
              selectedPhase={selectedPhase}
              selectedStage={selectedStage}
              selectedGeneralRemarks={selectedGeneralRemarks}
              selectedType={selectedType}
              selectedVerificationDate={selectedVerificationDate}
              selectedStatus={selectedStatus}
              selectedCapacityDate={selectedCapacityDate}
              selectedNormalBudget={selectedNormalBudget}
              selectedDiscretionaryRequired={selectedDiscretionaryRequired}
              selectedAmountRequired={selectedAmountRequired}
              selectedMotivationForDiscretionary={
                selectedMotivationForDiscretionary
              }
              selectedFundingDate={selectedFundingDate}
              selectedDateApproval={selectedDateApproval}
              selectedMonitoringDate={selectedMonitoringDate}
              setNewProjectNum={setNewProjectNum}
              setNewProjectName={setNewProjectName}
              setNewSector={setNewSector}
              setNewSubSector={setNewSubSector}
              setNewAdministrator={setNewAdministrator}
              setNewCoordinator={setNewCoordinator}
              setNewFacilitator={setNewFacilitator}
              setNewRegion={setNewRegion}
              setNewProvince={setNewProvince}
              setNewIdentificationDate={setNewIdentificationDate}
              setNewPhase={setNewPhase}
              setNewDateApproval={setNewDateApproval}
              setNewStage={setNewStage}
              setNewGeneralRemarks={setNewGeneralRemarks}
              setNewFundingDate={setNewFundingDate}
              setNewMonitoringDate={setNewMonitoringDate}
              setNewType={setNewType}
              setNewVerificationDate={setNewVerificationDate}
              setNewStatus={setNewStatus}
              setNewCapacityDate={setNewCapacityDate}
              setNewNormalBudget={setNewNormalBudget}
              setNewDiscretionaryRequired={setNewDiscretionaryRequired}
              setNewAmountRequired={setNewAmountRequired}
              setNewMotivationForDiscretionary={
                setNewMotivationForDiscretionary
              }
              setProvinceProjectData={setProvinceProjectData}
              setRegionProjectData={setRegionProjectData}
              setSubSectorProjectData={setSubSectorProjectData}
              uniqueProvinces={uniqueProvinces}
              uniqueSubSectors={uniqueSubSectors}
              uniqueProjects={uniqueProjects}
              uniqueRegions={uniqueRegions}
              uniqueFacilitators={uniqueFacilitators}
              uniqueAdministrators={uniqueAdministrators}
              uniqueCoordinators={uniqueCoordinators}
            />
          </DialogComponent>
        )}
      </div>
      {isMessagesVisible && (
        <div>
          <Modal
            open={isMessagesVisible}
            onClose={handleMessageDialogClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="message_outer_container">
                <h1>{`Chats about ${selectedProjectName} Project`}</h1>
                <div className="message_inner_container">
                  <MessageBox
                    users={allUserData}
                    chatID={selectedID}
                    chatUsers={selectedChatUsers}
                    type="project_id"
                  />
                </div>
                <button
                  className="add-button"
                  onClick={handleMessageDialogClose}
                >
                  <CloseIcon className="send-icon" /> Close
                </button>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
}
export default FinPhase1;
