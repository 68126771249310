import React from 'react';
import { TextBoxComponent} from '@syncfusion/ej2-react-inputs';
import { ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import './TaskDialog.css'

function TaskDialog({ selectedID, selectedDescription, selectedStartDate,  selectedDuration, selectedProgress, setNewProgress}) {

    const handleProgressChange = (event) => {
        // Update the 'notes' state with the new value when the input changes
        setNewProgress(event.target.value);
      };
      
  return (
    <table className='main-container-task-dialog'>
      <tbody className='project-field-task-dialog'>
        <tr className='my-task-dialog-row1'>
          <td className='field_task-id'>
            <TextBoxComponent id='id' name='id' value={selectedID} placeholder=" Task. ID" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='field_task-description'>
            <TextBoxComponent id='Description' name='Description' value={selectedDescription} placeholder=" Task Description" floatLabelType="Always" disabled={true}/>
          </td>
        </tr>
        <tr className='my-task-dialog-row2'>
          <td className='field_task-start-date'>
            <DatePickerComponent  id='datepicker' format='MMMM dd, yyyy' value={selectedStartDate} placeholder=" Start Date" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='field_task-duration'>
            <TextBoxComponent  id='Duration' name='Duration' value={selectedDuration} placeholder="Duration" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='field_task-progress'>
            <ComboBoxComponent  id='Progress' name='Progress' value={selectedProgress} onChange={handleProgressChange} placeholder=" Progress" floatLabelType="Always" dataSource={[0,50,100]}/>
          </td>
        </tr>
      </tbody>
    </table>
    
  )
}

export default TaskDialog