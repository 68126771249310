import React, { useState } from "react";
import "./Login.css";
import logo from "../../header/logo.png";
import { useNavigate } from "react-router-dom";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockResetIcon from "@mui/icons-material/LockReset";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import axios from "../../../config/axios";
// import { setUser } from "../../../app/slices/user";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [changePassword, setChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const setUser = useUserStore((state) => state.setUser);
  const setJwt = useUserStore((state) => state.setJwt);

  const handleLogin = async () => {
    try {
      setError(""); // Clear any previous error message
      // Perform API call to check if the username and password match
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/auth/login",
        { username, password }
      );

      if (response.status === 200) {
        const { data } = response;

        // Set user details in Redux store
        setUser(data.items);
        setJwt(data.jwt);
        // If user credentials match, navigate to home page
        navigate("/");
        console.log("Welcome to your Profile");
        toast.success("Welcome " + data.items?.FullName);
        console.log("Welcome to your Profile");
        setLoginSuccess(true);
      } else {
        setError("Invalid Username or Password"); // Set an error message
        setLoginSuccess(false); // Set login success to false
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("An error occurred while fetching user data."); // Set an error message
      setLoginSuccess(false); // Set login success to false
    }
  };

  const handleForgotPassword = async () => {
    try {
      // Perform API call to check if the username and email match
      setError(""); // Clear any previous error message
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/user"
      );
      if (response.ok) {
        const responseData = await response.json();
        const users = responseData.items;
        const user = users.find(
          (item) => item.UserName === username && item.EmailAddress === email
        );
        if (user) {
          // Simulate sending an email with the password (replace with actual email logic)
          setForgotPasswordSuccess(true); // Set forgot password success to true
          alert(
            `Password: ${user.Password} has been sent to ${user.EmailAddress}`
          );
        } else {
          setError("Invalid username or email."); // Set an error message
          setForgotPasswordSuccess(false); // Set forgot password success to false
        }
      } else {
        setError("Failed to verify the user."); // Set an error message
        setForgotPasswordSuccess(false); // Set forgot password success to false
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("An error occurred while fetching user data."); // Set an error message
      setForgotPasswordSuccess(false); // Set forgot password success to false
    }
  };

  const handleChangePassword = async () => {
    try {
      // Check if newPassword matches confirmNewPassword
      if (newPassword !== confirmNewPassword) {
        setError("New password and confirm password do not match.");
        setChangePasswordSuccess(false);
        return;
      }

      // Clear any previous error message
      setError("");

      // Perform API call to fetch user data
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/user"
      );
      if (response.ok) {
        const responseData = await response.json();
        const users = responseData.items;
        const user = users.find(
          (item) => item.UserName === username && item.Password === password
        );
        if (user) {
          // Update the user's password to newPassword
          user.Password = newPassword;

          // Perform API call to update user data with the new password
          const updateUserResponse = await axios.put(
            process.env.REACT_APP_SERVER_URL + `/api/user/${user.id}`,
            user
          );

          if (updateUserResponse.ok) {
            setChangePasswordSuccess(true); // Set change password success to true
          } else {
            setError("Failed to update password."); // Set an error message
            setChangePasswordSuccess(false); // Set change password success to false
          }
        } else {
          setError("Invalid username or password."); // Set an error message
          setChangePasswordSuccess(false); // Set change password success to false
        }
      } else {
        setError("Failed to fetch user data."); // Set an error message
        setChangePasswordSuccess(false); // Set change password success to false
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("An error occurred while fetching user data."); // Set an error message
      setChangePasswordSuccess(false); // Set change password success to false
    }
  };

  return (
    <div className="login-container">
      {!forgotPassword && !changePassword && (
        <div className="login-form">
          <img src={logo} alt="LOGO" />
          <h2>Login</h2>
          {loginSuccess && <p className="success-message">Login successful!</p>}
          {error && <p className="error-message">{error}</p>}
          <div className="input-group">
            <input
              className="username"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              autoFocus // Set autoFocus attribute here
            />
          </div>
          <div className="input-group">
            <input
              className="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button className="login-button" onClick={handleLogin}>
            <LockOpenIcon style={{ marginRight: "8px" }} /> Login
          </button>
          <button
            className="change-button2"
            onClick={() => setChangePassword(true)}>
            Change Password?
          </button>
        </div>
      )}
      {forgotPassword && (
        <div className="forgot-password">
          <img src={logo} alt="LOGO" />
          <h2>Forgot Password</h2>
          {forgotPasswordSuccess && (
            <p className="success-message">
              Password reset requested. Check your email!
            </p>
          )}
          {error && <p className="error-message">{error}</p>}
          <div className="input-group">
            <input
              className="username"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              autoFocus // Set autoFocus attribute here
            />
          </div>
          <div className="input-group">
            <input
              className="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button className="button" onClick={handleForgotPassword}>
            Request Password
          </button>
          <button className="button" onClick={() => setForgotPassword(false)}>
            Back to Login
          </button>
        </div>
      )}
      {changePassword && (
        <div className="change-password">
          <img src={logo} alt="LOGO" />
          <h2>Change Password</h2>
          {changePasswordSuccess && (
            <p className="success-message">Password changed successfully!</p>
          )}
          {error && <p className="error-message">{error}</p>}
          <div className="input-group">
            <input
              className="username"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              autoFocus // Set autoFocus attribute here
            />
          </div>
          <div className="input-group">
            <input
              className="password"
              type="password"
              placeholder="Current Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              className="password"
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              className="password"
              type="password"
              placeholder="Confirm New Password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              required
            />
          </div>
          <button className="change-button" onClick={handleChangePassword}>
            <LockResetIcon style={{ marginRight: "5px" }} />
            Change Password
          </button>
          <button
            className="login-button2"
            onClick={() => setChangePassword(false)}>
            Back to Login
          </button>
        </div>
      )}
    </div>
  );
}
export default Login;
