// useProjectDataFetch.js

import { useEffect, useState } from "react";
import axios from "../../config/axios";

const useProjectDataFetch = (user) => {
  const [projectData, setProjectData] = useState([]);
  const [isProjectLoaded, setIsProjectLoaded] = useState(false);

  const fetchProjectData = async () => {
    try {
      let apiUrl = process.env.REACT_APP_SERVER_URL + "/api/project";

      if (user?.Access === "Provincial") {
        apiUrl =
          process.env.REACT_APP_SERVER_URL +
          `/api/project/province/${user?.AccessProvince}`;
      } else if (user?.Access === "Regional") {
        apiUrl =
          process.env.REACT_APP_SERVER_URL +
          `/api/project/region/${user?.AccessRegion}`;
      }

      const response = await axios.get(apiUrl);
      setIsProjectLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsProjectLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    fetchProjectData().then((initialData) => {
      setProjectData(initialData);
    });
  }, [user?.Access, user?.AccessProvince, user?.AccessRegion]);

  return {
    projectData,
    isProjectLoaded,
  }
};

export default useProjectDataFetch;
