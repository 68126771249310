import { useEffect, useState } from "react";
import axios from "../../config/axios";

const useProjectRegNumDataFetch = () => {
  const [projectRegNumData, setProjectRegNumData] = useState([]);
  const [isProjectRegNumLoaded, setIsProjectRegNumLoaded] = useState(false);

  // Define the fetchData function for project RegNum change
  const fetchProjectRegNumData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/regnumchange"
      );
      setIsProjectRegNumLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching project RegNum data:", error);
      setIsProjectRegNumLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch project RegNum change
    fetchProjectRegNumData().then((InitialData) => {
      setProjectRegNumData(InitialData);
    });
  }, []);

  // Return an object containing all the fetched data
  return {
    projectRegNumData,
    isProjectRegNumLoaded,
  }
};

export default useProjectRegNumDataFetch;
