import React, { useEffect, useState, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import { getValue } from "@syncfusion/ej2-base";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import "../../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import axios from "../../../../../config/axios";
import "./AllProjects.css";
import PhaseApprovalDialog from "../../../../shared/PhaseApprovalDialog";
import useProjectDataFetch from "../../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../../shared/useOtherDataFetch"; 
import useWorkflowDataFetch from "../../../../shared/useWorkflowDataFetch";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import useUserStore from "../../../../../app/user";
import { toast } from "react-toastify";

function AllProjects() {
  const user = useUserStore((state) => state.user);
  const {projectData, isProjectLoaded} = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { taskData, attachmentData, beneficiaryData, isAttachmentLoaded, isBeneficiaryLoaded, isTaskLoaded } = useOtherDataFetch();
  const {workflowData, isWorkflowLoaded} = useWorkflowDataFetch();
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [updatedWorkflowData, setUpdatedWorkflowData] = useState([]);
  const [allWorkflowData, setAllWorkflowData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [selectedID, setSelectedID] = useState("");
  const [selectedPhase, setSelectedPhase] = useState("");
  const [selectedStage, setSelectedStage] = useState("");
  const [isStageDialogVisible, setIsStageDialogVisible] = useState(false);
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [isFaButtonEnabled, setIsFaButtonEnabled] = useState(false); // Initial state
  const [isNfButtonEnabled, setIsNfButtonEnabled] = useState(false); // Initial state
  const [nextPhase, setNextPhase] = useState("");
  const [nextPhaseType, setNextPhaseType] = useState("");
  const newPhaseIdentificationDate = new Date();
  const newVerificationStartDate = new Date(newPhaseIdentificationDate);
  newVerificationStartDate.setDate(newVerificationStartDate.getDate() + 42);
  const declaration = "I have monitored the perfomance of this project. I have also consulted with all relavant stakeholders. All stakeholders are satisfied that this project can be identified for the next Phase."
  const currentStage = "Monitoring";
  const nextStage = "Identification";

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
  }
  const currentDateTime = formatDate(new Date());

  useEffect(() => {
    if (isProjectLoaded && isTaskLoaded && isAttachmentLoaded && isWorkflowLoaded){
      setAllWorkflowData(workflowData)  
      setUpdatedWorkflowData([])
      const filteredProjectData = projectData.filter(
        (project) =>
          project.Stage === currentStage
      );
      setGridData(filteredProjectData);
      setIsDataLoaded(true);
    }
  }, [projectData, workflowData, isProjectLoaded, isWorkflowLoaded, isTaskLoaded, isAttachmentLoaded]);

  
  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  const handleRowSelect = async (args) => {
    setSelectedProjectName(args.data.ProjectName);
    setSelectedID(args.data.id);
    setSelectedPhase(args.data.Phase);
    setSelectedStage(args.data.Stage);
    const currentPhase = args.data.Phase
    const currentProjectStatus = args.data.ProjectStatus

    if (currentPhase === "Phase 1: Coordinators"){
      setNextPhase("Phase 2: Facilitators")
    }
    if (currentPhase === "Phase 2: Facilitators"){
      setNextPhase("Phase 3: NF")
    }
    if (currentProjectStatus === "Active"){
      setNextPhaseType("Legacy")
    }else{
      setNextPhaseType("Revived")
    }

    const updatedGridData = allWorkflowData.filter(
      (workflow) => workflow.ProjectID === Number(args.data.id)
    );
    setUpdatedWorkflowData(updatedGridData);

    //check attachments
    const projectNum = args.data.ProjectNum;
    const allAttachments = attachmentData.filter(
      (attachment) =>
        attachment.ProjectNum === projectNum &&
        attachment.Stage === currentStage
    );
    const filteredAttachments = allAttachments.filter(
      (attachment) => attachment.AttachPath !== ""
    );
    // check Tasks
    const projectID = args.data.id;
    const parentIDs = taskData?.map((task) => task.ParentID);
    const allTasks = taskData.filter(
      (task) =>
        task.ProjectID === projectID &&
        (task.ProjectStage === currentStage || task.ProjectStage === "") &&
        !parentIDs.includes(task.id)
    );
    const filteredTasks = allTasks.filter((task) => task.Progress === 100);
    //check beneficiaries
    const allBeneficiaries = beneficiaryData.filter(
      (beneficiary) => beneficiary.ProjNum === projectNum
    );
    const filteredBeneficiaries = allBeneficiaries.filter(
      (beneficiary) =>
        beneficiary.BenStatus === "Active" &&
        beneficiary.FirstNames !== "" &&
        beneficiary.LastName !== "" &&
        beneficiary.ID_Num !== ""
    );

    // Detemine the F button state
    if (
      filteredAttachments.length === allAttachments.length &&
      filteredTasks.length === allTasks.length &&
      filteredBeneficiaries.length === allBeneficiaries.length &&
      currentPhase === "Phase 1: Coordinators"
    ) {
      setIsFaButtonEnabled(true);
    } else {
      setIsFaButtonEnabled(false);
    }
    // Detemine the NF button state
    if (
      filteredAttachments.length === allAttachments.length &&
      filteredTasks.length === allTasks.length &&
      filteredBeneficiaries.length === allBeneficiaries.length &&
      currentPhase === "Phase 2: Facilitators"
    ) {
      setIsNfButtonEnabled(true);
    } else {
      setIsNfButtonEnabled(false);
    }
  };

  const identificationTaskData = [
    {
      TaskName: "Collect Project Profile",
      ProjectStage: nextStage,
      StartDate: newPhaseIdentificationDate,
      Duration: 168,
      Progress: 0,
    },
    {
      TaskName: "Project Verification",
      ProjectStage: nextStage,
      StartDate: newVerificationStartDate,
      Duration: 5,
      Progress: 0,
    },
    {
      TaskName: "Other Additional Tasks",
      ProjectStage: nextStage,
      StartDate: newPhaseIdentificationDate,
      Duration: 168,
      Progress: 0,
    },
  ];

  const handleStageApprovalButtonClick = () => {
    setIsStageDialogVisible(!isStageDialogVisible);
  };

  const handleStageDialogClose = () => {
    setIsStageDialogVisible(false);
  };

  const handleStageApprovalButton = async (selectedID) => {
    // Make a POST request to add the project record
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    try {
      const ProjectResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/project/${numericSelectedID}`,
        {
          Stage: nextStage,
          Phase: nextPhase,
          Type: nextPhaseType,
          CurrentUser: user?.FullName,
          CreatedBy: user?.FullName,
          IdentificationDate: newPhaseIdentificationDate,
          VerificationDate: ""
        }
      );
      console.log("Project response:", ProjectResponse.data);
      if (ProjectResponse.data.status === "success") {
        toast.success("Project phase has been changed successfully.");
        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/project"
        );
        const projects = updatedResponse.data.items;
        
        // Get the all Project Tasks ID by making a GET request
        const projectTasksResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/task"
        );
        const allTasks = projectTasksResponse.data.items;
        
        // Filter all tasks where Duration is greater than 0 and ProjectID matches
        const projectTasks = allTasks.filter(
          (task) => task.Duration > 0 && task.ProjectID === numericSelectedID
        );
        
        // Delete all stage tasks
        if (projectTasks.length > 0){
          for (const projectTask of projectTasks) {
            // Get the Task ID from the task object
            const taskID = projectTask.id;
            // Make a DELETE request to delete the task
            try {
              const deleteResponse = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/api/task/${taskID}`
              );
              console.log("Delete response:", deleteResponse.data);
              toast.success(deleteResponse.data.message);
            } catch (error) {
              console.error("Error deleting record:", error);
              toast.error("Error deleting the record");
            }
          }
        }     
       
        // Find the TaskID where TaskName matches Identification Stage
        const matchingStageTask = allTasks.find(
          (task) =>
            task.TaskName === "Identification Stage" &&
            task.ProjectID === numericSelectedID
        );
        // Extract the TaskID from the matching task or set it to a default value (e.g., 1 if not found)
        const stageTaskID = matchingStageTask ? matchingStageTask.id : 1;
        console.log("Identification Task ID:", stageTaskID);
        // Iterate through fundingTaskData and create tasks for the new project
        for (const identificationTask of identificationTaskData) {
          // Create a new task object
          const newIdentificationTask = {
            ...identificationTask,
            ProjectID: numericSelectedID,
            ParentID: stageTaskID,
          };
          // Make a POST request to add the task
          const IdentificationTaskResponse = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/task",
            newIdentificationTask
          );
          console.log("Identification Task response:", IdentificationTaskResponse.data);
          toast.success(
            `${IdentificationTaskResponse.data.items.TaskName} task was created successfully.`
          );
        }
        const updatedGridData = projects.filter(
          (project) =>
            project.Stage === currentStage
        );
        setGridData(updatedGridData);
        const workflowData = {
          ProjectID: numericSelectedID,
          ProjectStage: nextStage,
          Sender: user?.FullName,
          Receiver: user?.FullName,
          DateTime: currentDateTime,
          Notes: declaration,
        };
        // Make a POST request to add the workflow record
        const workflowResponse = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/workflow",
          workflowData
        );
        console.log("Workflow response:", workflowResponse.data);
        if (workflowResponse.data.status === "success") {
          toast.success("Workflow added successfully.");
          // Update the grid data
          try {
            const updatedResponse = await axios.get(
              process.env.REACT_APP_SERVER_URL + "/api/workflow"
            );
            const workflows = updatedResponse.data.items;
            setAllWorkflowData(workflows)
            const updatedGridData = workflows.filter(
              (workflow) => workflow.ProjectID === numericSelectedID
            );
            setUpdatedWorkflowData(updatedGridData);
          } catch (error) {
            console.error("Error fetching workflows:", error);
          }
        } else {
          console.error(
            "Error adding workflow record:",
            workflowResponse.data.message
          );
          toast.error("Error adding workflow.");
        }
      } else {
        console.error(
          "Error updating project record:",
          ProjectResponse.data.message
        );
        toast.error("Error updating project");
      }
    } catch (error) {
      console.error("Error updating Project:", error);
      toast.error("Error updating project");
    }
    toast.dismiss(loadingToastId);
  };

  const statusTemplate = (props) => {
    const status = props.ProjectStatus;
    let colorClass = "";
    if (status === "Active") {
      colorClass = "status-active";
    } else if (status === "Non-active") {
      colorClass = "status-inactive";
    } else if (status === "Progress delayed") {
      colorClass = "status-delayed";
    }
    return <div className={`status-cell ${colorClass}`}>{status}</div>;
  };

  const attachmentTemplate = (props) => {
    const projectNum = props.ProjectNum;
    const allAttachments = attachmentData.filter(
      (attachment) =>
        attachment.ProjectNum === projectNum &&
        attachment.Stage === currentStage
    );
    const filteredAttachments = allAttachments.filter(
      (attachment) => attachment.AttachPath !== ""
    );
    if (filteredAttachments.length === allAttachments.length) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  const taskTemplate = (props) => {
    const projectID = props.id;
    const parentIDs = taskData?.map((task) => task.ParentID);
    const allTasks = taskData.filter(
      (task) =>
        task.ProjectID === projectID &&
        (task.ProjectStage === currentStage || task.ProjectStage === "") &&
        !parentIDs.includes(task.id)
    );
    const filteredTasks = allTasks.filter((task) => task.Progress === 100);
    if (filteredTasks.length === allTasks.length) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  // Custom template for the "Beneficiaries" column
  const beneficiaryTemplate = (props) => {
    const projectNum = props.ProjectNum;
    const allBeneficiaries = beneficiaryData.filter(
      (beneficiary) => beneficiary.ProjNum === projectNum
    );
    const filteredBeneficiaries = allBeneficiaries.filter(
      (beneficiary) =>
        beneficiary.BenStatus === "Active" &&
        beneficiary.FirstNames !== "" &&
        beneficiary.LastName !== "" &&
        beneficiary.ID_Num !== ""
    );
    if (filteredBeneficiaries.length === allBeneficiaries.length) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  const dateFormatter = (field, data, column) => {
    const dateValue = getValue("DateTime", data); // Replace "DateFieldName" with your actual date field name
    // Format the date with the desired options
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour:"numeric",
      minute:"numeric",
      second:"numeric"
    }).format(new Date(dateValue));
    return formattedDate;
  };

  let stageButtons = [
    {
      buttonModel: {
        content: "Identify",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleStageApprovalButton(
          selectedID,
          selectedStage,
          selectedPhase,
          declaration,
          nextStage,
          nextPhase
        );
        setIsStageDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsStageDialogVisible(false);
      },
    },
  ];

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
    mode: "Dialog",
  };
  const toolbarOptions = [""];
  const renderButtons = () => {
    return (
      <div className="button-container1">        
        <div className="button-container-subA31">
          {user?.JobTitle === "Facilitator" && (
            <button
              className={`stage-approval-button ${
                isFaButtonEnabled ? "" : "disabled-button"
              }`}
              onClick={handleStageApprovalButtonClick}
              disabled={!isFaButtonEnabled}>
              <ShoppingCartIcon className="send-icon" /> Identify the Project
            </button>
          )}
          {user?.JobTitle === "National Facilitator" && (
            <button
              className={`stage-approval-button ${
                isNfButtonEnabled ? "" : "disabled-button"
              }`}
              onClick={handleStageApprovalButtonClick}
              disabled={!isNfButtonEnabled}>
              <ShoppingCartIcon className="send-icon" /> Identify the Project
            </button>
          )}
        </div>
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  return (
    <div className="main-all-projects-container">
      <div className="project-all-projects-container">
        <div className="all-projects">
          <h3>All Projects in my Area</h3>
          <br />
          <div className="field-line-all-projects">
            {isDataLoaded && (
              <GridComponent
                dataSource={gridData}
                childMapping="items"
                ref={GridRef}
                classClass="custom-treegrid-class"
                height="550"
                width="100%"
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                showColumnChooser={true}
                allowSelection={true}
                rowSelected={handleRowSelect}
                filterSettings={FilterOptions}
                pageSettings={pageSettings}
                editSettings={editOptions}
                toolbar={updatedToolbarOptions}>
                <ColumnsDirective>
                  <ColumnDirective
                    field="id"
                    headerText="ID"
                    width="100"
                    textAlign="Left"
                    isPrimaryKey={true}
                  />
                  <ColumnDirective
                    field="ProjectNum"
                    headerText="Project #"
                    width="120"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="ProjectName"
                    headerText="Project Name"
                    width="200"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    headerText="Ben's"
                    width="90"
                    textAlign="Left"
                    template={beneficiaryTemplate}
                  />
                  <ColumnDirective
                    headerText="Tasks"
                    width="90"
                    textAlign="Left"
                    template={taskTemplate}
                  />
                  <ColumnDirective
                    headerText="Attach's"
                    width="90"
                    textAlign="Left"
                    template={attachmentTemplate}
                  />
                  <ColumnDirective
                    field="CreatedBy"
                    headerText="Created By"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="CurrentUser"
                    headerText="With Who?"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Sector"
                    headerText="Sector"
                    width="120"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="SubSector"
                    headerText="Sub Sector"
                    width="120"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="Type"
                    headerText="Type"
                    width="150"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="Phase"
                    headerText="Phase"
                    width="150"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="Stage"
                    headerText="Stage"
                    width="150"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="ProjectStatus"
                    headerText="Status"
                    width="90"
                    textAlign="Left"
                    template={statusTemplate}
                  />
                  <ColumnDirective
                    field="Administrator"
                    headerText="Administrator"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Facilitator"
                    headerText="Facilitator"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Coordinator"
                    headerText="Coordinator"
                    width="150"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="Region"
                    headerText="Region"
                    width="150"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="Province"
                    headerText="Province"
                    width="150"
                    textAlign="Left"
                    editType="dropdownedit"
                  />
                  <ColumnDirective
                    field="GeneralRemarks"
                    headerText="General Remarks"
                    width="350"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="IdentificationDate"
                    headerText="Identification Date"
                    width="220"
                    format="yyyy/MM/dd"
                    textAlign="Right"
                    type="date"
                  />
                  <ColumnDirective
                    field="VerificationDate"
                    headerText="Verification Date"
                    width="220"
                    format="yyyy/MM/dd"
                    textAlign="Right"
                    type="date"
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Sort, Filter, Edit, Toolbar, ColumnChooser]}
                />
              </GridComponent>
            )}
          </div>
        </div>
      </div>
      <div className="workflow-history-all-projects">
        <h3>{selectedProjectName}: Workflow History</h3>
        <br />
        <div className="field_line-all-projects">
          {isDataLoaded && (
            <GridComponent
              dataSource={updatedWorkflowData}
              childMapping="items"
              ref={GridRef}
              classClass="custom-treegrid-class"
              height="550"
              width="100%"
              allowPaging={true}
              allowSorting={true}
              allowFiltering={true}
              showColumnChooser={true}
              allowTextWrap={true}
              filterSettings={FilterOptions}
              pageSettings={pageSettings}
              editSettings={editOptions}
              toolbar={toolbarOptions}>
              <ColumnsDirective>
                <ColumnDirective
                  field="Sender"
                  headerText="From"
                  width="150"
                  textAlign="Left"
                  wrapText={true}
                />
                <ColumnDirective
                  field="Receiver"
                  headerText="To"
                  width="150"
                  textAlign="Left"
                  wrapText={true}
                />
                <ColumnDirective
                  field="Notes"
                  headerText="Note"
                  width="400"
                  textAlign="Left"
                  wrapText={true}
                />
                <ColumnDirective
                  field="DateTime"
                  headerText="When?"
                  width="130"
                  textAlign="Left"
                  valueAccessor={dateFormatter}
                  Type="date"
                />
                <ColumnDirective
                  field="ProjectStage"
                  headerText="Stage"
                  width="150"
                  textAlign="Left"
                  wrapText={true}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Sort, Filter, Edit, Toolbar, ColumnChooser]}
              />
            </GridComponent>
          )}
        </div>
        <div>
        {isStageDialogVisible && (
          <DialogComponent
            visible={isStageDialogVisible}
            width="700px"
            height="650px"
            header="Identify Project for Next Phase"
            showCloseIcon={true}
            allowDragging={true}
            close={handleStageDialogClose}
            buttons={stageButtons}>
            <PhaseApprovalDialog
              selectedID={selectedID}
              selectedStage={selectedStage}
              nextStage={nextStage}
              selectedPhase={selectedPhase}
              nextPhase={nextPhase}
              declaration={declaration}
            />
          </DialogComponent>
        )}
      </div>
      </div>
    </div>
  );
}

export default AllProjects;
