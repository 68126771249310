import React, { useEffect, useState, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  Reorder,
  Resize,
  PdfExport,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import "../../../../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
import "../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import axios from "../../../../config/axios";
import "../../projects/children/ListView.css";
import useOtherDataFetch from "../../../shared/useOtherDataFetch"; // Import the custom hook
import useProjectDataFetch from "../../../shared/useProjectDataFetch"; // Import the custom hook
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AttachmentDialog from "../../../shared/AttachmentDialog";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useUserStore from "../../../../app/user";
import { toast } from "react-toastify";
import { dateFormatter } from "../../../../utils/formats";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import useUserDataFetch from "../../../shared/useUserDataFetch";
import MessageBox from "../../messages/MessageBox";
import ForumIcon from "@mui/icons-material/Forum";
import {
  getAttachmentChatUsers,
  getCommonUsers,
  getProjectName,
  getUserDeleteRight,
  getUserLocation,
} from "../../../../utils";
import useUserChatsDataFetch from "../../../shared/useUserChatsDataFetch";
import socket from "../../../../utils/socket";
import { useSearchParams } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "90%",
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 2,
};

function AttachmentBankAccountDue() {
  const user = useUserStore((state) => state.user);
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { attachmentData, isAttachmentLoaded } = useOtherDataFetch();
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [userLocation, setUserLocation] = useState("");
  const [gridData, setGridData] = useState([]);
  const { allUserData, isAllUserLoaded } = useUserDataFetch([]);
  const [commonUserData, setCommonUserData] = useState([]);
  const [selectedChatUsers, setSelectedChatUsers] = useState([]);
  const { userChatsData, isUserChatsLoaded, setUserChatsData } =
    useUserChatsDataFetch([]);
  const [isMessagesVisible, setIsMessagesVisible] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();
  const id = Number(searchParams.get("id"));

  useEffect(() => {
    if (isProjectLoaded && isAttachmentLoaded) {
      const finalProjectData = projectData.filter(
        (project) => project.ProjectStatus !== "Deleted"
      );
      const finalAttachments = attachmentData.filter((attachment) => {
        const matchingProject = finalProjectData.find(
          (project) => project.ProjectNum === attachment.ProjectNum
        );
        return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
      });
      const filteredAttachments = finalAttachments.filter((attachment) => {
        const lastUpdated = new Date(attachment.LastUpdated);
        const validityInYears = attachment.Validity;
        if (!isNaN(lastUpdated) && !isNaN(validityInYears)) {
          const nextUpdateDate = new Date(lastUpdated);
          if (validityInYears === 0.0192307692307692) {
            nextUpdateDate.setDate(nextUpdateDate.getDate() + 7);
          } else if (validityInYears === 0.0833333333333333) {
            nextUpdateDate.setDate(nextUpdateDate.getDate() + 30);
          } else {
            nextUpdateDate.setDate(
              nextUpdateDate.getDate() + 365.25 * validityInYears
            );
          }
          const today = new Date();
          // Calculate the difference between nextUpdateDate and today's date in milliseconds
          const timeDifference = nextUpdateDate - today;
          // Calculate the difference in days
          const remainingDays = Math.ceil(
            timeDifference / (1000 * 60 * 60 * 24)
          );
          // Filter attachments where remainingDays is greater than 0
          return (
            (remainingDays < 30 || attachment.AttachPath === "") &&
            attachment.AttachName === "Bank Account Proof"
          );
        }
        return false; // Filter out attachments with invalid dates or validityInYears
      });
      setGridData(filteredAttachments);

      const filteredUserData = getCommonUsers(allUserData);
      setCommonUserData(filteredUserData);

      setIsDataLoaded(true);
    }
  }, [
    attachmentData,
    isAttachmentLoaded,
    projectData,
    isProjectLoaded,
    isAllUserLoaded,
    isUserChatsLoaded,
  ]);

  useEffect(() => {
    socket.on("notification", (data) => {
      console.log("Receive Notification: ", data);
      const isTrue = userChatsData.find((chat) => chat.ID === data.ID);
      if (!isTrue)
        setUserChatsData((prev) => (prev ? [...prev, data] : [data]));
    });
  }, []);

  useEffect(() => {
    const deleteRights = getUserDeleteRight(user);
    setIsButtonEnabled(deleteRights);
    const location = getUserLocation(user);
    setUserLocation(location);
  }, [user]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  const [selectedID, setSelectedID] = useState("");
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedProjectNum, setSelectedProjectNum] = useState("");
  const [selectedAttachName, setSelectedAttachName] = useState("");
  const [selectedAttachPath, setSelectedAttachPath] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedProjectNum(args.data.ProjectNum);
    setSelectedAttachName(args.data.AttachName);
    setSelectedStage(args.data.Stage);
    setSelectedAttachPath(args.data.AttachPath);
    setSelectedFile("");
    if (args.data.ProjectNum) {
      const projectName = getProjectName(projectData, args.data.ProjectNum);
      setSelectedProjectName(projectName);
      const chatUsers = getAttachmentChatUsers(
        projectData,
        args.data.ProjectNum,
        allUserData,
        commonUserData,
        user
      );
      setSelectedChatUsers(chatUsers);
    } else {
      setSelectedProjectName("");
      setSelectedChatUsers("");
    }
  };

  const handleDeleteButtonClick = () => {};

  const currentTitleDate = dateFormatter(new Date());
  const handleExcelButtonClick = () => {
    if (GridRef.current && GridRef.current.excelExport) {
      const exportProperties = {
        pageOrientation: "Landscape",
        fileName:
          "List Printed by " +
          user.FullName +
          " on " +
          currentTitleDate +
          ".xlsx",
      };
      GridRef.current.excelExport(exportProperties);
    }
  };

  const handlePdfButtonClick = () => {};

  const handleDialogClose = () => {
    setIsDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsDialogVisible(!isDialogVisible);
  };

  const handleUpdateAttachmentButton = async (selectedID, selectedFile) => {
    // Send the updated data to the API for updating the records
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    if (selectedAttachPath)
      await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/attachment/delete-file",
        {
          path: selectedAttachPath,
        }
      );

    if (!selectedFile) {
      try {
        const AttachmentResponse2 = await axios.put(
          process.env.REACT_APP_SERVER_URL +
            `/api/attachment/${numericSelectedID}`,
          {
            AttachPath: "",
            LastUpdatedBy: user?.FullName,
          }
        );
        console.log("Project response:", AttachmentResponse2.data);
        if (AttachmentResponse2.data.status === "success") {
          toast.success("Attachment updated successfully.");
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/api/attachment"
          );
          const attachments = updatedResponse.data.items;
          const matchingAttachments = attachments.filter((attachment) => {
            return gridData.some((item) => item.id === attachment.id);
          });
          setGridData(matchingAttachments);
          toast.dismiss(loadingToastId);
        } else {
          console.error(
            "Error updating attachment record:",
            AttachmentResponse2.data.message
          );
          toast.dismiss(loadingToastId);
          toast.error("Error updating attachment");
        }
      } catch (error) {
        console.error("Error updating attachment:", error);
        toast.dismiss(loadingToastId);
        toast.error("Error updating attachment");
      }

      return;
    }
    const formData = new FormData();
    formData.append("media", selectedFile);
    const AttachmentResponse = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/api/attachment/upload",
      formData
    );
    console.log("Project response:", AttachmentResponse.data);
    if (AttachmentResponse.data.status === "success") {
      console.log(
        "Attachment record updated successfully.",
        AttachmentResponse
      );
      setSelectedFile(AttachmentResponse.data.items);
    }
    try {
      const AttachmentResponse2 = await axios.put(
        process.env.REACT_APP_SERVER_URL +
          `/api/attachment/${numericSelectedID}`,
        {
          AttachPath: AttachmentResponse.data.items,
          LastUpdatedBy: user?.FullName,
        }
      );
      console.log("Project response:", AttachmentResponse2.data);
      if (AttachmentResponse2.data.status === "success") {
        toast.success("Attachment updated successfully.");
        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/attachment"
        );
        const attachments = updatedResponse.data.items;
        const matchingAttachments = attachments.filter((attachment) => {
          return gridData.some((item) => item.id === attachment.id);
        });
        setGridData(matchingAttachments);
        toast.dismiss(loadingToastId);
      } else {
        console.error(
          "Error updating attachment record:",
          AttachmentResponse2.data.message
        );
        toast.dismiss(loadingToastId);
        toast.error("Error updating attachment");
      }
    } catch (error) {
      console.error("Error updating attachment:", error);
      toast.dismiss(loadingToastId);
      toast.error("Error updating attachment");
    }
  };

  const viewAttachment = (media) => {
    window.open(media, "_blank");
  };

  const projectNameTemplate = (props) => {
    const projectNum = props.ProjectNum; // Assuming the field name is "ProjectNum"
    // Lookup the project name in projectData based on the provided projectNum
    const project = projectData.find(
      (project) => project.ProjectNum === projectNum
    );
    // Check if a project with the given projectNum was found
    if (project) {
      const projectName = project.ProjectName;
      return <div>{projectName}</div>;
    } else {
      // Handle the case where no matching project was found
      return <div>Refresh Page</div>;
    }
  };

  const projectStatusTemplate = (props) => {
    const projectNum = props.ProjectNum; // Assuming the field name is "ProjectNum"
    // Lookup the project name in projectData based on the provided projectNum
    const project = projectData.find(
      (project) => project.ProjectNum === projectNum
    );
    // Check if a project with the given projectNum was found
    if (project) {
      const projectStatus = project.ProjectStatus;
      return <div>{projectStatus}</div>;
    } else {
      // Handle the case where no matching project was found
      return <div>Refresh Page</div>;
    }
  };

  // Custom template for the "Attachments" column
  const attachmentTemplate = (props) => {
    const lastUpdated = new Date(props.LastUpdated);
    const validityInYears = props.Validity;
    if (
      !isNaN(lastUpdated) &&
      !isNaN(validityInYears) &&
      props.AttachPath !== ""
    ) {
      const nextUpdateDate = new Date(lastUpdated);
      if (validityInYears === 0.0192307692307692) {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 7);
      } else if (validityInYears === 0.0833333333333333) {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 30);
      } else {
        nextUpdateDate.setDate(
          nextUpdateDate.getDate() + 365.25 * validityInYears
        );
      }
      const today = new Date();
      // Calculate the difference between nextUpdateDate and today's date in milliseconds
      const timeDifference = nextUpdateDate - today;
      // Calculate the difference in days
      const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      let requiredDays = 30;
      if (validityInYears === 0.0192307692307692) {
        requiredDays = 0;
      } else if (validityInYears === 0.0833333333333333) {
        requiredDays = 5;
      } else if (validityInYears === 0.25) {
        requiredDays = 10;
      } else {
        requiredDays = 30;
      }
      if (remainingDays < 0) {
        // Display a negative value to indicate that the date has passed
        return (
          <span>
            <CancelIcon style={{ color: "red" }} />
          </span>
        );
      } else if (
        remainingDays < requiredDays &&
        (remainingDays > 0 || remainingDays === 0)
      ) {
        return (
          <span>
            <CheckCircleIcon style={{ color: "Orange" }} />
          </span>
        );
      } else {
        return (
          <span>
            <CheckCircleIcon style={{ color: "green" }} />
          </span>
        );
      }
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  // Custom template for the "Attachments" column
  const buttonTemplate = (props) => {
    const media =
      process.env.REACT_APP_SERVER_URL + "/public/" + props.AttachPath;
    if (props.AttachPath !== "") {
      return (
        <div className="button-container1">
          <button className="view-button" onClick={() => viewAttachment(media)}>
            <VisibilityIcon className="view-icon" />
            View
          </button>
        </div>
      );
    }
  };

  const nextUpdateTemplate = (props) => {
    const lastUpdated = new Date(props.LastUpdated);
    const validityInYears = props.Validity;
    if (
      !isNaN(lastUpdated) &&
      !isNaN(validityInYears) &&
      props.AttachPath !== ""
    ) {
      const nextUpdateDate = new Date(lastUpdated);
      if (validityInYears === 0.0192307692307692) {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 7);
      } else if (validityInYears === 0.0833333333333333) {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 30);
      } else {
        nextUpdateDate.setDate(
          nextUpdateDate.getDate() + 365.25 * validityInYears
        );
      }
      const options = { year: "numeric", month: "short", day: "numeric" };
      const formattedNextUpdate = nextUpdateDate.toLocaleDateString(
        "en-US",
        options
      );
      return <div>{formattedNextUpdate}</div>;
    } else {
      return <div>Now</div>;
    }
  };

  const remainingDaysTemplate = (props) => {
    const lastUpdated = new Date(props.LastUpdated);
    const validityInYears = props.Validity;
    if (
      !isNaN(lastUpdated) &&
      !isNaN(validityInYears) &&
      props.AttachPath !== ""
    ) {
      const nextUpdateDate = new Date(lastUpdated);
      if (validityInYears === 0.0192307692307692) {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 7);
      } else if (validityInYears === 0.0833333333333333) {
        nextUpdateDate.setDate(nextUpdateDate.getDate() + 30);
      } else {
        nextUpdateDate.setDate(
          nextUpdateDate.getDate() + 365.25 * validityInYears
        );
      }
      const today = new Date();
      // Calculate the difference between nextUpdateDate and today's date in milliseconds
      const timeDifference = nextUpdateDate - today;
      // Calculate the difference in days
      const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      if (remainingDays < 0) {
        // Display a negative value to indicate that the date has passed
        return (
          <div style={{ color: "red" }}>
            {Math.abs(remainingDays)} days (past)
          </div>
        );
      } else {
        return <div style={{ color: "green" }}>{remainingDays} days</div>;
      }
    } else {
      return <div style={{ color: "red" }}>0 days</div>;
    }
  };

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];
  const loadingIndicator = { indicatorType: "Spinner" };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button
            className={`view-button ${
              userChatsData.length > 0 ? "chat-button" : ""
            }`}
            onClick={viewMessages}
            disabled={selectedID === ""}
          >
            <ForumIcon className="view-icon" /> Chats{" "}
          </button>
        </div>
        <div className="button-container-subA1">
          {user?.JobTitle === "Administrator" && (
            <button
              className="edit-button"
              onClick={handleEditButtonClick}
              disabled={selectedID === ""}
            >
              <EditIcon className="send-icon" /> Edit{" "}
            </button>
          )}
          <button
            className={`delete-button-whereabouts ${
              isButtonEnabled ? "" : "disabled-button"
            }`}
            onClick={handleDeleteButtonClick}
            disabled={!isButtonEnabled}
          >
            <DeleteIcon className="but-icon-whereabouts" /> Delete{" "}
          </button>
        </div>
        <div className="button-container-subA2">
          <button className="add-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          {/* <button className="edit-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button> */}
        </div>
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Upload",
        cssClass: "e-flat",
        isPrimary: false,
      },
      click: () => {
        handleUpdateAttachmentButton(selectedID, selectedFile);
        setIsDialogVisible(false);
      },
    },
    selectedAttachPath && {
      buttonModel: {
        content: "Remove",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        setSelectedFile(null);
        handleUpdateAttachmentButton(selectedID, selectedFile);
        setIsDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsDialogVisible(false);
      },
    },
  ];

  //-------------=---------Messages--------------------------------//
  const viewMessages = (id) => {
    setIsMessagesVisible(true);
    axios
      .patch(process.env.REACT_APP_SERVER_URL + `/api/chat/read`, {
        type: "attachment_id",
        id: id ? id : selectedID,
      })
      .then(({ data }) => {
        console.log("Read : ", data);
        setUserChatsData((prev) =>
          prev?.filter((notif) =>
            notif.attachment_id !== id ? id : selectedID
          )
        );
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  };

  useEffect(() => {
    if (id) {
      setSelectedID(() => id);
      viewMessages(id);
      setSearchParams({});
    }
  }, [id]);

  // Custom template for the "message" column
  const chatCountTemplate = (props) => {
    const ID = props.id;
    const chatCount = userChatsData.filter(
      (chat) => chat.attachment_id === ID
    ).length;

    const fontColor = chatCount < 1 ? "darkgreen" : "darkred";
    const backgroundColor = chatCount < 1 ? "lightgreen" : "pink";
    const fontWeight = chatCount > 0 ? "bold" : "normal";

    const style = {
      color: fontColor,
      backgroundColor: backgroundColor,
      width: "fit-content",
      height: "100%",
      display: "inline-flex",
      justifyContent: "left",
      alignItems: "left",
      padding: "5px 10px",
      borderRadius: "10px",
      fontWeight: fontWeight,
    };

    return (
      <div style={style}>
        <span>{chatCount}</span>
      </div>
    );
  };

  const handleMessageDialogClose = () => {
    setIsMessagesVisible(false);
  };
  //-------------=---------Messages--------------------------------//

  return (
    <div className="my-to-do-attachments">
      <h3>List of Outdated Bank Accounts in {userLocation}</h3>
      <br />
      <div className="field-line-my-attachments">
        {isDataLoaded && (
          <GridComponent
            dataSource={gridData}
            childMapping="items"
            ref={GridRef}
            classClass="custom-treegrid-class"
            height="630"
            allowReordering={true}
            allowResizing={true}
            allowExcelExport={true}
            allowPdfExport={true}
            allowPaging={true}
            allowSorting={true}
            allowFiltering={true}
            showColumnChooser={true}
            filterSettings={FilterOptions}
            pageSettings={pageSettings}
            editSettings={editOptions}
            toolbar={updatedToolbarOptions}
            loadingIndicator={loadingIndicator}
            allowSelection={true}
            rowSelected={handleRowSelect}
          >
            <ColumnsDirective>
              {/* <ColumnDirective
                field="id"
                headerText="ID"
                width="60"
                textAlign="Left"
                isPrimaryKey={true}
              /> */}
              <ColumnDirective
                headerText="Chats"
                width="100"
                textAlign="Left"
                template={chatCountTemplate}
              />
              <ColumnDirective
                field="ProjectNum"
                headerText="Project #"
                width="120"
                textAlign="Left"
                editType="dropdownedit"
              />
              <ColumnDirective
                headerText="Project Name"
                width="160"
                textAlign="Left"
                template={projectNameTemplate}
              />
              <ColumnDirective
                headerText="Status"
                width="90"
                textAlign="Left"
                template={projectStatusTemplate}
              />
              <ColumnDirective
                field="NatureOfAttach"
                headerText="Type"
                width="80"
                textAlign="Left"
              />
              <ColumnDirective
                field="AttachName"
                headerText="Attachment Name"
                width="150"
                textAlign="Left"
              />
              <ColumnDirective
                headerText="Valid?"
                width="70"
                textAlign="Center"
                template={attachmentTemplate}
              />
              <ColumnDirective
                headerText="View"
                width="75"
                textAlign="Left"
                template={buttonTemplate}
              />
              <ColumnDirective
                field="LastUpdated"
                headerText="Last Updated On"
                width="120"
                format="MMM dd, yyyy"
                textAlign="Left"
                type="date"
                allowFiltering={false}
              />
              <ColumnDirective
                field="LastUpdatedBy"
                headerText="Last Updated By"
                width="120"
                textAlign="Left"
              />
              <ColumnDirective
                headerText="Next Update"
                width="120"
                textAlign="Left"
                format="yyyy/MM/dd"
                template={nextUpdateTemplate}
                type="date"
                allowFiltering={false}
              />
              <ColumnDirective
                headerText="Remaining Days"
                width="120"
                textAlign="Left"
                template={remainingDaysTemplate}
              />
            </ColumnsDirective>
            <Inject
              services={[
                Page,
                Sort,
                Filter,
                Edit,
                Toolbar,
                ColumnChooser,
                Reorder,
                Resize,
                PdfExport,
                ExcelExport,
              ]}
            />
          </GridComponent>
        )}
      </div>
      <div>
        {isDialogVisible && (
          <DialogComponent
            visible={isDialogVisible}
            width="700px"
            height="485px"
            header="Upload Attachment"
            allowDragging={true}
            showCloseIcon={true}
            close={handleDialogClose}
            buttons={editButtons}
          >
            <AttachmentDialog
              selectedID={selectedID}
              selectedStage={selectedStage}
              selectedProjectNum={selectedProjectNum}
              selectedProjectName={selectedProjectName}
              setSelectedAttachName={selectedAttachName}
              selectedAttachPath={selectedAttachPath}
              setSelectedFile={setSelectedFile}
              selectedFile={selectedFile}
            />
          </DialogComponent>
        )}
      </div>
      {isMessagesVisible && (
        <div>
          <Modal
            open={isMessagesVisible}
            onClose={handleMessageDialogClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="message_outer_container">
                <h1>{`Chats about ${selectedAttachName} of ${selectedProjectName} Project`}</h1>
                <div className="message_inner_container">
                  <MessageBox
                    users={allUserData}
                    chatID={selectedID}
                    chatUsers={selectedChatUsers}
                    type="attachment_id"
                  />
                </div>
                <button
                  className="add-button"
                  onClick={handleMessageDialogClose}
                >
                  <CloseIcon className="send-icon" /> Close
                </button>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
}
export default AttachmentBankAccountDue;
