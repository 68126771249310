export function getProjectName(data, projectNum) {
  const foundProject = data?.find(
    (project) => project.ProjectNum === projectNum
  );
  return foundProject ? foundProject.ProjectName : null;
}

export function getProvince(data, projectNum) {
  const foundProject = data?.find(
    (project) => project.ProjectNum === projectNum
  );
  return foundProject ? foundProject.Province : null;
}

export function getRegion(data, projectNum) {
  const foundProject = data?.find(
    (project) => project.ProjectNum === projectNum
  );
  return foundProject ? foundProject.Region : null;
}

export function getUserDeleteRight(user) {
  let hasDelete = false;
  if (user?.FullName === "Vusi Shongwe" || user?.FullName === "Fana Mkwanazi") {
    hasDelete = true;
  } else {
    hasDelete = false;
  }
  return hasDelete;
}

export function getUserLocation(user) {
  let location = "All Provinces";
  if (user?.Access === "Provincial") {
    location = user?.AccessProvince;
  } else if (user?.Access === "Regional") {
    location = user?.AccessRegion;
  } else {
    location = "All Provinces";
  }
  return location;
}

export function getCommonUsers(data) {
  const filteredUserData = data.filter(
    (user) =>
      user.AccessProvince === "All" &&
      user.FullName !== "Vusi Shongwe" &&
      user.FullName !== "Fana Mkwanazi" &&
      user.FullName !== "Nqobile Dlamini"
  );
  return filteredUserData ? filteredUserData : [];
}

export function getProjectChatUsers(
  province,
  region,
  allUsers,
  commonUsers,
  currentUser
) {
  const selectedUserData = allUsers?.filter(
    (user) =>
      (user.AccessProvince === province && user.JobTitle === "Facilitator") ||
      (user.AccessProvince === province && user.JobTitle === "Administrator") ||
      user.AccessRegion === region
  );
  const combinedList = commonUsers?.concat(selectedUserData);
  const filteredUserData = combinedList?.filter(
    (item) => item?.FullName !== currentUser?.FullName
  );
  return filteredUserData ? filteredUserData : [];
}

export function getAttachmentChatUsers(
  data,
  projectID,
  allUsers,
  commonUsers,
  currentUser
) {
  const region = getRegion(data, projectID);
  const province = getProvince(data, projectID);
  console.log(region, "region", province, "province");
  const selectedUserData = allUsers?.filter(
    (user) =>
      (user.AccessProvince === province && user.JobTitle === "Facilitator") ||
      (user.AccessProvince === province && user.JobTitle === "Administrator") ||
      user.AccessRegion === region
  );
  console.log(selectedUserData, "selectedUserData");
  const combinedList = commonUsers?.concat(selectedUserData);
  const filteredUserData = combinedList?.filter(
    (item) => item?.FullName !== currentUser?.FullName
  );
  return filteredUserData ? filteredUserData : [];
}

export function getPaymentChatUsers(
  data,
  projectID,
  allUsers,
  commonUsers,
  currentUser
) {
  const region = getRegion(data, projectID);
  const province = getProvince(data, projectID);
  console.log(region, "region", province, "province");
  const selectedUserData = allUsers?.filter(
    (user) =>
      (user.AccessProvince === province && user.JobTitle === "Facilitator") ||
      (user.AccessProvince === province && user.JobTitle === "Administrator") ||
      user.AccessRegion === region
  );
  console.log(selectedUserData, "selectedUserData");
  const combinedList = commonUsers?.concat(selectedUserData);
  const filteredUserData = combinedList?.filter(
    (item) => item?.FullName !== currentUser?.FullName
  );
  return filteredUserData ? filteredUserData : [];
}

export function getBeneficiaryChatUsers(
  data,
  projectID,
  allUsers,
  commonUsers,
  currentUser
) {
  const region = getRegion(data, projectID);
  const province = getProvince(data, projectID);
  console.log(region, "region", province, "province");
  const selectedUserData = allUsers?.filter(
    (user) =>
      (user.AccessProvince === province && user.JobTitle === "Facilitator") ||
      (user.AccessProvince === province && user.JobTitle === "Administrator") ||
      user.AccessRegion === region
  );
  console.log(selectedUserData, "selectedUserData");
  const combinedList = commonUsers?.concat(selectedUserData);
  const filteredUserData = combinedList?.filter(
    (item) => item?.FullName !== currentUser?.FullName
  );
  return filteredUserData ? filteredUserData : [];
}
