import React from "react";
import ThisWeekDetails from "./ThisWeekDetails";
import NextWeekDetails from "./NextWeekDetails";
import LastWeekDetails from "./LastWeekDetails";
import ThisMonthDetails from "./ThisMonthDetails";
import ThisYearDetails from "./ThisYearDetails";
import "./SmtsTabs.css";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";

function SmtsWhereabouts() {
  let headerText = [
    { text: "This Week" },
    { text: "Next Week" },
    { text: "Last Week" },
    { text: "This Month" },
    { text: "This Year" },
  ];

  return (
    <div className="main-tab-container">
      <TabComponent>
        <TabItemsDirective>
          <TabItemDirective header={headerText[0]} content={ThisWeekDetails} />
          <TabItemDirective header={headerText[1]} content={NextWeekDetails} />
          <TabItemDirective header={headerText[2]} content={LastWeekDetails} />
          <TabItemDirective header={headerText[3]} content={ThisMonthDetails} />
          <TabItemDirective header={headerText[4]} content={ThisYearDetails} />
        </TabItemsDirective>
      </TabComponent>
    </div>
  );
}

export default SmtsWhereabouts;
