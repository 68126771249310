import { useEffect, useState } from "react";
import axios from "../../config/axios";

const useProjectSectorDataFetch = () => {
  const [projectSectorData, setProjectSectorData] = useState([]);
  const [isProjectSectorLoaded, setIsProjectSectorLoaded] = useState(false);

  // Define the fetchData function for project Sector change
  const fetchProjectSectorData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/sectorchange"
      );
      setIsProjectSectorLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching project Sector data:", error);
      setIsProjectSectorLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch project Sector change
    fetchProjectSectorData().then((InitialData) => {
      setProjectSectorData(InitialData);
    });
  }, []);

  // Return an object containing all the fetched data
  return {
    projectSectorData,
    isProjectSectorLoaded,
  }
};

export default useProjectSectorDataFetch;
