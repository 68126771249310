import React, { useEffect, useState, useRef } from "react";
import "./ToDoTasks.css";
import "../../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import axios from "../../../../../config/axios";
import {
  GanttComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Edit,
  Selection,
  Filter,
  Toolbar,
} from "@syncfusion/ej2-react-gantt";
import useProjectDataFetch from "../../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../../shared/useOtherDataFetch"; // Import the custom hook
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import TaskDialog from "../../../../shared/TaskDialog";
import EditIcon from "@mui/icons-material/Edit";
import useUserStore from "../../../../../app/user";
import { toast } from "react-toastify";

function ToDoTasks() {
  const user = useUserStore((state) => state.user);
  const {projectData, isProjectLoaded} = useProjectDataFetch(user); // Use the custom hook to fetch data
  const {taskData, isTaskLoaded} = useOtherDataFetch();
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [taskGridData, setTaskGridData] = useState([]);
  const [filteredProjectData, setFilteredProjectData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const currentStage = "Monitoring";
  
  useEffect(() => {
    if (isProjectLoaded && isTaskLoaded){
      const finalProjectData = projectData.filter(
        (project) =>
          project.Stage === currentStage && project.CurrentUser === user?.FullName
      );
      setFilteredProjectData(finalProjectData)
      const matchingTasks = taskData.filter((task) => {
        const matchingProject = finalProjectData.find(
          (project) => project.id === task.ProjectID
        );
        return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
      });
      const filteredTaskData = matchingTasks.filter(
        (task) => task.ProjectStage === currentStage || task.ProjectStage === ""
      );
      setTaskGridData(filteredTaskData);
      setIsDataLoaded(true);
    }
  }, [taskData, isTaskLoaded, projectData, isProjectLoaded]);
  
  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  const taskFields = {
    id: "id",
    name: "TaskName",
    startDate: "StartDate",
    duration: "Duration",
    progress: "Progress",
    parentID: "ParentID",
    status: "Status",
    expandState: "isExpand",
  };

  const timelineSettings = {
    timelineUnitSize: 30,
    timelineViewMode: "Month",
    topTier: { format: "MMM-yyyy", unit: "Month" },
    bottomTier: { unit: "Week", format: "W" },
  };
  const [selectedID, setSelectedID] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("");
  const [selectedProgress, setSelectedProgress] = useState("");
  const [newProgress, setNewProgress] = useState("");

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedDescription(args.data.TaskName);
    setSelectedStartDate(args.data.StartDate);
    setSelectedDuration(args.data.Duration);
    setSelectedProgress(args.data.Progress);
  };

  const handleDialogClose = () => {
    setIsDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsDialogVisible(!isDialogVisible);
  };

  const handleUpdateTaskProgressButton = async (selectedID, newProgress) => {
    // Send the updated data to the API for updating the records
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    const numericNewProgress = parseInt(newProgress);
    try {
      const TaskResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/task/${numericSelectedID}`,
        {
          Progress: numericNewProgress,
          LastUpdatedBy: user?.FullName,
        }
      );

      console.log("Project response:", TaskResponse.data);
      if (TaskResponse.data.status === "success") {
        toast.success("Progress updated successfully.");

        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/task"
        );
        const tasks = updatedResponse.data.items;
        // Filter taskData to get tasks that have a matching ProjectID in projectData
        const matchingTasks = tasks.filter((task) => {
          const matchingProject = filteredProjectData.find(
            (project) => project.id === task.ProjectID
          );
          return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
        });
        const filteredTaskData = matchingTasks.filter(
          (task) =>
            task.ProjectStage === currentStage || task.ProjectStage === ""
        );
        setTaskGridData(filteredTaskData);
      } else {
        console.error(
          "Error updating task progress:",
          TaskResponse.data.message
        );
        toast.error("Error updating progress");
      }
    } catch (error) {
      console.error("Error updating attachment:", error);
      toast.error("Error updating progress");
    }
    toast.dismiss(loadingToastId);
  };

  // Custom template for the "Status" column
  const statusTemplate = (props) => {
    const taskProgress = props.Progress;
    const taskDuration = props.Duration;
    const taskStartDate = new Date(props.StartDate);
    const taskEndDate = new Date(taskStartDate);
    taskEndDate.setDate(taskEndDate.getDate() + props.Duration);
    const currentDate = new Date();
    if (taskProgress === 100) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else if (taskProgress < 100 && currentDate < taskEndDate) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else if ((taskDuration = 0)) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleUpdateTaskProgressButton(selectedID, newProgress);
        setIsDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsDialogVisible(false);
      },
    },
  ];

  const splitterSettings = {
    position: "40%",
  };

  const editSettings = {
    allowEditing: false,
    mode: "Auto",
  };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button
            className="edit-button"
            onClick={handleEditButtonClick}
            disabled={selectedID === ""}>
            <EditIcon className="send-icon" /> Edit{" "}
          </button>
        </div>
      </div>
    );
  };

  const toolbarOptions = [{ template: renderButtons }];

  return (
    <div className="my-to-do-tasks">
      <h3>To Do List of Tasks</h3>
      <br />
      <div className="field-line-my-tasks">
        {isDataLoaded && (
          <GanttComponent
            dataSource={taskGridData}
            taskFields={taskFields}
            timelineSettings={timelineSettings}
            allowSelection={true}
            splitterSettings={splitterSettings}
            editSettings={editSettings}
            allowFiltering={true}
            height="830px"
            width="99%"
            rowSelected={handleRowSelect}
            toolbar={toolbarOptions}>
            <ColumnsDirective>
              <ColumnDirective
                field="TaskName"
                width="300px"
                headerText="Task Description"
              />
              <ColumnDirective field="StartDate" />
              <ColumnDirective field="Duration" width="100px" />
              <ColumnDirective field="Progress" width="90px" />
              <ColumnDirective field="Status" width="90px" />
              <ColumnDirective field="id" width="50px" headerText="id" />
            </ColumnsDirective>
            <Inject services={[Edit, Selection, Filter, Toolbar]} />
          </GanttComponent>
        )}
      </div>
      <div>
        {isDialogVisible && (
          <DialogComponent
            visible={isDialogVisible}
            width="700px"
            header="Update Progress"
            showCloseIcon={true}
            close={handleDialogClose}
            buttons={editButtons}>
            <TaskDialog
              selectedID={selectedID}
              selectedDescription={selectedDescription}
              selectedStartDate={selectedStartDate}
              selectedDuration={selectedDuration}
              selectedProgress={selectedProgress}
              setNewProgress={setNewProgress}
            />
          </DialogComponent>
        )}
      </div>
    </div>
  );
}
export default ToDoTasks;
