import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './components/header/Header';
import { Outlet } from 'react-router-dom';

function MainLayout() {
  const location = useLocation();
  //const navigate = useNavigate();

  // Check if the current location is the login page
  const isLoginPage = location.pathname === '/login';

  return (
    <div>
      {!isLoginPage && (
        <div className='header'>
          <Header />
        </div>
      )}
      <div>
        <Outlet />
      </div>
    </div>
  );
}

export default MainLayout;






