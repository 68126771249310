import React, { useEffect, useRef, useState } from "react";
import "./TaskLists.css";
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import axios from "../../../../config/axios";
import {
  GanttComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Edit,
  Selection,
  Filter,
  ExcelExport,
  PdfExport,
  Toolbar,
} from "@syncfusion/ej2-react-gantt";
import useProjectDataFetch from "../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../shared/useOtherDataFetch"; // Import the custom hook
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import TaskDialog from "../../../shared/TaskDialog";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import useUserStore from "../../../../app/user";
import { toast } from "react-toastify";
import {
  FundedDataSource,
  getUpdateProjectGridData,
  identifiedDataSource,
} from "../../../../utils/project";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import useDateCalculator from "../../../shared/useDateCalculator";
import { dateFormatter } from "../../../../utils/formats";

function TaskLists() {
  const user = useUserStore((state) => state.user);
  const GridRef = useRef(null); // Create the treeGridRef reference
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { taskData, isTaskLoaded } = useOtherDataFetch();
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [taskGridData, setTaskGridData] = useState([]);

  const { beneficiaryData, isBeneficiaryLoaded } = useOtherDataFetch();
  const dates = useDateCalculator();
  const [projectNames, setProjectNames] = useState();
  const [status, setStatus] = useState();
  const [provinces, setProvinces] = useState();
  const [regions, setRegions] = useState();
  const [sectors, setSectors] = useState();
  const [subSectors, setSubSectors] = useState();

  const [projectFilter, setProjectFilter] = useState("All");
  const [provinceFilter, setProvinceFilter] = useState("All");
  const [regionFilter, setRegionFilter] = useState("All");
  const [sectorFilter, setSectorFilter] = useState("All");
  const [subSectorFilter, setSubSectorFilter] = useState("All");
  const [statusFilter, setStatusFilter] = useState("All");
  const [stageFilter, setStageFilter] = useState("All");
  const [phaseFilter, setPhaseFilter] = useState("All");
  const [noCompliantFilter, setNoCompliantFilter] = useState("All");
  const [bankAccountFilter, setBankAccountFilter] = useState("All");
  const [cipcCompliantFilter, setCipcCompliantFilter] = useState("All");
  const [beneficiaryFilter, setBeneficiaryFilter] = useState("All");
  const [identifiedDateFilter, setIdentifiedDateFilter] = useState("All");
  const [fundedDateFilter, setFundedDateFilter] = useState("All");

  const [userLocation, setUserLocation] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // Initial state
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isApiRequest, setIsApiRequest] = useState(true);
  const [updatedProjectData, setUpdatedProjectData] = useState([]);

  useEffect(() => {
    if (isProjectLoaded && isTaskLoaded) {
      const finalProjectData = projectData.filter(
        (project) => project.ProjectStatus !== "Deleted"
      );
      const matchingTasks = taskData.filter((task) => {
        const matchingProject = finalProjectData.find(
          (project) => project.id === task.ProjectID
        );
        return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
      });
      const filteredTaskData = matchingTasks.filter((task) => {
        const taskDuration = task.Duration;
        return taskDuration > 0;
      });
      const stageParentIDs = filteredTaskData?.map((task) => task.ParentID);
      const stageTasks = taskData.filter((task) =>
        stageParentIDs.includes(task.id)
      );
      const combinedTaskList = stageTasks.concat(filteredTaskData);
      const projectParentIDs = stageTasks.map((task) => task.ParentID);
      const projectTasks = taskData.filter((task) =>
        projectParentIDs.includes(task.id)
      );
      const updatedCombinedTaskList = projectTasks.concat(combinedTaskList);
      setTaskGridData(updatedCombinedTaskList);
      setUpdatedProjectData(projectData);
      setIsDataLoaded(true);
    }
  }, [projectData, taskData, isTaskLoaded, isProjectLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded && !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  useEffect(() => {
    //Get DropdownData
    const uniqueProvinces = [
      ...new Set(
        projectData?.map((item) => (item.Province ? item.Province : ""))
      ),
    ];
    const formattedUniqueProvinces = ["All", ...uniqueProvinces];
    setProvinces(formattedUniqueProvinces);

    const uniqueProjects = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.ProjectName ? item.ProjectName : ""
        )
      ),
    ];
    const formattedUniqueProjects = ["All", ...uniqueProjects];
    setProjectNames(formattedUniqueProjects);

    const uniqueregions = [
      ...new Set(
        updatedProjectData?.map((item) => (item.Region ? item.Region : ""))
      ),
    ];
    const formattedUniqueregions = ["All", ...uniqueregions];
    setRegions(formattedUniqueregions);

    const uniqueProjectstatus = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.ProjectStatus ? item.ProjectStatus : ""
        )
      ),
    ];
    const formattedUniqueProjectstatus = ["All", ...uniqueProjectstatus];
    setStatus(formattedUniqueProjectstatus);

    const uniqueSectors = [
      ...new Set(
        updatedProjectData?.map((item) => (item.Sector ? item.Sector : ""))
      ),
    ];
    const formattedUniqueSectors = ["All", ...uniqueSectors];
    setSectors(formattedUniqueSectors);

    const uniqueSubSectors = [
      ...new Set(
        updatedProjectData?.map((item) =>
          item.SubSector ? item.SubSector : ""
        )
      ),
    ];
    const formattedUniqueSubSectors = ["All", ...uniqueSubSectors];
    setSubSectors(formattedUniqueSubSectors);
  }, [updatedProjectData]);

  useEffect(() => {
    if (!isApiRequest) {
      const updatedData = getUpdateProjectGridData(
        updatedProjectData,
        beneficiaryData,
        provinceFilter,
        regionFilter,
        statusFilter,
        sectorFilter,
        subSectorFilter,
        stageFilter,
        phaseFilter,
        noCompliantFilter,
        bankAccountFilter,
        cipcCompliantFilter,
        beneficiaryFilter,
        identifiedDateFilter,
        fundedDateFilter,
        projectFilter,
        dates
      );

      const finalProjectData = updatedData.filter(
        (project) => project.ProjectStatus !== "Deleted"
      );
      const matchingTasks = taskData.filter((task) => {
        const matchingProject = finalProjectData.find(
          (project) => project.id === task.ProjectID
        );
        return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
      });
      const filteredTaskData = matchingTasks.filter((task) => {
        const taskDuration = task.Duration;
        return taskDuration > 0;
      });
      const stageParentIDs = filteredTaskData?.map((task) => task.ParentID);
      const stageTasks = taskData.filter((task) =>
        stageParentIDs.includes(task.id)
      );
      const combinedTaskList = stageTasks.concat(filteredTaskData);
      const projectParentIDs = stageTasks.map((task) => task.ParentID);
      const projectTasks = taskData.filter((task) =>
        projectParentIDs.includes(task.id)
      );
      const updatedCombinedTaskList = projectTasks.concat(combinedTaskList);
      setTaskGridData(updatedCombinedTaskList);

      setIsDataChanged(true);
      setIsApiRequest(true);
    }
  }, [
    updatedProjectData,
    beneficiaryData,
    provinceFilter,
    regionFilter,
    statusFilter,
    sectorFilter,
    subSectorFilter,
    stageFilter,
    phaseFilter,
    noCompliantFilter,
    bankAccountFilter,
    cipcCompliantFilter,
    beneficiaryFilter,
    identifiedDateFilter,
    fundedDateFilter,
    projectFilter,
    dates,
  ]);

  const handleProvinceChange = (e) => {
    if (e && e.value) {
      let provinceFilteredData;
      if (e.value !== "All") {
        provinceFilteredData = updatedProjectData?.filter(
          (item) => item.Province === e.value
        );
      } else {
        provinceFilteredData = updatedProjectData;
      }
      const uniqueregions = [
        ...new Set(
          provinceFilteredData?.map((item) => (item.Region ? item.Region : ""))
        ),
      ];
      const formattedUniqueregions = ["All", ...uniqueregions];
      setRegions(formattedUniqueregions);

      const uniqueProjects = [
        ...new Set(
          provinceFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setProvinceFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleRegionChange = (e) => {
    if (e && e.value) {
      let regionFilteredData;
      if (e.value !== "All") {
        regionFilteredData = updatedProjectData?.filter(
          (item) => item.Region === e.value
        );
      } else {
        regionFilteredData = updatedProjectData;
      }
      const uniqueProjects = [
        ...new Set(
          regionFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setRegionFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleStatusChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setStatusFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleSectorChange = (e) => {
    if (e && e.value) {
      let sectorFilteredData;
      if (e.value !== "All") {
        sectorFilteredData = updatedProjectData?.filter(
          (item) => item.Sector === e.value
        );
      } else {
        sectorFilteredData = updatedProjectData;
      }
      const uniqueSubSectors = [
        ...new Set(
          sectorFilteredData?.map((item) =>
            item.SubSector ? item.SubSector : ""
          )
        ),
      ];
      const formattedUniqueSubSectors = ["All", ...uniqueSubSectors];
      setSubSectors(formattedUniqueSubSectors);

      const uniqueProjects = [
        ...new Set(
          sectorFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setSectorFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleSubSectorChange = (e) => {
    if (e && e.value) {
      let subSectorFilteredData;
      if (e.value !== "All") {
        subSectorFilteredData = updatedProjectData?.filter(
          (item) => item.SubSector === e.value
        );
      } else {
        subSectorFilteredData = updatedProjectData;
      }

      const uniqueProjects = [
        ...new Set(
          subSectorFilteredData?.map((item) =>
            item.ProjectName ? item.ProjectName : ""
          )
        ),
      ];
      const formattedUniqueProjects = ["All", ...uniqueProjects];
      setProjectNames(formattedUniqueProjects);

      setIsDataChanged(false);
      setSubSectorFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleProjectChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setProjectFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleStageChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setStageFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handlePhaseChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setPhaseFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleNonComliantChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setNoCompliantFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleBankChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setBankAccountFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleCipcChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setCipcCompliantFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleBeneficiaryChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setBeneficiaryFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleIdentificationChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setIdentifiedDateFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const handleFundingChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setFundedDateFilter(e.value);
      setIsApiRequest(false);
    }
  };

  const taskFields = {
    id: "id",
    name: "TaskName",
    startDate: "StartDate",
    duration: "Duration",
    progress: "Progress",
    parentID: "ParentID",
    status: "Status",
    expandState: "isExpand",
  };

  const timelineSettings = {
    timelineUnitSize: 30,
    timelineViewMode: "Month",
    topTier: { format: "MMM-yyyy", unit: "Month" },
    bottomTier: { unit: "Week", format: "W" },
  };
  const [selectedID, setSelectedID] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("");
  const [selectedProgress, setSelectedProgress] = useState("");
  const [newProgress, setNewProgress] = useState("");

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedDescription(args.data.TaskName);
    setSelectedStartDate(args.data.StartDate);
    setSelectedDuration(args.data.Duration);
    setSelectedProgress(args.data.Progress);
  };

  const currentTitleDate = dateFormatter(new Date());
  const handleExcelButtonClick = () => {
    if (GridRef.current && GridRef.current.excelExport) {
      const exportProperties = {
        pageOrientation: "Landscape",
        fileName:
          "Task List Printed by " +
          user.FullName +
          " on " +
          currentTitleDate +
          ".xlsx",
      };
      GridRef.current.excelExport(exportProperties);
    }
  };

  const handlePdfButtonClick = () => {};

  const handleDialogClose = () => {
    setIsDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsDialogVisible(!isDialogVisible);
  };

  const handleUpdateTaskProgressButton = async (selectedID, newProgress) => {
    // Send the updated data to the API for updating the records
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    const numericNewProgress = parseInt(newProgress);
    try {
      const TaskResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/task/${numericSelectedID}`,
        {
          Progress: numericNewProgress,
          LastUpdatedBy: user?.FullName,
        }
      );
      console.log("Project response:", TaskResponse.data);
      if (TaskResponse.data.status === "success") {
        toast.success("Progress updated successfully.");
        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/task"
        );
        const tasks = updatedResponse.data.items;
        const matchingTasks = tasks.filter((task) => {
          return taskGridData.some((item) => item.id === task.id);
        });
        setTaskGridData(matchingTasks);
      } else {
        console.error(
          "Error updating task progress:",
          TaskResponse.data.message
        );
        toast.error("Error updating progress");
      }
    } catch (error) {
      console.error("Error updating progress:", error);
      toast.error("Error updating progress");
    }
    toast.dismiss(loadingToastId);
  };

  // Custom template for the "Status" column
  const statusTemplate = (props) => {
    const taskProgress = props.Progress;
    const taskDuration = props.Duration;
    const taskStartDate = new Date(props.StartDate);
    const taskEndDate = new Date(taskStartDate);
    taskEndDate.setDate(taskEndDate.getDate() + taskDuration);
    const currentDate = new Date();
    if (taskProgress === 100) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else if (taskProgress < 100 && currentDate < taskEndDate) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else if ((taskDuration = 0)) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleUpdateTaskProgressButton(selectedID, newProgress);
        setIsDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsDialogVisible(false);
      },
    },
  ];

  const splitterSettings = {
    position: "40%",
  };

  const editSettings = {
    allowEditing: false,
    mode: "Auto",
  };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          {user?.JobTitle === "Administrator" && (
            <button
              className="edit-button"
              onClick={handleEditButtonClick}
              disabled={selectedID === ""}
            >
              <EditIcon className="send-icon" /> Edit{" "}
            </button>
          )}
        </div>
        <div className="button-container-subA2">
          <button className="add-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          {/* <button className="edit-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button> */}
        </div>
      </div>
    );
  };

  const toolbarOptions = [{ template: renderButtons }];

  return (
    <div className="my-to-do-tasks">
      <div className="summary-container-project">
        <div className="decicion_requester1">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Province"
            floatLabelType="Always"
            dataSource={provinces}
            change={handleProvinceChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Region"
            floatLabelType="Always"
            dataSource={regions}
            change={handleRegionChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Sector"
            floatLabelType="Always"
            dataSource={sectors}
            change={handleSectorChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Sub-Sector"
            floatLabelType="Always"
            dataSource={subSectors}
            change={handleSubSectorChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Project Status"
            floatLabelType="Always"
            dataSource={status}
            change={handleStatusChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Project"
            floatLabelType="Always"
            dataSource={projectNames}
            change={handleProjectChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Phase"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Phase 1: Coordinators",
              "Phase 2: Facilitattors",
              "Phase 3: NF",
            ]}
            change={handlePhaseChange}
          />
        </div>
      </div>
      <div className="summary-container-project">
        <div className="decicion_requester1">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Selected Stage"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Identification",
              "Capacity Building",
              "Funding",
              "Monitoring",
            ]}
            change={handleStageChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Overall Compliant"
            floatLabelType="Always"
            dataSource={["All", "Compliant", "Non-Compliant"]}
            change={handleNonComliantChange}
          />
        </div>
        <div className="decicion_requester">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Beneficiary Compliant"
            floatLabelType="Always"
            dataSource={[
              "All",
              "Sufficiant Beneficiaries",
              "Insufficiant Beneficiaries",
            ]}
            change={handleBeneficiaryChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Bank Compliant"
            floatLabelType="Always"
            dataSource={["All", "With Bank Account", "No Bank Account"]}
            change={handleBankChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="CIPC Compliant"
            floatLabelType="Always"
            dataSource={["All", "CIPC Compliant", "CIPC Non-Compliant"]}
            change={handleCipcChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Identification Date"
            floatLabelType="Always"
            dataSource={identifiedDataSource}
            change={handleIdentificationChange}
          />
        </div>
        <div className="decicion_approver">
          <ComboBoxComponent
            id="comboelement"
            value="All"
            placeholder="Funding Date"
            floatLabelType="Always"
            dataSource={FundedDataSource}
            change={handleFundingChange}
          />
        </div>
      </div>
      <h3>List of All Tasks</h3>
      <br />
      <div className="field-line-my-tasks">
        {taskGridData.length > 0 && (
          <GanttComponent
            dataSource={taskGridData}
            taskFields={taskFields}
            timelineSettings={timelineSettings}
            allowSelection={true}
            allowExcelExport={true}
            allowPdfExport={true}
            splitterSettings={splitterSettings}
            editSettings={editSettings}
            allowFiltering={true}
            height="830px"
            width="99%"
            ref={GridRef}
            rowSelected={handleRowSelect}
            toolbar={toolbarOptions}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="TaskName"
                width="300px"
                headerText="Task Description"
              />
              <ColumnDirective field="StartDate" />
              <ColumnDirective field="Duration" width="100px" />
              <ColumnDirective field="Progress" width="90px" />
              <ColumnDirective field="Status" width="90px" />
              <ColumnDirective field="id" width="50px" headerText="id" />
            </ColumnsDirective>
            <Inject
              services={[
                Edit,
                Selection,
                Filter,
                Toolbar,
                ExcelExport,
                PdfExport,
              ]}
            />
          </GanttComponent>
        )}
      </div>
      <div>
        {isDialogVisible && (
          <DialogComponent
            visible={isDialogVisible}
            width="700px"
            header="Update Progress"
            showCloseIcon={true}
            close={handleDialogClose}
            buttons={editButtons}
          >
            <TaskDialog
              selectedID={selectedID}
              selectedDescription={selectedDescription}
              selectedStartDate={selectedStartDate}
              selectedDuration={selectedDuration}
              selectedProgress={selectedProgress}
              setNewProgress={setNewProgress}
            />
          </DialogComponent>
        )}
      </div>
    </div>
  );
}
export default TaskLists;
