import { useEffect, useState } from "react";
import axios from "../../config/axios";

const useSmtsDataFetch = () => {
  const [smtsData, setSmtsData] = useState([]);
  const [isSmtsLoaded, setIsSmtsLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchSmtsData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/smts"
      );
      setIsSmtsLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching smts data:", error);
      setIsSmtsLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    fetchSmtsData().then((smtsInitialData) => {
      setSmtsData(smtsInitialData);
    });
  }, []);

  // Return an object containing all the fetched data
  return {
    smtsData,
    isSmtsLoaded,
  };
};

export default useSmtsDataFetch;
