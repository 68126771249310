import React from 'react'
import '../Options.css'

function CompliancyOption({Icon, title}) {
  return (
    <div className='projectOption'>
        {Icon && <Icon className='custom_icon' />}
        <h3 className='projectOption_title'>{title}</h3>
    </div>
  )
}

export default CompliancyOption