import React from 'react'
import { Outlet } from 'react-router-dom';
import Attachments from './Attachment';
import '../MainBody.css'


function AttachmentLayout() {
  return (
    <div className='projects_container'>
      <div className='projects_sidebar'>
        <Attachments />
      </div>
      <div className='projects_main'>
        <Outlet />
      </div>
    </div>
  )
}

export default AttachmentLayout