import { useEffect, useState } from "react";
import axios from "../../config/axios";

const useBeneficiaryBankDataFetch = () => {
  const [beneficiaryBankData, setBeneficiaryBankData] = useState([]);
  const [isBeneficiaryBankLoaded, setBeneficiaryBankLoaded] = useState(false);

  // Define the fetchData function for project Bank change
  const fetchBeneficiaryBankData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/benbankchange"
      );
      setBeneficiaryBankLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching Bank data:", error);
      setBeneficiaryBankLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch Bank change
    fetchBeneficiaryBankData().then((InitialData) => {
      setBeneficiaryBankData(InitialData);
    });
  }, []);

  // Return an object containing all the fetched data
  return {
    beneficiaryBankData,
    isBeneficiaryBankLoaded,
  }
};

export default useBeneficiaryBankDataFetch;
