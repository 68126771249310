import React, { useEffect, useState, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import "../../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import "../../../../../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
import "../../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../../../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import axios from "../../../../../config/axios";
import "./ToDoBeneficiaries.css";
import useOtherDataFetch from "../../../../shared/useOtherDataFetch"; // Import the custom hook
import useProjectDataFetch from "../../../../shared/useProjectDataFetch"; // Import the custom hook
import BeneficiaryDialog from "../../../../shared/BeneficiaryDialog";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import useUserStore from "../../../../../app/user";
import { toast } from "react-toastify";

function ToDoBeneficiaries() {
  const user = useUserStore((state) => state.user);
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { beneficiaryData, isBeneficiaryLoaded } = useOtherDataFetch();
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
  const [filteredProjectData, setFilteredProjectData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const newCurrentDate = new Date();
  //const gridData = filteredProjectData
  const [gridData, setGridData] = useState([]);

  const currentStage = "Monitoring";
  useEffect(() => {
    if (isProjectLoaded && isBeneficiaryLoaded) {
      const finalProjectData = projectData.filter(
        (project) =>
          project.Stage === currentStage &&
          project.CurrentUser === user?.FullName
      );
      setFilteredProjectData(finalProjectData);
      const matchingBeneficiaries = beneficiaryData.filter((beneficiary) => {
        const matchingProject = finalProjectData.find(
          (project) => project.ProjectNum === beneficiary.ProjNum
        );
        return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
      });
      setGridData(matchingBeneficiaries);
      setIsDataLoaded(true);
    }
  }, [beneficiaryData, isBeneficiaryLoaded, projectData, isProjectLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  // Get unique banks from projectData
  const uniqueBanks = [
    ...new Set(
      projectData?.map((item) => (item.BankName ? item.BankName : ""))
    ),
  ];
  // Get unique branch from projectData
  const uniqueBranches = [
    ...new Set(
      projectData?.map((item) => (item.BranchName ? item.BranchName : ""))
    ),
  ];
  // Get unique account types from projectData
  const uniqueTypes = [
    ...new Set(
      projectData?.map((item) => (item.AccountType ? item.AccountType : ""))
    ),
  ];

  const uniqueProjects = [
    ...new Set(
      filteredProjectData?.map((item) =>
        item.ProjectNum ? item.ProjectNum : ""
      )
    ),
  ];

  const [selectedID, setSelectedID] = useState("");
  const [selectedProjNum, setSelectedProjNum] = useState("");
  const [selectedID_Num, setSelectedID_Num] = useState("");
  const [selectedInitials, setSelectedInitials] = useState("");
  const [selectedFirstNames, setSelectedFirstNames] = useState("");
  const [selectedD_O_B, setSelectedD_O_B] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedLastName, setSelectedLastName] = useState("");
  const [selectedRace, setSelectedRace] = useState("");
  const [selectedHomeLanguage, setSelectedHomeLanguage] = useState("");
  const [selectedCellNumber, setSelectedCellNumber] = useState("");
  const [selectedEmailAddress, setSelectedEmailAddress] = useState("");
  const [selectedPhysicalAddress, setSelectedPhysicalAddress] = useState("");
  const [selectedPostalAddress, setSelectedPostalAddress] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedAccountHolder, setSelectedAccountHolder] = useState("");
  const [selectedAccountNum, setSelectedAccountNum] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const [selectedBankName, setSelectedBankName] = useState("");
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [selectedBranchCode, setSelectedBranchCode] = useState("");

  const [newProjNum, setNewProjNum] = useState("");
  const [newID_Num, setNewID_Num] = useState("");
  const [newInitials, setNewInitials] = useState("");
  const [newFirstNames, setNewFirstNames] = useState("");
  const [newD_O_B, setNewD_O_B] = useState("");
  const [newGender, setNewGender] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newRace, setNewRace] = useState("");
  const [newHomeLanguage, setNewHomeLanguage] = useState("");
  const [newCellNumber, setNewCellNumber] = useState("");
  const [newEmailAddress, setNewEmailAddress] = useState("");
  const [newPhysicalAddress, setNewPhysicalAddress] = useState("");
  const [newPostalAddress, setNewPostalAddress] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [newAccountHolder, setNewAccountHolder] = useState("");
  const [newAccountNum, setNewAccountNum] = useState("");
  const [newAccountType, setNewAccountType] = useState("");
  const [newBankName, setNewBankName] = useState("");
  const [newBranchName, setNewBranchName] = useState("");
  const [newBranchCode, setNewBranchCode] = useState("");

  const beneficiaryNewData = {
    ProjNum: newProjNum,
    ID_Num: newID_Num,
    Initials: newInitials,
    FirstNames: newFirstNames,
    D_O_B: newD_O_B,
    Gender: newGender,
    LastName: newLastName,
    Race: newRace,
    HomeLanguage: newHomeLanguage,
    CellNumber: newCellNumber,
    EmailAddress: newEmailAddress,
    PhysicalAddress: newPhysicalAddress,
    PostalAddress: newPostalAddress,
    AccountHolder: newAccountHolder,
    AccountNum: newAccountNum,
    AccountType: newAccountType,
    BankName: newBankName,
    BranchName: newBranchName,
    BranchCode: newBranchCode,
    BenStatus: newStatus,
    CreatedBy: user?.FullName,
    CreatedOn: newCurrentDate,
  };

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedProjNum(args.data.ProjNum);
    setSelectedID_Num(args.data.ID_Num);
    setSelectedInitials(args.data.Initials);
    setSelectedD_O_B(args.data.D_O_B);
    setSelectedFirstNames(args.data.FirstNames);
    setSelectedLastName(args.data.LastName);
    setSelectedGender(args.data.Gender);
    setSelectedRace(args.data.Race);
    setSelectedHomeLanguage(args.data.HomeLanguage);
    setSelectedCellNumber(args.data.CellNumber);
    setSelectedEmailAddress(args.data.EmailAddress);
    setSelectedPhysicalAddress(args.data.PhysicalAddress);
    setSelectedPostalAddress(args.data.PostalAddress);
    setSelectedStatus(args.data.BenStatus);
    setSelectedAccountHolder(args.data.AccountHolder);
    setSelectedAccountNum(args.data.AccountNum);
    setSelectedAccountType(args.data.AccountType);
    setSelectedBankName(args.data.BankName);
    setSelectedBranchName(args.data.BranchName);
    setSelectedBranchCode(args.data.BranchCode);
    if (args.data.ProjNum) {
      const project = projectData.find(
        (project) => project.ProjectNum === args.data.ProjNum
      );
      const projectName = project.ProjectName;
      setSelectedProjectName(projectName);
    } else {
      setSelectedProjectName("");
    }
  };

  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };

  const handleAddDialogClose = () => {
    setIsAddDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsEditDialogVisible(!isEditDialogVisible);
    setNewProjNum(selectedProjNum);
    setNewID_Num(selectedID_Num);
    setNewInitials(selectedInitials);
    setNewD_O_B(selectedD_O_B);
    setNewFirstNames(selectedFirstNames);
    setNewLastName(selectedLastName);
    setNewGender(selectedGender);
    setNewRace(selectedRace);
    setNewHomeLanguage(selectedHomeLanguage);
    setNewCellNumber(selectedCellNumber);
    setNewEmailAddress(selectedEmailAddress);
    setNewPhysicalAddress(selectedPhysicalAddress);
    setNewPostalAddress(selectedPostalAddress);
    setNewStatus(selectedStatus);
  };

  const handleAddButtonClick = () => {
    setIsAddDialogVisible(!isAddDialogVisible);
    setSelectedID("");
    setSelectedProjNum("");
    setSelectedID_Num("");
    setSelectedInitials("");
    setSelectedD_O_B("");
    setSelectedFirstNames("");
    setSelectedLastName("");
    setSelectedGender("");
    setSelectedRace("");
    setSelectedHomeLanguage("");
    setSelectedCellNumber("");
    setSelectedEmailAddress("");
    setSelectedPhysicalAddress("");
    setSelectedPostalAddress("");
    setSelectedStatus("");
    setSelectedAccountHolder("");
    setSelectedAccountNum("");
    setSelectedAccountType("");
    setSelectedBankName("");
    setSelectedBranchName("");
    setSelectedBranchCode("");
    if (GridRef.current) {
      GridRef.current.clearSelection();
    }
    setNewProjNum("");
    setNewID_Num("");
    setNewInitials("");
    setNewD_O_B("");
    setNewFirstNames("");
    setNewLastName("");
    setNewGender("");
    setNewRace("");
    setNewHomeLanguage("");
    setNewCellNumber("");
    setNewEmailAddress("");
    setNewPhysicalAddress("");
    setNewPostalAddress("");
    setNewStatus("");
    setNewAccountHolder("");
    setNewAccountNum("");
    setNewAccountType("");
    setNewBankName("");
    setNewBranchName("");
    setNewBranchCode("");
  };

  const handleAddBeneficiaryButton = async () => {
    // Make a POST request to add the beneficiary record
    const loadingToastId = toast.loading("Please wait...");
    try {
      const BeneficiaryResponse = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/beneficiary",
        beneficiaryNewData
      );
      console.log("Beneficiary response:", BeneficiaryResponse.data);
      if (BeneficiaryResponse.data.status === "success") {
        toast.success("Beneficiary added successfully.");
        // Get the updated by making a GET request
        const BeneficiaryResponse2 = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/beneficiary"
        );
        const beneficiaries = BeneficiaryResponse2.data.items;
        const matchingBeneficiaries = beneficiaries.filter((beneficiary) => {
          const matchingProject = filteredProjectData.find(
            (project) => project.ProjectNum === beneficiary.ProjNum
          );
          return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
        });
        setGridData(matchingBeneficiaries);
      } else {
        console.error(
          "Error adding beneficiary record:",
          BeneficiaryResponse.data.message
        );
        toast.error("Error adding beneficiary");
      }
    } catch (error) {
      console.error("Error adding Beneficiary:", error);
      toast.error("Error adding beneficiary");
    }
    toast.dismiss(loadingToastId);
  };

  const handleUpdateBeneficiaryButton = async (selectedID) => {
    // Send the updated data to the API for updating the records
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    try {
      const BeneficiaryResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL +
          `/api/beneficiary/${numericSelectedID}`,
        {
          ProjNum: newProjNum,
          ID_Num: newID_Num,
          Initials: newInitials,
          FirstNames: newFirstNames,
          D_O_B: newD_O_B,
          Gender: newGender,
          LastName: newLastName,
          Race: newRace,
          HomeLanguage: newHomeLanguage,
          CellNumber: newCellNumber,
          EmailAddress: newEmailAddress,
          PhysicalAddress: newPhysicalAddress,
          PostalAddress: newPostalAddress,
          BenStatus: newStatus,
          AccountHolder: newAccountHolder,
          AccountNum: newAccountNum,
          AccountType: newAccountType,
          BankName: newBankName,
          BranchName: newBranchName,
          BranchCode: newBranchCode,
          LastUpdatedBy: user?.FullName,
          LastUpdated: newCurrentDate,
        }
      );
      console.log("Beneficiary response:", BeneficiaryResponse.data);
      if (BeneficiaryResponse.data.status === "success") {
        toast.success("Beneciary updated successfully.");
        // Get the updated by making a GET request
        const BeneficiaryResponse2 = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/beneficiary"
        );
        const beneficiaries = BeneficiaryResponse2.data.items;
        const matchingBeneficiaries = beneficiaries.filter((beneficiary) => {
          const matchingProject = filteredProjectData.find(
            (project) => project.ProjectNum === beneficiary.ProjNum
          );
          return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
        });
        setGridData(matchingBeneficiaries);
      } else {
        console.error(
          "Error updating beneficiary record:",
          BeneficiaryResponse.data.message
        );
        toast.error("Error updating beneficiary");
      }
    } catch (error) {
      console.error("Error updating beneficiary:", error);
      toast.error("Error updating beneficiary");
    }
    toast.dismiss(loadingToastId);
  };

  const projectNameTemplate = (props) => {
    const projectNum = props.ProjNum; // Assuming the field name is "ProjectNum"
    // Lookup the project name in projectData based on the provided projectNum
    const project = projectData.find(
      (project) => project.ProjectNum === projectNum
    );
    // Check if a project with the given projectNum was found
    if (project) {
      const projectName = project.ProjectName;
      return <div>{projectName}</div>;
    } else {
      // Handle the case where no matching project was found
      return <div>Project Not Found</div>;
    }
  };

  const statusTemplate = (props) => {
    const status = props.BenStatus; // Assuming the status field is named "Status"
    let colorClass = "";

    if (status === "Active") {
      colorClass = "status-active";
    } else if (status === "Resigned") {
      colorClass = "status-inactive";
    } else if (status === "Deceased") {
      colorClass = "status-delayed";
    }

    return <div className={`status-cell ${colorClass}`}>{status}</div>;
  };

  const ageTemplate = (props) => {
    if (!props.D_O_B) {
      return <span>N/A</span>; // Age can't be calculated if D_O_B is missing or blank
    }
    const dob = new Date(props.D_O_B); // Assuming 'D_O_B' is the Date of Birth field

    if (!isNaN(dob.getTime())) {
      const currentDate = new Date();
      const ageInMilliseconds = currentDate - dob;
      const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Approximate calculation for leap years
      return <span>{Math.floor(ageInYears)}</span>;
    } else {
      return <span>N/A</span>; // Age can't be calculated if D_O_B is missing or not valid
    }
  };

  const ageGroupTemplate = (props) => {
    if (!props.D_O_B) {
      return <span>N/A</span>; // Age can't be calculated if D_O_B is missing or blank
    }
    const dob = new Date(props.D_O_B); // Assuming 'D_O_B' is the Date of Birth field
    if (!isNaN(dob.getTime())) {
      const currentDate = new Date();
      const ageInMilliseconds = currentDate - dob;
      const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Approximate calculation for leap years
      let ageGroup = "N/A";
      if (ageInYears < 36) {
        ageGroup = "Youth";
      } else if (ageInYears < 65) {
        ageGroup = "Adult";
      } else if (ageInYears >= 65) {
        ageGroup = "Pensioner";
      }
      return <span>{ageGroup}</span>;
    } else {
      return <span>N/A</span>; // Age group can't be determined if D_O_B is missing or not valid
    }
  };

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];
  const loadingIndicator = { indicatorType: "Spinner" };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button className="add-button" onClick={handleAddButtonClick}>
            <AddIcon className="send-icon" /> Add{" "}
          </button>
          <button
            className="edit-button"
            onClick={handleEditButtonClick}
            disabled={selectedID === ""}
          >
            <EditIcon className="send-icon" /> Edit{" "}
          </button>
        </div>
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  let addButtons = [
    {
      buttonModel: {
        content: "Add",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleAddBeneficiaryButton(selectedID);
        setIsAddDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsAddDialogVisible(false);
      },
    },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleUpdateBeneficiaryButton(selectedID);
        setIsEditDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  return (
    <div className="my-to-do-beneficiaries">
      <h3>To Do List of Beneficiaries</h3>
      <br />
      <div className="field-line-my-beneficiaries">
        {isDataLoaded && (
          <GridComponent
            dataSource={gridData}
            childMapping="items"
            ref={GridRef}
            classClass="custom-treegrid-class"
            height="650"
            allowPaging={true}
            allowSorting={true}
            allowFiltering={true}
            showColumnChooser={true}
            filterSettings={FilterOptions}
            pageSettings={pageSettings}
            editSettings={editOptions}
            toolbar={updatedToolbarOptions}
            loadingIndicator={loadingIndicator}
            allowSelection={true}
            rowSelected={handleRowSelect}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="id"
                headerText="ID"
                width="100"
                textAlign="Left"
                isPrimaryKey={true}
              />
              <ColumnDirective
                field="ProjNum"
                headerText="Project #"
                width="120"
                textAlign="Left"
              />
              <ColumnDirective
                headerText="Project Name"
                width="160"
                textAlign="Left"
                template={projectNameTemplate}
              />
              <ColumnDirective
                field="Initials"
                headerText="Initials"
                width="70"
                textAlign="Left"
              />
              <ColumnDirective
                field="FirstNames"
                headerText="First Names"
                width="200"
                textAlign="Left"
              />
              <ColumnDirective
                field="LastName"
                headerText="LastName"
                width="120"
                textAlign="Left"
              />
              <ColumnDirective
                field="BenStatus"
                headerText="Status"
                width="90"
                textAlign="Left"
                template={statusTemplate}
              />
              <ColumnDirective
                field="ID_Num"
                headerText="ID Number"
                width="150"
                textAlign="Left"
              />
              <ColumnDirective
                field="D_O_B"
                headerText="Date of Birth"
                width="120"
                format="yyyy/MM/dd"
                textAlign="Left"
                type="date"
              />
              <ColumnDirective
                headerText="Age"
                width="80"
                textAlign="Left"
                template={ageTemplate}
              />
              <ColumnDirective
                headerText="Age Group"
                width="120"
                textAlign="Left"
                template={ageGroupTemplate}
              />
              <ColumnDirective
                field="Gender"
                headerText="Gender"
                width="90"
                textAlign="Left"
                editType="dropdownedit"
              />
              <ColumnDirective
                field="Race"
                headerText="Race"
                width="70"
                textAlign="Left"
                editType="dropdownedit"
              />
              <ColumnDirective
                field="HomeLanguage"
                headerText="Language"
                width="100"
                textAlign="Left"
                editType="dropdownedit"
              />
              <ColumnDirective
                field="CellNumber"
                headerText="Cell Number"
                width="120"
                textAlign="Left"
              />
              <ColumnDirective
                field="EmailAddress"
                headerText="Email Address"
                width="250"
                textAlign="Left"
              />
              <ColumnDirective
                field="PhysicalAddress"
                headerText="Physical Address"
                width="350"
                textAlign="Left"
              />
              <ColumnDirective
                field="PostalAddress"
                headerText="Postal Address"
                width="350"
                textAlign="Left"
              />
              <ColumnDirective
                field="ActiveBankAcc"
                headerText="Active Bank?"
                width="60"
                textAlign="Left"
              />
              <ColumnDirective
                field="AccountHolder"
                headerText="Account Holder"
                width="150"
                textAlign="Left"
              />
              <ColumnDirective
                field="AccountNum"
                headerText="Account Number"
                width="150"
                textAlign="Left"
              />
              <ColumnDirective
                field="AccountType"
                headerText="Account Type"
                width="120"
                textAlign="Left"
              />
              <ColumnDirective
                field="BankName"
                headerText="Bank Name"
                width="130"
                textAlign="Left"
              />
              <ColumnDirective
                field="BranchName"
                headerText="Branch Name"
                width="120"
                textAlign="Left"
              />
              <ColumnDirective
                field="BranchCode"
                headerText="Branch Code"
                width="120"
                textAlign="Left"
              />
              <ColumnDirective
                field="LastUpdated"
                headerText="Last Updated"
                width="120"
                format="yyyy/MM/dd"
                textAlign="Right"
                type="date"
              />
              <ColumnDirective
                field="LastUpdatedBy"
                headerText="Last Updated By"
                width="150"
                textAlign="Right"
              />
            </ColumnsDirective>
            <Inject
              services={[Page, Sort, Filter, Edit, Toolbar, ColumnChooser]}
            />
          </GridComponent>
        )}
      </div>
      <div>
        {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="80%"
            height="580px"
            header="Edit Beneficiary"
            allowDragging={true}
            showCloseIcon={true}
            close={handleEditDialogClose}
            buttons={editButtons}
          >
            <BeneficiaryDialog
              selectedID={selectedID}
              selectedProjNum={selectedProjNum}
              selectedProjectName={selectedProjectName}
              selectedID_Num={selectedID_Num}
              selectedInitials={selectedInitials}
              selectedD_O_B={selectedD_O_B}
              selectedFirstNames={selectedFirstNames}
              selectedLastName={selectedLastName}
              selectedGender={selectedGender}
              selectedRace={selectedRace}
              selectedHomeLanguage={selectedHomeLanguage}
              selectedCellNumber={selectedCellNumber}
              selectedEmailAddress={selectedEmailAddress}
              selectedPhysicalAddress={selectedPhysicalAddress}
              selectedPostalAddress={selectedPostalAddress}
              selectedStatus={selectedStatus}
              selectedAccountHolder={selectedAccountHolder}
              selectedAccountNum={selectedAccountNum}
              selectedAccountType={selectedAccountType}
              selectedBankName={selectedBankName}
              selectedBranchName={selectedBranchName}
              selectedBranchCode={selectedBranchCode}
              filteredProjectData={filteredProjectData}
              uniqueBanks={uniqueBanks}
              uniqueBranches={uniqueBranches}
              uniqueTypes={uniqueTypes}
              setNewProjNum={setNewProjNum}
              setNewID_Num={setNewID_Num}
              setNewInitials={setNewInitials}
              setNewD_O_B={setNewD_O_B}
              setNewFirstNames={setNewFirstNames}
              setNewLastName={setNewLastName}
              setNewGender={setNewGender}
              setNewRace={setNewRace}
              setNewHomeLanguage={setNewHomeLanguage}
              setNewCellNumber={setNewCellNumber}
              setNewEmailAddress={setNewEmailAddress}
              setNewPhysicalAddress={setNewPhysicalAddress}
              setNewPostalAddress={setNewPostalAddress}
              setNewStatus={setNewStatus}
              setNewAccountHolder={setNewAccountHolder}
              setNewAccountNum={setNewAccountNum}
              setNewAccountType={setNewAccountType}
              setNewBankName={setNewBankName}
              setNewBranchName={setNewBranchName}
              setNewBranchCode={setNewBranchCode}
              uniqueProjects={uniqueProjects}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isAddDialogVisible && (
          <DialogComponent
            visible={isAddDialogVisible}
            width="80%"
            height="580px"
            header="Add Beneficiary"
            allowDragging={true}
            showCloseIcon={true}
            close={handleAddDialogClose}
            buttons={addButtons}
          >
            <BeneficiaryDialog
              selectedID={selectedID}
              selectedProjNum={selectedProjNum}
              selectedID_Num={selectedID_Num}
              selectedInitials={selectedInitials}
              selectedD_O_B={selectedD_O_B}
              selectedFirstNames={selectedFirstNames}
              selectedLastName={selectedLastName}
              selectedGender={selectedGender}
              selectedRace={selectedRace}
              selectedHomeLanguage={selectedHomeLanguage}
              selectedCellNumber={selectedCellNumber}
              selectedEmailAddress={selectedEmailAddress}
              selectedPhysicalAddress={selectedPhysicalAddress}
              selectedPostalAddress={selectedPostalAddress}
              selectedStatus={selectedStatus}
              filteredProjectData={filteredProjectData}
              uniqueBanks={uniqueBanks}
              uniqueBranches={uniqueBranches}
              uniqueTypes={uniqueTypes}
              setNewProjNum={setNewProjNum}
              setNewID_Num={setNewID_Num}
              setNewInitials={setNewInitials}
              setNewD_O_B={setNewD_O_B}
              setNewFirstNames={setNewFirstNames}
              setNewLastName={setNewLastName}
              setNewGender={setNewGender}
              setNewRace={setNewRace}
              setNewHomeLanguage={setNewHomeLanguage}
              setNewCellNumber={setNewCellNumber}
              setNewEmailAddress={setNewEmailAddress}
              setNewPhysicalAddress={setNewPhysicalAddress}
              setNewPostalAddress={setNewPostalAddress}
              setNewStatus={setNewStatus}
              uniqueProjects={uniqueProjects}
            />
          </DialogComponent>
        )}
      </div>
    </div>
  );
}
export default ToDoBeneficiaries;
