import React from 'react'
import { Outlet } from 'react-router-dom';
import Smts from './Smts';
import '../MainBody.css'


function SmtsLayout() {
  return (
    <div className='projects_container'>
      <div className='projects_sidebar'>
        <Smts />
      </div>
      <div className='projects_main'>
        <Outlet />
      </div>
    </div>
  )
}

export default SmtsLayout