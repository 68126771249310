import React, { useState } from "react";
import { TextBoxComponent, MaskedTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  ComboBoxComponent, AutoCompleteComponent
} from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import "./BeneficiaryDialogMain.css";
import useProjectDataFetch from "./useProjectDataFetch"; // Import the custom hook
import useUserStore from "../../app/user";

function BeneficiaryDialogMain({
  selectedID,
              selectedProjNum,
              selectedID_Num,
              selectedProjectName,
              selectedInitials,
              selectedD_O_B,
              selectedFirstNames,
              selectedLastName,
              selectedGender,
              selectedRace,
              selectedHomeLanguage,
              selectedCellNumber,
              selectedEmailAddress,
              selectedPhysicalAddress,
              selectedPostalAddress,
              selectedStatus,
              selectedAccountHolder,
              selectedAccountNum,
              selectedAccountType,
              selectedBankName,
              selectedBranchName,
              selectedBranchCode,
              uniqueProjects,
              setNewProjNum,
              setNewID_Num,
              setNewInitials,
              setNewD_O_B,
              setNewFirstNames,
              setNewLastName,
              setNewGender,
              setNewRace,
              setNewHomeLanguage,
              setNewCellNumber,
              setNewEmailAddress,
              setNewPhysicalAddress,
              setNewPostalAddress,
              setNewStatus,
              setNewAccountHolder,
              setNewAccountNum,
              setNewAccountType,
              setNewBankName,
              setNewBranchName,
              setNewBranchCode,
              uniqueBanks,
              uniqueBranches,
              uniqueTypes,
              currentStage,
}) {
  const user = useUserStore((state) => state.user);
  const {projectData, isProjectLoaded} = useProjectDataFetch(user); // Use the custom hook to fetch data
  const [matchingProjectName, setMatchingProjectName] = useState(selectedProjectName);
    
  const handleID_NumChange = (e) => {
    if (e && e.value) {setNewID_Num(e.value)};
  };
  const handleD_O_BChange = (e) => {
    if (e && e.value) {setNewD_O_B(e.value)};
  };
  const handleGenderChange = (e) => {
    if (e && e.value) {setNewGender(e.value)};
  };
  const handleRaceChange = (e) => {
    if (e && e.value) {setNewRace(e.value)};
  };
  const handleHomeLanguageChange = (e) => {
    if (e && e.value) {setNewHomeLanguage(e.value)};
  };
  const handleCellNumberChange = (e) => {
    if (e && e.value) {setNewCellNumber(e.value)};
  };
  const handleStatusChange = (e) => {
    if (e && e.value) {setNewStatus(e.value)};
  };
  const handleEmailAddressChange = (e) => {
    if (e && e.value) {setNewEmailAddress(e.value)};
  };
  const handlePhysicalAddressChange = (e) => {
    if (e && e.value) {setNewPhysicalAddress(e.value)};
  };
  const handlePostalAddressChange = (e) => {
    if (e && e.value) {setNewPostalAddress(e.value)};
  };

  const handleProjNumChange = (e) => {
    if (e && e.value) {setNewProjNum(e.value)};
    const projNum = e.value
    if (projNum) {
      const project = projectData.find((project) => project.ProjectNum === projNum);
      if (project) {
        const projectName = project.ProjectName;
        setMatchingProjectName(projectName);
      }else{
        setMatchingProjectName("");
      }
    } else{
      setMatchingProjectName("");
    }
  };

  const handleInitialsChange = (e) => {
    if (e && e.value) {setNewInitials(e.value)};
  };
  const handleFirstNamesChange = (e) => {
    if (e && e.value) {setNewFirstNames(e.value)};
  };
  const handleLastNameChange = (e) => {
    if (e && e.value) {setNewLastName(e.value)};
  };

  const handleAccountHolderChange = (e) => {
    if (e && e.value) {setNewAccountHolder(e.value)};
  };
  const handleAccountNumChange = (e) => {
    if (e && e.value) {setNewAccountNum(e.value)};
  };
  const handleAccountTypeChange = (e) => {
    if (e && e.value) {setNewAccountType(e.value)};
  };
  const handleBankNameChange = (e) => {
    if (e && e.value) {setNewBankName(e.value)};
  };
  const handleBranchNameChange = (e) => {
    if (e && e.value) {setNewBranchName(e.value)};
  };
  const handleBranchCodeChange = (e) => {
    if (e && e.value) {setNewBranchCode(e.value)};
  };
  
  return (
    <div className="main-ben-container-details1">
      <div className="beneficiary-field-container1">
        <div className="my-cap-ben-row1">
          <div className="field_project1">
            <TextBoxComponent
              id="field_project"
              placeholder="Project Name"
              value={matchingProjectName}
              floatLabelType="Always"
              disabled={true}
            />
          </div>
          <div className="field_projectnum">
            <ComboBoxComponent
              id="field_projectnum1"
              value={selectedProjNum}
              placeholder="Project Number"
              floatLabelType="Always"
              change={handleProjNumChange}
              dataSource={uniqueProjects}
              enabled={selectedID ? false : true}
            />
          </div>
          <div className="field_initials">
            <TextBoxComponent
              id="field_initials1"
              value={selectedInitials}
              placeholder="Initials"
              floatLabelType="Always"
              change={handleInitialsChange}
            />
          </div>
          <div className="field_first_names">
            <TextBoxComponent
              id="field_first_names1"
              value={selectedFirstNames}
              placeholder="First Names"
              floatLabelType="Always"
              change={handleFirstNamesChange}
            />
          </div>
          <div className="field_surname">
            <TextBoxComponent
              id="field_surname1"
              value={selectedLastName}
              placeholder="Surname"
              floatLabelType="Always"
              change={handleLastNameChange}
            />
          </div>
        </div>
        <div className="my-cap-ben-row2">
          <div className="field_id_number">
            <MaskedTextBoxComponent
              id="field_id_number1"
              name="field_id_number1"
              value={selectedID_Num}
              placeholder="ID Number"
              mask="000000 0000 000"
              floatLabelType="Always"
              change={handleID_NumChange}
            />
          </div>
          <div className="field_date_of_birth">
            <DatePickerComponent
              id="datepicker1"
              value={selectedD_O_B}
              placeholder="Date of Birth"
              floatLabelType="Always"
              format="dd-MMMM-yyyy"
              change={handleD_O_BChange}
            />
          </div>
          <div className="field_gender">
            <ComboBoxComponent
              id="field_gender1"
              value={selectedGender}
              placeholder="Gender"
              floatLabelType="Always"
              dataSource={["", "Male", "Female", "Other"]}
              change={handleGenderChange}
            />
          </div>
          <div className="field_race">
            <ComboBoxComponent
              id="field_race1"
              value={selectedRace}
              placeholder="Race"
              floatLabelType="Always"
              dataSource={[
                "",
                "African",
                "Asian",
                "Coloured",
                "Indian",
                "White",
                "Other",
              ]}
              change={handleRaceChange}
            />
          </div>
          <div className="field_home_language">
            <ComboBoxComponent
              id="field_home_language1"
              value={selectedHomeLanguage}
              placeholder="Home Language"
              floatLabelType="Always"
              dataSource={[
                "Afrikaans",
                "English",
                "Ndebele",
                "Pedi",
                "Sotho",
                "Swati",
                "Tsonga",
                "Tswana",
                "Venda",
                "Xhosa",
                "Zulu",
                "Other",
              ]}
              change={handleHomeLanguageChange}
            />
          </div>
        </div>
        <div className="my-cap-ben-row3">
          <div className="field_cell_number">
            <MaskedTextBoxComponent
              id="field_cell_number1"
              name="field_cell_number1"
              value={selectedCellNumber}
              mask="000 000 0000"
              placeholder="Cell Number"
              floatLabelType="Always"
              change={handleCellNumberChange}
            />
          </div>
          <div className="field_status">
            <ComboBoxComponent
              id="field_status1"
              value={selectedStatus}
              placeholder="Status"
              floatLabelType="Always"
              dataSource={[
                "Active",
                "Resigned",
                "Deceased",
              ]}
              change={handleStatusChange}
              //enabled={selectedStatus ? false : true}
            />
          </div>
          <div className="field_email_address">
            <TextBoxComponent
              id="field_email_address1"
              value={selectedEmailAddress}
              placeholder="Email Address"
              floatLabelType="Always"
              change={handleEmailAddressChange}
            />
          </div>
        </div>
        <div className="my-cap-ben-row4">
          <div className="field_ben_physcal_address">
            <TextBoxComponent
              id="field_ben_physcal_address1"
              value={selectedPhysicalAddress}
              placeholder="Physical Address"
              floatLabelType="Always"
              change={handlePhysicalAddressChange}
            />
          </div>
          <div className="field_ben_postal_address">
            <TextBoxComponent
              id="field_ben_postal_address1"
              value={selectedPostalAddress}
              placeholder="Postal Address"
              floatLabelType="Always"
              change={handlePostalAddressChange}
            />
          </div>
          <div className="field_ben_id">
            <TextBoxComponent
              id="field_ben_id1"
              value={selectedID}
              placeholder="ID"
              floatLabelType="Always"
              disabled={true}
            />
          </div>
        </div>
        <div className="my-cap-ben-row5">
        <div className="field_bank_name">
            <AutoCompleteComponent
              id="field_bank_name1"
              value={selectedBankName}
              placeholder="Bank Name"
              floatLabelType="Always"
              change={handleBankNameChange}
              dataSource={uniqueBanks}
              //enabled={selectedBankName ? false : true}
            />
          </div>
          <div className="field_account_holder">
            <TextBoxComponent
              id="field_account_holder1"
              value={selectedAccountHolder}
              placeholder="Account Holder"
              floatLabelType="Always"
              change={handleAccountHolderChange}
              //disabled={selectedAccountHolder ? true : false}
            />
          </div>
          <div className="field_account_number">
            <TextBoxComponent
              id="field_account_number1"
              value={selectedAccountNum}
              placeholder="Account Number"
              floatLabelType="Always"
              change={handleAccountNumChange}
              //disabled={selectedAccountNum ? true : false}
            />
          </div>   
          <div className="field_brank_code">
            <TextBoxComponent
              id="field_brank_code1"
              value={selectedBranchCode}
              placeholder="Branch Code"
              floatLabelType="Always"
              change={handleBranchCodeChange}
              //disabled={selectedBranchCode ? true : false}
            />
          </div>
        </div>
        <div className="my-cap-ben-row6">
          <div className="field_branch_name">
            <AutoCompleteComponent
              id="field_branch_name1"
              value={selectedBranchName}
              placeholder="Branch Name"
              floatLabelType="Always"
              change={handleBranchNameChange}
              dataSource={uniqueBranches}
              //enabled={selectedBranchName ? false : true}
            />
          </div>
          <div className="field_account_type">
            <AutoCompleteComponent
              id="field_account_type1"
              value={selectedAccountType}
              placeholder="Account Type"
              floatLabelType="Always"
              change={handleAccountTypeChange}
              dataSource={uniqueTypes}
              //enabled={selectedAccountType ? false : true}
            />
          </div>
        </div>
      </div>
    </div>

  );
}

export default BeneficiaryDialogMain;
