import React, { useState } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  ComboBoxComponent,
  AutoCompleteComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import "./DetailTemplate.css";

function DetailTemplate({
  selectedID,
  selectedProjectNum,
  selectedProjectName,
  selectedSector,
  selectedSubSector,
  selectedAdministrator,
  selectedCoordinator,
  selectedFacilitator,
  selectedRegion,
  selectedProvince,
  selectedIdentificationDate,
  selectedPhase,
  selectedPhysicalAddress,
  selectedPostalAddress,
  selectedStage,
  selectedGeneralRemarks,
  selectedTown,
  selectedType,
  selectedVerificationDate,
  selectedStatus,
  projectData,
  setNewProjectNum,
  setNewProjectName,
  setNewSector,
  setNewSubSector,
  setNewAdministrator,
  setNewCoordinator,
  setNewFacilitator,
  setNewRegion,
  setNewProvince,
  setNewIdentificationDate,
  setNewPhase,
  setNewPhysicalAddress,
  setNewPostalAddress,
  setNewStage,
  setNewGeneralRemarks,
  setNewTown,
  setNewType,
  setNewVerificationDate,
  setNewStatus,
  uniqueProvinces,
  uniqueSubSectors,
  uniqueProjects,
  uniqueRegions,
  uniqueSectors,
  uniqueFacilitators,
  uniqueAdministrators,
  uniqueCoordinators,
  uniqueTowns,
  isDuplicate,
}) {
  const [regions, setRegions] = useState(uniqueRegions);
  const [coordinators, setCoordinators] = useState(uniqueCoordinators);
  const [subSectors, setSubSectors] = useState(uniqueSubSectors);

  const handleProvinceChange = (e) => {
    console.log(e.value);
    setNewProvince(e.value);
    const selectProvince = e.value; // Use the selected value from the event
    // Perform filtering based on the selected ComboBox value
    const filteredData = projectData.filter(
      (item) => item.Province === selectProvince
    );
    // Get unique Region
    const uniqueRegions = [
      ...new Set(filteredData?.map((item) => (item.Region ? item.Region : ""))),
    ];
    setRegions(uniqueRegions);
    // Get unique Coordinators
    const uniqueCoordinators = [
      ...new Set(
        filteredData?.map((item) => (item.Coordinator ? item.Coordinator : ""))
      ),
    ];
    setCoordinators(uniqueCoordinators);
  };

  const handleRegionChange = (e) => {
    console.log(e.value);
    setNewRegion(e.value);
    const selectRegion = e.value; // Use the selected value from the event
    const filteredData = projectData.filter(
      (item) => item.Region === selectRegion
    );
    // Get unique Coordinators
    const uniqueCoordinators = [
      ...new Set(
        filteredData?.map((item) => (item.Coordinator ? item.Coordinator : ""))
      ),
    ];
    setCoordinators(uniqueCoordinators);
  };

  const handleFacilitatorChange = (e) => {
    setNewFacilitator(e.value);
  };
  const handleAdministratorChange = (e) => {
    setNewAdministrator(e.value);
  };
  const handleCoordinatorChange = (e) => {
    setNewCoordinator(e.value);
  };
  const handleIdentificationDateChange = (e) => {
    setNewIdentificationDate(e.value);
  };
  const handleVerificationDateChange = (e) => {
    setNewVerificationDate(e.value);
  };
  const handleStatusChange = (e) => {
    setNewStatus(e.value);
  };
  const handleSectorChange = (e) => {
    if (e && e.value) {
      setNewSector(e.value);
    }
    const selectSector = e.value; // Use the selected value from the event
    if (selectSector !== "") {
      const filteredData = projectData?.filter(
        (item) => item.Sector === selectSector
      );
      // Get unique sub sectors
      const uniqueSubSectors = [
        ...new Set(
          filteredData?.map((item) => (item.SubSector ? item.SubSector : ""))
        ),
      ];
      setSubSectors(uniqueSubSectors);
    }
  };
  const handleSubSectorChange = (e) => {
    setNewSubSector(e.value);
  };
  const handlePhysicalAddressChange = (e) => {
    setNewPhysicalAddress(e.value);
  };
  const handlePostalAddressChange = (e) => {
    setNewPostalAddress(e.value);
  };
  const handleTownChange = (e) => {
    setNewTown(e.value);
  };
  const handleGeneralRemarksChange = (e) => {
    setNewGeneralRemarks(e.value);
  };
  const handleProjectNameChange = (e) => {
    setNewProjectName(e.value);
  };
  const handleProjectNumChange = (e) => {
    setNewProjectNum(e.value);
  };
  const handleTypeChange = (e) => {
    setNewType(e.value);
  };
  const handlePhaseChange = (e) => {
    setNewPhase(e.value);
  };
  const handleStageChange = (e) => {
    setNewStage(e.value);
  };

  return (
    <div className="main-container-detail">
      <div className="project-field-container">
        <div className="my-id-row1">
          <div className="field_project">
            <AutoCompleteComponent
              value={selectedProjectName}
              placeholder=" Project Name"
              floatLabelType="Always"
              dataSource={uniqueProjects}
              change={handleProjectNameChange}
              enabled={selectedID ? false : true}
            />
          </div>
          <div className="field_projectnum">
            <TextBoxComponent
              value={selectedProjectNum}
              placeholder=" Project Number"
              floatLabelType="Always"
              disabled={true}
              change={handleProjectNumChange}
            />
          </div>
          <div className="field_type">
            <ComboBoxComponent
              value={selectedType}
              placeholder=" Type"
              floatLabelType="Always"
              dataSource={["New", "Revived", "Legacy"]}
              change={handleTypeChange}
              enabled={selectedID ? false : true}
            />
          </div>
          <div className="field_phase">
            <ComboBoxComponent
              value={selectedPhase}
              placeholder=" Phase"
              floatLabelType="Always"
              dataSource={[
                "Phase 1: Coordinators",
                "Phase 2: Facilitators",
                "Phase 3: NF",
              ]}
              change={handlePhaseChange}
              enabled={false}
            />
          </div>
          <div className="field_stage">
            <ComboBoxComponent
              value={selectedStage}
              placeholder=" Stage"
              floatLabelType="Always"
              dataSource={[
                "Identification",
                "Capacity Building",
                "Funding",
                "Monitoring",
              ]}
              change={handleStageChange}
              enabled={false}
            />
          </div>
        </div>
        <div className="my-id-row2">
          <div className="field_provice">
            <ComboBoxComponent
              value={selectedProvince}
              placeholder=" Province"
              floatLabelType="Always"
              dataSource={uniqueProvinces}
              change={handleProvinceChange}
              enabled={false}
            />
          </div>
          <div className="field_region">
            <ComboBoxComponent
              value={selectedRegion}
              placeholder=" Region"
              floatLabelType="Always"
              dataSource={regions}
              change={handleRegionChange}
              enabled={false}
            />
          </div>
          <div className="field_facilitator">
            <ComboBoxComponent
              value={selectedFacilitator}
              placeholder="Facilitator"
              floatLabelType="Always"
              dataSource={uniqueFacilitators}
              change={handleFacilitatorChange}
              enabled={false}
            />
          </div>
          <div className="field_administrator">
            <ComboBoxComponent
              value={selectedAdministrator}
              placeholder="Administrator"
              floatLabelType="Always"
              dataSource={uniqueAdministrators}
              change={handleAdministratorChange}
              enabled={false}
            />
          </div>
          <div className="field_coordinator">
            <ComboBoxComponent
              value={selectedCoordinator}
              placeholder=" Coordinator"
              floatLabelType="Always"
              dataSource={coordinators}
              change={handleCoordinatorChange}
              enabled={false}
            />
          </div>
        </div>
        <div className="my-id-row3">
          <div className="field_identification_date">
            <DatePickerComponent
              id="datepicker"
              value={selectedIdentificationDate}
              placeholder=" Identification Date"
              floatLabelType="Always"
              format="dd-MMMM-yyyy"
              change={handleIdentificationDateChange}
              disabled={selectedID ? true : false}
            />
          </div>
          <div className="field_verification_date">
            <DatePickerComponent
              id="datepicker"
              value={selectedVerificationDate}
              placeholder=" Verification Date"
              floatLabelType="Always"
              format="dd-MMMM-yyyy"
              change={handleVerificationDateChange}
              disabled={selectedID ? false : true}
            />
          </div>
          <div className="field_status">
            <ComboBoxComponent
              value={selectedStatus}
              placeholder=" Status"
              floatLabelType="Always"
              dataSource={[
                "Active",
                "Non-active",
                "Canceled",
                "On-hold",
                "Suspended",
                "Expelled",
              ]}
              change={handleStatusChange}
              enabled={false}
            />
          </div>
          <div className="field_sector">
            <ComboBoxComponent
              id="comboelement"
              value={selectedSector}
              placeholder=" Sector"
              floatLabelType="Always"
              dataSource={uniqueSectors}
              change={handleSectorChange}
            />
          </div>
          <div className="field_subsector">
            <ComboBoxComponent
              id="comboelement"
              value={selectedSubSector}
              placeholder=" Sub Sector"
              floatLabelType="Always"
              dataSource={subSectors}
              change={handleSubSectorChange}
            />
          </div>
        </div>
        <div className="my-id-row5">
          <div className="field_physcal_address">
            <TextBoxComponent
              value={selectedPhysicalAddress}
              placeholder="Physcal Address"
              floatLabelType="Always"
              change={handlePhysicalAddressChange}
            />
          </div>
          <div className="field_postal_address">
            <TextBoxComponent
              value={selectedPostalAddress}
              placeholder=" Postal Address"
              floatLabelType="Always"
              change={handlePostalAddressChange}
            />
          </div>
          <div className="field_town">
            <ComboBoxComponent
              value={selectedTown}
              placeholder="Town"
              floatLabelType="Always"
              dataSource={uniqueTowns}
              change={handleTownChange}
            />
          </div>
        </div>
        <div className="my-id-row4">
          <div className="field_general_comment">
            <TextBoxComponent
              value={selectedGeneralRemarks}
              multiline={true}
              placeholder=" General Comment"
              floatLabelType="Always"
              change={handleGeneralRemarksChange}
            />
          </div>
          <div className="field_id">
            <TextBoxComponent
              id="id"
              name="id"
              value={selectedID}
              placeholder=" ID"
              floatLabelType="Always"
              disabled={true}
            />
          </div>
        </div>
        {isDuplicate && (
          <div style={{ color: "red", fontSize: "18px", paddingTop: "20px" }}>
            The project name is already being used.
          </div>
        )}
      </div>
    </div>
  );
}

export default DetailTemplate;
