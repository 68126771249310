import React, { useState } from "react";
import { MaskedTextBoxComponent, TextBoxComponent, NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  ComboBoxComponent,
  AutoCompleteComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import "./DetailTemplate.css";
import useProjectDataFetch from "../../../../shared/useProjectDataFetch"; // Import the custom hook
import useUserStore from "../../../../../app/user";


function DetailTemplate({
  selectedID,
  selectedProjectNum,
  selectedProjectName,
  selectedSector,
  selectedSubSector,
  selectedAdministrator,
  selectedCoordinator,
  selectedFacilitator,
  selectedRegion,
  selectedProvince,
  selectedIdentificationDate,
  selectedPhase,
  selectedPhysicalAddress,
  selectedPostalAddress,
  selectedStage,
  selectedGeneralRemarks,
  selectedTown,
  selectedType,
  selectedVerificationDate,
  selectedStatus,
  selectedRegNum,
  selectedTaxNum,
  selectedVatNum,
  selectedRegStatus,
  selectedCompanyWebsite,
  selectedContactPerson,
  selectedEmailAddress,
  selectedTelephone,
  selectedCapacityDate,
  selectedNormalBudget,
  selectedDiscretionaryRequired,
  selectedAmountRequired,
  selectedMotivationForDiscretionary,
  selectedFundingDate,
  selectedDateApproval,
  selectedActiveBankAccount,
  selectedAccountHolder,
  selectedAccountNum,
  selectedAccountType,
  selectedBankName,
  selectedBranchName,
  selectedBranchCode,
  selectedMonitoringDate,
  setNewProjectNum,
  setNewProjectName,
  setNewSector,
  setNewSubSector,
  setNewAdministrator,
  setNewCoordinator,
  setNewFacilitator,
  setNewRegion,
  setNewProvince,
  setNewIdentificationDate,
  setNewPhase,
  setNewPhysicalAddress,
  setNewPostalAddress,
  setNewStage,
  setNewGeneralRemarks,
  setNewTown,
  setNewType,
  setNewVerificationDate,
  setNewStatus,
  setNewRegNum,
  setNewTaxNum,
  setNewVatNum,
  setNewRegStatus,
  setNewCompanyWebsite,
  setNewContactPerson,
  setNewEmailAddress,
  setNewTelephone,
  setNewCapacityDate,
  setNewNormalBudget,
  setNewDiscretionaryRequired,
  setNewAmountRequired,
  setNewMotivationForDiscretionary,
  setNewActiveBankAccount,
  setNewAccountHolder,
  setNewAccountNum,
  setNewAccountType,
  setNewBankName,
  setNewBranchName,
  setNewBranchCode,
  uniqueBanks,
  uniqueBranches,
  uniqueTypes,
}) {
  const usedCurrenncy ='R # ###.00'
  const user = useUserStore((state) => state.user);
  const {projectData, isProjectLoaded} = useProjectDataFetch(user); // Use the custom hook to fetch data
  const [provinceProjectData, setProvinceProjectData] = useState(projectData); // Use useState for filtered data
  const [regionProjectData, setRegionProjectData] = useState(projectData); // Use useState for filtered data

  const handleProvinceChange = (e) => {
    console.log(e.value);
    setNewProvince(e.value);
    const selectProvince = e.value; // Use the selected value from the event
    // Perform filtering based on the selected ComboBox value
    const filteredData = projectData.filter(
      (item) => item.Province === selectProvince
    );
    setProvinceProjectData(filteredData); // Update state to trigger re-render
  };

  const handleRegionChange = (e) => {
    console.log(e.value);
    setNewRegion(e.value);
    const selectRegion = e.value; // Use the selected value from the event
    const filteredData = projectData.filter(
      (item) => item.Region === selectRegion
    );
    setRegionProjectData(filteredData); // Update state to trigger re-render
  };

  const handleFacilitatorChange = (e) => {
    setNewFacilitator(e.value);
  };
  const handleAdministratorChange = (e) => {
    setNewAdministrator(e.value);
  };
  const handleCoordinatorChange = (e) => {
    setNewCoordinator(e.value);
  };
  const handleIdentificationDateChange = (e) => {
    setNewIdentificationDate(e.value);
  };
  const handleVerificationDateChange = (e) => {
    setNewVerificationDate(e.value);
  };
  const handleStatusChange = (e) => {
    setNewStatus(e.value);
  };
  const handleSectorChange = (e) => {
    setNewSector(e.value);
  };
  const handleSubSectorChange = (e) => {
    setNewSubSector(e.value);
  };
  const handlePhysicalAddressChange = (e) => {
    setNewPhysicalAddress(e.value);
  };
  const handlePostalAddressChange = (e) => {
    setNewPostalAddress(e.value);
  };
  const handleTownChange = (e) => {
    setNewTown(e.value);
  };
  const handleGeneralRemarksChange = (e) => {
    setNewGeneralRemarks(e.value);
  };
  const handleProjectNameChange = (e) => {
    setNewProjectName(e.value);
  };
  const handleProjectNumChange = (e) => {
    setNewProjectNum(e.value);
  };
  const handleTypeChange = (e) => {
    setNewType(e.value);
  };
  const handlePhaseChange = (e) => {
    setNewPhase(e.value);
  };
  const handleStageChange = (e) => {
    setNewStage(e.value);
  };
  const handleRegNumChange = (e) => {
    setNewRegNum(e.value);
  };
  const handleRegStatusChange = (e) => {
    setNewRegStatus(e.value);
  };
  const handleTaxNumChange = (e) => {
    setNewTaxNum(e.value);
  };
  const handleVatNumChange = (e) => {
    setNewVatNum(e.value);
  };
  const handleCapacityDateChange = (e) => {
    setNewCapacityDate(e.value);
  };
  const handleContactPersonChange = (e) => {
    setNewContactPerson(e.value);
  };
  const handleTelephoneChange = (e) => {
    setNewTelephone(e.value);
  };
  const handleCompanyWebsiteChange = (e) => {
    setNewCompanyWebsite(e.value);
  };
  const handleEmailAddressChange = (e) => {
    setNewEmailAddress(e.value);
  };

  const handleNormalBudgetChange = (e) => {
    setNewNormalBudget(e.value);
  };
  const handleDiscretionaryRequiredChange = (e) => {
    setNewDiscretionaryRequired(e.value);
  };
  const handleAmountRequiredChange = (e) => {
    setNewAmountRequired(e.value);
  };
  const handleMotivationForDiscretionaryChange = (e) => {
    setNewMotivationForDiscretionary(e.value);
  };

  const handleActiveBankAccountChange = (e) => {
    setNewActiveBankAccount(e.value);
  };
  const handleAccountHolderChange = (e) => {
    setNewAccountHolder(e.value);
  };
  const handleAccountNumChange = (e) => {
    setNewAccountNum(e.value);
  };

  const handleAccountTypeChange = (e) => {
    setNewAccountType(e.value);
  };
  const handleBankNameChange = (e) => {
    setNewBankName(e.value);
  };
  const handleBranchNameChange = (e) => {
    setNewBranchName(e.value);
  };
  const handleBranchCodeChange = (e) => {
    setNewBranchCode(e.value);
  };
  // Get unique provinces from projectData
  const uniqueProvinces = [
    ...new Set(projectData?.map((item) => item.Province)),
  ];
  // Get unique provinces from projectData
  const uniqueProjects = [
    ...new Set(projectData?.map((item) => item.ProjectName ? item.ProjectName : "")),
  ];
  // Get unique RegionS from provinceProjectData
  const uniqueRegions = [
    ...new Set(provinceProjectData?.map((item) => item.Region)),
  ];
  // Get unique FacilitatorS from provinceProjectData
  const uniqueFacilitators = [
    ...new Set(provinceProjectData?.map((item) => item.Facilitator)),
  ];
  // Get unique AdministratorS from provinceProjectData
  const uniqueAdministrators = [
    ...new Set(provinceProjectData?.map((item) => item.Administrator)),
  ];
  // Get unique AdministratorS from regionProjectData
  const uniqueCoordinators = [
    ...new Set(regionProjectData?.map((item) => item.Coordinator)),
  ];
  // Get unique Towns from regionProjectData
  const uniqueTowns = [...new Set(regionProjectData?.map((item) => item.Town))];

  return (
    <div className="main-moni-container-detail">
      <div className="project-moni-field-container">
        <div className="my-cap-row1">
          <div className="field_project">
            <AutoCompleteComponent
              value={selectedProjectName}
              placeholder=" Project Name"
              floatLabelType="Always"
              dataSource={uniqueProjects}
              change={handleProjectNameChange}
              enabled={selectedID ? false : true}
            />
          </div>
          <div className="field_projectnum">
            <TextBoxComponent
              value={selectedProjectNum}
              placeholder=" Project Number"
              floatLabelType="Always"
              change={handleProjectNumChange}
              disabled={true}
            />
          </div>
          <div className="field_type">
            <ComboBoxComponent
              value={selectedType}
              placeholder=" Type"
              floatLabelType="Always"
              dataSource={["New", "Revived", "Legacy"]}
              change={handleTypeChange}
              enabled={false}
            />
          </div>
          <div className="field_phase">
            <ComboBoxComponent
              value={selectedPhase}
              placeholder=" Phase"
              floatLabelType="Always"
              dataSource={[
                "Phase 1: Coordinators",
                "Phase 2: Facilitators",
                "Phase 3: NF",
              ]}
              change={handlePhaseChange}
              enabled={false}
            />
          </div>
          <div className="field_stage">
            <ComboBoxComponent
              value={selectedStage}
              placeholder=" Stage"
              floatLabelType="Always"
              dataSource={[
                "Identification",
                "Capacity Building",
                "Funding",
                "Monitoring",
              ]}
              change={handleStageChange}
              enabled={false}
            />
          </div>
        </div>
        <div className="my-cap-row2">
          <div className="field_provice">
            <ComboBoxComponent
              value={selectedProvince}
              placeholder=" Province"
              floatLabelType="Always"
              dataSource={uniqueProvinces}
              change={handleProvinceChange}
              enabled={false}
            />
          </div>
          <div className="field_region">
            <ComboBoxComponent
              value={selectedRegion}
              placeholder=" Region"
              floatLabelType="Always"
              dataSource={uniqueRegions}
              change={handleRegionChange}
              enabled={false}
            />
          </div>
          <div className="field_facilitator">
            <ComboBoxComponent
              value={selectedFacilitator}
              placeholder="Facilitator"
              floatLabelType="Always"
              dataSource={uniqueFacilitators}
              change={handleFacilitatorChange}
              enabled={false}
            />
          </div>
          <div className="field_administrator">
            <ComboBoxComponent
              value={selectedAdministrator}
              placeholder="Administrator"
              floatLabelType="Always"
              dataSource={uniqueAdministrators}
              change={handleAdministratorChange}
              enabled={false}
            />
          </div>
          <div className="field_coordinator">
            <ComboBoxComponent
              value={selectedCoordinator}
              placeholder=" Coordinator"
              floatLabelType="Always"
              dataSource={uniqueCoordinators}
              change={handleCoordinatorChange}
              enabled={false}
            />
          </div>
        </div>
        <div className="my-cap-row3">
          <div className="field_identification_date">
            <DatePickerComponent
              id="datepicker"
              value={selectedIdentificationDate}
              placeholder=" Identification Date"
              floatLabelType="Always"
              format="dd-MMMM-yyyy"
              change={handleIdentificationDateChange}
              disabled={selectedID ? true : false}
            />
          </div>
          <div className="field_verification_date">
            <DatePickerComponent
              id="datepicker"
              value={selectedVerificationDate}
              placeholder=" Verification Date"
              floatLabelType="Always"
              format="dd-MMMM-yyyy"
              change={handleVerificationDateChange}
              disabled={selectedID ? true : false}
            />
          </div>
          <div className="field_status">
            <ComboBoxComponent
              value={selectedStatus}
              placeholder=" Status"
              floatLabelType="Always"
              dataSource={[
                "Active",
                "Non-active",
                "Canceled",
                "On-hold",
                "Suspended",
                "Expelled",
              ]}
              change={handleStatusChange}
              enabled={false}
            />
          </div>
          <div className="field_sector">
            <ComboBoxComponent
              value={selectedSector}
              placeholder=" Sector"
              floatLabelType="Always"
              change={handleSectorChange}
              enabled={false}
            />
          </div>
          <div className="field_subsector">
            <ComboBoxComponent
              value={selectedSubSector}
              placeholder=" Sub Sector"
              floatLabelType="Always"
              change={handleSubSectorChange}
              enabled={false}
            />
          </div>
        </div>
        <div className="my-cap-row5">
          <div className="field_physcal_address">
            <TextBoxComponent
              value={selectedPhysicalAddress}
              placeholder="Physcal Address"
              floatLabelType="Always"
              change={handlePhysicalAddressChange}
              disabled={true}
            />
          </div>
          <div className="field_postal_address">
            <TextBoxComponent
              value={selectedPostalAddress}
              placeholder=" Postal Address"
              floatLabelType="Always"
              change={handlePostalAddressChange}
              disabled={true}
            />
          </div>
          <div className="field_town">
            <ComboBoxComponent
              value={selectedTown}
              placeholder="Town"
              floatLabelType="Always"
              dataSource={uniqueTowns}
              change={handleTownChange}
              enabled={false}
            />
          </div>
        </div>
        <div className="my-cap-row2">
          <div className="field_reg_number">
            <TextBoxComponent
              value={selectedRegNum}
              placeholder=" CIPC Reg Number"
              floatLabelType="Always"
              change={handleRegNumChange}
              disabled = {true}
            />
          </div>
          <div className="field_reg_status">
            <ComboBoxComponent
              value={selectedRegStatus}
              placeholder="CIPC Reg Status"
              floatLabelType="Always"
              dataSource={[
                "In Business",
                "De-registering",
                "De-registered",
                "Not Registered",
              ]}
              change={handleRegStatusChange}
              enabled={false}
            />
          </div>
          <div className="field_tax_number">
            <TextBoxComponent
              value={selectedTaxNum}
              placeholder=" Tax Number"
              floatLabelType="Always"
              change={handleTaxNumChange}
              disabled = {true}
            />
          </div>
          <div className="field_vat_number">
            <TextBoxComponent
              value={selectedVatNum}
              placeholder="Vat Number"
              floatLabelType="Always"
              change={handleVatNumChange}
              disabled = {true}
            />
          </div>
          <div className="field_capacity_date">
            <DatePickerComponent
              id="datepicker"
              value={selectedCapacityDate}
              placeholder="Capacity Building Date"
              floatLabelType="Always"
              format="dd-MMMM-yyyy"
              change={handleCapacityDateChange}
              disabled = {true}
            />
          </div>
        </div>
        <div className="my-cap-row3">
          <div className="field_contact_person">
            <TextBoxComponent
              id="datepicker"
              value={selectedContactPerson}
              placeholder="Contact Person"
              floatLabelType="Always"
              change={handleContactPersonChange}
              disabled = {true}
            />
          </div>
          <div className="field_telephone">
            <MaskedTextBoxComponent
              value={selectedTelephone}
              placeholder="Telephone"
              floatLabelType="Always"
              mask="000 000 0000"
              change={handleTelephoneChange}
              disabled = {true}
            />
          </div>
          <div className="field_email_address1">
            <TextBoxComponent
              value={selectedEmailAddress}
              placeholder="Email Address"
              floatLabelType="Always"
              change={handleEmailAddressChange}
              disabled = {true}
            />
          </div>
          <div className="field_company_website">
            <TextBoxComponent
              value={selectedCompanyWebsite}
              placeholder="Company Website"
              floatLabelType="Always"
              change={handleCompanyWebsiteChange}
              disabled = {true}
            />
          </div>
        </div>
        <div className="my-cap-row2">
          <div className="field_normal_budget">
            <NumericTextBoxComponent
              value={selectedNormalBudget}
              placeholder="Normal Budget"
              floatLabelType="Always"
              change={handleNormalBudgetChange}
              disabled = {true}
              format={usedCurrenncy}
              showSpinButton={false}
            />
          </div>
          <div className="field_dis_required">
            <ComboBoxComponent
              value={selectedDiscretionaryRequired}
              placeholder="Discr. Required?"
              floatLabelType="Always"
              dataSource={[
                "Yes",
                "No",
              ]}
              change={handleDiscretionaryRequiredChange}
              enabled={false}
            />
          </div>
          <div className="field_amount_required">
            <NumericTextBoxComponent
              value={selectedAmountRequired}
              placeholder="Discretionary Amount"
              floatLabelType="Always"
              change={handleAmountRequiredChange}
              disabled = {true}
              format={usedCurrenncy}
              showSpinButton={false}
            />
          </div>
          <div className="field_dis_motivation">
            <TextBoxComponent
              value={selectedMotivationForDiscretionary}
              placeholder="Motivation For Discretionary"
              floatLabelType="Always"
              multiline={true}
              change={handleMotivationForDiscretionaryChange}
              disabled = {true}
            />
          </div>
        </div>
        <div className="my-cap-row2">
          <div className="field_funding_date">
            <DatePickerComponent
              id="datepicker"
              value={selectedFundingDate}
              placeholder="Funding Date"
              floatLabelType="Always"
              format="dd-MMMM-yyyy"
              disabled = {true}
            />
          </div>
          <div className="field_active_bank">
            <ComboBoxComponent
              value={selectedActiveBankAccount}
              placeholder="Active Bank Account"
              floatLabelType="Always"
              dataSource={[
                "Yes",
                "No",
              ]}
              change={handleActiveBankAccountChange}
            />
          </div>
          <div className="field_account_holder">
            <TextBoxComponent
              value={selectedAccountHolder}
              placeholder="Account Holder"
              floatLabelType="Always"
              change={handleAccountHolderChange}
            />
          </div>
          <div className="field_bank_Name">
            <AutoCompleteComponent
              value={selectedBankName}
              placeholder="Bank Name"
              floatLabelType="Always"
              dataSource={uniqueBanks}
              change={handleBankNameChange}
            />
          </div>
          <div className="field_account_number">
            <TextBoxComponent
              value={selectedAccountNum}
              placeholder="Account Number"
              floatLabelType="Always"
              change={handleAccountNumChange}
            />
          </div>
        </div>
        <div className="my-cap-row2">
          <div className="field_account_type">
            <AutoCompleteComponent
              value={selectedAccountType}
              placeholder="Account Type"
              floatLabelType="Always"
              dataSource={uniqueTypes}
              change={handleAccountTypeChange}
            />
          </div>
          <div className="field_branch_name">
            <AutoCompleteComponent
              value={selectedBranchName}
              placeholder="Branch Name"
              floatLabelType="Always"
              dataSource={uniqueBranches}
              change={handleBranchNameChange}
            />
          </div>
          <div className="field_branch_code">
            <TextBoxComponent
              value={selectedBranchCode}
              placeholder="Branch Code"
              floatLabelType="Always"
              change={handleBranchCodeChange}
            />
          </div>
          <div className="field_monitoring_date">
            <DatePickerComponent
              id="datepicker"
              value={selectedMonitoringDate}
              placeholder="Monitoring Start Date"
              floatLabelType="Always"
              format="dd-MMMM-yyyy"
              disabled = {true}
            />
          </div>
          <div className="field_monitoring_date">
            <DatePickerComponent
              id="datepicker"
              value={selectedDateApproval}
              placeholder="Monitoring End Date"
              floatLabelType="Always"
              format="dd-MMMM-yyyy"
              disabled = {true}
            />
          </div>
        </div>
        <div className="my-cap-row4">
          <div className="field_general_comment">
            <TextBoxComponent
              value={selectedGeneralRemarks}
              multiline={true}
              placeholder=" General Comment"
              floatLabelType="Always"
              change={handleGeneralRemarksChange}
            />
          </div>
          <div className="field_id">
            <TextBoxComponent
              id="id"
              name="id"
              value={selectedID}
              placeholder=" ID"
              floatLabelType="Always"
              disabled={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailTemplate;
