import React, { useEffect, useRef, useState } from "react";
import "./TaskDueThisQuarter.css";
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import axios from "../../../../config/axios";
import {
  GanttComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Edit,
  Selection,
  Filter,
  PdfExport,
  ExcelExport,
  Toolbar,
} from "@syncfusion/ej2-react-gantt";
import useProjectDataFetch from "../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../shared/useOtherDataFetch"; // Import the custom hook
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import TaskDialog from "../../../shared/TaskDialog";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import useUserStore from "../../../../app/user";
import { toast } from "react-toastify";
import { dateFormatter } from "../../../../utils/formats";

function TaskDueThisQuarter() {
  const user = useUserStore((state) => state.user);
  const GridRef = useRef(null); // Create the treeGridRef reference
  const { projectData, isProjectLoaded } = useProjectDataFetch(user); // Use the custom hook to fetch data
  const { taskData, isTaskLoaded } = useOtherDataFetch();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [taskGridData, setTaskGridData] = useState([]);

  useEffect(() => {
    if (isProjectLoaded && isTaskLoaded) {
      const finalProjectData = projectData.filter(
        (project) => project.ProjectStatus !== "Deleted"
      );
      const matchingTasks = taskData.filter((task) => {
        const matchingProject = finalProjectData.find(
          (project) => project.id === task.ProjectID
        );
        return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
      });
      const filteredTaskData = matchingTasks.filter((task) => {
        const taskProgress = task.Progress;
        const taskDuration = task.Duration;
        const taskStartDate = new Date(task.StartDate);
        const taskEndDate = new Date(taskStartDate);
        taskEndDate.setDate(taskEndDate.getDate() + taskDuration);
        const currentDate = new Date();
        // Calculate the current quarter's first month
        const currentQuarterMonth = Math.floor(currentDate.getMonth() / 3) * 3;
        // Calculate the first day of the current quarter
        const firstDayOfQuarter = new Date(
          currentDate.getFullYear(),
          currentQuarterMonth,
          1
        );
        // Calculate the last day of the current quarter
        const lastDayOfQuarter = new Date(
          currentDate.getFullYear(),
          currentQuarterMonth + 3,
          0
        );
        return (
          taskEndDate >= firstDayOfQuarter &&
          taskEndDate <= lastDayOfQuarter &&
          taskProgress < 100 &&
          taskDuration > 0
        );
      });
      const stageParentIDs = filteredTaskData?.map((task) => task.ParentID);
      const stageTasks = taskData.filter((task) =>
        stageParentIDs.includes(task.id)
      );
      const combinedTaskList = stageTasks.concat(filteredTaskData);
      const projectParentIDs = stageTasks.map((task) => task.ParentID);
      const projectTasks = taskData.filter((task) =>
        projectParentIDs.includes(task.id)
      );
      const updatedCombinedTaskList = projectTasks.concat(combinedTaskList);
      setTaskGridData(updatedCombinedTaskList);
      setIsDataLoaded(true);
    }
  }, [projectData, taskData, isTaskLoaded, isProjectLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  const taskFields = {
    id: "id",
    name: "TaskName",
    startDate: "StartDate",
    duration: "Duration",
    progress: "Progress",
    parentID: "ParentID",
    status: "Status",
    expandState: "isExpand",
  };

  const timelineSettings = {
    timelineUnitSize: 30,
    timelineViewMode: "Month",
    topTier: { format: "MMM-yyyy", unit: "Month" },
    bottomTier: { unit: "Week", format: "W" },
  };
  const [selectedID, setSelectedID] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("");
  const [selectedProgress, setSelectedProgress] = useState("");
  const [newProgress, setNewProgress] = useState("");

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedDescription(args.data.TaskName);
    setSelectedStartDate(args.data.StartDate);
    setSelectedDuration(args.data.Duration);
    setSelectedProgress(args.data.Progress);
  };

  const currentTitleDate = dateFormatter(new Date());
  const handleExcelButtonClick = () => {
    if (GridRef.current && GridRef.current.excelExport) {
      const exportProperties = {
        pageOrientation: "Landscape",
        fileName:
          "Tasks Due This Quarter Printed by " +
          user.FullName +
          " on " +
          currentTitleDate +
          ".xlsx",
      };
      GridRef.current.excelExport(exportProperties);
    }
  };

  const handlePdfButtonClick = () => {};

  const handleDialogClose = () => {
    setIsDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsDialogVisible(!isDialogVisible);
  };

  const handleUpdateTaskProgressButton = async (selectedID, newProgress) => {
    // Send the updated data to the API for updating the records
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    const numericNewProgress = parseInt(newProgress);
    try {
      const TaskResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/task/${numericSelectedID}`,
        {
          Progress: numericNewProgress,
          LastUpdatedBy: user?.FullName,
        }
      );
      console.log("Project response:", TaskResponse.data);
      if (TaskResponse.data.status === "success") {
        toast.success("Progress updated successfully.");
        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/task"
        );
        const tasks = updatedResponse.data.items;
        const matchingTasks = tasks.filter((task) => {
          return taskGridData.some((item) => item.id === task.id);
        });
        setTaskGridData(matchingTasks);
      } else {
        console.error(
          "Error updating task progress:",
          TaskResponse.data.message
        );
        toast.error("Error updating progress");
      }
    } catch (error) {
      console.error("Error updating progress:", error);
      toast.error("Error updating progress");
    }
    toast.dismiss(loadingToastId);
  };

  // Custom template for the "Status" column
  const statusTemplate = (props) => {
    const taskProgress = props.Progress;
    const taskDuration = props.Duration;
    const taskStartDate = new Date(props.StartDate);
    const taskEndDate = new Date(taskStartDate);
    taskEndDate.setDate(taskEndDate.getDate() + taskDuration);
    const currentDate = new Date();
    if (taskProgress === 100) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else if (taskProgress < 100 && currentDate < taskEndDate) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else if ((taskDuration = 0)) {
      return (
        <span>
          <CheckCircleIcon style={{ color: "green" }} />
        </span>
      );
    } else {
      return (
        <span>
          <CancelIcon style={{ color: "red" }} />
        </span>
      );
    }
  };

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleUpdateTaskProgressButton(selectedID, newProgress);
        setIsDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsDialogVisible(false);
      },
    },
  ];

  const splitterSettings = {
    position: "40%",
  };

  const editSettings = {
    allowEditing: false,
    mode: "Auto",
  };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          {user?.JobTitle === "Administrator" && (
            <button
              className="edit-button"
              onClick={handleEditButtonClick}
              disabled={selectedID === ""}
            >
              <EditIcon className="send-icon" /> Edit{" "}
            </button>
          )}
        </div>
        <div className="button-container-subA2">
          <button className="add-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          {/* <button className="edit-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button> */}
        </div>
      </div>
    );
  };

  const toolbarOptions = [{ template: renderButtons }];

  return (
    <div className="my-to-do-tasks">
      <h3>List of All Tasks due This Quarter</h3>
      <br />
      <div className="field-line-my-tasks">
        {isDataLoaded && (
          <GanttComponent
            dataSource={taskGridData}
            taskFields={taskFields}
            timelineSettings={timelineSettings}
            allowSelection={true}
            ref={GridRef}
            allowExcelExport={true}
            allowPdfExport={true}
            splitterSettings={splitterSettings}
            editSettings={editSettings}
            allowFiltering={true}
            height="830px"
            width="99%"
            rowSelected={handleRowSelect}
            toolbar={toolbarOptions}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="TaskName"
                width="300px"
                headerText="Task Description"
              />
              <ColumnDirective field="StartDate" />
              <ColumnDirective field="Duration" width="100px" />
              <ColumnDirective field="Progress" width="90px" />
              <ColumnDirective field="Status" width="90px" />
              <ColumnDirective field="id" width="50px" headerText="id" />
            </ColumnsDirective>
            <Inject
              services={[
                Edit,
                Selection,
                Filter,
                Toolbar,
                PdfExport,
                ExcelExport,
              ]}
            />
          </GanttComponent>
        )}
      </div>
      <div>
        {isDialogVisible && (
          <DialogComponent
            visible={isDialogVisible}
            width="700px"
            header="Update Progress"
            showCloseIcon={true}
            close={handleDialogClose}
            buttons={editButtons}
          >
            <TaskDialog
              selectedID={selectedID}
              selectedDescription={selectedDescription}
              selectedStartDate={selectedStartDate}
              selectedDuration={selectedDuration}
              selectedProgress={selectedProgress}
              setNewProgress={setNewProgress}
            />
          </DialogComponent>
        )}
      </div>
    </div>
  );
}
export default TaskDueThisQuarter;
