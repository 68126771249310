import React from "react";
import ThisWeekLocation from "./ThisWeekLocation";
import NextWeekLocation from "./NextWeekLocation";
import LastWeekLocation from "./LastWeekLocation";
import "./SmtsTabs.css";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";

function SmtsLocation() {
  let headerText = [
    { text: "This Week" },
    { text: "Next Week" },
    { text: "Last Week" },
  ];

  return (
    <div className="main-tab-container">
      <TabComponent>
        <TabItemsDirective>
          <TabItemDirective header={headerText[0]} content={ThisWeekLocation} />
          <TabItemDirective header={headerText[1]} content={NextWeekLocation} />
          <TabItemDirective header={headerText[2]} content={LastWeekLocation} />
        </TabItemsDirective>
      </TabComponent>
    </div>
  );
}

export default SmtsLocation;
