import React, { useState } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent} from '@syncfusion/ej2-react-dropdowns';
import './OneChange.css'

function ProjectBankChange({ projectData, uniqueProjects, uniqueBanks, uniqueBranches, setNewName, uniqueTypes, setProjectID, setCurrentAccountHolder, setCurrentAccountNum, setCurrentAccountType, setCurrentBankName, setCurrentBranchName, setCurrentBranchCode, setFutureAccountHolder, setFutureAccountNum, setFutureAccountType, setFutureBankName, setFutureBranchName, setFutureBranchCode, setNotes}) {
  const [selectedID, setSelectedID] = useState("");
  const [selectedProjectNum, setSelectedProjectNum] = useState("");
  const [selectedAccountHolder, setSelectedAccountHolder] = useState("");
  const [selectedAccountNum, setSelectedAccountNum] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const [selectedBankName, setSelectedBankName] = useState("");
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [selectedBranchCode, setSelectedBranchCode] = useState("");

  
  const handleNameChange = (e) => {
    setNewName(e.value);
    const selectedProject = e.value
    const filteredData = projectData.filter(
      (item) => item.ProjectName === selectedProject
    );
    if (filteredData.length > 0) {
      setSelectedID(filteredData[0]?.id);
      setProjectID(filteredData[0]?.id);
      setSelectedProjectNum(filteredData[0]?.ProjectNum);
      setSelectedAccountHolder(filteredData[0]?.AccountHolder);
      setSelectedAccountNum(filteredData[0]?.AccountNum);
      setSelectedAccountType(filteredData[0]?.AccountType);
      setSelectedBankName(filteredData[0]?.BankName);
      setSelectedBranchName(filteredData[0]?.BranchName);
      setSelectedBranchCode(filteredData[0]?.BranchCode);
      setCurrentAccountHolder(filteredData[0]?.AccountHolder);
      setCurrentAccountNum(filteredData[0]?.AccountNum);
      setCurrentAccountType(filteredData[0]?.AccountType);
      setCurrentBankName(filteredData[0]?.BankName);
      setCurrentBranchName(filteredData[0]?.BranchName);
      setCurrentBranchCode(filteredData[0]?.BranchCode);
    }
  };
  const handleNotesChange = (e) => {
    setNotes(e.value);
  };
  const handleAccountHolderChange = (e) => {
    if (e !== ""){setFutureAccountHolder(e.value)};
  };
  const handleAccountNumChange = (e) => {
    if (e !== ""){setFutureAccountNum(e.value)};
  };
  const handleAccountTypeChange = (e) => {
    if (e !== ""){setFutureAccountType(e.value)};
  };
  const handleBankNameChange = (e) => {
    if (e !== ""){setFutureBankName(e.value)};
  };
  const handleBranchNameChange = (e) => {
    if (e !== ""){setFutureBranchName(e.value)};
  };
  const handleBranchCodeChange = (e) => {
    if (e !== ""){setFutureBranchCode(e.value)};
  };
  
  return (
    <table className='main-name-dialog'>
      <tbody className='name-field-dialog'>
        <tr className='name-change-row1'>
          <td className='name_project-id'>
            <TextBoxComponent id='id' name='id' value={selectedID} placeholder=" Proj ID" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='name_project-name'>
            <AutoCompleteComponent id='name' name='name' onChange={handleNameChange} dataSource={uniqueProjects} placeholder="Project Name" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='name_project-num'>
            <TextBoxComponent id='num' name='num' value={selectedProjectNum} placeholder="Project Number" floatLabelType="Always" disabled={true}/>
          </td>
        </tr>
        <tr className='name-change-row2'>
        <td className='name_requestor'>
            <TextBoxComponent  id='requestor' name='requestor' value={selectedBankName} placeholder="Current Bank" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='name_requestor'>
            <AutoCompleteComponent  id='requestor' name='requestor' dataSource={uniqueBanks} onChange={handleBankNameChange} placeholder="New Bank" floatLabelType="Always"/>
          </td>
        </tr>
        <tr className='name-change-row2'>
        <td className='name_requestor'>
            <TextBoxComponent  id='requestor' name='requestor' value={selectedAccountHolder} placeholder="Current Account Holder" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='name_requestor'>
            <TextBoxComponent  id='requestor' name='requestor' onChange={handleAccountHolderChange} placeholder="New Account Holder" floatLabelType="Always"/>
          </td>
        </tr>
        <tr className='name-change-row2'>
        <td className='name_requestor'>
            <TextBoxComponent  id='requestor' name='requestor' value={selectedAccountNum} placeholder="Current Account Number" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='name_requestor'>
            <TextBoxComponent  id='requestor' name='requestor' onChange={handleAccountNumChange} placeholder="New Account Number" floatLabelType="Always"/>
          </td>
        </tr>
        <tr className='name-change-row2'>
        <td className='name_requestor'>
            <TextBoxComponent  id='requestor' name='requestor' value={selectedAccountType} placeholder="Current Account Type" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='name_requestor'>
            <AutoCompleteComponent  id='requestor' name='requestor' dataSource={uniqueTypes} onChange={handleAccountTypeChange} placeholder="New Account Type" floatLabelType="Always"/>
          </td>
        </tr>
        <tr className='name-change-row2'>
        <td className='name_requestor'>
            <TextBoxComponent  id='requestor' name='requestor' value={selectedBranchName} placeholder="Current Branch Name" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='name_requestor'>
            <AutoCompleteComponent  id='requestor' name='requestor' dataSource={uniqueBranches} onChange={handleBranchNameChange} placeholder="New Branch Name" floatLabelType="Always"/>
          </td>
        </tr>
        <tr className='name-change-row2'>
        <td className='name_requestor'>
            <TextBoxComponent  id='requestor' name='requestor' value={selectedBranchCode} placeholder="Current Branch Code" floatLabelType="Always" disabled={true}/>
          </td>
          <td className='name_requestor'>
            <TextBoxComponent  id='requestor' name='requestor' onChange={handleBranchCodeChange} placeholder="New Branch Code" floatLabelType="Always"/>
          </td>
        </tr>
        <tr className='name-change-row3'>
          <td className='name_notes'>
            <TextBoxComponent  id='notes' name='notes' onChange={handleNotesChange} multiline={true} placeholder=" Notes" floatLabelType="Always" />
          </td>
        </tr>
      </tbody>
    </table>
    
  )
}

export default ProjectBankChange