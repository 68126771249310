import { useEffect, useState } from "react";
import axios from "../../config/axios";

const useProjectBankDataFetch = () => {
  const [projectBankData, setProjectBankData] = useState([]);
  const [isProjectBankLoaded, setIsProjectBankLoaded] = useState(false);

  // Define the fetchData function for project Bank change
  const fetchProjectBankData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/bankchange"
      );
      setIsProjectBankLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching project Bank data:", error);
      setIsProjectBankLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch project Bank change
    fetchProjectBankData().then((InitialData) => {
      setProjectBankData(InitialData);
    });
  }, []);

  // Return an object containing all the fetched data
  return {
    projectBankData,
    isProjectBankLoaded,
  }
};

export default useProjectBankDataFetch;
