import { useEffect, useState } from "react";
import axios from "../../config/axios";

const useProjectSubSectorDataFetch = () => {
  const [projectSubSectorData, setProjectSubSectorData] = useState([]);
  const [isProjectSubSectorLoaded, setIsProjectSubSectorLoaded] = useState(false);

  // Define the fetchData function for project SubSector change
  const fetchProjectSubSectorData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/subsectorchange"
      );
      setIsProjectSubSectorLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching project SubSector data:", error);
      setIsProjectSubSectorLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch project SubSector change
    fetchProjectSubSectorData().then((InitialData) => {
      setProjectSubSectorData(InitialData);
    });
  }, []);

  // Return an object containing all the fetched data
  return {
    projectSubSectorData,
    isProjectSubSectorLoaded,
  }
};

export default useProjectSubSectorDataFetch;
