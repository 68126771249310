import React, { useEffect, useState, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  Reorder,
  Resize,
  PdfExport,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import "../../../../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
import "../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import axios from "../../../../config/axios";
import "../../projects/children/ListView.css";
import useOtherDataFetch from "../../../shared/useOtherDataFetch"; // Import the custom hook
import BeneficiaryDialogMain from "../../../shared/BeneficiaryDialogMain";
import useProjectDataFetch from "../../../shared/useProjectDataFetch"; // Import the custom hook
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import useUserStore from "../../../../app/user";
import { toast } from "react-toastify";
import { dateFormatter } from "../../../../utils/formats";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import useUserDataFetch from "../../../shared/useUserDataFetch";
import MessageBox from "../../messages/MessageBox";
import ForumIcon from "@mui/icons-material/Forum";
import {
  getBeneficiaryChatUsers,
  getCommonUsers,
  getProjectName,
  getUserDeleteRight,
  getUserLocation,
} from "../../../../utils";
import useUserChatsDataFetch from "../../../shared/useUserChatsDataFetch";
import socket from "../../../../utils/socket";
import { useSearchParams } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "90%",
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 2,
};

function BenActiveBeneficiaries() {
  const user = useUserStore((state) => state.user);
  const { projectData, isProjectLoaded } = useProjectDataFetch(user);
  const { beneficiaryData, isBeneficiaryLoaded } = useOtherDataFetch();
  const GridRef = useRef(null);
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
  const [filteredProjectData, setFilteredProjectData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [userLocation, setUserLocation] = useState("");
  const newCurrentDate = new Date();
  const [gridData, setGridData] = useState([]);
  const { allUserData, isAllUserLoaded } = useUserDataFetch([]);
  const [commonUserData, setCommonUserData] = useState([]);
  const [selectedChatUsers, setSelectedChatUsers] = useState([]);
  const { userChatsData, isUserChatsLoaded, setUserChatsData } =
    useUserChatsDataFetch([]);
  const [isMessagesVisible, setIsMessagesVisible] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();
  const id = Number(searchParams.get("id"));

  useEffect(() => {
    if (isProjectLoaded && isBeneficiaryLoaded) {
      const finalProjectData = projectData.filter(
        (project) => project.ProjectStatus !== "Deleted"
      );
      setFilteredProjectData(finalProjectData);
      const matchingBeneficiaries = beneficiaryData.filter((beneficiary) => {
        const matchingProject = finalProjectData.find(
          (project) => project.ProjectNum === beneficiary.ProjNum
        );
        return matchingProject !== undefined; // Only keep tasks with a matching ProjectID
      });
      const finalBeneficiaries = matchingBeneficiaries.filter(
        (beneficiary) => beneficiary.BenStatus === "Active"
      );
      setGridData(finalBeneficiaries);

      const filteredUserData = getCommonUsers(allUserData);
      setCommonUserData(filteredUserData);

      setIsDataLoaded(true);
    }
  }, [
    beneficiaryData,
    isBeneficiaryLoaded,
    projectData,
    isProjectLoaded,
    isAllUserLoaded,
    isUserChatsLoaded,
  ]);

  useEffect(() => {
    socket.on("notification", (data) => {
      console.log("Receive Notification: ", data);
      const isTrue = userChatsData.find((chat) => chat.ID === data.ID);
      if (!isTrue)
        setUserChatsData((prev) => (prev ? [...prev, data] : [data]));
    });
  }, []);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  useEffect(() => {
    const deleteRights = getUserDeleteRight(user);
    setIsButtonEnabled(deleteRights);
    const location = getUserLocation(user);
    setUserLocation(location);
  }, [user]);

  // Get unique banks from projectData
  const uniqueBanks = [
    ...new Set(
      projectData?.map((item) => (item.BankName ? item.BankName : ""))
    ),
  ];
  // Get unique branch from projectData
  const uniqueBranches = [
    ...new Set(
      projectData?.map((item) => (item.BranchName ? item.BranchName : ""))
    ),
  ];
  // Get unique account types from projectData
  const uniqueTypes = [
    ...new Set(
      projectData?.map((item) => (item.AccountType ? item.AccountType : ""))
    ),
  ];

  const uniqueProjects = [
    ...new Set(
      filteredProjectData?.map((item) =>
        item.ProjectNum ? item.ProjectNum : ""
      )
    ),
  ];

  const [selectedID, setSelectedID] = useState("");
  const [selectedProjNum, setSelectedProjNum] = useState("");
  const [selectedID_Num, setSelectedID_Num] = useState("");
  const [selectedInitials, setSelectedInitials] = useState("");
  const [selectedFirstNames, setSelectedFirstNames] = useState("");
  const [selectedD_O_B, setSelectedD_O_B] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedLastName, setSelectedLastName] = useState("");
  const [selectedRace, setSelectedRace] = useState("");
  const [selectedHomeLanguage, setSelectedHomeLanguage] = useState("");
  const [selectedCellNumber, setSelectedCellNumber] = useState("");
  const [selectedEmailAddress, setSelectedEmailAddress] = useState("");
  const [selectedPhysicalAddress, setSelectedPhysicalAddress] = useState("");
  const [selectedPostalAddress, setSelectedPostalAddress] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedAccountHolder, setSelectedAccountHolder] = useState("");
  const [selectedAccountNum, setSelectedAccountNum] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const [selectedBankName, setSelectedBankName] = useState("");
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [selectedBranchCode, setSelectedBranchCode] = useState("");

  const [newProjNum, setNewProjNum] = useState("");
  const [newID_Num, setNewID_Num] = useState("");
  const [newInitials, setNewInitials] = useState("");
  const [newFirstNames, setNewFirstNames] = useState("");
  const [newD_O_B, setNewD_O_B] = useState("");
  const [newGender, setNewGender] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newRace, setNewRace] = useState("");
  const [newHomeLanguage, setNewHomeLanguage] = useState("");
  const [newCellNumber, setNewCellNumber] = useState("");
  const [newEmailAddress, setNewEmailAddress] = useState("");
  const [newPhysicalAddress, setNewPhysicalAddress] = useState("");
  const [newPostalAddress, setNewPostalAddress] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [newAccountHolder, setNewAccountHolder] = useState("");
  const [newAccountNum, setNewAccountNum] = useState("");
  const [newAccountType, setNewAccountType] = useState("");
  const [newBankName, setNewBankName] = useState("");
  const [newBranchName, setNewBranchName] = useState("");
  const [newBranchCode, setNewBranchCode] = useState("");

  const beneficiaryNewData = {
    ProjNum: newProjNum,
    ID_Num: newID_Num,
    Initials: newInitials,
    FirstNames: newFirstNames,
    D_O_B: newD_O_B,
    Gender: newGender,
    LastName: newLastName,
    Race: newRace,
    HomeLanguage: newHomeLanguage,
    CellNumber: newCellNumber,
    EmailAddress: newEmailAddress,
    PhysicalAddress: newPhysicalAddress,
    PostalAddress: newPostalAddress,
    AccountHolder: newAccountHolder,
    AccountNum: newAccountNum,
    AccountType: newAccountType,
    BankName: newBankName,
    BranchName: newBranchName,
    BranchCode: newBranchCode,
    BenStatus: newStatus,
    CreatedBy: user?.FullName,
    CreatedOn: newCurrentDate,
  };

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedProjNum(args.data.ProjNum);
    setSelectedID_Num(args.data.ID_Num);
    setSelectedInitials(args.data.Initials);
    setSelectedD_O_B(args.data.D_O_B);
    setSelectedFirstNames(args.data.FirstNames);
    setSelectedLastName(args.data.LastName);
    setSelectedGender(args.data.Gender);
    setSelectedRace(args.data.Race);
    setSelectedHomeLanguage(args.data.HomeLanguage);
    setSelectedCellNumber(args.data.CellNumber);
    setSelectedEmailAddress(args.data.EmailAddress);
    setSelectedPhysicalAddress(args.data.PhysicalAddress);
    setSelectedPostalAddress(args.data.PostalAddress);
    setSelectedStatus(args.data.BenStatus);
    setSelectedAccountHolder(args.data.AccountHolder);
    setSelectedAccountNum(args.data.AccountNum);
    setSelectedAccountType(args.data.AccountType);
    setSelectedBankName(args.data.BankName);
    setSelectedBranchName(args.data.BranchName);
    setSelectedBranchCode(args.data.BranchCode);
    if (args.data.ProjNum) {
      const projectName = getProjectName(projectData, args.data.ProjNum);
      setSelectedProjectName(projectName);
      const chatUsers = getBeneficiaryChatUsers(
        projectData,
        args.data.ProjNum,
        allUserData,
        commonUserData,
        user
      );
      setSelectedChatUsers(chatUsers);
    } else {
      setSelectedProjectName("");
      setSelectedChatUsers("");
    }
  };

  const currentTitleDate = dateFormatter(new Date());
  const handleExcelButtonClick = () => {
    if (GridRef.current && GridRef.current.excelExport) {
      const exportProperties = {
        pageOrientation: "Landscape",
        fileName:
          "List Printed by " +
          user.FullName +
          " on " +
          currentTitleDate +
          ".xlsx",
      };
      GridRef.current.excelExport(exportProperties);
    }
  };

  const handlePdfButtonClick = () => {};

  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };

  const handleAddDialogClose = () => {
    setIsAddDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsEditDialogVisible(!isEditDialogVisible);
    setNewProjNum(selectedProjNum);
    setNewID_Num(selectedID_Num);
    setNewInitials(selectedInitials);
    setNewD_O_B(selectedD_O_B);
    setNewFirstNames(selectedFirstNames);
    setNewLastName(selectedLastName);
    setNewGender(selectedGender);
    setNewRace(selectedRace);
    setNewHomeLanguage(selectedHomeLanguage);
    setNewCellNumber(selectedCellNumber);
    setNewEmailAddress(selectedEmailAddress);
    setNewPhysicalAddress(selectedPhysicalAddress);
    setNewPostalAddress(selectedPostalAddress);
    setNewStatus(selectedStatus);
  };

  const handleAddButtonClick = () => {
    setIsAddDialogVisible(!isAddDialogVisible);
    setSelectedID("");
    setSelectedProjNum("");
    setSelectedID_Num("");
    setSelectedInitials("");
    setSelectedD_O_B("");
    setSelectedFirstNames("");
    setSelectedLastName("");
    setSelectedGender("");
    setSelectedRace("");
    setSelectedHomeLanguage("");
    setSelectedCellNumber("");
    setSelectedEmailAddress("");
    setSelectedPhysicalAddress("");
    setSelectedPostalAddress("");
    setSelectedStatus("");
    setSelectedAccountHolder("");
    setSelectedAccountNum("");
    setSelectedAccountType("");
    setSelectedBankName("");
    setSelectedBranchName("");
    setSelectedBranchCode("");
    if (GridRef.current) {
      GridRef.current.clearSelection();
    }
    setNewProjNum("");
    setNewID_Num("");
    setNewInitials("");
    setNewD_O_B("");
    setNewFirstNames("");
    setNewLastName("");
    setNewGender("");
    setNewRace("");
    setNewHomeLanguage("");
    setNewCellNumber("");
    setNewEmailAddress("");
    setNewPhysicalAddress("");
    setNewPostalAddress("");
    setNewStatus("");
    setNewAccountHolder("");
    setNewAccountNum("");
    setNewAccountType("");
    setNewBankName("");
    setNewBranchName("");
    setNewBranchCode("");
  };

  const handleAddBeneficiaryButton = async () => {
    // Make a POST request to add the beneficiary record
    const loadingToastId = toast.loading("Please wait...");
    try {
      const BeneficiaryResponse = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/beneficiary",
        beneficiaryNewData
      );
      console.log("Beneficiary response:", BeneficiaryResponse.data);
      if (BeneficiaryResponse.data.status === "success") {
        toast.success("Beneficiary added successfully.");
        // Get the UPDATED DATA by making a GET request
        const BeneficiaryResponseR = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/beneficiary"
        );
        const beneficiaries = BeneficiaryResponseR.data.items;
        const filteredBeneficiaries = beneficiaries.filter((beneficiary) => {
          return gridData.some((item) => item.id === beneficiary.id);
        });
        setGridData(filteredBeneficiaries);
      } else {
        console.error(
          "Error adding beneficiary record:",
          BeneficiaryResponse.data.message
        );
        toast.error("Error adding beneficiary");
      }
    } catch (error) {
      console.error("Error adding Beneficiary:", error);
      toast.error("Error adding beneficiary");
    }
    toast.dismiss(loadingToastId);
  };

  const handleUpdateBeneficiaryButton = async (selectedID) => {
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    try {
      const BeneficiaryResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL +
          `/api/beneficiary/${numericSelectedID}`,
        {
          ProjNum: newProjNum,
          ID_Num: newID_Num,
          Initials: newInitials,
          FirstNames: newFirstNames,
          D_O_B: newD_O_B,
          Gender: newGender,
          LastName: newLastName,
          Race: newRace,
          HomeLanguage: newHomeLanguage,
          CellNumber: newCellNumber,
          EmailAddress: newEmailAddress,
          PhysicalAddress: newPhysicalAddress,
          PostalAddress: newPostalAddress,
          BenStatus: newStatus,
          AccountHolder: newAccountHolder,
          AccountNum: newAccountNum,
          AccountType: newAccountType,
          BankName: newBankName,
          BranchName: newBranchName,
          BranchCode: newBranchCode,
          LastUpdatedBy: user?.FullName,
          LastUpdated: newCurrentDate,
        }
      );
      console.log("Beneficiary response:", BeneficiaryResponse.data);
      if (BeneficiaryResponse.data.status === "success") {
        toast.success("Beneciary updated successfully.");
        // Get the UPDATED DATA by making a GET request
        const BeneficiaryResponseR = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/beneficiary"
        );
        const beneficiaries = BeneficiaryResponseR.data.items;
        const filteredBeneficiaries = beneficiaries.filter((beneficiary) => {
          return gridData.some((item) => item.id === beneficiary.id);
        });
        setGridData(filteredBeneficiaries);
      } else {
        console.error(
          "Error updating beneficiary record:",
          BeneficiaryResponse.data.message
        );
        toast.error("Error updating beneficiary");
      }
    } catch (error) {
      console.error("Error updating beneficiary:", error);
      toast.error("Error updating beneficiary");
    }
    toast.dismiss(loadingToastId);
  };

  const projectNameTemplate = (props) => {
    const projectNum = props.ProjNum; // Assuming the field name is "ProjectNum"
    // Lookup the project name in projectData based on the provided projectNum
    const project = projectData.find(
      (project) => project.ProjectNum === projectNum
    );
    // Check if a project with the given projectNum was found
    if (project) {
      const projectName = project.ProjectName;
      return <div>{projectName}</div>;
    } else {
      // Handle the case where no matching project was found
      return <div>Project Not Found</div>;
    }
  };

  const statusTemplate = (props) => {
    const status = props.BenStatus; // Assuming the status field is named "Status"
    let colorClass = "";

    if (status === "Active") {
      colorClass = "status-active";
    } else if (status === "Resigned") {
      colorClass = "status-inactive";
    } else if (status === "Deceased") {
      colorClass = "status-delayed";
    }

    return <div className={`status-cell ${colorClass}`}>{status}</div>;
  };

  const ageTemplate = (props) => {
    if (!props.D_O_B) {
      return <span>N/A</span>; // Age can't be calculated if D_O_B is missing or blank
    }
    const dob = new Date(props.D_O_B); // Assuming 'D_O_B' is the Date of Birth field

    if (!isNaN(dob.getTime())) {
      const currentDate = new Date();
      const ageInMilliseconds = currentDate - dob;
      const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Approximate calculation for leap years
      return <span>{Math.floor(ageInYears)}</span>;
    } else {
      return <span>N/A</span>; // Age can't be calculated if D_O_B is missing or not valid
    }
  };

  const ageGroupTemplate = (props) => {
    if (!props.D_O_B) {
      return <span>N/A</span>; // Age can't be calculated if D_O_B is missing or blank
    }
    const dob = new Date(props.D_O_B); // Assuming 'D_O_B' is the Date of Birth field
    if (!isNaN(dob.getTime())) {
      const currentDate = new Date();
      const ageInMilliseconds = currentDate - dob;
      const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Approximate calculation for leap years
      let ageGroup = "N/A";
      if (ageInYears < 36) {
        ageGroup = "Youth";
      } else if (ageInYears < 65) {
        ageGroup = "Adult";
      } else if (ageInYears >= 65) {
        ageGroup = "Pensioner";
      }
      return <span>{ageGroup}</span>;
    } else {
      return <span>N/A</span>; // Age group can't be determined if D_O_B is missing or not valid
    }
  };

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];
  const loadingIndicator = { indicatorType: "Spinner" };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button
            className={`view-button ${
              userChatsData.length > 0 ? "chat-button" : ""
            }`}
            onClick={viewMessages}
            disabled={selectedID === ""}
          >
            <ForumIcon className="view-icon" /> Chats{" "}
          </button>
        </div>
        <div className="button-container-subA1">
          {user?.JobTitle === "Administrator" && (
            <button className="add-button" onClick={handleAddButtonClick}>
              <AddIcon className="send-icon" /> Add{" "}
            </button>
          )}
          {user?.JobTitle === "Administrator" && (
            <button
              className="edit-button"
              onClick={handleEditButtonClick}
              disabled={selectedID === ""}
            >
              <EditIcon className="send-icon" /> Edit{" "}
            </button>
          )}
          <button
            className={`delete-button-whereabouts ${
              isButtonEnabled ? "" : "disabled-button"
            }`}
            onClick={handleEditButtonClick}
            disabled={!isButtonEnabled}
          >
            <DeleteIcon className="but-icon-whereabouts" /> Delete{" "}
          </button>
        </div>
        <div className="button-container-subA2">
          <button className="add-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          {/* <button className="edit-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button> */}
        </div>
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  let addButtons = [
    {
      buttonModel: {
        content: "Add",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleAddBeneficiaryButton(selectedID);
        setIsAddDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsAddDialogVisible(false);
      },
    },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleUpdateBeneficiaryButton(selectedID);
        setIsEditDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  //-------------=---------Messages--------------------------------//
  const viewMessages = (id) => {
    setIsMessagesVisible(true);
    axios
      .patch(process.env.REACT_APP_SERVER_URL + `/api/chat/read`, {
        type: "benif_id",
        id: id ? id : selectedID,
      })
      .then(({ data }) => {
        console.log("Read : ", data);
        setUserChatsData((prev) =>
          prev?.filter((notif) => (notif.benif_id !== id ? id : selectedID))
        );
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  };

  useEffect(() => {
    if (id) {
      setSelectedID(() => id);
      viewMessages(id);
      setSearchParams({});
    }
  }, [id]);

  // Custom template for the "message" column
  const chatCountTemplate = (props) => {
    const ID = props.id;
    const chatCount = userChatsData.filter(
      (chat) => chat.benif_id === ID
    ).length;

    const fontColor = chatCount < 1 ? "darkgreen" : "darkred";
    const backgroundColor = chatCount < 1 ? "lightgreen" : "pink";
    const fontWeight = chatCount > 0 ? "bold" : "normal";

    const style = {
      color: fontColor,
      backgroundColor: backgroundColor,
      width: "fit-content",
      height: "100%",
      display: "inline-flex",
      justifyContent: "left",
      alignItems: "left",
      padding: "5px 10px",
      borderRadius: "10px",
      fontWeight: fontWeight,
    };

    return (
      <div style={style}>
        <span>{chatCount}</span>
      </div>
    );
  };

  const handleMessageDialogClose = () => {
    setIsMessagesVisible(false);
  };
  //-------------=---------Messages--------------------------------//

  return (
    <div className="my-to-do-beneficiaries">
      <h3>List of Active Beneficiaries in {userLocation}</h3>
      <br />
      <div className="field-line-my-beneficiaries">
        {isDataLoaded && (
          <GridComponent
            dataSource={gridData}
            childMapping="items"
            ref={GridRef}
            classClass="custom-treegrid-class"
            height="650"
            allowReordering={true}
            allowResizing={true}
            allowExcelExport={true}
            allowPdfExport={true}
            allowPaging={true}
            allowSorting={true}
            allowFiltering={true}
            showColumnChooser={true}
            filterSettings={FilterOptions}
            pageSettings={pageSettings}
            editSettings={editOptions}
            toolbar={updatedToolbarOptions}
            loadingIndicator={loadingIndicator}
            allowSelection={true}
            rowSelected={handleRowSelect}
          >
            <ColumnsDirective>
              {/* <ColumnDirective
                field="id"
                headerText="ID"
                width="100"
                textAlign="Left"
                isPrimaryKey={true}
              /> */}
              <ColumnDirective
                headerText="Chats"
                width="100"
                textAlign="Left"
                template={chatCountTemplate}
              />
              <ColumnDirective
                field="ProjNum"
                headerText="Project #"
                width="120"
                textAlign="Left"
              />
              <ColumnDirective
                headerText="Project Name"
                width="160"
                textAlign="Left"
                template={projectNameTemplate}
              />
              <ColumnDirective
                field="Initials"
                headerText="Initials"
                width="70"
                textAlign="Left"
              />
              <ColumnDirective
                field="FirstNames"
                headerText="First Names"
                width="200"
                textAlign="Left"
              />
              <ColumnDirective
                field="LastName"
                headerText="LastName"
                width="120"
                textAlign="Left"
              />
              <ColumnDirective
                field="BenStatus"
                headerText="Status"
                width="90"
                textAlign="Left"
                template={statusTemplate}
              />
              <ColumnDirective
                field="ID_Num"
                headerText="ID Number"
                width="150"
                textAlign="Left"
                type="string"
              />
              <ColumnDirective
                field="D_O_B"
                headerText="Date of Birth"
                width="120"
                format="yyyy/MM/dd"
                textAlign="Left"
                type="date"
                allowFiltering={false}
              />
              <ColumnDirective
                headerText="Age"
                width="80"
                textAlign="Left"
                template={ageTemplate}
              />
              <ColumnDirective
                headerText="Age Group"
                width="120"
                textAlign="Left"
                template={ageGroupTemplate}
              />
              <ColumnDirective
                field="Gender"
                headerText="Gender"
                width="90"
                textAlign="Left"
                editType="dropdownedit"
              />
              <ColumnDirective
                field="Race"
                headerText="Race"
                width="70"
                textAlign="Left"
                editType="dropdownedit"
              />
              <ColumnDirective
                field="HomeLanguage"
                headerText="Language"
                width="100"
                textAlign="Left"
                editType="dropdownedit"
              />
              <ColumnDirective
                field="CellNumber"
                headerText="Cell Number"
                width="120"
                textAlign="Left"
              />
              <ColumnDirective
                field="EmailAddress"
                headerText="Email Address"
                width="250"
                textAlign="Left"
              />
              <ColumnDirective
                field="PhysicalAddress"
                headerText="Physical Address"
                width="350"
                textAlign="Left"
              />
              <ColumnDirective
                field="PostalAddress"
                headerText="Postal Address"
                width="350"
                textAlign="Left"
              />
              <ColumnDirective
                field="ActiveBankAcc"
                headerText="Active Bank?"
                width="60"
                textAlign="Left"
              />
              <ColumnDirective
                field="AccountHolder"
                headerText="Account Holder"
                width="150"
                textAlign="Left"
              />
              <ColumnDirective
                field="AccountNum"
                headerText="Account Number"
                width="150"
                textAlign="Left"
              />
              <ColumnDirective
                field="AccountType"
                headerText="Account Type"
                width="120"
                textAlign="Left"
              />
              <ColumnDirective
                field="BankName"
                headerText="Bank Name"
                width="130"
                textAlign="Left"
              />
              <ColumnDirective
                field="BranchName"
                headerText="Branch Name"
                width="120"
                textAlign="Left"
              />
              <ColumnDirective
                field="BranchCode"
                headerText="Branch Code"
                width="120"
                textAlign="Left"
              />
              <ColumnDirective
                field="LastUpdated"
                headerText="Last Updated"
                width="120"
                format="yyyy/MM/dd"
                textAlign="Right"
                type="date"
                allowFiltering={false}
              />
              <ColumnDirective
                field="LastUpdatedBy"
                headerText="Last Updated By"
                width="150"
                textAlign="Right"
              />
            </ColumnsDirective>
            <Inject
              services={[
                Page,
                Sort,
                Filter,
                Edit,
                Toolbar,
                ColumnChooser,
                Reorder,
                Resize,
                PdfExport,
                ExcelExport,
              ]}
            />
          </GridComponent>
        )}
      </div>
      <div>
        {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="80%"
            height="790px"
            header="Edit Beneficiary"
            allowDragging={true}
            showCloseIcon={true}
            close={handleEditDialogClose}
            buttons={editButtons}
          >
            <BeneficiaryDialogMain
              selectedID={selectedID}
              selectedProjNum={selectedProjNum}
              selectedProjectName={selectedProjectName}
              selectedID_Num={selectedID_Num}
              selectedInitials={selectedInitials}
              selectedD_O_B={selectedD_O_B}
              selectedFirstNames={selectedFirstNames}
              selectedLastName={selectedLastName}
              selectedGender={selectedGender}
              selectedRace={selectedRace}
              selectedHomeLanguage={selectedHomeLanguage}
              selectedCellNumber={selectedCellNumber}
              selectedEmailAddress={selectedEmailAddress}
              selectedPhysicalAddress={selectedPhysicalAddress}
              selectedPostalAddress={selectedPostalAddress}
              selectedStatus={selectedStatus}
              selectedAccountHolder={selectedAccountHolder}
              selectedAccountNum={selectedAccountNum}
              selectedAccountType={selectedAccountType}
              selectedBankName={selectedBankName}
              selectedBranchName={selectedBranchName}
              selectedBranchCode={selectedBranchCode}
              uniqueProjects={uniqueProjects}
              uniqueBanks={uniqueBanks}
              uniqueBranches={uniqueBranches}
              uniqueTypes={uniqueTypes}
              filteredProjectData={filteredProjectData}
              setNewProjNum={setNewProjNum}
              setNewID_Num={setNewID_Num}
              setNewInitials={setNewInitials}
              setNewD_O_B={setNewD_O_B}
              setNewFirstNames={setNewFirstNames}
              setNewLastName={setNewLastName}
              setNewGender={setNewGender}
              setNewRace={setNewRace}
              setNewHomeLanguage={setNewHomeLanguage}
              setNewCellNumber={setNewCellNumber}
              setNewEmailAddress={setNewEmailAddress}
              setNewPhysicalAddress={setNewPhysicalAddress}
              setNewPostalAddress={setNewPostalAddress}
              setNewStatus={setNewStatus}
              setNewAccountHolder={setNewAccountHolder}
              setNewAccountNum={setNewAccountNum}
              setNewAccountType={setNewAccountType}
              setNewBankName={setNewBankName}
              setNewBranchName={setNewBranchName}
              setNewBranchCode={setNewBranchCode}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isAddDialogVisible && (
          <DialogComponent
            visible={isAddDialogVisible}
            width="80%"
            height="790px"
            header="Add Beneficiary"
            allowDragging={true}
            showCloseIcon={true}
            close={handleAddDialogClose}
            buttons={addButtons}
          >
            <BeneficiaryDialogMain
              selectedID={selectedID}
              selectedProjNum={selectedProjNum}
              selectedID_Num={selectedID_Num}
              selectedInitials={selectedInitials}
              selectedD_O_B={selectedD_O_B}
              selectedFirstNames={selectedFirstNames}
              selectedLastName={selectedLastName}
              selectedGender={selectedGender}
              selectedRace={selectedRace}
              selectedHomeLanguage={selectedHomeLanguage}
              selectedCellNumber={selectedCellNumber}
              selectedEmailAddress={selectedEmailAddress}
              selectedPhysicalAddress={selectedPhysicalAddress}
              selectedPostalAddress={selectedPostalAddress}
              selectedStatus={selectedStatus}
              uniqueProjects={uniqueProjects}
              uniqueBanks={uniqueBanks}
              uniqueBranches={uniqueBranches}
              uniqueTypes={uniqueTypes}
              filteredProjectData={filteredProjectData}
              setNewProjNum={setNewProjNum}
              setNewID_Num={setNewID_Num}
              setNewInitials={setNewInitials}
              setNewD_O_B={setNewD_O_B}
              setNewFirstNames={setNewFirstNames}
              setNewLastName={setNewLastName}
              setNewGender={setNewGender}
              setNewRace={setNewRace}
              setNewHomeLanguage={setNewHomeLanguage}
              setNewCellNumber={setNewCellNumber}
              setNewEmailAddress={setNewEmailAddress}
              setNewPhysicalAddress={setNewPhysicalAddress}
              setNewPostalAddress={setNewPostalAddress}
              setNewStatus={setNewStatus}
              setNewAccountHolder={setNewAccountHolder}
              setNewAccountNum={setNewAccountNum}
              setNewAccountType={setNewAccountType}
              setNewBankName={setNewBankName}
              setNewBranchName={setNewBranchName}
              setNewBranchCode={setNewBranchCode}
            />
          </DialogComponent>
        )}
      </div>
      {isMessagesVisible && (
        <div>
          <Modal
            open={isMessagesVisible}
            onClose={handleMessageDialogClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="message_outer_container">
                <h1>{`Chats about ${selectedFirstNames} ${selectedLastName} of ${selectedProjectName} Project`}</h1>
                <div className="message_inner_container">
                  <MessageBox
                    users={allUserData}
                    chatID={selectedID}
                    chatUsers={selectedChatUsers}
                    type="benif_id"
                  />
                </div>
                <button
                  className="add-button"
                  onClick={handleMessageDialogClose}
                >
                  <CloseIcon className="send-icon" /> Close
                </button>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
}
export default BenActiveBeneficiaries;
