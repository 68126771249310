import React, { useEffect, useState, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
//import { registerLicense} from '@syncfusion/ej2-base';
import { UploaderComponent } from "@syncfusion/ej2-react-inputs"; // Import the Uploader component
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import axios from "../../../../config/axios";
import "./SmtsLists.css";
//registerLicense('ORg4AjUWIQA/Gnt2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5bdkdiUH1ecXNTQmlc');

function SmtsLists() {
  const [data, setData] = useState([]);
  const GridRef = useRef(null); // Create the treeGridRef reference

  // Define the fetchData function
  const fetchData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/user"
      );
      return response.data.items;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  useEffect(() => {
    // Call the fetchData function inside the useEffect to fetch initial data
    fetchData().then((initialData) => {
      setData(initialData);
    });
  }, []);

  const handleActionBegin = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Handle add operation
        try {
          // Assuming args.data contains the new record
          const newRecord = { ...args.data };
          delete newRecord.id; // Remove the 'id' field from the new record

          // Save the new record to the backend API
          const response = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/user",
            newRecord
          );
          console.log("Data added successfully:", response.data);

          // Update the state with the new record
          setData([...data, response.data]);

          // If needed, you can refresh the Grid after adding the record
          GridRef.current.refresh();
        } catch (error) {
          console.error("Error adding data:", error);
          // Prevent the Grid from closing the add dialog on error
          args.cancel = true;
        }
      } else if (args.action === "delete") {
        // Handle delete operation
        try {
          const response = await axios.delete(
            process.env.REACT_APP_SERVER_URL + `/api/user/${args.data[0].id}`
          );
          console.log("Data deleted successfully:", response.data);

          // Fetch the updated data from the API after successful deletion
          const updatedData = await fetchData(); // Make sure you have this function
          setData(updatedData);
        } catch (error) {
          console.error("Error deleting data:", error);
          // Prevent the Grid from closing the delete confirmation dialog on error
          args.cancel = true;
        }
      }
    }
  };

  const handleSave = async (args) => {
    console.log(args);
    // Send the updated data to the API for updating the records
    try {
      if (args && args.data && args.data.id) {
        const data = args.data;
        const response = await axios.put(
          process.env.REACT_APP_SERVER_URL + "/api/user/" + args.data.id,
          data
        );
        // Handle the response if needed (e.g., show success message)
        console.log("Data updated successfully:", response.data);
      } else {
        console.error(
          'Error: Invalid data or missing "id" property in args.data'
        );
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleProfilePicUpload = async (args, userId) => {
    const file = args.file; // The uploaded file object from the Uploader
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL +
          `/api/user/${userId}/upload-profile-pic`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Profile picture uploaded successfully:", response.data);

      // Fetch the updated data after successful upload
      const updatedData = await fetchData();
      setData(updatedData);
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: true,
    allowDeleting: true,
    allowEditing: true,
  };
  const toolbarOptions = [
    "Add",
    "Edit",
    "Delete",
    "Update",
    "Cancel",
    "ColumnChooser",
  ];
  const loadingIndicator = { indicatorType: "Spinner" };

  return (
    <GridComponent
      dataSource={data}
      childMapping="items"
      ref={GridRef}
      classClass="custom-treegrid-class"
      height="650"
      allowPaging={true}
      allowSorting={true}
      allowFiltering={true}
      showColumnChooser={true}
      actionBegin={handleActionBegin}
      filterSettings={FilterOptions}
      pageSettings={pageSettings}
      editSettings={editOptions}
      toolbar={toolbarOptions}
      actionComplete={handleSave}
      loadingIndicator={loadingIndicator}>
      <ColumnsDirective>
        <ColumnDirective
          field="id"
          headerText="ID"
          width="60"
          textAlign="Left"
          isPrimaryKey={true}
        />
        <ColumnDirective
          field="FullName"
          headerText="Full Name #"
          width="200"
          textAlign="Left"
        />
        <ColumnDirective
          field="JobTitle"
          headerText="Job Title"
          width="230"
          textAlign="Left"
        />
        <ColumnDirective
          field="UserStatus"
          headerText="Status"
          width="120"
          textAlign="Left"
        />
        <ColumnDirective
          field="Access"
          headerText="Access"
          width="120"
          textAlign="Left"
        />
        <ColumnDirective
          field="AccessProvince"
          headerText="Province"
          width="160"
          textAlign="Left"
        />
        <ColumnDirective
          field="AccessDristrict"
          headerText="District"
          width="220"
          textAlign="Left"
        />
        <ColumnDirective
          field="AccessRegion"
          headerText="Region"
          width="220"
          textAlign="Left"
        />
        <ColumnDirective
          field="UserName"
          headerText="UserName"
          width="120"
          textAlign="Left"
          editType="dropdownedit"
        />
        <ColumnDirective
          field="Password"
          headerText="Password"
          width="120"
          textAlign="Left"
          editType="dropdownedit"
        />
        <ColumnDirective
          field="PrintReports"
          headerText="Reports"
          width="120"
          textAlign="Left"
          editType="dropdownedit"
        />
        <ColumnDirective
          field="DeleteItems"
          headerText="Delete"
          width="120"
          textAlign="Left"
        />
        <ColumnDirective
          field="EditItems"
          headerText="Edit"
          width="120"
          textAlign="Left"
        />
        <ColumnDirective
          field="AddItems"
          headerText="Add New"
          width="120"
          textAlign="Left"
        />
        <ColumnDirective
          field="ChangeUsers"
          headerText="Change Users"
          width="120"
          textAlign="Left"
        />
        <ColumnDirective
          field="EmailAddress"
          headerText="Email Address"
          width="250"
          textAlign="Left"
        />
        <ColumnDirective
          field="ProfilePic"
          headerText="Profile Pic"
          width="300"
        />
        <ColumnDirective
          field="CreatedOn"
          headerText="Created On"
          width="120"
          format="yyyy/MM/dd"
          textAlign="Right"
          type="date"
        />
        <ColumnDirective
          field="CreatedBy"
          headerText="Created By"
          width="150"
          textAlign="Right"
        />
        <ColumnDirective
          field="LastUpdated"
          headerText="Last Updated"
          width="120"
          format="yyyy/MM/dd"
          textAlign="Right"
          type="date"
        />
        <ColumnDirective
          field="LastUpdatedBy"
          headerText="Last Updated By"
          width="150"
          textAlign="Right"
        />
      </ColumnsDirective>
      <Inject services={[Page, Sort, Filter, Edit, Toolbar, ColumnChooser]} />
    </GridComponent>
  );
}
export default SmtsLists;
