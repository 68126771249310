import { useEffect, useState } from "react";
import axios from "../../config/axios";

const useProjectStatusDataFetch = () => {
  const [projectStatusData, setProjectStatusData] = useState([]);
  const [isProjectStatusLoaded, setIsProjectStatusLoaded] = useState(false);

  // Define the fetchData function for project status change
  const fetchProjectStatusData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/statuschange"
      );
      setIsProjectStatusLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching project status data:", error);
      setIsProjectStatusLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch project status change
    fetchProjectStatusData().then((projectStatusInitialData) => {
      setProjectStatusData(projectStatusInitialData);
    });
  }, []);

  // Return an object containing all the fetched data
  return {
    projectStatusData,
    isProjectStatusLoaded,
  }
};

export default useProjectStatusDataFetch;
