import React from "react";
import "../Sidebar.css";
import MessageOption from "./MessageOption";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleIcon from "@mui/icons-material/People";
import AddTaskIcon from "@mui/icons-material/AddTask";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PaymentsIcon from "@mui/icons-material/Payments";
import { NavLink } from "react-router-dom";

function Messages() {
  return (
    <div className="sidebar">
      <NavLink to="projects">
        {<MessageOption Icon={ApartmentIcon} title="Project Messages" />}
      </NavLink>
      <NavLink to="beneficiaries">
        {<MessageOption Icon={PeopleIcon} title="Beneficiary Messages" />}
      </NavLink>
      <NavLink to="attachments">
        {<MessageOption Icon={AttachFileIcon} title="Attachment Messages" />}
      </NavLink>
      <NavLink to="payments">
        {<MessageOption Icon={PaymentsIcon} title="Payment Messages" />}
      </NavLink>
      {/* <NavLink to="tasks">
          {<MessageOption Icon={AddTaskIcon} title="Task Messages" />}
        </NavLink>    */}
    </div>
  );
}

export default Messages;
