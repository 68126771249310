import React, { useEffect, useState, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  Reorder,
  Resize,
  PdfExport,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import axios from "../../../../config/axios";
import "./CompliancyList.css";
import CompliancyDialogMain from "./CompliancyDialogMain";
import useProjectDataFetch from "../../../shared/useProjectDataFetch"; // Import the custom hook
import useOtherDataFetch from "../../../shared/useOtherDataFetch"; // Import the custom hook
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import useUserStore from "../../../../app/user";
import { toast } from "react-toastify";
import { dateFormatter } from "../../../../utils/formats";
import "../../../shared/MessageDialog.css";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import ForumIcon from "@mui/icons-material/Forum";
import MessageBox from "../../messages/MessageBox";
import useUserDataFetch from "../../../shared/useUserDataFetch";
import {
  getCommonUsers,
  getProjectChatUsers,
  getUserDeleteRight,
  getUserLocation,
} from "../../../../utils";
import useUserChatsDataFetch from "../../../shared/useUserChatsDataFetch";
import socket from "../../../../utils/socket";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "90%",
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 2,
};

function ComNotActive() {
  const user = useUserStore((state) => state.user);
  const [gridData, setGridData] = useState([]);
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [userLocation, setUserLocation] = useState("");
  const { projectData, isProjectLoaded } = useProjectDataFetch(user);
  const { allUserData, isAllUserLoaded } = useUserDataFetch([]);
  const { userChatsData, isUserChatsLoaded, setUserChatsData } =
    useUserChatsDataFetch([]); // Use the custom hook to fetch data
  const [commonUserData, setCommonUserData] = useState([]);
  const [selectedChatUsers, setSelectedChatUsers] = useState([]);
  const [isMessagesVisible, setIsMessagesVisible] = useState(false);
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const { beneficiaryData, isBeneficiaryLoaded } = useOtherDataFetch();

  useEffect(() => {
    if (isProjectLoaded && isBeneficiaryLoaded && isAllUserLoaded) {
      const filteredProjectData = projectData.filter(
        (project) =>
          project.Status !== "Deleted" && project.ProjectStatus !== "Active"
      );
      setGridData(filteredProjectData);

      const filteredUserData = getCommonUsers(allUserData);
      setCommonUserData(filteredUserData);

      setIsDataLoaded(true);
    }
  }, [
    projectData,
    isProjectLoaded,
    isBeneficiaryLoaded,
    isAllUserLoaded,
    isUserChatsLoaded,
  ]);

  useEffect(() => {
    socket.on("notification", (data) => {
      console.log("Receive Notification: ", data);
      const isTrue = userChatsData.find((chat) => chat.ID === data.ID);
      if (!isTrue)
        setUserChatsData((prev) => (prev ? [...prev, data] : [data]));
    });
  }, []);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  useEffect(() => {
    const deleteRights = getUserDeleteRight(user);
    setIsButtonEnabled(deleteRights);
    const location = getUserLocation(user);
    setUserLocation(location);
  }, [user]);

  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // Initial state
  const [selectedID, setSelectedID] = useState("");
  const [selectedProjectNum, setSelectedProjectNum] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedGeneralComment, setSelectedGeneralComment] = useState("");
  const [selectedPhysicalAddress, setSelectedPhysicalAddress] = useState("");
  const [selectedPostalAddress, setSelectedPostalAddress] = useState("");
  const [selectedTown, setSelectedTown] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedRegNum, setSelectedRegNum] = useState("");
  const [selectedTaxNum, setSelectedTaxNum] = useState("");
  const [selectedVatNum, setSelectedVatNum] = useState("");
  const [selectedRegStatus, setSelectedRegStatus] = useState("");
  const [selectedCompanyWebsite, setSelectedCompanyWebsite] = useState("");
  const [selectedContactPerson, setSelectedContactPerson] = useState("");
  const [selectedEmailAddress, setSelectedEmailAddress] = useState("");
  const [selectedTelephone, setSelectedTelephone] = useState("");
  const [selectedActiveBankAccount, setSelectedActiveBankAccount] =
    useState("");
  const [selectedAccountHolder, setSelectedAccountHolder] = useState("");
  const [selectedAccountNum, setSelectedAccountNum] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const [selectedBankName, setSelectedBankName] = useState("");
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [selectedBranchCode, setSelectedBranchCode] = useState("");

  const [newGeneralComment, setNewGeneralComment] = useState("");
  const [newPhysicalAddress, setNewPhysicalAddress] = useState("");
  const [newPostalAddress, setNewPostalAddress] = useState("");
  const [newTown, setNewTown] = useState("");
  const [newRegNum, setNewRegNum] = useState("");
  const [newTaxNum, setNewTaxNum] = useState("");
  const [newVatNum, setNewVatNum] = useState("");
  const [newRegStatus, setNewRegStatus] = useState("");
  const [newCompanyWebsite, setNewCompanyWebsite] = useState("");
  const [newContactPerson, setNewContactPerson] = useState("");
  const [newEmailAddress, setNewEmailAddress] = useState("");
  const [newTelephone, setNewTelephone] = useState("");
  const [newActiveBankAccount, setNewActiveBankAccount] = useState("");
  const [newAccountHolder, setNewAccountHolder] = useState("");
  const [newAccountNum, setNewAccountNum] = useState("");
  const [newAccountType, setNewAccountType] = useState("");
  const [newBankName, setNewBankName] = useState("");
  const [newBranchName, setNewBranchName] = useState("");
  const [newBranchCode, setNewBranchCode] = useState("");

  // Get unique towns from projectData
  const uniqueTowns = [...new Set(projectData?.map((item) => item.Town))];
  // Get unique banks from projectData
  const uniqueBanks = [
    ...new Set(
      projectData?.map((item) => (item.BankName ? item.BankName : ""))
    ),
  ];
  // Get unique branches from projectData
  const uniqueBranches = [
    ...new Set(
      projectData?.map((item) => (item.BranchName ? item.BranchName : ""))
    ),
  ];
  // Get unique account types from projectData
  const uniqueTypes = [
    ...new Set(
      projectData?.map((item) => (item.AccountType ? item.AccountType : ""))
    ),
  ];

  const handleEditButtonClick = () => {
    setIsEditDialogVisible(!isEditDialogVisible);

    setNewGeneralComment(selectedGeneralComment);
    setNewPhysicalAddress(selectedPhysicalAddress);
    setNewPostalAddress(selectedPostalAddress);
    setNewTown(selectedTown);
    setNewRegNum(selectedRegNum);
    setNewTaxNum(selectedTaxNum);
    setNewVatNum(selectedVatNum);
    setNewRegStatus(selectedRegStatus);
    setNewCompanyWebsite(selectedCompanyWebsite);
    setNewContactPerson(selectedContactPerson);
    setNewEmailAddress(selectedEmailAddress);
    setNewTelephone(selectedTelephone);
    setNewActiveBankAccount(selectedActiveBankAccount);
    setNewAccountHolder(selectedAccountHolder);
    setNewAccountNum(selectedAccountNum);
    setNewAccountType(selectedAccountType);
    setNewBankName(selectedBankName);
    setNewBranchName(selectedBranchName);
    setNewBranchCode(selectedBranchCode);
  };

  const handleDeleteButtonClick = () => {};

  const currentTitleDate = dateFormatter(new Date());
  const handleExcelButtonClick = () => {
    if (GridRef.current && GridRef.current.excelExport) {
      const exportProperties = {
        pageOrientation: "Landscape",
        fileName:
          "List Printed by " +
          user.FullName +
          " on " +
          currentTitleDate +
          ".xlsx",
      };
      GridRef.current.excelExport(exportProperties);
    }
  };

  const handlePdfButtonClick = () => {};

  const handleRowSelect = async (args) => {
    setSelectedID(args.data.id);
    setSelectedProjectNum(args.data.ProjectNum);
    setSelectedProjectName(args.data.ProjectName);
    setSelectedGeneralComment(args.data.GeneralComment);
    setSelectedPhysicalAddress(args.data.PhysicalAddress);
    setSelectedPostalAddress(args.data.PostalAddress);
    setSelectedTown(args.data.Town);
    setSelectedStatus(args.data.ProjectStatus);
    setSelectedRegNum(args.data.RegNum);
    setSelectedTaxNum(args.data.TaxNum);
    setSelectedVatNum(args.data.VatNum);
    setSelectedRegStatus(args.data.RegStatus);
    setSelectedCompanyWebsite(args.data.CompanyWebsite);
    setSelectedContactPerson(args.data.ContactPerson);
    setSelectedEmailAddress(args.data.EmailAddress);
    setSelectedTelephone(args.data.Telephone);
    setSelectedActiveBankAccount(args.data.ActiveBankAccount);
    setSelectedAccountHolder(args.data.AccountHolder);
    setSelectedAccountNum(args.data.AccountNum);
    setSelectedAccountType(args.data.AccountType);
    setSelectedBankName(args.data.BankName);
    setSelectedBranchName(args.data.BranchName);
    setSelectedBranchCode(args.data.BranchCode);
    if (args.data.ProjectNum) {
      const chatUsers = getProjectChatUsers(
        args.data.Province,
        args.data.Region,
        allUserData,
        commonUserData,
        user
      );
      setSelectedChatUsers(chatUsers);
    } else {
      setSelectedChatUsers("");
    }
  };

  const handleUpdateProjectDetailsButton = async (selectedID) => {
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    try {
      const ProjectResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/project/${numericSelectedID}`,
        {
          GeneralComment: newGeneralComment,
          PhysicalAddress: newPhysicalAddress,
          PostalAddress: newPostalAddress,
          Town: newTown,
          RegNum: newRegNum,
          TaxNum: newTaxNum,
          VatNum: newVatNum,
          RegStatus: newRegStatus,
          CompanyWebsite: newCompanyWebsite,
          ContactPerson: newContactPerson,
          EmailAddress: newEmailAddress,
          Telephone: newTelephone,
          ActiveBankAccount: newActiveBankAccount,
          AccountHolder: newAccountHolder,
          AccountNum: newAccountNum,
          AccountType: newAccountType,
          BankName: newBankName,
          BranchName: newBranchName,
          BranchCode: newBranchCode,
          LastUpdatedBy: user?.FullName,
          LastUpdated: new Date(),
        }
      );
      console.log("Project response:", ProjectResponse.data);
      if (ProjectResponse.data.status === "success") {
        toast.success(`${selectedProjectName} was updated successfully.`);
        const updatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/project"
        );
        const projects = updatedResponse.data.items;
        const updatedGridData = projects.filter((project) => {
          return gridData.some((item) => item.id === project.id);
        });
        setGridData(updatedGridData);
      } else {
        console.error(
          "Error updating project record:",
          ProjectResponse.data.message
        );
        toast.error("Error updating the project");
      }
    } catch (error) {
      console.error("Error updating project:", error);
      toast.error("Error updating the project");
    }
    toast.dismiss(loadingToastId);
  };

  const statusTemplate = (props) => {
    const status = props.ProjectStatus; // Assuming the status field is named "Status"
    let colorClass = "";
    if (status === "Active") {
      colorClass = "status-active";
    } else if (status === "Non-active") {
      colorClass = "status-inactive";
    } else if (status === "Progress delayed") {
      colorClass = "status-delayed";
    }
    return <div className={`status-cell ${colorClass}`}>{status}</div>;
  };

  const beneficiaryCountTemplate = (rowData) => {
    if (!rowData || !beneficiaryData.length) {
      return null;
    }
    const projectNum = rowData.ProjectNum;
    const matchingBeneficiaries = beneficiaryData.filter(
      (beneficiary) => beneficiary.ProjNum === projectNum
    );
    const beneficiaryCount = matchingBeneficiaries.length;

    return <span>{beneficiaryCount}</span>;
  };

  const activeBeneficiaryCountTemplate = (rowData) => {
    if (!rowData || !beneficiaryData.length) {
      return null;
    }
    const projectNum = rowData.ProjectNum;
    const matchingBeneficiaries = beneficiaryData.filter((beneficiary) => {
      return (
        beneficiary.ProjNum === projectNum && beneficiary.BenStatus === "Active"
      );
    });
    const beneficiaryCount = matchingBeneficiaries.length;
    const isGreen = beneficiaryCount >= 5;
    return (
      <div
        style={{
          color: isGreen ? "green" : "red",
        }}
      >
        {beneficiaryCount}
      </div>
    );
  };

  function regNumTemplate(props) {
    const regNum = props.RegNum;
    // Check if the last 3 characters of RegNum are "/24" and the total character count is 14
    const isGreen = regNum.length === 14 && regNum.slice(-3) === "/24";
    return (
      <div
        style={{
          color: isGreen ? "green" : "red",
        }}
      >
        {regNum}
      </div>
    );
  }

  const compliancyStatusTemplate = (rowData) => {
    if (!rowData) {
      return null;
    }
    // Calculate beneficiary count
    const projectNum = rowData.ProjectNum;
    const matchingBeneficiaries = beneficiaryData.filter((beneficiary) => {
      return (
        beneficiary.ProjNum === projectNum && beneficiary.BenStatus === "Active"
      );
    });
    const beneficiaryCount = matchingBeneficiaries.length;
    // Check conditions for CompliancyStatus
    const isGreen =
      rowData.RegNum.length === 14 && rowData.RegNum.endsWith("/24");
    const isActiveBankAccount = rowData.ActiveBankAccount === "Yes";
    const isCompliant = isActiveBankAccount && beneficiaryCount >= 5 && isGreen;
    return (
      <div
        style={{
          color: isCompliant ? "green" : "red",
        }}
      >
        {isCompliant ? "Compliant" : "Not Compliant"}
      </div>
    );
  };

  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleUpdateProjectDetailsButton(selectedID); // Replace with your actual logic
        setIsEditDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button
            className={`view-button ${
              userChatsData.length > 0 ? "chat-button" : ""
            }`}
            onClick={viewMessages}
            disabled={selectedID === ""}
          >
            <ForumIcon className="view-icon" /> Chats{" "}
          </button>
        </div>
        <div className="button-container-subA1">
          {user?.JobTitle === "Administrator" && (
            <button
              className="edit-button"
              onClick={handleEditButtonClick}
              disabled={selectedID === ""}
            >
              <EditIcon className="send-icon" /> Edit{" "}
            </button>
          )}
          <button
            className={`delete-button-whereabouts ${
              isButtonEnabled ? "" : "disabled-button"
            }`}
            onClick={handleDeleteButtonClick}
            disabled={!isButtonEnabled}
          >
            <DeleteIcon className="but-icon-whereabouts" /> Delete{" "}
          </button>
        </div>
        <div className="button-container-subA2">
          <button className="add-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          {/* <button className="edit-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button> */}
        </div>
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];
  //-------------=---------Messages--------------------------------//
  const viewMessages = () => {
    setIsMessagesVisible(true);
    axios
      .patch(process.env.REACT_APP_SERVER_URL + `/api/chat/read`, {
        type: "project_id",
        id: selectedID,
      })
      .then(({ data }) => {
        console.log("Read : ", data);
        setUserChatsData((prev) =>
          prev?.filter((notif) => notif.project_id !== selectedID)
        );
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  };

  // Custom template for the "message" column
  const chatCountTemplate = (props) => {
    const ID = props.id;
    const chatCount = userChatsData.filter(
      (chat) => chat.project_id === ID
    ).length;

    const fontColor = chatCount < 1 ? "darkgreen" : "darkred";
    const backgroundColor = chatCount < 1 ? "lightgreen" : "pink";
    const fontWeight = chatCount > 0 ? "bold" : "normal";

    const style = {
      color: fontColor,
      backgroundColor: backgroundColor,
      width: "fit-content",
      height: "100%",
      display: "inline-flex",
      justifyContent: "left",
      alignItems: "left",
      padding: "5px 10px",
      borderRadius: "10px",
      fontWeight: fontWeight,
    };

    return (
      <div style={style}>
        <span>{chatCount}</span>
      </div>
    );
  };

  const handleMessageDialogClose = () => {
    setIsMessagesVisible(false);
  };
  //-------------=---------Messages--------------------------------//

  return (
    <div className="all-main-compliancy-containers">
      <div className="all-outer-compliancy-containers">
        <h3>List of All None-Active Projects in {userLocation}</h3>
        <br />
        <div className="all-inner-compliancy-containers">
          {isDataLoaded && (
            <GridComponent
              dataSource={gridData}
              childMapping="items"
              ref={GridRef}
              classClass="custom-treegrid-class"
              height="650"
              width="99.5%"
              allowReordering={true}
              allowResizing={true}
              allowExcelExport={true}
              allowPdfExport={true}
              allowPaging={true}
              allowSorting={true}
              allowFiltering={true}
              showColumnChooser={true}
              filterSettings={FilterOptions}
              pageSettings={pageSettings}
              editSettings={editOptions}
              toolbar={updatedToolbarOptions}
              allowSelection={true}
              rowSelected={handleRowSelect}
            >
              <ColumnsDirective>
                {/* <ColumnDirective
                  field="id"
                  headerText="ID"
                  width="100"
                  textAlign="Left"
                  isPrimaryKey={true}
                /> */}
                <ColumnDirective
                  headerText="Chats"
                  width="100"
                  textAlign="Left"
                  template={chatCountTemplate}
                />
                <ColumnDirective
                  field="ProjectNum"
                  headerText="Project #"
                  width="150"
                  textAlign="Left"
                  allowEditing={false}
                />
                <ColumnDirective
                  field="ProjectName"
                  headerText="Project Name"
                  width="200"
                  textAlign="Left"
                  allowEditing={false}
                />
                <ColumnDirective
                  field="ProjectStatus"
                  headerText="Status"
                  width="100"
                  textAlign="Left"
                  template={statusTemplate}
                  allowEditing={false}
                />
                <ColumnDirective
                  field="RegNum"
                  headerText="Registration #"
                  width="150"
                  textAlign="Left"
                  template={regNumTemplate}
                />
                <ColumnDirective
                  headerText="All Beneficiaries"
                  width="150"
                  textAlign="Left"
                  template={beneficiaryCountTemplate}
                  allowEditing={false}
                />
                <ColumnDirective
                  headerText="Active Beneficiaries"
                  width="150"
                  textAlign="Left"
                  template={activeBeneficiaryCountTemplate}
                  allowEditing={false}
                />
                <ColumnDirective
                  field="RegStatus"
                  headerText="CIPC Status"
                  width="120"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="ActiveBankAccount"
                  headerText="Active Account"
                  width="120"
                  textAlign="Left"
                />
                <ColumnDirective
                  headerText="CompliancyStatus"
                  width="150"
                  textAlign="Left"
                  template={compliancyStatusTemplate}
                  allowEditing={false}
                />
              </ColumnsDirective>
              <Inject
                services={[
                  Page,
                  Sort,
                  Filter,
                  Edit,
                  Toolbar,
                  ColumnChooser,
                  Reorder,
                  Resize,
                  PdfExport,
                  ExcelExport,
                ]}
              />
            </GridComponent>
          )}
        </div>
      </div>
      <div>
        {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="70%"
            height="95%"
            header="Edit Project Details"
            showCloseIcon={true}
            allowDragging={true}
            close={handleEditDialogClose}
            buttons={editButtons}
          >
            <CompliancyDialogMain
              projectData={projectData}
              selectedID={selectedID}
              selectedProjectNum={selectedProjectNum}
              selectedProjectName={selectedProjectName}
              selectedGeneralComment={selectedGeneralComment}
              selectedPhysicalAddress={selectedPhysicalAddress}
              selectedPostalAddress={selectedPostalAddress}
              selectedTown={selectedTown}
              selectedStatus={selectedStatus}
              selectedRegNum={selectedRegNum}
              selectedTaxNum={selectedTaxNum}
              selectedVatNum={selectedVatNum}
              selectedRegStatus={selectedRegStatus}
              selectedCompanyWebsite={selectedCompanyWebsite}
              selectedContactPerson={selectedContactPerson}
              selectedEmailAddress={selectedEmailAddress}
              selectedTelephone={selectedTelephone}
              selectedActiveBankAccount={selectedActiveBankAccount}
              selectedAccountHolder={selectedAccountHolder}
              selectedAccountNum={selectedAccountNum}
              selectedAccountType={selectedAccountType}
              selectedBankName={selectedBankName}
              selectedBranchName={selectedBranchName}
              selectedBranchCode={selectedBranchCode}
              setNewGeneralComment={setNewGeneralComment}
              setNewPhysicalAddress={setNewPhysicalAddress}
              setNewPostalAddress={setNewPostalAddress}
              setNewTown={setNewTown}
              setNewRegNum={setNewRegNum}
              setNewTaxNum={setNewTaxNum}
              setNewVatNum={setNewVatNum}
              setNewRegStatus={setNewRegStatus}
              setNewCompanyWebsite={setNewCompanyWebsite}
              setNewContactPerson={setNewContactPerson}
              setNewEmailAddress={setNewEmailAddress}
              setNewTelephone={setNewTelephone}
              setNewActiveBankAccount={setNewActiveBankAccount}
              setNewAccountHolder={setNewAccountHolder}
              setNewAccountNum={setNewAccountNum}
              setNewAccountType={setNewAccountType}
              setNewBankName={setNewBankName}
              setNewBranchName={setNewBranchName}
              setNewBranchCode={setNewBranchCode}
              uniqueTowns={uniqueTowns}
              uniqueBanks={uniqueBanks}
              uniqueBranches={uniqueBranches}
              uniqueTypes={uniqueTypes}
            />
          </DialogComponent>
        )}
      </div>
      {isMessagesVisible && (
        <div>
          <Modal
            open={isMessagesVisible}
            onClose={handleMessageDialogClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="message_outer_container">
                <h1>{`Chats about ${selectedProjectName} Project`}</h1>
                <div className="message_inner_container">
                  <MessageBox
                    users={allUserData}
                    chatID={selectedID}
                    chatUsers={selectedChatUsers}
                    type="project_id"
                  />
                </div>
                <button
                  className="add-button"
                  onClick={handleMessageDialogClose}
                >
                  <CloseIcon className="send-icon" /> Close
                </button>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
}
export default ComNotActive;
