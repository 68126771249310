import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../app/user";

const WithAuthProtection = (redirectPath) => (WrappedComponent) => {
  const WithAuthProtection = (props) => {
    const jwt = useUserStore((state) => state.jwt);
    const navigate = useNavigate();

    useEffect(() => {
      if (!jwt) return navigate(redirectPath);
    });

    return jwt ? <WrappedComponent {...props} /> : null;
  };

  return WithAuthProtection;
};

export default WithAuthProtection;
