import { useEffect, useState } from "react";
import axios from "../../config/axios";

const useProjectNameDataFetch = () => {
  const [projectNameData, setProjectNameData] = useState([]);
  const [isProjectNameLoaded, setIsProjectNameLoaded] = useState(false);

  // Define the fetchData function for project Name change
  const fetchProjectNameData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/namechange"
      );
      setIsProjectNameLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching project Name data:", error);
      setIsProjectNameLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch project Name change
    fetchProjectNameData().then((InitialData) => {
      setProjectNameData(InitialData);
    });
  }, []);

  // Return an object containing all the fetched data
  return {
    projectNameData,
    isProjectNameLoaded,
  }
};

export default useProjectNameDataFetch;
