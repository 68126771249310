import { useEffect, useState } from "react";
import axios from "../../config/axios";

const useUserDataFetch = () => {
  const [allUserData, setAllUserData] = useState([]);
  const [isAllUserLoaded, setIsAllUserLoaded] = useState(false);

  // Define the fetchData function for all users
  const fetchAllUserData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/user"
      );
      setIsAllUserLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching user data:", error);
      setIsAllUserLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    fetchAllUserData().then((allUserInitialData) => {
      setAllUserData(allUserInitialData);
    });
    // Set data loading flag
  }, []);

  // Return an object containing all the fetched data
  return {
    allUserData,
    isAllUserLoaded,
  }
};

export default useUserDataFetch;
