import React, { useEffect, useState, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  Reorder,
  Resize,
  PdfExport,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import "../../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import "../../../../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
import "../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
import axios from "../../../../config/axios";
import "./Details.css";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import useSmtsDataFetch from "../../../shared/useSmtsDataFetch"; // Import the custom hook
import useUserStore from "../../../../app/user";
import WhereaboutsDialog from "../../../shared/WhereaboutsDialog";
import { toast } from "react-toastify";
import { dateFormatter } from "../../../../utils/formats";

function LastWeekDetails() {
  const user = useUserStore((state) => state.user);
  const { smtsData, isSmtsLoaded } = useSmtsDataFetch();
  const [data, setData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // Initial state
  const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);

  const [selectedID, setSelectedID] = useState("");
  const [selectedFullName, setSelectedFullName] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedNotes, setSelectedNotes] = useState("");
  const [selectedWeekDay, setSelectedWeekDay] = useState("");

  const [newFullName, setNewFullName] = useState("");
  const [newDate, setNewDate] = useState("");
  const [newProvince, setNewProvince] = useState("");
  const [newLocation, setNewLocation] = useState("");
  const [newNotes, setNewNotes] = useState("");
  const [newWeekDay, setNewWeekDay] = useState("");
  const currentDateTime = new Date(); // Get the current date and time
  const newMonth = newDate.toLocaleString("default", { month: "long" });

  useEffect(() => {
    if (isSmtsLoaded) {
      const currentDate = new Date();
      const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)

      // Calculate the date for Sunday of the previous week
      const beginningOfLastWeek = new Date(currentDate);
      beginningOfLastWeek.setDate(currentDate.getDate() - dayOfWeek - 7); // Adjust to previous Sunday

      // Calculate the date for Saturday of the previous week
      const endOfLastWeek = new Date(beginningOfLastWeek);
      endOfLastWeek.setDate(beginningOfLastWeek.getDate() + 6); // Adjust to Saturday

      // Filter smtsData for items within the previous week (Sunday to Saturday)
      const finalSmtsData = smtsData.filter((item) => {
        const itemDate = new Date(item?.Date);
        return itemDate >= beginningOfLastWeek && itemDate <= endOfLastWeek;
      });

      // Sort the final data by date
      finalSmtsData.sort((a, b) => new Date(a.Date) - new Date(b.Date));

      setData(finalSmtsData);
      setIsDataLoaded(true);
    }
  }, [smtsData, isSmtsLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded]);

  const uniqueLocations = [
    ...new Set(smtsData?.map((item) => (item.Location ? item.Location : ""))),
  ];

  const newRecordData = {
    FullName: newFullName,
    Date: newDate,
    Province: newProvince,
    Location: newLocation,
    Notes: newNotes,
    WeekDay: newWeekDay,
    Month: newMonth,
    LastUpdated: currentDateTime,
  };

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedFullName(args.data.FullName);
    setSelectedDate(args.data.Date);
    setSelectedProvince(args.data.Province);
    setSelectedLocation(args.data.Location);
    setSelectedNotes(args.data.Notes);
    setSelectedWeekDay(args.data.WeekDay);

    const yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 2);
    const selectedItemDate = new Date(args.data.Date);
    if (
      user.FullName === args.data.FullName &&
      selectedItemDate >= yesterdayDate
    ) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };

  const handleAddButtonClick = () => {
    setIsAddDialogVisible(true);
    setSelectedID("");
    setSelectedFullName(user.FullName);
    setSelectedDate("");
    setSelectedProvince("");
    setSelectedLocation("");
    setSelectedNotes("");
    setSelectedWeekDay("");
    if (GridRef.current) {
      GridRef.current.clearSelection();
    }
    setNewFullName(user.FullName);
    setNewDate("");
    setNewProvince("");
    setNewLocation("");
    setNewNotes("");
    setNewWeekDay("");
    setIsButtonEnabled(false);
  };

  const handleEditButtonClick = () => {
    if (user.FullName === selectedFullName && selectedID !== "") {
      setIsEditDialogVisible(true);
    }
    setNewFullName(selectedFullName);
    setNewDate(selectedDate);
    setNewProvince(selectedProvince);
    setNewLocation(selectedLocation);
    setNewNotes(selectedNotes);
    setNewWeekDay(selectedWeekDay);
  };

  const handleAddNewButton = async () => {
    // Make a POST request to add the project record
    const loadingToastId = toast.loading("Please wait...");
    try {
      const ItemResponse = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/smts",
        newRecordData
      );
      console.log("Project response:", ItemResponse.data);
      if (ItemResponse.data.status === "success") {
        toast.success("The record was created successfully.");
        const UpdatedResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/smts"
        );
        const records = UpdatedResponse.data.items;
        if (records) {
          const currentDate = new Date();
          const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)

          // Calculate the date for Sunday of the previous week
          const beginningOfLastWeek = new Date(currentDate);
          beginningOfLastWeek.setDate(currentDate.getDate() - dayOfWeek - 7); // Adjust to previous Sunday

          // Calculate the date for Saturday of the previous week
          const endOfLastWeek = new Date(beginningOfLastWeek);
          endOfLastWeek.setDate(beginningOfLastWeek.getDate() + 6); // Adjust to Saturday

          // Filter smtsData for items within the previous week (Sunday to Saturday)
          const finalSmtsData = smtsData.filter((item) => {
            const itemDate = new Date(item?.Date);
            return itemDate >= beginningOfLastWeek && itemDate <= endOfLastWeek;
          });

          // Sort the final data by date
          finalSmtsData.sort((a, b) => new Date(a.Date) - new Date(b.Date));
          setData(finalSmtsData);
        }
      } else {
        console.error("Error adding record:", ItemResponse.data.message);
        toast.error("Error adding the record");
      }
    } catch (error) {
      console.error("Error adding Record:", error);
      toast.error("Error adding the record");
    }
    toast.dismiss(loadingToastId);
  };

  const handleEditExistingButton = async () => {
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    try {
      const ItemResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/smts/${numericSelectedID}`,
        {
          FullName: newFullName,
          Date: newDate,
          Province: newProvince,
          Location: newLocation,
          Notes: newNotes,
          WeekDay: newWeekDay,
          Month: newMonth,
          LastUpdated: currentDateTime,
        }
      );
      console.log("Project response:", ItemResponse.data);
      if (ItemResponse.data.status === "success") {
        toast.success("The record was updated successfully.");
        try {
          const UpdatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/api/smts"
          );
          const records = UpdatedResponse.data.items;
          if (records) {
            const currentDate = new Date();
            const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)

            // Calculate the date for Sunday of the previous week
            const beginningOfLastWeek = new Date(currentDate);
            beginningOfLastWeek.setDate(currentDate.getDate() - dayOfWeek - 7); // Adjust to previous Sunday

            // Calculate the date for Saturday of the previous week
            const endOfLastWeek = new Date(beginningOfLastWeek);
            endOfLastWeek.setDate(beginningOfLastWeek.getDate() + 6); // Adjust to Saturday

            // Filter smtsData for items within the previous week (Sunday to Saturday)
            const finalSmtsData = smtsData.filter((item) => {
              const itemDate = new Date(item?.Date);
              return (
                itemDate >= beginningOfLastWeek && itemDate <= endOfLastWeek
              );
            });

            // Sort the final data by date
            finalSmtsData.sort((a, b) => new Date(a.Date) - new Date(b.Date));
            setData(finalSmtsData);
          }
        } catch (error) {
          setData([]);
        }
      } else {
        console.error("Error updating record:", ItemResponse.data.message);
        toast.error("Error updating the record");
      }
    } catch (error) {
      console.error("Error updating Record:", error);
      toast.error("Error updating the record");
    }
    toast.dismiss(loadingToastId);
  };

  const handleDeleteRecordButton = async (selectedID) => {
    const loadingToastId = toast.loading("Please wait...");
    const numericSelectedID = parseInt(selectedID);
    try {
      const deleteResponse = await axios.delete(
        process.env.REACT_APP_SERVER_URL + `/api/smts/${numericSelectedID}`
      );
      console.log("Delete response:", deleteResponse.data);
      if (deleteResponse.data.status === "success") {
        toast.success("The record was deleted successfully.");
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/api/smts"
          );
          const records = updatedResponse.data.items;
          if (records) {
            const currentDate = new Date();
            const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)

            // Calculate the date for Sunday of the previous week
            const beginningOfLastWeek = new Date(currentDate);
            beginningOfLastWeek.setDate(currentDate.getDate() - dayOfWeek - 7); // Adjust to previous Sunday

            // Calculate the date for Saturday of the previous week
            const endOfLastWeek = new Date(beginningOfLastWeek);
            endOfLastWeek.setDate(beginningOfLastWeek.getDate() + 6); // Adjust to Saturday

            // Filter smtsData for items within the previous week (Sunday to Saturday)
            const finalSmtsData = smtsData.filter((item) => {
              const itemDate = new Date(item?.Date);
              return (
                itemDate >= beginningOfLastWeek && itemDate <= endOfLastWeek
              );
            });

            // Sort the final data by date
            finalSmtsData.sort((a, b) => new Date(a.Date) - new Date(b.Date));
            setData(finalSmtsData);
          }
        } catch (error) {
          setData([]);
        }
      } else {
        console.error("Error deleting record:", deleteResponse.data.message);
        toast.error("Error deleting the record");
      }
    } catch (error) {
      console.error("Error deleting record:", error);
      toast.error("Error deleting the record");
    }
    toast.dismiss(loadingToastId);
  };

  const currentTitleDate = dateFormatter(new Date());
  const handleExcelButtonClick = () => {
    if (GridRef.current && GridRef.current.excelExport) {
      const exportProperties = {
        pageOrientation: "Landscape",
        fileName:
          "List Printed by " +
          user.FullName +
          " on " +
          currentTitleDate +
          ".xlsx",
      };
      GridRef.current.excelExport(exportProperties);
    }
  };

  const handlePdfButtonClick = () => {};

  const handleAddDialogClose = () => {
    setIsAddDialogVisible(false);
  };

  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };

  let addButtons = [
    {
      buttonModel: {
        content: "Add",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleAddNewButton(
          newFullName,
          newDate,
          newProvince,
          newLocation,
          newNotes,
          newWeekDay
        );
        setIsAddDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsAddDialogVisible(false);
      },
    },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-flat",
        isPrimary: false,
      },
      click: () => {
        handleEditExistingButton(
          selectedID,
          selectedFullName,
          selectedDate,
          selectedProvince,
          selectedLocation,
          selectedNotes,
          selectedWeekDay
        );
        setIsEditDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Delete",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleDeleteRecordButton(selectedID);
        setIsEditDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];
  const loadingIndicator = { indicatorType: "Spinner" };

  const renderButtons = () => {
    return (
      <div className="button-container-whereabouts">
        <div className="button-container-whereabouts-subA1">
          <button
            className="add-button-whereabouts"
            onClick={handleAddButtonClick}
          >
            <AddIcon className="but-icon-whereabouts" /> Add{" "}
          </button>
          <button
            className={`edit-button-whereabouts ${
              isButtonEnabled ? "" : "disabled-button"
            }`}
            onClick={handleEditButtonClick}
            disabled={!isButtonEnabled}
          >
            <EditIcon className="but-icon-whereabouts" /> Edit{" "}
          </button>
          <button
            className={`delete-button-whereabouts ${
              isButtonEnabled ? "" : "disabled-button"
            }`}
            onClick={handleEditButtonClick}
            disabled={!isButtonEnabled}
          >
            <DeleteIcon className="but-icon-whereabouts" /> Delete{" "}
          </button>
        </div>
        <div className="button-container-subA2">
          <button className="add-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          {/* <button className="edit-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button> */}
        </div>
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  return (
    <div className="this-week-whereabouts">
      <h3>Where was Everyone Last Week?</h3>
      <br />
      <div className="feed-line-this-week-whereabouts">
        {isDataLoaded && (
          <GridComponent
            dataSource={data}
            childMapping="items"
            ref={GridRef}
            classClass="custom-treegrid-class"
            height="600"
            allowReordering={true}
            allowResizing={true}
            allowExcelExport={true}
            allowPdfExport={true}
            allowPaging={true}
            allowSorting={true}
            allowFiltering={true}
            showColumnChooser={true}
            filterSettings={FilterOptions}
            pageSettings={pageSettings}
            editSettings={editOptions}
            toolbar={updatedToolbarOptions}
            loadingIndicator={loadingIndicator}
            allowSelection={true}
            rowSelected={handleRowSelect}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="WeekDay"
                headerText="Week Day"
                width="100"
                textAlign="Left"
              />
              <ColumnDirective
                field="Date"
                headerText="Date"
                width="100"
                format="yyyy/MM/dd"
                textAlign="Left"
                type="date"
                allowFiltering={false}
              />
              <ColumnDirective
                field="FullName"
                headerText="Full Name"
                width="150"
                textAlign="Left"
              />
              <ColumnDirective
                field="Province"
                headerText="Province"
                width="150"
                textAlign="Left"
              />
              <ColumnDirective
                field="Location"
                headerText="Location"
                width="160"
                textAlign="Left"
              />
              <ColumnDirective
                field="Notes"
                headerText="Notes"
                width="320"
                textAlign="Left"
              />
            </ColumnsDirective>
            <Inject
              services={[
                Page,
                Sort,
                Filter,
                Edit,
                Toolbar,
                ColumnChooser,
                Reorder,
                Resize,
                PdfExport,
                ExcelExport,
              ]}
            />
          </GridComponent>
        )}
      </div>
      <div>
        {isAddDialogVisible && (
          <DialogComponent
            visible={isAddDialogVisible}
            width="700px"
            height="650px"
            header="Add your Whereabout"
            showCloseIcon={true}
            close={handleAddDialogClose}
            buttons={addButtons}
          >
            <WhereaboutsDialog
              selectedFullName={selectedFullName}
              selectedWeekDay={selectedWeekDay}
              selectedDate={selectedDate}
              uniqueLocations={uniqueLocations}
              setNewFullName={setNewFullName}
              setNewDate={setNewDate}
              setNewProvince={setNewProvince}
              setNewLocation={setNewLocation}
              setNewNotes={setNewNotes}
              setNewWeekDay={setNewWeekDay}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="700px"
            height="650px"
            header="Edit/Delete your Whereabout"
            showCloseIcon={true}
            close={handleEditDialogClose}
            buttons={editButtons}
          >
            <WhereaboutsDialog
              selectedID={selectedID}
              selectedFullName={selectedFullName}
              selectedDate={selectedDate}
              selectedProvince={selectedProvince}
              selectedLocation={selectedLocation}
              selectedNotes={selectedNotes}
              selectedWeekDay={selectedWeekDay}
              uniqueLocations={uniqueLocations}
              setNewFullName={setNewFullName}
              setNewDate={setNewDate}
              setNewProvince={setNewProvince}
              setNewLocation={setNewLocation}
              setNewNotes={setNewNotes}
              setNewWeekDay={setNewWeekDay}
            />
          </DialogComponent>
        )}
      </div>
    </div>
  );
}
export default LastWeekDetails;
