import React from "react";
import ToDoProjects from "./identification/ToDoProjects";
import MyProjects from "./identification/MyProjects";
import AllProjects from "./identification/AllProjects";
import ToDoTasks from "./identification/ToDoTasks";
import ToDoAttachments from "./identification/ToDoAttachments";
import '../../../shared/PageTabs.css';
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";

function Identification() {
  let headerText = [
    { text: "ToDo List - Projects" },
    { text: "ToDo List - Tasks" },
    { text: "ToDo List - Attachments" },
    { text: "My Projects" },
    { text: "All Projects" },
  ];

  return (
    <div className="main-tab-container">
      <TabComponent>
        <TabItemsDirective>
          <TabItemDirective header={headerText[0]} content={ToDoProjects} />
          <TabItemDirective header={headerText[1]} content={ToDoTasks} />
          <TabItemDirective header={headerText[2]} content={ToDoAttachments} />
          <TabItemDirective header={headerText[3]} content={MyProjects} />
          <TabItemDirective header={headerText[4]} content={AllProjects} />
        </TabItemsDirective>
      </TabComponent>
    </div>
  );
}

export default Identification;
