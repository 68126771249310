import { useEffect, useState } from "react";
import axios from "../../config/axios";

const useOtherDataFetch = () => {
  const [paymentData, setPaymentData] = useState([]);
  const [beneficiaryData, setBeneficiaryData] = useState([]);
  const [attachmentData, setAttachmentData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [isPaymentLoaded, setIsPaymentLoaded] = useState(false);
  const [isBeneficiaryLoaded, setIsBeneficiaryLoaded] = useState(false);
  const [isAttachmentLoaded, setIsAttachmentLoaded] = useState(false);
  const [isTaskLoaded, setIsTaskLoaded] = useState(false);

  // Define the fetchData function for beneficiaries
  const fetchBeneficiaryData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/beneficiary"
      );
      setIsBeneficiaryLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching beneficiary data:", error);
      setIsBeneficiaryLoaded(true);
      return [];
    }
  };

  // Define the fetchData function for attachments
  const fetchAttachmentData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/attachment"
      );
      setIsAttachmentLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching attachment data:", error);
      setIsAttachmentLoaded(true);
      return [];
    }
  };

  // Define the fetchData function for payments
  const fetchPaymentData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/payment"
      );
      setIsPaymentLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching payment data:", error);
      setIsPaymentLoaded(true);
      return [];
    }
  };

  // Define the fetchData function for tasks
  const fetchTaskData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/task"
      );
      setIsTaskLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching task data:", error);
      setIsTaskLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch beneficiary data
    fetchBeneficiaryData().then((beneficiaryInitialData) => {
      setBeneficiaryData(beneficiaryInitialData);
    });

    // Fetch attachment data
    fetchAttachmentData().then((attachmentInitialData) => {
      setAttachmentData(attachmentInitialData);
    });

    // Fetch payment data
    fetchPaymentData().then((paymentInitialData) => {
      setPaymentData(paymentInitialData);
    });

    // Fetch task data
    fetchTaskData().then((taskInitialData) => {
      setTaskData(taskInitialData);
    });
  }, []);

  // Return an object containing all the fetched data
  return {
    paymentData,
    beneficiaryData,
    attachmentData,
    taskData,
    isAttachmentLoaded,
    isBeneficiaryLoaded,
    isPaymentLoaded,
    isTaskLoaded,
  };
};

export default useOtherDataFetch;
